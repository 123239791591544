export const ModalEvidence = ({ onClose, title, rowData }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center overflow-auto mx-auto">
      {/* Fondo oscurecido */}
      <div
        onClick={onClose} // Llama a la función onClose al hacer clic
        className="fixed h-screen z-40 inset-0 bg-gray-900 opacity-30 top-0"
      ></div>
      <div className="bg-white rounded-lg p-8 z-50 overflow-y-auto">
        <div className="flex justify-end">
          <button
            onClick={onClose} // Llama a la función onClose al hacer clic
            className="text-gray-500 hover:text-gray-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="flex justify-center items-center">
          <h2 className="text-2xl font-semibold text-[#004876]">
            {title}
          </h2>
        </div>

        <div className="w-auto flex justify-center">
          <div className="max-w-full border border-gray-300 shadow-lg" style={{ height: "70vh" }}>
            <img src={rowData} alt="Evidencia" style={{ maxHeight: "100%" }} />
          </div>
        </div>
      </div>
    </div>
  );
};
