import React, { useEffect, useState, useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField, MenuItem, IconButton, useMediaQuery, Popover, } from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { Close } from "@mui/icons-material";
import { es } from "date-fns/locale";

import { AcceptButton } from "../Buttons/AcceptButton";
import { CancelButton } from "../Buttons/CancelButton";

export const FiltersModal = ({
  handleClose,
  onClickFilter,
  onClickResetFilter,
  dates,
  errors,
  fields = [],
  view,
  date,
}) => {
  const today = new Date();
  const phone = useMediaQuery("(max-width:480px)");
  const { error, errorNullStart, errorNullEnd } = errors;

  const handleChangeStart = (newValue) => {
    dates.setStart(newValue);
    formik.setFieldValue("startDate", newValue);
  };

  const handleChangeEnd = (newValue) => {
    dates.setEnd(newValue);
    formik.setFieldValue("endDate", newValue);
  };

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  const formatDate = (date) => {
    if (!date) return '';
    const d = new Date(date);
    const month = `${d.getMonth() + 1}`.padStart(2, '0');
    const day = `${d.getDate()}`.padStart(2, '0');
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const formik = useFormik({
    initialValues: {
      startDate: dates.start,
      endDate: dates.end,
    },
    validationSchema: Yup.object().shape(date && {
      startDate: Yup.date()
        .nullable(true)
        .notRequired()
        .when("endDate", {
          is: (endDate) => endDate !== null,
          then: Yup.date()
            .nullable(true)
            .required("Ingresa una fecha inicio"),
          otherwise: Yup.date().notRequired().nullable(true),
        }),
      endDate: Yup.date()
        .nullable(true)
        .notRequired()
        .when("startDate", {
          is: (startDate) => startDate !== null,
          then: Yup.date()
            .nullable(true)
            .required("Ingresa una fecha fin")
            .min(Yup.ref("startDate"), "No puede ser menor que la fecha inicio"),
          otherwise: Yup.date().notRequired().nullable(true),
        })
        .min(Yup.ref("startDate"), "No puede ser menor que la fecha inicio"),
    }, ["endDate", "startDate"]),
    onSubmit: (values) => {
      const formattedValues = {
        ...values,
        startDate: formatDate(values.startDate),
        endDate: formatDate(values.endDate),
      };
      onClickFilter();
      // RequestData(formattedValues);
    },
  });

  return (
    <div className="p-2">
      <form onSubmit={formik.handleSubmit}>
        <div className="d-flex justify-content-between align-items-center">
          <p id="modal-modal-description" className="ds-blue-font h4 m-0">
            Filtrar:
          </p>
          <IconButton onClick={() => handleClose()}>
            <Close fontSize="large" />
          </IconButton>
        </div>

        <div className={`mt-1 ${phone ? "px-1" : "px-4"}}`}>
          {date &&
            <div className={`d-flex flex-column mb-2 `}>
              <div className={`d-flex align-items-center ds-blue-font`} style={{ minWidth: "100px" }}>
                <label>Fechas: </label>
              </div>
              <div className={`d-flex flex-column mb-3 `}>
                <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    maxDate={today}
                    label={"Fecha inicio"}
                    inputFormat="yyyy-MM-dd"
                    value={dates.start}
                    onChange={handleChangeStart}
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        error={
                          formik.touched.startDate && formik.errors.startDate
                        }
                        helperText={
                          formik.touched.startDate && formik.errors.startDate ? (
                            <div>{formik.errors.startDate}</div>
                          ) : null
                        }
                        onKeyDown={onKeyDown}
                      />}
                  />
                </LocalizationProvider>
                <div className="text-danger xs-font"></div>
              </div>

              <div className="d-flex flex-column">
                <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    maxDate={today}
                    label={"Fecha fin"}
                    inputFormat="yyyy-MM-dd"
                    value={dates.end}
                    onChange={handleChangeEnd}
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        error={
                          formik.touched.startDate && formik.errors.endDate
                        }
                        helperText={
                          formik.touched.endDate && formik.errors.endDate ? (
                            <div>{formik.errors.endDate}</div>
                          ) : null
                        }
                        onKeyDown={onKeyDown}
                      />}
                  />
                </LocalizationProvider>
                <div className="text-danger xs-font">
                  {error ? "No puede ser menor a la fecha inicio" : null}
                  {errorNullEnd ? "Elige una fecha fin" : null}
                </div>
              </div>
            </div>
          }

          {fields.map((el) => (
            <div style={{ minWidth: 250 }} className={`d-flex mb-2 flex-column`} key={el.type}>
              <div className={`d-flex align-items-center mb-1 ds-blue-font`} style={{ minWidth: "100px" }}>
                <p className="no-wrap h6" htmlFor={el.type}>{`${el.type}:`}</p>
              </div>
              <div className="d-flex align-items-center w-100">
                <div className="d-flex flex-column w-100">
                  <TextField
                    select
                    size="small"
                    label={el.type}
                    value={el.value}
                    name={el.type}
                    id={el.type}
                    onChange={(e) => el.handleOnChange(e)}
                    fullWidth
                  >
                    {el.array.map((option) => (
                      <MenuItem key={option.id} value={option[el.id]}>
                        {option[el.prop]}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className={`d-flex mt-1 ${phone ? "flex-column " : "justify-content-between"}`}>
          <CancelButton text={"Limpiar"} onClick={() => { onClickResetFilter() }} width={phone ? "100%" : "48%"} />
          <AcceptButton text={"Filtrar"} type={"text"} width={phone ? "100%" : "48%"} phone={phone} />
        </div>
      </form>
    </div>
  );
};