import React from "react";
import { TablePagination } from "@mui/material";

export const TablesPagination = ({ total, setPage, page }) => {

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  return (
    <div className="d-flex w-100 justify-content-between">

      <TablePagination
        sx={{
          ".MuiTablePagination-displayedRows": {
            margin: 0, /*Remueve margin de paginacion */
            color: "#122028",
          },
          ".MuiButtonBase-root:hover": {
            backgroundColor: "#122028"
          },
          ".MuiIconButton-colorInherit": {
            color: "#122028"
          },
          ".MuiIconButton-colorInherit:hover": {
            color: "#fff"
          }

        }}
        labelDisplayedRows={
          ({ from, to, count }) => {
            return '' + from + '-' + to + ' de ' + count
          }
        }
        className="d-flex justify-content-start align-items-center"
        component="div"
        count={total}
        rowsPerPageOptions={[]} /*Para eliminar rows per page*/
        rowsPerPage={10}
        page={page}
        onPageChange={handleChangePage}
      />


      <div className="d-flex align-items-center pr-3">
      <span style={{ color: "#122028", fontSize: 15 }} className="ds-blue-font small-font">{page + 1}</span>
      </div>
    </div>
  );
};
