import { HttpRequest } from './HttpRequest';
import { getPath } from '../helpers/getHost';

export class API {
    constructor() {
        this.http = new HttpRequest()
        this.host = getPath()
        this.token = localStorage.getItem("token");
        this.config = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Token ${this.token}`
            }
        }
    }
}
