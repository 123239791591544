import React from "react";
import { Toolbar, Typography, IconButton, useMediaQuery } from "@mui/material";
import { Menu, Close } from "@mui/icons-material";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
// import { BreadcrumbsMenu } from "../components/Breadcrumb/BreadcrumbsMenu";
import { Actives } from "../pages/Guides/Actives";
import { Historical } from "../pages/Guides/Historical";
import { AccountProfile } from "../pages/Account/AccountProfile";
import { nameSectionsUser } from "../utils/Menu/NameSections";
import { DetailsRoutesOp } from "../pages/Details/DetailsRoutesOp";

export const LayoutRouting = ({ handleDrawerToggle, }) => {
    const { pathname } = useLocation();
    const phone = useMediaQuery("(max-width:600px)");

    const section = nameSectionsUser.find((el) => el.link == pathname);

    return (
        <>
            <Toolbar
                sx={{
                    bgcolor: "white",
                    color: "#122028",
                    height: phone ? "50px" : "65px",
                    boxShadow: "1px 0 5px 1px #d0d0d0"
                }}
                className={`w-100 d-flex  ${phone ? "" : "flex-column justify-content-center "
                    }`}
            >
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, display: { sm: "none" } }}
                >
                    <Menu />
                </IconButton>

                <div className={`w-100 ${phone ? "text-center" : ""}`}>
                    <Typography sx={{ fontSize: 20 }}>
                        {section != undefined && section.name.toUpperCase()}
                    </Typography>
                </div>
            </Toolbar>

            <div
                className={`mt-1 w-100 d-flex ${phone
                        ? "justify-content-center"
                        : "justify-content-start ml-3 pl-4 mb-2"
                    }`}
            >
                {/* <BreadcrumbsMenu namesSections={nameSections} rol={"hfm"} /> */}
            </div>

            <div className="w-100">
                <Routes>
                    {/* Todas las rutas inexistentes te redirigen a ruta principal */}
                    <Route path="*" element={<Navigate to="guides/active/" replace />} />
                    <Route path="guides/active/" element={<Actives />} />
                    <Route path="guides/active/details" element={<DetailsRoutesOp />} />

                    <Route path="guides/historical/" element={<Historical />} />
                   
                    <Route path="account/" element={<AccountProfile />} />

                </Routes>
            </div>
        </>
    );
};
