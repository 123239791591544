import React, { useState } from "react";
import { Typography, Box, useMediaQuery, TextField, InputAdornment, IconButton, Button } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AcceptButton } from "../Buttons/AcceptButton";
import { CancelButton } from "../Buttons/CancelButton";
import { toast } from "react-toastify";


export const ResetPasswordModal = ({ onClick, emailEmploye, handleClose }) => {
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);

  const phone = useMediaQuery("(max-width:600px)");
  const tablet = useMediaQuery("(max-width:900px)");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: phone ? "90%" : tablet ? "56%" : "35%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
    borderRadius: 2,
  };

  const handleShowPass = () => {
    setShowPass(!showPass);
  };

  const handleShowConfirmPass = () => {
    setShowConfirmPass(!showConfirmPass);
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .required("Campo Obligatorio")
      .max(30, "Demasiado largo")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Mínimo 8 caracteres, una mayúscula, una minúscula, un número y un carácter especial"
      ),
    confirmPassword: Yup.string()
      .required("Campo Obligatorio")
      .oneOf([Yup.ref('password'), null], "Las contraseñas deben coincidir"),
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onClick(emailEmploye, values.password);
      handleClose();
    },
  });

  return (
    <Box sx={style}>
      <Typography variant="h6" mb={2}>
        Cambiar contraseña
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          size="small"
          label="Contraseña"
          variant="outlined"
          fullWidth
          type={showPass ? "text" : "password"}
          {...formik.getFieldProps('password')}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleShowPass}
                  edge="end"
                >
                  {showPass ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          margin="normal"
        />
        <TextField
          size="small"
          label="Confirmar Contraseña"
          variant="outlined"
          fullWidth
          type={showConfirmPass ? "text" : "password"}
          {...formik.getFieldProps('confirmPassword')}
          error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
          helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleShowConfirmPass}
                  edge="end"
                >
                  {showConfirmPass ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          margin="normal"
        />
        <Box mt={2} display="flex" justifyContent="space-between">
          <AcceptButton
            text={"Aceptar"}
            // text={"Agregar"}
            type={"submit"}
            width={phone ? "100%" : ""}
          />
          <CancelButton
              text={"Cancelar"}
              onClick={
                handleClose
              }
              width={phone ? "100%" : ""}
          />
        </Box>
      </form>
    </Box>
  );
};
