import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  Typography,
  Box,
  useMediaQuery,
  TextField,
  IconButton,
  MenuItem,
  Backdrop,
  Modal,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { AcceptButton } from "../Buttons/AcceptButton";
import { useNavigate } from "react-router-dom";
import { AdminAPI } from "../../api/admin";
import { SuccessModal } from "./SuccessModal";
import { ErrorModal } from "./ErrorModal";

export const AssignOperativeModal = ({
  handleClose,
  GetVehiclesList,
  vehicle,
}) => {
  const phone = useMediaQuery("(max-width:480px)");
  const tablet = useMediaQuery("(max-width:900px)");
  const [operatives, setOperatives] = useState([]);
  const [openBD, setOpenBD] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const navigate = useNavigate();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: phone ? "90%" : tablet ? "56%" : "37%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: "5px 5px 15px 5px",
    borderRadius: 2,
  };

  useEffect(() => {
    GetPartnersList();
  }, []);

  const GetPartnersList = async () => {
    let data = new FormData();
    data.append("available", true);

    try {
      const api = new AdminAPI();
      const response = await api.getPartners(data);

      setOperatives(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const AssignVehicle = async (obj) => {
    setOpenBD(true);
    let data = new FormData();
    data.append("partnerId", obj.operative);
    data.append("vehicleId", vehicle.idVehicle);

    try {
      const api = new AdminAPI();
      const response = await api.assignVehicleToPartner(data);

      setOpenSuccess(true);
      setOpenBD(false);
      GetVehiclesList();
    } catch (error) {
      setOpenBD(false);
      setOpenError(true);
    }
  };

  const formik = useFormik({
    initialValues: {
      operative: "",
    },
    validationSchema: Yup.object({
      operative: "",
    }),

    onSubmit: (values) => {
      const obj = {
        ...values,
      };
      AssignVehicle(obj);
    },
  });

  return (
    <>
      <Box sx={style}>
        <div className=" d-flex justify-content-between align-items-center">
          <div></div>
          <Typography variant="h5" className="font-weight-bold">
            Asignar operador
          </Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </div>

        <form onSubmit={formik.handleSubmit}>
          <div
            className={`mt-2 d-flex mb-3 flex-column ${
              phone ? "px-2 " : "px-2"
            }`}
          >
            <div>
              <Typography>
                <strong>Unidad:</strong> {vehicle.brand}
              </Typography>
              <Typography>
                <strong>Placas:</strong> {vehicle.licensePlate}
              </Typography>
            </div>
            <div
              className={`mt-2 d-flex align-items-center mr-2 ${
                phone && "mb-2"
              }`}
            >
              <p className="h6 te" htmlFor="operative">{`Operadores: *`}</p>
            </div>
            <div className="d-flex align-items-center w-100">
              <div className="d-flex flex-column w-100">
                <TextField
                  select
                  error={formik.touched.operative && formik.errors.operative}
                  size="small"
                  label={"Operadores"}
                  variant="outlined"
                  fullWidth
                  onChange={formik.handleChange}
                  value={formik.values.operative}
                  name={"operative"}
                  id={"operative"}
                  helperText={
                    formik.touched.operative && formik.errors.operative ? (
                      <div>{formik.errors.operative}</div>
                    ) : null
                  }
                >
                  {operatives.length == 0 ? (
                    <MenuItem>Sin operadores disponibles</MenuItem>
                  ) : (
                    operatives.map((op, i) => (
                      <MenuItem key={i} value={op.idPartner}>
                        {op.name} {op.lastName}
                      </MenuItem>
                    ))
                  )}
                </TextField>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center mt-2">
            <AcceptButton
              type={"submit"}
              text={"Asignar"}
              width={phone ? "90%" : "40%"}
            />
          </div>
        </form>
      </Box>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBD}
      >
        <span className="loader"></span>
      </Backdrop>

      <Modal open={openSuccess} onClose={() => console.log("Cerrando")}>
        <SuccessModal
          handleClose={handleClose}
          text={"Operador asignado con éxito."}
        />
      </Modal>
      <Modal open={openError} onClose={() => console.log("Cerrando")}>
        <ErrorModal
          text={`No se pudo asignar al operador.`}
          handleClose={() => setOpenError(false)}
        />
      </Modal>
    </>
  );
};
