
import {
    List,
    MenuItem,
    Popover,
    styled,
  } from "@mui/material";
  
  export const StyledPopover = styled(Popover)(({ theme, selected }) => ({
      "& .MuiPaper-root": {
        backgroundColor: "#122028",
        borderRadius: "15px",
        boxShadow:
          " 0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)",
        marginLeft: 3,
        marginTop: 3,
        color: selected ? "#fbae08" : "#fff",
      },
    }));
    
  export const StyledMenuItem = styled(MenuItem)(({ theme, open, selected }) => ({
      "&.MuiMenuItem-root": {
        color: selected ? "#fbae08" : "#fff",
        justifyContent: open ? "center" : "between",
        borderRadius: "15px",
        backgroundColor: selected && "#49596a",
        minHeight: 0,
        boxShadow:
          selected &&
          "0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)",
      },
      "&.MuiMenuItem-root:hover": {
        backgroundColor: "#49596a",
        boxShadow:
          "0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)",
      },
    }));
  
    export const StyledList = styled(List)(() => ({
      '& .MuiListItemIcon-root': {
        minWidth: 0,
        color: "inherit",
        marginRight: 0,
      },
      '& .MuiListItemText-primary': {
        fontSize: "15px",
        marginRight: 1,
        paddingY: 0
      }
    }));