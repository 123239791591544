import { useState, useEffect } from "react";
import { Marker as LeafletMarker, useMap } from "react-leaflet";
import L from "leaflet";

export const Marker = (options) => {
  const [marker, setMarker] = useState(null);
  const map = useMap();

  // Definir iconos personalizados
  const shipmentIcon = L.divIcon({
    className: "custom-icon",
    html: "<div style='background-color:#314184; width: 32px; height: 32px; border-radius: 50%; display: flex; align-items: center; justify-content: center; color: white;'>📦</div>",
    iconSize: [32, 32],
  });

  const selectedShipmentIcon = L.divIcon({
    className: "custom-selected-icon",
    html: "<div style='background-color:#00387B; width: 32px; height: 32px; border-radius: 50%; display: flex; align-items: center; justify-content: center;'>📦</div>",
    iconSize: [32, 32],
  });

  // Definir un icono genérico
  const genericIcon = L.icon({
    iconUrl: 'https://cdn-icons-png.flaticon.com/512/64/64113.png', // Puedes usar una URL de tu elección
    iconSize: [32, 32], // Tamaño del icono
    iconAnchor: [16, 32], // Punto de anclaje (mitad inferior del icono)
    popupAnchor: [0, -32], // Punto de anclaje para los popups
  });

  useEffect(() => {
    if (!marker) {
      const newMarker = L.marker(options.position, {
        draggable: options.draggable || false,
        icon:
          options.markerType === "shipmentMarker"
            ? shipmentIcon
            : genericIcon, // Usa el icono genérico si no es shipmentMarker
      }).addTo(map);

      setMarker(newMarker);

      // Asignar eventos al marcador
      if (options.clickable) {
        newMarker.on("click", () => {
          if (options.markerType === "shipmentMarker") {
            // Mostrar información cuando se clickea
            newMarker.bindPopup(newMarker.getTitle || "Marcador de envío").openPopup();
            options.handleChange(true, options.instance);
          } else {
            newMarker.bindPopup("Marcador genérico").openPopup();
            options.handleChange(false, options.instance);
          }
        });
      }

      if (options.draggable) {
        newMarker.on("dragend", (e) => {
          options.getAddress(e.target.getLatLng());
        });
      }
    }

    // Actualizar las opciones del marcador
    if (marker) {
      marker.setLatLng(options.position);

      if (options.markerType === "shipmentMarker") {
        if (options.selected) {
          marker.setIcon(selectedShipmentIcon);
        } else {
          marker.setIcon(shipmentIcon);
        }
      } else {
        marker.setIcon(genericIcon); // Actualizar el marcador con el icono genérico
      }
    }

    // Limpiar el marcador al desmontar el componente
    return () => {
      if (marker) {
        map.removeLayer(marker);
      }
    };
  }, [marker, map, options]);

  useEffect(() => {
    if (marker) {
      marker.setLatLng(options.position);
    }
  }, [options.position]);

  return null;
};
