import React from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import App from '../App'
import AppAdmin from '../Admin/AppAdmin'
import { Login } from '../pages/Login/Login'
import RequireAuth from './RequireAuth'
import { useAuthContext } from '../context/AuthProvider'
import { Integration } from '../Admin/pages/Integration/Integration'
import { TrackingSearch } from '../Admin/pages/Integration/TrackingSearch'

export const Router = () => {
    const { token } = useAuthContext();
    const path = localStorage.getItem("pathName");

    return (

        <Routes>
            {/*Cuando existe el token y la ruta no, redirige a la ruta principal, de lo contrario redirige a "/" */}
            {token ? <Route path="*" element={<Navigate to={path} />} /> : <Route path="*" element={<Navigate to="/" replace />} />}
            {/*Si existe el token y la ruta es "/" debe retornar a la ruta principal, de lo contrario navegar al login */}
            {token ? <Route path="/" element={<Navigate to={path} />} /> : <Route path="/" element={<Login />} />}
            <Route path="user/*" element={<RequireAuth><App /></RequireAuth>} />
            <Route path="admin/*" element={<RequireAuth><AppAdmin /></RequireAuth>} />
            <Route path="admin/integration/" element={<RequireAuth><Integration /></RequireAuth>} />
            <Route path="search/" element={<TrackingSearch />} />


        </Routes>
    )
}