import React, { useState, useEffect } from "react";
import {
  Tooltip,
  Typography,
  useMediaQuery,
  TextField,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  Grid,
  Backdrop,
  IconButton,
} from "@mui/material";
import { CreateRouteConfirmationModal } from "../../components/Modal/CreateRouteConfirmationModal";
import { AcceptButton } from "../../components/Buttons/AcceptButton";
import { AdminAPI } from "../../api/admin";
import { Wrapper } from "@googlemaps/react-wrapper";
import { Marker } from "../../components/Maps/Marker";
import { Map } from "../../components/Maps/Map";
// import { DirectionsService } from "../../components/Maps/Directions";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoaderDS from "../../assets/LoaderDS.svg";
import { Search } from "@mui/icons-material";
import { RemoveIconButton } from "../../components/Buttons/RemoveIconButton";
import { AddIconButton } from "../../components/Buttons/AddIconButton";

export const CreateRoute = () => {
  const phone = useMediaQuery("(max-width:480px)");
  const [shipments, setShipments] = useState([]);
  const [operatives, setOperatives] = useState([]);
  const [operative, setOperative] = useState(0);
  const [selected, setSelected] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [routeInfo, setRouteInfo] = useState([]);
  const [isLoadingShipments, setIsLoadingShipments] = useState(false);
  const [unassignedShipments, setUnassignedShipments] = useState([]);
  const [directions, setDirections] = useState(null);
  const [waypoints, setWaypoints] = useState([]);
  const [originPosition, setOriginPosition] = useState("");
  const [destinationPosition, setDestinationPosition] = useState({});
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openBD, setOpenBD] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [addressIsLoading, setAddressIsLoading] = useState(false);
  const [errorText, setErrorText] = useState("");
  const company = localStorage.getItem("company");
  // Route totals
  const [totalDistance, setTotalDistance] = useState(0);
  const [totalDuration, setTotalDuration] = useState(0);

  const handleDirectionsResponse = (response) => {
    // Handle the directions response and set it in state
    setDirections(response);
    computeRouteTotals(response);
  };

  const computeRouteTotals = (response) => {
    let totalDistance = 0;
    let totalDuration = 0;
    const myroute = response.routes[0];

    if (!myroute) {
      return;
    }

    for (let i = 0; i < myroute.legs.length; i++) {
      totalDistance += myroute.legs[i].distance.value;
      totalDuration += myroute.legs[i].duration.value;
    }

    totalDistance = totalDistance / 1000;
    setTotalDistance(totalDistance);
    setTotalDuration((totalDuration / 60).toFixed(2));
  };

  const handleOnChange = (isClicked, shipmentInstance) => {
    if (isClicked == true) {
      shipmentInstance.isSelected = true;
      setSelected((oldArray) => [...oldArray, shipmentInstance]);
      setUnassignedShipments((prev) =>
        prev.filter(
          (selected) => selected.tracking_id !== shipmentInstance.tracking_id
        )
      );
    } else {
      shipmentInstance.isSelected = false;
      setUnassignedShipments((oldArray) => [...oldArray, shipmentInstance]);
      setSelected((prev) =>
        prev.filter((selected) => selected !== shipmentInstance)
      );
    }
  };

  const removeSelectedShipment = (shipmentInstance) => {
    shipmentInstance.isSelected = false;
    setUnassignedShipments((oldArray) => [...oldArray, shipmentInstance]);
    setSelected((prev) =>
      prev.filter((selected) => selected !== shipmentInstance)
    );
  };

  const addSelectedShipment = (shipmentInstance) => {
    shipmentInstance.isSelected = true;
    setSelected((oldArray) => [...oldArray, shipmentInstance]);
    setUnassignedShipments((prev) =>
      prev.filter(
        (selected) => selected.tracking_id !== shipmentInstance.tracking_id
      )
    );
  };

  const TravelModes = {
    DRIVING: 'DRIVING',
    BICYCLING: 'BICYCLING',
    TRANSIT: 'TRANSIT',
    WALKING: 'WALKING'
  };

  // HOOKS

  useEffect(() => {
    getPartnersList();
    getShipments();
  }, []);

  useEffect(() => {
    setWaypoints(selected.map((shipTo, i) => ({ location: shipTo.location, stopover: false })));
  }, [selected, originPosition, destinationPosition]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      operative: "",
      originMarkerPosition: "Moliere 61 Polanco",
      destinationMarkerPosition: { lat: 19.4802177, lng: -99.1945311 },
      search: "",
      searchDestination: "",
    },
    validationSchema: Yup.object({}),

    onSubmit: (values) => {
      setRouteInfo({
        ...values,
        shipments: selected,
      });
      setOpenConfirmation(true);
    },
  });

  //DATA REQUEST
  //------------------------------------------------

  const getShipments = async () => {
    setIsLoadingShipments(true);
    let data = new FormData();
    data.append("allShipments", false);
    data.append("isActive", true);
    data.append("inRoute", false);

    try {
      const api = new AdminAPI();
      const response = await api.getAllShipments(data);

      const shipmentsData = response.data.data.map((obj) => ({
        ...obj,
        isSelected: false,
      }));

      setShipments(shipmentsData);
      setUnassignedShipments(shipmentsData);
      setIsLoadingShipments(false);
    } catch (error) {
      console.log(error);
    }
  };

  //console.log(shipments)

  const getPartnersList = async () => {
    let data = new FormData();
    data.append("available", true);
    data.append("allOperators", false);

    try {
        const api = new AdminAPI();
        const response = await api.getAssignmentsEP(data);
        const operativesFilter = response.data.data.filter(item => item.operator.company === company);
        setOperatives(operativesFilter);
    } catch (error) {
        console.log(error);
    }
};

  //console.log(originPosition);

  const getGeolocation = (address) => {
    setAddressIsLoading(true);
  
    // Llamada a la API de Nominatim para buscar las coordenadas de la dirección
    fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(address)}`)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.length > 0) {
          const result = data[0];
  
          // Establecer las coordenadas devueltas (latitud y longitud)
          const lat = parseFloat(result.lat);
          const lon = parseFloat(result.lon);
          const location = { lat, lng: lon };
  
          // Actualizar los campos con la posición obtenida
          formik.setFieldValue("originMarkerPosition", location);
          setOriginPosition(location);
        } else {
          setOpenError(true);
          setErrorText("No se encontró ningún resultado, incluye más datos a tu búsqueda");
          formik.setFieldValue("originMarkerPosition", "");
        }
        
        setAddressIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setOpenError(true);
        setErrorText("No se encontró ningún resultado, incluye más datos a tu búsqueda");
        formik.setFieldValue("originMarkerPosition", "");
        setAddressIsLoading(false);
      });
  };

  const getDestinationGeolocation = (address) => {
    setAddressIsLoading(true);
  
    // Llamada a la API de Nominatim para buscar las coordenadas de la dirección
    fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(address)}`)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.length > 0) {
          const result = data[0];
  
          // Establecer las coordenadas devueltas (latitud y longitud)
          const lat = parseFloat(result.lat);
          const lon = parseFloat(result.lon);
          const location = { lat, lng: lon };
  
          // Actualizar los campos con la posición obtenida
          formik.setFieldValue("destinationMarkerPosition", location);
          setDestinationPosition(location);
        } else {
          setOpenError(true);
          setErrorText("No se encontró ningún resultado, incluye más datos a tu búsqueda");
          formik.setFieldValue("destinationMarkerPosition", "");
        }
        
        setAddressIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setOpenError(true);
        setErrorText("No se encontró ningún resultado, incluye más datos a tu búsqueda");
        formik.setFieldValue("destinationMarkerPosition", "");
        setAddressIsLoading(false);
      });
  };

  return (
    <>
      <div className="w-100 px-3">
        <div>
          {phone ? (
            <div className="w-100 bg-white d-flex justify-content-between mt-1 p-3 border-radius-10">
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center h4">Crear ruta</div>
              </div>
            </div>
          ) : (
            <div className="w-100 bg-white d-flex justify-content-between mt-1 p-3 border-radius-10">
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center h4">Crear ruta</div>
              </div>
            </div>
          )}
          <div className="bg-white mt-1 w-100 p-3 border-radius-10">
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid className="p-3" xs={6}>
                  <div className="my-3">
                    <h5 className="text-center">Guías sin asignar</h5>
                    {"Total: " + unassignedShipments.length}
                    <TableContainer
                      component={Paper}
                      style={{
                        overflowY: "scroll",
                        whiteSpace: "nowrap",
                        height: 300,
                      }}
                    >
                      <Table sx={{ minWidth: 300 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell>#</TableCell>
                            <TableCell>Número de guía</TableCell>
                            {/* <TableCell align="center">Origen</TableCell> */}
                            <TableCell align="left">Destino</TableCell>
                            <TableCell align="left">Cliente</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {unassignedShipments.map((row, i) => (
                            <TableRow
                              key={row.tracking_id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell>
                                <AddIconButton
                                  onClick={() =>
                                    addSelectedShipment(row)
                                  }
                                />
                              </TableCell>
                              <TableCell scope="row">
                                {i + 1}
                              </TableCell>
                              <TableCell scope="row">
                                {row.tracking_id}
                              </TableCell>
                              {/* <TableCell align="right">{row.origin}</TableCell> */}
                              <TableCell align="left">
                                {row.destination}
                              </TableCell>
                              <TableCell align="left">{row.client}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  <div className="my-3">
                    <h5>1. Seleccionar conductor</h5>
                    {/* <h5>{totalDistance}</h5>
                    <h5>{totalDuration}</h5> */}
                    <TextField
                      className={"my-2"}
                      select
                      error={
                        formik.touched["operative"] &&
                        formik.errors["operative"]
                      }
                      size="small"
                      label={"Operadores disponibles"}
                      variant="outlined"
                      fullWidth
                      onChange={(e) => {
                        formik.handleChange(e);
                        setOperative(e.target.value);
                      }}
                      value={formik.values.operative}
                      name={"operative"}
                      id={"operative"}
                      helperText={
                        formik.touched["operative"] &&
                        formik.errors["operative"] ? (
                          <div>{formik.errors["operative"]}</div>
                        ) : null
                      }
                    >
                      {operatives.length == 0 ? (
                        <MenuItem>No hay conductores disponibles</MenuItem>
                      ) : (
                        operatives.map((op, i) => (
                          <MenuItem key={i} value={op.id}>
                            {op.assigned_unit.operatorName}{" "}
                            {op.assigned_unit.operatorLastName}
                          </MenuItem>
                        ))
                      )}
                    </TextField>
                  </div>
                  <div className="my-3">
                    <div className="row">
                      <div className="col-9">
                        <p>
                          2. Selecciona los envíos a asignar al conductor
                          haciendo click en los marcadores dentro del mapa.
                        </p>
                      </div>
                      <div className="col d-flex justify-content-end">
                        <Tooltip
                          title={`Selecciona un operador y al menos una ruta`}
                          placement="top"
                          arrow
                        >
                          <div>
                            <AcceptButton
                              disabled={
                                operative !== 0 && selected.length !== 0
                                  ? false
                                  : true
                              }
                              text={"Crear ruta"}
                              type={"submit"}
                              width={phone ? "100%" : ""}
                            />
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                    <TableContainer
                      component={Paper}
                      style={{
                        overflowY: "scroll",
                        whiteSpace: "nowrap",
                        height: 190,
                      }}
                    >
                      <Table sx={{ minWidth: 300 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Número de guía</TableCell>
                            {/* <TableCell align="center">Origen</TableCell> */}
                            <TableCell align="center">Destino</TableCell>
                            <TableCell align="center">Cliente</TableCell>
                            {/* <TableCell align="center">Eliminar</TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {selected.map((shipment, i) => (
                            <TableRow
                              key={shipment.tracking_id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell>
                                <RemoveIconButton
                                  onClick={() =>
                                    removeSelectedShipment(shipment)
                                  }
                                  text={"Eliminar"}
                                />
                              </TableCell>
                              <TableCell>{i + 1}</TableCell>
                              <TableCell component="th" scope="row">
                                {shipment.tracking_id}
                              </TableCell>
                              <TableCell align="right">
                                {shipment.destination}
                              </TableCell>
                              <TableCell align="right">
                                {shipment.client}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <p className="float-right my-3">
                      {" "}
                      Total: {selected.length}
                    </p>
                  </div>
                </Grid>

                <Grid item xs={6} md={6} lg={6}>
                  <div
                    className={` d-flex mb-2 flex-column ${
                      phone ? "px-2 " : "px-1"
                    }`}
                  >
                    <div
                      className={`d-flex ${phone && "mb-2"} `}
                      style={{ minWidth: `${phone ? "125px" : "160px"}` }}
                    >
                      <p
                        className={`mb-1 ${phone ? "xs-font" : ""}`}
                        htmlFor={"search"}
                      >{`Inicio de la ruta:`}</p>
                      <TextField
                        className=""
                        size="small"
                        fullWidth
                        label={"Dirección"}
                        onChange={formik.handleChange}
                        onKeyPress={(e) => {
                          if (e.key == "Enter") {
                            e.preventDefault();
                            getGeolocation(e.target.value);
                          }
                        }}
                        value={formik.values.search}
                        name={"search"}
                        inputProps={phone && { style: { fontSize: 13 } }}
                        InputProps={{
                          endAdornment: (
                            <Search
                              className="ds-yellow-font mr-2 cursor-pointer"
                              onClick={() =>
                                getGeolocation(formik.values.search)
                              }
                            />
                          ),
                        }}
                        error={formik.errors.location && formik.touched.search}
                        helperText={
                          formik.errors.location &&
                          formik.touched.search &&
                          formik.errors.location
                        }
                      />
                    </div>
                  </div>
                  <div
                    className={` d-flex mb-3 flex-column ${
                      phone ? "px-2 " : "px-1"
                    }`}
                  >
                    <div
                      className={`d-flex   ${phone && "mb-2"} `}
                      style={{ minWidth: `${phone ? "125px" : "160px"}` }}
                    >
                      <p
                        className={`mb-1 ${phone ? "xs-font" : ""}`}
                        htmlFor={"searchDestination"}
                      >{`Fin de la ruta:`}</p>
                      <TextField
                        className=""
                        size="small"
                        fullWidth
                        label={"Dirección"}
                        onChange={formik.handleChange}
                        onKeyPress={(e) => {
                          if (e.key == "Enter") {
                            e.preventDefault();
                            getDestinationGeolocation(e.target.value);
                          }
                        }}
                        value={formik.values.searchDestination}
                        name={"searchDestination"}
                        inputProps={phone && { style: { fontSize: 13 } }}
                        InputProps={{
                          endAdornment: (
                            <Search
                              className="ds-yellow-font mr-2 cursor-pointer"
                              onClick={() =>
                                getDestinationGeolocation(
                                  formik.values.searchDestination
                                )
                              }
                            />
                          ),
                        }}
                        error={
                          formik.errors.location &&
                          formik.touched.searchDestination
                        }
                        helperText={
                          formik.errors.location &&
                          formik.touched.searchDestination &&
                          formik.errors.location
                        }
                      />
                    </div>
                  </div>
                  <Typography>
                    <strong>Distancia total:</strong> {totalDistance} km
                  </Typography>
                  <Typography>
                    <strong>Tiempo aproximado:</strong> {totalDuration} min
                  </Typography>
                  <Wrapper apiKey={process.env.REACT_APP_GOOGLE_MAPS_API}>
                      {/* {waypoints && originPosition && destinationPosition && (
                        <DirectionsService
                          origin={originPosition}
                          destination={destinationPosition}
                          onDirectionsFetched={handleDirectionsResponse}
                          waypoints={waypoints}
                          travelMode={TravelModes.DRIVING}
                        />
                      )} */}

                      <Map
                        center={{ lat: 19.406011, lng: -99.137612 }}
                        style={{ height: "80vh", width: "100%" }}
                        zoom={8}
                        directions={directions}
                      >
                        {shipments.map((shipTo, i) => (
                          <Marker
                            key={i}
                            position={shipTo.location}
                            title={shipTo.tracking_id}
                            clickable={true}
                            handleChange={handleOnChange}
                            instance={shipTo}
                            markerType={"shipmentMarker"}
                            selected={shipTo.isSelected}
                          />
                        ))}
                      </Map>
                    </Wrapper>
                </Grid>
              </Grid>
            </form>
          </div>
        </div>
      </div>
      <Modal open={openConfirmation}>
        <CreateRouteConfirmationModal
          handleCancel={() => setOpenConfirmation(false)}
          operatives={operatives}
          text={"Ruta creada con éxito."}
          routeInfo={routeInfo}
        />
      </Modal>{" "}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoadingShipments}
      >
        <img src={LoaderDS} alt="" width={200} />
      </Backdrop>
    </>
  );
};

// const handleOnChange = (isClicked, shipmentInstance) => {

//   // Find unassigned shipments with the same destination as the provided shipment
//   const sameDestination = unassignedShipments.filter(
//     ({ destination }) => destination == shipmentInstance.destination
//   );

//   if (isClicked == "true") {
//     setSelected((oldArray) => [...oldArray, ...sameDestination]);
//     setUnassignedShipments((prev) =>
//       prev.filter(
//         (selected) => selected.destination !== shipmentInstance.destination
//       )
//     );
//   } else {
//     setUnassignedShipments((oldArray) => [...oldArray, ...sameDestination]);
//     setSelected((prev) =>
//       prev.filter(
//         (selected) => selected.destination !== shipmentInstance.destination
//       )
//     );
//   }
// };
