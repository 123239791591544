import BagLogo from "../../assets/BagLogo.svg";
import LoaderDS from "../../assets/BagLogo.svg";

import {
  Backdrop,
  IconButton,
  List,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import {
  SettingsRounded,
  KeyboardArrowRightRounded,
  ManageAccountsRounded,
  KeyRounded,
  ForkRight,
  ListAlt,
  LocalShipping,
  Add,
  AccountCircle,
  Pending,
  AddLocationAltOutlined,
  RocketLaunch,
  LocationOn,
  Assessment
} from "@mui/icons-material";
import { useAuthContext } from "../../context/AuthProvider";
import { OpenedMenuList } from "./OpenedMenuList";
import { LogoutSection } from "./LogoutSection";

export const Sidebar = ({
  DrawerStyled,
  open,
  handleDrawer,
  DrawerHeader,
  handleDrawerToggle,
  mobileOpen,
  rol,
}) => {
  const { logoutFunction, isLoading, openBD } = useAuthContext();
  const tablet = useMediaQuery("(max-width:600px)");
  const company_image = localStorage.getItem("company_image");
  const tamañoDiv = open ? "w-20 h-20" : "w-12 h-12";
  const tamañoImagen = open ? "w-16 h-16" : "w-10 h-10";
  const sections = {
    2: [
      /*Admin */
      {
        link: "/",
        name: "Envíos",
        icon: <RocketLaunch />,
        submenu: [
          {
            link: "guides/active/",
            name: "Guías activas",
            icon: <Pending fontSize="18px" />,
          },
          {
            link: "guides/routes/",
            name: "Rutas",
            icon: <ForkRight fontSize="18px" />,
          },
          {
            link: "guides/historical/",
            name: "Histórico",
            icon: <ListAlt fontSize="18px" />,
          },
        ],
      },
      {
        link: "guides/active/add/",
        name: "Crear guía",
        icon: <Add />,
      },
      {
        link: "guides/active/addOrigin/",
        name: "Crear origen",
        icon: <AddLocationAltOutlined />,
      },
      {
        link: "shipmentsOrigins/",
        name: "Origenes",
        icon: <LocationOn/>,
      },
      {
        link: "operatives/",
        name: "Operadores",
        icon: <AccountCircle />,
      },
      {
        link: "vehicles/",
        name: "Unidades",
        icon: <LocalShipping />,
      },
      {
        link: "reports/",
        name: "Reportes",
        icon: <Assessment />,
      },
      {
        link: "/",
        name: "Cuenta",
        icon: <SettingsRounded />,
        submenu: [
          {
            link: "account/profile/",
            name: "Perfil",
            icon: <ManageAccountsRounded fontSize="18px" />,
          },
          {
            link: "account/password/",
            name: "Contraseña",
            icon: <KeyRounded fontSize="18px" />,
          },
        ],
      },
    ],
  };

  const drawer = (
    <>
      {tablet && <div style={{ height: "70px" }}></div>}
      <DrawerHeader sx={{ height: tablet ? "50px" : "68px" }}>
        {open ? (
          <div className="d-flex justify-content-around w-100">
            <img src={BagLogo} alt="Bolsa" width={40} />
            <div className="d-flex flex-column align-items-start">
              <Typography
                variant="h5"
                noWrap
                component="div"
                className="text-white font-weight-bold"
              >
                Day Store
              </Typography>
              {/* <img src={LogoPlus} alt="" height={13} /> */}
              <Typography variant="" className="text-white">
                Logistics
              </Typography>
            </div>
          </div>
        ) : (
          <div className=" ml-2">
            <img src={BagLogo} alt="Bolsa" width={40} />
          </div>
        )}
      </DrawerHeader>
      <div className="d-flex w-100 justify-content-end position-relative align-items-center">
        <div className="w-100 border-top-white" key={"divider1"}></div>
        {!tablet && (
          <IconButton
            onClick={handleDrawer}
            className={`ds-yellow-background p-0 `}
          >
            <KeyboardArrowRightRounded
              sx={{
                color: "#fff",
                transform: open ? "rotate(-180deg)" : "rotate(0)",
                transition: "0.5s",
              }}
            />
          </IconButton>
        )}

        <div className="w-100 border-top-gray"></div>
      </div>
      <List>
        {sections[rol].map((section, i) => (
          <OpenedMenuList key={i} section={section} sidebarOpen={open} />
        ))}
      </List>
      <div className="w-100 border-top-gray" key={"divider2"}></div>
      
      <LogoutSection sidebarOpen={open} />
      {/* <img
                  src={company_image}
                  alt="logo unilever"
                  className="logoCompany"
                  style={{ marginLeft: "auto" }}
                /> */}
      <div className="mt-auto mb-4 flex justify-center">
        <div className={`${tamañoDiv} rounded-full overflow-hidden flex items-center justify-center bg-gray-200`}>
          <img src={company_image} alt="Circular" className={`${tamañoImagen} rounded-full border-2 border-white`} />
        </div>
      </div>
    

    </>
  );

  return (
    <>
      <DrawerStyled
        variant={tablet ? "temporary" : "permanent"}
        open={tablet ? mobileOpen : open}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {drawer}
      </DrawerStyled>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBD}
      >
        <img src={LoaderDS} alt="" width={200} />
      </Backdrop>
    </>
  );
};
