import { getPath } from '../helpers/getHost';
import { HttpRequest } from './HttpRequest';

export class LoginAPI {
    constructor() {
        this.http = new HttpRequest()
        this.host = getPath()
        this.token = localStorage.getItem("token");
        this.config = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Token ${this.token}`
            }
        }
    }

    loginEP(data){
        return this.http.post(`${this.host}/ws/login/`, data)
    }

    logoutEP(data){
        return this.http.post(`${this.host}/ws/logout/`, data, this.config)
    }

    recoveryEP(data){
        return this.http.post(`${this.host}/ws/resetPassword/`, data)
    }

    changePasswordEP(data){
        return this.http.post(`${this.host}/ws/updatePassword/`, data, this.config)
    }
}
