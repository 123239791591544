import React, { useRef, useState } from "react";
import { InputBase, Paper, TextField } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import debounce from 'lodash/debounce';

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#1f4368",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#d1d8f3",
      borderRadius: 5,
    },
    "&:hover fieldset": {
      borderColor: "#1f4368",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#1f4368",
    },
  },
});


export const Search = ({ placeholder, setSearch}) => {

  const sendQuery = (query) => setSearch(query);
  const [text, setText] = useState("");

  const delayedQuery = useRef(debounce(q => sendQuery(q), 500)).current;

  const handleOnChange = (e) => {
    setText(e.target.value);
    delayedQuery(e.target.value);
  };

  return (
    <>
      <Paper
        component="form"
        sx={{
          p: '2px',
          display: 'flex',
          alignItems: 'center',
          border: "1px solid #8d8d8d8a",
          borderRadius: 5
        }}
      >
        <InputBase
          sx={{ ml: 2, flex: 1, height: 35, }}
          placeholder={placeholder}
          inputProps={{ 'aria-label': 'search ' }}
          onChange={handleOnChange}
          onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
        />
        <SearchIcon className="text-secondary mr-2" />
      </Paper>
    </>
  );
};
