import { API } from './abs';

export class IntegrationAPI extends API {
    getListStatusShipmentEP(data){
        return this.http.post(`${this.host}/ws/getListStatusShipment/`, data, this.config)
    }

    updateShipmentEP(data){
        return this.http.post(`${this.host}/ws/updateShipment/`, data, this.config)
    }
}