import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ActiveGuidesTable } from "../../components/Tables/ActiveGuidesTable";
import { SkeletonTables } from "../../components/Tables/SkeletonTables";
import { OperativeAPI } from "../../api/operative";

export const Actives = () => {
    const [view, setView] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const phone = useMediaQuery("(max-width:480px)");
    const navigate = useNavigate();
    const [search, setSearch] = useState("");
    const [data, setData] = useState(null);
    const [page, setPage] = useState(0); /*Estado p/paginacion*/
    const [total, setTotal] = useState(); /*Total de paginas enviado desde api*/
    const [count, setCount] = useState(); /*Paginacion listas */
    const [open, setOpen] = useState(true);

    //ERRORES
    //------------------------------------------------

    //------------------------------------------------

    //FILTERS STATES
    //------------------------------------------------

    // const [prevData, setPrevData] = useState({
    //     // startFilterDate: null,
    //     // endFilterDate: null,
    //     status: "Todos"
    // });

    // const today = new Date();
    // const [startFilterDate, setStartFilterDate] = useState(null);
    // const [endFilterDate, setEndFilterDate] = useState(null);

    // const [status, setStatus] = useState("Todos"); /*Select one period */
    // const statusList = [
    //     { status: "Todos", value: "Todos" },
    //     { status: "Pendiente", value: 1 },
    //     { status: "Finalizado", value: 2 },

    // ];
    //------------------------------------------------

    //HANDLERS
    //------------------------------------------------

    // const [anchorEl, setAnchorEl] = useState(null);
    // const handleClick = (event) => { setAnchorEl(event.currentTarget); };
    // const open = Boolean(anchorEl);
    // const id = open ? 'simple-popover' : undefined;
    // const handleCloseFilterModal = () => setAnchorEl(null);

    const handleCloseModal = () => {
        // setAnchorEl(null);
        // settingFilters(
        //     // prevData.startFilterDate, 
        //     // prevData.endFilterDate, 
        //     prevData.status
        //     );
    };

    const handleFilter = () => {

        // handleCloseFilterModal();
        // RequestData();
        // setPrevData({
        //     // startFilterDate: startFilterDate,
        //     // endFilterDate: endFilterDate,
        //     status: status,
        // });
    };

    const handleResetFilter = async () => {
        // settingFilters("Todos");
    };

    const handleChange = (e) => {
        // setStatus(e.target.value);
    }
    //------------------------------------------------

    //FILTERS SETTING
    //------------------------------------------------

    const settingFilters = (status) => {
        // setStartFilterDate(firstDay);
        // setEndFilterDate(endDay);
        // setStatus(status)
    };

    //------------------------------------------------

    //EFFECTS
    //------------------------------------------------

    // useEffect(() => {
    //         GetListShipmentList()
    // }, [page]);
    // useEffect(() => {
    //         GetListShipmentList()
    // }, [search]);

    useEffect(() => {
        RequestData()
    }, [page, search]);

    //DATA REQUEST
    //------------------------------------------------

    const RequestData = () => {
        GetRoutesActives()
    };

    const GetRoutesActives = async () => {
        setIsLoading(true);
        let data = new FormData();
 
        if (search != "") {
            data.append("search", search);
        }

        data.append("page", page + 1);

        try {
            const api = new OperativeAPI()
            const response = await api.getAssignedRouteEP(data);
            setData(response.data);
            
            setTotal(response.data.total);
            if(response.data.total > 0){
                setCount(Math.ceil(response.data.total / 10));
            }
            
            setIsLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <div className="w-100 px-3">
                <div>
                    {phone ? (
                        <div className="w-100 bg-white d-flex justify-content-between mt-1 p-3 border-radius-10">

                            <div className="d-flex align-items-center">


                                <div className="d-flex align-items-center">
                                    Rutas activas
                                </div>
                            </div>

                        </div>
                    ) : (
                        <div className="w-100 bg-white d-flex justify-content-between mt-1 p-3 border-radius-10">

                            <div className="d-flex align-items-center">


                                <div className="d-flex align-items-center">
                                    Rutas activas
                                </div>
                            </div>

                        </div>
                    )}

                    {isLoading ? (
                        <div className="mt-3 bg-white w-100 d-flex justify-content-between p-2 border-radius-10">
                            <SkeletonTables
                                num={3}
                                icon={true}
                            />
                        </div>
                    ) : (
                        <div className="mt-3 bg-white w-100 d-flex justify-content-between p-3 border-radius-10">
                            <ActiveGuidesTable
                                search={search}
                                data={data}
                                total={total}
                                page={page}
                                setPage={setPage}
                            />
                        </div>
                    )}

                </div>
            </div>



            {/* <Modal open={openError} onClose={() => console.log("Cerrando")}>
                <ErrorModal text={"Error al enviar respuestas."} handleClose={() => { setOpenError(false); navigate("/cm/skillsassessment/") }} />
            </Modal>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBD}
            >
                <span className="loader"></span>
            </Backdrop> */}

            {/* <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleCloseFilterModal}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <FiltersModal
                    handleClose={handleCloseModal}
                    dates={{
                    }}
                    fields={[
                        {
                            array: statusList,
                            prop: "status",
                            type: "Estatus",
                            value: status,
                            handleOnChange: handleChangeStatus,
                        },
                    ]}
                    onClickFilter={handleFilter}
                    onClickResetFilter={handleResetFilter}
                />
            </Popover> */}
        </>
    );
};