export const activeGuidesHeaders = [
  { label: "No de cliente", key: "tracking_id", excel: true },
  { label: "Direccion de entrega", key: "destination", excel: true },
  { label: "Geolocalizacion", key: "coordinates", excel: true },
  { label: "No de rastreo", key: "tracking_id", excel: true },
  { label: "Producto(s)", key: "item_description", excel: true }, 
  { label: "Monto", key: "total_price", excel: true }, 
  { label: "Fecha de creacion", key: "formatted_date", excel: true }, 
  { label: "Punto de recolección", key: "origin", excel: true },
  { label: "Hora de creación", key: "formatted_time", excel: true }, 
  { label: "Hora de entrega", key: "pick_up_date", excel: true },
  { label: "Estatus", key: "status", excel: true },
  { label: "Rechazo/Incidencia", key: "previous_status", excel: true }, 
];

export const routesHeaders = [
  { label: "Folio", key: "id", excel: true },
  { label: "Cantidad de guías", key: "shipments_count", excel: true },
  { label: "Unidad asignada", key: "unit", excel: true },
  { label: "Operador", key: "partnerName", excel: true },
  { label: "Estatus", key: "status", excel: true },
  { label: "Fecha de inicio", key: "start_date", excel: true },
  { label: "Fecha de recolección", key: "", excel: true },
  { label: "Fecha de entrega", key: "end_date", excel: true },
  { label: "% Avance", key: "percentage_completion", excel: true },
  { label: "# Guías canceladas", key: "canceled_guides", excel: true },
  { label: "Tipo de ruta", key: "is_automatic", excel: true },
];

export const historicalHeaders = [
  { label: "No. Guía", key: "tracking_id", excel: true },
  { label: "Estatus", key: "status", excel: true },
  { label: "Punto de recolección", key: "origin", excel: true },
  { label: "Punto de entrega", key: "destination", excel: true },
  { label: "Código de ruta", key: "route", excel: true },
  { label: "Fecha de creación", key: "date_created", excel: true },
  { label: "Fecha de recolección", key: "pick_up_date", excel: true },
  { label: "Fecha de entrega", key: "delivery_date", excel: true },
  { label: "Nombre de Operador", key: "partnerName", excel: true },
  { label: "Apellido de Operador", key: "partnerLastName", excel: true },
  { label: "Unidad", key: "unit", excel: true },
  { label: "# Rutas previas", key: "routes_count", excel: true },
  { label: "Tiempo total de entrega", key: "delivery_time", excel: true },
  { label: "Evidencia", key: "evidence", excel: true },
  { label: "Comentarios", key: "comments", excel: true },
];

export const partnersHeaders = [
  { label: "Fecha de nacimiento", key: "dateBirth", excel: true },
  { label: "Disponible", key: "isAvailable", excel: true },
  { label: "Nombre", key: "name", excel: true },
  { label: "Apellido", key: "lastName", excel: true },
  { label: "Correo electrónico", key: "email", excel: true },
  {
    label: "Fecha de expiración licencencia",
    key: "expirationDriveLicense",
    excel: true,
  },
  { label: "Teléfono", key: "phone", excel: true },
  { label: "Código de empleado", key: "employeeId", excel: true },
  { label: "Activo", key: "isActive", excel: true },
];

export const vehiclesHeaders = [
  { label: "Id", key: "idVehicle", excel: true },
  { label: "Vin", key: "vinNumber", excel: true },
  { label: "Tipo de vehículo", key: "typeVehicle", excel: true },
  { label: "Modelo", key: "model", excel: true },
  { label: "Marca", key: "brand", excel: true },
  { label: "Año", key: "year", excel: true },
  { label: "Capacidad", key: "capacity", excel: true },
  { label: "Placa", key: "licensePlate", excel: true },
  { label: "Holograma", key: "hologram", excel: true },
  { label: "Operador - Nombre", key: "operatorName", excel: true },
  { label: "Operador - Apellido", key: "operatorLastName", excel: true },
  { label: "Id", key: "operatorId", excel: true },
  { label: "Activo", key: "isActive", excel: true },
];

export const shipmentGuideHeaders = [
  { label: "Id", key: "", excel: true },
  { label: "OrigenNombre", key: "", excel: true },
  { label: "OrigenApellido", key: "", excel: true },
  { label: "OrigenTelefono", key: "", excel: true },
  { label: "OrigenCorreo", key: "", excel: true },
  { label: "OrigenCalle", key: "", excel: true },
  { label: "OrigenNumeroInterior", key: "", excel: true },
  { label: "OrigenNumeroExterior", key: "", excel: true },
  { label: "OrigenColonia", key: "", excel: true },
  { label: "OrigenCodigoPostal", key: "", excel: true },
  { label: "OrigenReferencias", key: "", excel: true },
  { label: "DestinoNombre", key: "", excel: true },
  { label: "DestinoApellido", key: "", excel: true },
  { label: "DestinoTelefono", key: "", excel: true },
  { label: "DestinoCorreo", key: "", excel: true },
  { label: "DestinoCalle", key: "", excel: true },
  { label: "DestinoNumeroInterior", key: "", excel: true },
  { label: "DestinoNumeroExterior", key: "", excel: true },
  { label: "DestinoColonia", key: "", excel: true },
  { label: "DestinoCodigoPostal", key: "", excel: true },
  { label: "DestinoReferencias", key: "", excel: true },
];

export const shipmentItemsHeaders = [
  { label: "IdGuia", key: "", excel: true },
  { label: "Descripcion", key: "", excel: true },
  { label: "Tamaño", key: "", excel: true },
  { label: "Peso", key: "", excel: true },
  { label: "Largo", key: "", excel: true },
  { label: "Ancho", key: "", excel: true },
  { label: "Alto", key: "", excel: true },
];
