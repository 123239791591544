import Box from "@mui/material/Box";
import { IconButton, useMediaQuery } from "@mui/material";
import { Close } from "@mui/icons-material";

export default function PdfVisorModal({ pdf, handleClose }) {
  const phone = useMediaQuery("(max-width:600px)");
  const tablet = useMediaQuery("(max-width:900px)");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: phone ? "95%" : "50%",
    maxHeight: 700,
    height: "80vh",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <Box sx={style}>
      <div className="w-100 d-flex justify-content-end">
        <IconButton onClick={handleClose}>
          <Close sx={{ fontSize: 35 }} />
        </IconButton>
      </div>
      <iframe
        src={`${pdf}#view=fitH`}
        title="Guía"
        height="100%"
        width="100%"
      />
    </Box>
  );
}
//#view=fitH
