import React from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

export const AcceptButton = ({ type, text, width, onClick, disabled, height }) => {
  const AcceptButtonStyled = styled(Button)({
    width: width,
    height: height,
    boxShadow: "1",
    textTransform: "none",
    fontSize: 16,
    padding: "6px 12px",
    border: "1.5px solid",
    lineHeight: 1.5,
    borderRadius: 5,
    backgroundColor: disabled ? "#b6b6b6" : "#3B71CA",
    borderColor: disabled ? "transparent" : "#3B71CA",
    color: "white",
    // fontWeight: 'bold',
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),

    "&:hover": {
      backgroundColor: "white",
      color: "#3B71CA"
      //   borderColor: '#0062cc',
      //   boxShadow: '3',
      //   color: '#fbae08',
    },
    "&:active": {
      //   boxShadow: 'none',
      //   backgroundColor: '#0062cc',
      //   borderColor: '#005cbf',
    },
    "&:focus": {
      //   boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  });
  return (
    <AcceptButtonStyled disabled={disabled} type={type} onClick={onClick}>
      {text}
    </AcceptButtonStyled>
  );
};
