import React, { useState } from "react";
import { useMediaQuery } from "@mui/material";
import { HistoricalGuidesTable } from "../../components/Tables/HistoricalGuidesTable";
import { roles } from "../../helpers/enums";


export const Historical = () => {
    const [isLoading, setIsLoading] = useState(false);
    const phone = useMediaQuery("(max-width:480px)");
    const [search, setSearch] = useState("");
    const [data, setData] = useState([
        {id: 15652548, departure: "Condesa", arrive: "Av. Uno Colonia: Dos", route: "Ruta 1", status: "Asignada", date: "5/9/22", hour: "14:55"},
        {id: 25487484, departure: "Planeta", arrive: "Av. Dos Colonia: Tres", route: "Ruta 2", status: "Asignada", date: "5/9/22", hour: "14:58"},
        {id: 34955626, departure: "Inglaterra", arrive: "Av. Tres Colonia: Cuatro", route: "Ruta 3", status: "Asignada", date: "5/9/22", hour: "15:00"},

    ]);
    const [page, setPage] = useState(0); /*Estado p/paginacion*/
    const [total, setTotal] = useState(); /*Total de paginas enviado desde api*/
    const [count, setCount] = useState(); /*Paginacion listas */

    //ERRORES
    //------------------------------------------------

    //------------------------------------------------

    //FILTERS STATES
    //------------------------------------------------

    // const [prevData, setPrevData] = useState({
    //     // startFilterDate: null,
    //     // endFilterDate: null,
    //     status: "Todos"
    // });

    // const today = new Date();
    // const [startFilterDate, setStartFilterDate] = useState(null);
    // const [endFilterDate, setEndFilterDate] = useState(null);

    // const [status, setStatus] = useState("Todos"); /*Select one period */
    // const statusList = [
    //     { status: "Todos", value: "Todos" },
    //     { status: "Pendiente", value: 1 },
    //     { status: "Finalizado", value: 2 },

    // ];
    //------------------------------------------------

    //HANDLERS
    //------------------------------------------------

    // const [anchorEl, setAnchorEl] = useState(null);
    // const handleClick = (event) => { setAnchorEl(event.currentTarget); };
    // const open = Boolean(anchorEl);
    // const id = open ? 'simple-popover' : undefined;
    // const handleCloseFilterModal = () => setAnchorEl(null);

    const handleCloseModal = () => {
        // setAnchorEl(null);
        // settingFilters(
        //     // prevData.startFilterDate, 
        //     // prevData.endFilterDate, 
        //     prevData.status
        //     );
    };

    const handleFilter = () => {

        // handleCloseFilterModal();
        // RequestData();
        // setPrevData({
        //     // startFilterDate: startFilterDate,
        //     // endFilterDate: endFilterDate,
        //     status: status,
        // });
    };

    const handleResetFilter = async () => {
        // settingFilters("Todos");
    };

    const handleChange = (e) => {
        // setStatus(e.target.value);
    }
    //------------------------------------------------

    //FILTERS SETTING
    //------------------------------------------------

    const settingFilters = (status) => {
        // setStartFilterDate(firstDay);
        // setEndFilterDate(endDay);
        // setStatus(status)
    };

    //------------------------------------------------

    //EFFECTS
    //------------------------------------------------



    //DATA REQUEST
    //------------------------------------------------

    const RequestData = () => {
        // Peticion a la API
        // Aquí va el excel();
    };


    return (
        <>
            <div className="w-100 px-3">
                <div>
                    {phone ? (
                        <div className="mt-2 w-100 p-1 ">
                            <div className="d-flex bg-white justify-content-between border-radius-10">

                            </div>
                            <div className="d-flex w-100 mt-2">
                            </div>
                        </div>
                    ) : (
                        <div className="w-100 bg-white d-flex justify-content-between mt-1 p-3 border-radius-10">

                            <div className="d-flex align-items-center">

                              
                                <div className="d-flex align-items-center">
                                    Historial de entregas
                                </div>
                            </div>

                            {/* <ToggleView setView={setView} view={view} /> */}
                        </div>
                    )}
     
                    {isLoading ? (
                        <div className="mt-3 bg-white w-100 d-flex justify-content-between p-2 border-radius-10">
                            Aquí el skeleton
                            {/* <SkeletonSkillTable
                                    num={3}
                                    icon={true}
                                // total={total}
                                // page={page}
                                // setPage={setPage}
                                /> */}
                        </div>
                    ) : (
                        <div className="mt-3 bg-white w-100 d-flex justify-content-between p-3 border-radius-10">
                            <HistoricalGuidesTable
                                search={search}
                                data={data}
                                rol={roles.USER}
                                total={total}
                                page={page}
                                setPage={setPage}
                            />
                        </div>
                    )}
                   
                </div>
            </div>

            {/* <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleCloseFilterModal}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <FiltersModal
                    handleClose={handleCloseModal}
                    dates={{
                    }}
                    fields={[
                        {
                            array: statusList,
                            prop: "status",
                            type: "Estatus",
                            value: status,
                            handleOnChange: handleChangeStatus,
                        },
                    ]}
                    onClickFilter={handleFilter}
                    onClickResetFilter={handleResetFilter}
                />
            </Popover> */}
        </>
    );
};