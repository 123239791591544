import React, { useState } from "react";

import { Button, Backdrop } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Download } from "@mui/icons-material";

import { ExcelDownloadOneSheet } from "./ExcelDownloadOneSheet";
import LoaderDS from "../../assets/LoaderDS.svg";

const DownloadButtonStyled = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  lineHeight: 1.5,
  borderRadius: 15,
  backgroundColor: "white",
  color: "#1f4368",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  "&:hover": {
    backgroundColor: "#e9eaef",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#FFF",
  },
  "&:focus": {},
});

export const DownloadButtonExcel = ({
  text,
  disabled,
  getData,
  data,
  headers,
  sheetName,
  isLoading,
}) => {
  const [click, setClick] = useState(false);

  return (
    <>
      <div className="d-flex align-items-center m-1">
        <DownloadButtonStyled
          variant="contained"
          disableRipple
          disabled={disabled}
          onClick={() => {
            getData();
            setClick(true);
          }}
        >
          <Download className="mr-1" />

          <p className="m-0 no-wrap">{text}</p>
        </DownloadButtonStyled>
        {data ? (
          <ExcelDownloadOneSheet
            data={data}
            headers={headers}
            sheetName={sheetName}
            click={click}
            setClick={setClick}
          />
        ) : null}
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <img src={LoaderDS} alt="" width={200} />
      </Backdrop>
    </>
  );
};
