import React, { useState } from "react";
import Box from "@mui/material/Box";
import {
  CssBaseline,
  Grid,
  Toolbar,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
//import Sidebar from '../components/Sidebar';
import { Sidebar } from "../components/Sidebar/Sidebar";
import { LayoutAdminRouting } from "./LayoutAdminRouting";
import { menuAdmin } from "../utils/Menu/Menu";
import { AppBar, DrawerHeader, DrawerStyled } from "../utils/menuStyles";
import { ActivesAdmin } from "./pages/Guides/ActivesAdmin";
import { RoutesAdmin } from "./pages/Guides/RoutesAdmin";
import { CreateRoute } from "../pages/Guides/CreateRoute";
import { HistoricalAdmin } from "./pages/Guides/HistoricalAdmin";
import { Operatives } from "./pages/Operatives/Operatives";
import { Vehicles } from "./pages/Vehicles/Vehicles";
import { AccountProfileAdmin } from "./pages/Account/AccountProfileAdmin";
import { AddOperative } from "./pages/Add/AddOperative";
import { AddVehicle } from "./pages/Add/AddVehicle";
import { AddGuide } from "./pages/Add/AddGuide";
import { AddShipmentOrigin } from "./pages/Add/AddShipmentOrigin";
import { EditPasswordAdmin } from "./pages/Account/EditPasswordAdmin";
import { Assignments, VehiclesAssigned } from "./pages/Assigments/Assignments";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { BreadcrumbsMenu } from "../components/Breadcrumb/BreadcrumbsMenu";
import MenuIcon from "@mui/icons-material/Menu";
import { adminSections } from "../utils/menuSections";
import { ShipmentsOrigins } from "./pages/Guides/ShipmentsOrigins";
import { Reports } from "./pages/Reports/Reports";

const drawerWidth = 220;

export const LayoutAdmin = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const tablet = useMediaQuery("(max-width:600px)");
  const phone = useMediaQuery("(max-width:600px)");
  const breakpointBC = useMediaQuery("(max-width:630px)");
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const company = localStorage.getItem("company")
  const company_image = localStorage.getItem("company_image");
  //   const handleDrawerToggle = () => {
  //     tablet && setMobileOpen(!mobileOpen);
  //   };

  const handleDrawer = () => {
    setOpen(!open);
  };

  const handleDrawerToggle = () => {
    tablet && setMobileOpen(!mobileOpen);
    setOpen(true);
  };

  const nameSection = adminSections.find((el) => `/${el.link}/` == pathname);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <AppBar
          position="fixed"
          open={tablet ? false : open}
          style={{ width: !open && !tablet && "calc(100% - 65px)" }}
          elevation={0} 
        >
          <Toolbar
            sx={{
              bgcolor: "white",
              color: "#fbae08 ",
              height: phone ? "65px" : "68px",
            }}
            className={`w-100 d-flex align-items-start align-items-center justify-content-between`}
          >
            <div className="d-flex align-items-center">
              {tablet && (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerToggle}
                  edge="start"
                  sx={{ mr: 2, display: { sm: "none" } }}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <Typography
                variant="h5"
                noWrap
                component="div"
                className="ds-blue-font font-weight-bold capitalize"
              >
                {nameSection != undefined && nameSection.section}
              </Typography>
            </div>
            {/* <div className="d-flex align-items-center">
              
                <img
                  src={company_image}
                  alt="logo unilever"
                  className="logoCompany"
                  style={{ marginLeft: "auto" }}
                />
              
            </div> */}
          </Toolbar>
        </AppBar>


        {/* -------------------------------------------SIDEBAR------------------------------------------ */}
        <Sidebar
          DrawerStyled={DrawerStyled}
          open={open}
          handleDrawer={handleDrawer}
          isLoading={isLoading}
          DrawerHeader={DrawerHeader}
          handleDrawerToggle={handleDrawerToggle}
          mobileOpen={mobileOpen}
          rol={2}
        />

        {/* ------------------------------------------------------------------------------------------- */}

        <div className="d-flex justify-content-center w-100">
          <div
            className=""
            style={{
              width: phone
                ? "100%"
                : open && !phone
                ? "calc(100vw - 235px)"
                : "calc(100vw - 80px)",
            }}
          >
            <Grid container sx={{ marginTop: !tablet ? 8.3 : 7.6 }}>
              <DrawerHeader />
              <Routes>
                {/* Todas las rutas inexistentes te redirigen a ruta principal */}

                <Route path="guides/active/" element={<ActivesAdmin />} />
                <Route path="guides/active/add/" element={<AddGuide />} />
                <Route path="guides/active/addOrigin/" element={<AddShipmentOrigin />} />
                <Route path="shipmentsOrigins/" element={<ShipmentsOrigins/>} />
                <Route path="guides/routes/" element={<RoutesAdmin />} />
                <Route path="guides/active/route/" element={<CreateRoute />} />
                <Route path="guides/historical/" element={<HistoricalAdmin />}/>
                <Route path="operatives/" element={<Operatives />} />
                <Route path="operatives/add/" element={<AddOperative />} />
                <Route path="operatives/edit/" element={<AddOperative />} />
                <Route path="origins/edit/" element={<AddShipmentOrigin />} />
                <Route path="vehicles/" element={<Vehicles />} />
                <Route path="reports/" element={<Reports />} />
                <Route path="vehicles/add/" element={<AddVehicle />} />
                <Route path="vehicles/edit/" element={<AddVehicle />} />
                <Route path="assignments/" element={<Assignments />} />
                <Route path="account/profile/" element={<AccountProfileAdmin />} />
                <Route path="account/password/" element={<EditPasswordAdmin />} />
              </Routes>
            </Grid>
          </div>
        </div>
      </Box>
    </>
  );

  // return (
  // <Box sx={{ display: "flex" }}>
  //   <CssBaseline enableColorScheme />
  //         <Box
  //             component="nav"
  //             sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
  //             aria-label="mailbox folders"
  //         >
  //             <Sidebar mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} menu={menuAdmin} />
  //         </Box>
  //         <div className='content-width'>
  //             <Grid container className='content-width'>
  //                 <LayoutAdminRouting handleDrawerToggle={handleDrawerToggle} />
  //             </Grid>
  //         </div>
  //     </Box>
  // )
};
