import React from 'react'
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CancelButton = ({ text, width, height, onClick }) => {
    const CancelButtonStyled = styled(Button)({
        width: width,
        height: height,
        boxShadow: '1',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        border: '1.5px solid',
        lineHeight: 1.5,
        borderRadius: 5,
        backgroundColor: '#D60000',
        borderColor: '#D60000',
        color: 'white',
        fontFamily: [
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),

        '&:hover': {
          color: '#D60000',
          backgroundColor: '#FFFFFF',
        }
      });
  return (
    <CancelButtonStyled onClick={onClick}>
        {text}
    </CancelButtonStyled>
  )
}
