import React, { useEffect, useState } from "react";

import {
  Box,
  useMediaQuery,
  IconButton,
  Button,
  Modal,
  Grid,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Skeleton,
} from "@mui/material";
import {
  Close,
  DescriptionOutlined,
  Place,
  PersonPinCircle,
} from "@mui/icons-material";
import { AdminAPI } from "../../api/admin";
import styled from "styled-components";
import PdfVisorModal from "./PdfVisorModal";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  // padding: theme.spacing(1),
  // textAlign: 'center',
  // color: theme.palette.text.secondary,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#181C46",
    color: "white",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const DetailsGuidesModal = ({ handleClose, text, detail }) => {
  const phone = useMediaQuery("(max-width:480px)");
  const tablet = useMediaQuery("(max-width:900px)");
  const [data, setData] = useState({});
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openPdf, setOpenPdf] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");

  const style = {
    position: "absolute",
    maxHeight: "90vh",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: phone ? "90%" : tablet ? "56%" : "65%",
    bgcolor: "background.paper",
    overflow: "scroll",
    boxShadow: 24,
    p: "0px 0px 15px 0px",
    borderRadius: 2,
  };

  const handlePdf = (pdf) => {
    //console.log("el pdf bro", pdf);
    if (pdf.startsWith("http://")) {
        pdf = pdf.replace("http://", "https://");
        //console.log("link cambiado", pdf)
    }
    setPdfUrl(pdf);
    setOpenPdf(true);
};

  useEffect(() => {
    GetDetailsShipment();
  }, []);

  const GetDetailsShipment = async () => {
    setIsLoading(true);
    let data = new FormData();
    data.append("shipment_id", detail.id);

    try {
      const api = new AdminAPI();
      const response = await api.getShipmentDetails(data);
      setData(response.data);
      setItems(response.data.items);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box sx={style}>
      <div className="w-100 px-3  ">
        <div className="d-flex justify-content-end">
          <IconButton onClick={handleClose}>
            <Close sx={{ fontSize: 30 }} />
          </IconButton>
        </div>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          rowSpacing={2}
          columns={{ xs: 6, sm: 6, md: 12, lg: 12 }}
        >
          {/*---Contenedor padre---*/}
          {/* {location.state != undefined && */}
          <Grid item xs={6} sm={6} md={12} lg={12}>
            {" "}
            {/*---Ocupara mitad de pantalla---*/}
            <Grid item xs={12} style={{ height: "100%" }}>
              {/*---Altura de 100 para que ocupe verticalmente---*/}
              <Item>
                <Grid container spacing={2} className="p-3">
                  <Grid item xs={12} md={6}>
                    <div className="text-left">
                      <p className={`font-weight-bold ${phone ? "h5" : "h4"}`}>
                        No. de rastreo
                      </p>
                      <h2 className="font-weight-bold">#{data.trackingId}</h2>
                      <p>{data.status}</p>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {data.pdf != null ? (
                      <Button
                        className={`${phone ? "" : "float-right"}`}
                        size="medium"
                        variant="contained"
                        style={{
                          height: 40,
                          backgroundColor: "#E74C3C",
                        }}
                        startIcon={<DescriptionOutlined fontSize="inherit" />}
                        onClick={() => handlePdf(data.pdf)}
                      >
                        {" "}
                        Ver PDF
                      </Button>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
                <div className="mt-2 w-100">
                  {isLoading ? (
                    <div className="w-100 d-flex">
                      <div className="w-50 p-3">
                        <p>
                          <Skeleton variant="text" />
                        </p>
                        <p>
                          <Skeleton variant="text" />
                        </p>
                        <p>
                          <Skeleton variant="text" />
                        </p>
                        <p>
                          <Skeleton variant="text" />
                        </p>
                        <p>
                          <Skeleton variant="text" />
                        </p>
                      </div>

                      <div className="w-50 p-3">
                        <p>
                          <Skeleton variant="text" />
                        </p>
                        <p>
                          <Skeleton variant="text" />
                        </p>
                        <p>
                          <Skeleton variant="text" />
                        </p>
                        <p>
                          <Skeleton variant="text" />
                        </p>
                        <p>
                          <Skeleton variant="text" />
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex w-100">
                      <Grid container spacing={2} className="p-3">
                        <Grid item xs={12} md={6}>
                          <h4 className="font-weight-bold">
                            <Place /> Recolección
                          </h4>
                          <p>
                            {/* <strong>Origen: </strong> */}
                            {data.origin}{" "}
                          </p>
                          <p>
                            {/* <strong>Fecha recolección: </strong> */}
                            {data.pickUp}
                          </p>
                          <p>{data.deliveryType} </p>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <h4 className="font-weight-bold">
                            <PersonPinCircle /> Dirección de envío
                          </h4>
                          <p>{data.destination} </p>
                          <p>
                            {data.destinationFirstName}{" "}
                            {data.destinationLastName}
                          </p>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          className="d-flex align-items-end flex-column p-0"
                        >
                          <p className="font-weight-bold">Total</p>
                          <p className="">${data.price}</p>
                        </Grid>
                      </Grid>
                    </div>
                  )}
                </div>
              </Item>
            </Grid>
          </Grid>

          <Grid item xs={6} sm={6} md={12} lg={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} className="">
                <Box className="mt-2 " sx={{ width: "100%" }}>
                  <Paper className="px-2" sx={{ width: "100%" }}>
                    <div className="pt-2 w-100 d-flex justify-content-center">
                      <p
                        className={`font-weight-bold gray-font ml-2 ${
                          phone ? "h5" : "h4"
                        }`}
                      >
                        Artículos
                      </p>
                    </div>

                    <TableContainer
                      sx={{ maxHeight: 220 }}
                      component={Paper}
                      // sx={{ maxHeight: height - (74 + 90) }}
                    >
                      <Table
                        sx={{ minWidth: 750 }}
                        aria-label="sticky table"
                        stickyHeader
                      >
                        <thead className="h-12 bg-[#004876c2] text-white">
                          <TableRow className="text-center">
                            <StyledTableCell>
                              <span>Producto</span>
                            </StyledTableCell>
                            <StyledTableCell>Alto</StyledTableCell>
                            <StyledTableCell>Largo</StyledTableCell>
                            <StyledTableCell>Peso</StyledTableCell>
                            <StyledTableCell>Ancho</StyledTableCell>
                            <StyledTableCell>Cantidad</StyledTableCell>
                            <StyledTableCell>Unidad de medida</StyledTableCell>
                            <StyledTableCell>Id del paquete</StyledTableCell>
                            <StyledTableCell>Precio unitario</StyledTableCell>
                          </TableRow>
                        </thead>
                        <TableBody>
                          {isLoading ? (
                            <TableRow hover role="checkbox" tabIndex={-1}>
                              <TableCell>
                                <Skeleton variant="text" />
                              </TableCell>
                              <TableCell align="center">
                                <Skeleton variant="text" />
                              </TableCell>
                              <TableCell align="center">
                                <Skeleton variant="text" />
                              </TableCell>
                              <TableCell align="center">
                                <Skeleton variant="text" />
                              </TableCell>
                            </TableRow>
                          ) : (
                            items.map((item, index) => {
                              const labelId = `enhanced-table-checkbox-${index}`;
                              return (
                                <TableRow hover role="checkbox" tabIndex={-1} style={{ backgroundColor: index % 2 === 0 ? "#f0f0f0" : "white" }}>
                                  <TableCell
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                    padding="none"
                                    align="center"
                                  >
                                    {item.description || "no disponible"}
                                  </TableCell>
                                  <TableCell align="center">
                                    {item.height || "no disponible"}
                                  </TableCell>
                                  <TableCell align="center">
                                    {item.length || "no disponible"}
                                  </TableCell>
                                  <TableCell align="center">
                                    {item.weight || "no disponible"}
                                  </TableCell>
                                  <TableCell align="center">
                                    {item.width || "no disponible"}
                                  </TableCell>
                                  <TableCell align="center">
                                    {item.quantity || "no disponible"}
                                  </TableCell>
                                  <TableCell align="center">
                                    {item.weightUnit || "no disponible"}
                                  </TableCell>
                                  <TableCell align="center">
                                    {item.clientPackageId || "no disponible"}
                                  </TableCell>
                                  <TableCell align="center">
                                    {item.unitaryProductPrice || "no disponible"}
                                  </TableCell>
                                </TableRow>
                              );
                            })
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      {/* <Modal open={open} onClose={() => console.log("Cerrando")}>
            <ModalImgEvidences images={images} handleClose={handleClose} />
        </Modal>*/}

      <Modal open={openPdf} onClose={() => console.log("Cerrando")}>
        <PdfVisorModal pdf={pdfUrl} handleClose={() => setOpenPdf(false)} />
      </Modal>
    </Box>
  );
};

{
  /* <div className="d-flex flex-row justify-content-between">
                  <div className="text-left">
                    <p className={`font-weight-bold ${phone ? "h5" : "h4"}`}>
                      No. de rastreo
                    </p>
                    <h2 className="font-weight-bold">#{data.trackingId}</h2>
                    <p>{data.status}</p>
                  </div>
                  
                    {data.pdf != null ? (
                      <Button
                        className="float-right"
                        size="medium"
                        variant="contained"
                        style={{
                          height: 40,
                          backgroundColor: "#E74C3C",
                        }}
                        startIcon={<DescriptionOutlined fontSize="inherit" />}
                        onClick={() => handlePdf(data.pdf[0].pdf_file)}
                      > Ver PDF
                      </Button>
                    ) : (
                      ""
                    )}
                  
                </div> */
}

{
  /* <Grid container spacing={2}>
<Grid item xs={4}>
    <div>
        <Typography><strong>Cliente:</strong> {data.destinationFirstName} {data.destinationLastName}</Typography>
        <Typography><strong>Origen:</strong> {data.origin}</Typography>
        <Typography><strong>Destino:</strong> {data.destination}</Typography>
        <Typography><strong>Tipo de envío:</strong> {data.deliveryType}</Typography>

    </div>
</Grid>

<Grid item xs={4}>
    {data.items.map((el, i) => (
        <div key={i}>
        <Typography><strong>Producto: </strong>{el.description} </Typography>
        <Typography><strong>Talla: </strong> {el.size} </Typography>
        <Typography><strong>Ancho: </strong> {el.width} </Typography>
        <Typography><strong>Alto: </strong> {el.height} </Typography>
        <Typography><strong>Largo: </strong> {el.length} </Typography>
        <Typography><strong>Peso: </strong> {el.weight} </Typography>

        <Typography><strong>Precio: </strong> </Typography>

    </div>
    ))}
</Grid>

<Grid item xs={4}>
    <Typography>Fecha de recolección: <strong></strong></Typography>
    <Typography>Estatus: <strong></strong></Typography>
    <Typography>Guía: <strong></strong></Typography>

</Grid>

</Grid> */
}
