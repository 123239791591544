import { getPath } from '../helpers/getHost';
import { HttpRequest } from './HttpRequest';

export class OperativeAPI {
    constructor() {
        this.http = new HttpRequest()
        this.host = getPath()
        this.token = localStorage.getItem("token");
        this.config = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Token ${this.token}`
            }
        }
    }

    getAssignedRouteEP(data) {
        return this.http.post(`${this.host}/ws/getAssignedRoute/`, data, this.config)
    }

   
}
