import React, { useEffect, useState } from "react";
import { useMediaQuery, Popover } from "@mui/material";
import { roles } from "../../../helpers/enums";
import { Search } from "../../../components/Search/Search";
import { AddButton } from "../../../components/Buttons/AddButton";
import { VehiclesTable } from "../../../components/Tables/VehiclesTable";
import { AdminAPI } from "../../../api/admin";
import { SkeletonTables } from "../../../components/Tables/SkeletonTables";
import { FiltersModal } from "../../../components/Modal/FiltersModal";
import { vehiclesHeaders } from "../../../components/Excel/headers";
import { DownloadButtonExcel } from "../../../components/Excel/DownloadButtonExcel";
import { getDateCleaned } from "../../../helpers/getDateCleaned";

export const Vehicles = () => {
  const [isLoading, setIsLoading] = useState(false);
  const phone = useMediaQuery("(max-width:480px)");
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0); /*Estado p/paginacion*/
  const [total, setTotal] = useState(); /*Total de paginas enviado desde api*/
  const [count, setCount] = useState(); /*Paginacion listas */
  const [anchorEl, setAnchorEl] = useState(null);

  // ERROR STATES
  const [errors, setErrors] = useState({
    error: false,
    errorNullStart: false,
    errorNullEnd: false,
  });

  // EXCEL STATES

  const [isLoadingExcel, setIsLoadingExcel] = useState(false);
  const [dataExcel, setDataExcel] = useState([]);

  // FILTER STATES
  const [startFilterDate, setStartFilterDate] = useState(null);
  const [endFilterDate, setEndFilterDate] = useState(null);
  const [status, setStatus] = useState("Todas");
  const statusList = [
    { status: "Asignadas", value: 1 },
    { status: "No asignadas", value: 2 },
    { status: "Todas", value: 3 },
  ];
  const [prevData, setPrevData] = useState({
    status: "Asignadas",
  });
  const [option, setOption] = useState(status);
  const today = new Date();

  // EFFECTS
  useEffect(() => {
    RequestData();
  }, [page, search]);

  // CLASSES
  class Vehicle {
    static form(download) {
      let data = new FormData();

      data.append("download", download);
      data.append("page", page + 1);

      if (endFilterDate != null) {
        data.append("endDate", getDateCleaned(endFilterDate));
      }
      if (startFilterDate != null) {
        data.append("startDate", getDateCleaned(startFilterDate));
      }
      if (status == "Asignadas") {
        data.append("available", false);
      }
      if (status == "No asignadas") {
        data.append("available", true);
      }
      if (status == "Todas") {
        data.append("allVehicles", true);
      }
      if (search != "") {
        data.append("search", search);
      }

      return data;
    }
  }

  //DATA REQUESTS
  //------------------------------------------------

  const GetVehiclesList = async () => {
    setIsLoading(true);
    const data = Vehicle.form(false);
    try {
      const api = new AdminAPI();
      const response = await api.getVehicles(data);
      setData(response.data.data);
      setTotal(response.data.total);
      setCount(Math.ceil(response.data.total / 10));
      setIsLoading(false);
    } catch (error) {}
  };

  const GetVehiclesExcelList = async () => {
    setIsLoadingExcel(true);
    const data = Vehicle.form(true);

    try {
      const api = new AdminAPI();
      const response = await api.getVehicles(data);
      setDataExcel(response.data.data);
      setIsLoadingExcel(false);
    } catch (error) {}
  };

  const RequestData = () => {
    GetVehiclesList();
  };

  //FILTER SETTINGS

  const settingFilters = (status) => {
    setStatus(status);
  };

  //HANDLERS
  //------------------------------------------------

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleCloseFilterModal = () => setAnchorEl(null);

  const handleCloseModal = () => {
    setAnchorEl(null);
    settingFilters(prevData.status);
  };

  const handleFilter = () => {
    handleCloseFilterModal();
    RequestData();
    setPrevData({
      status: status,
    });
  };

  const handleResetFilter = async () => {
    settingFilters("Asignadas");
  };

  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
  };

  return (
    <>
      <div className="w-100 px-3">
        <div>
          {phone ? (
            <div className="mt-2 w-100 p-1 ">
              <div className="d-flex bg-white justify-content-between border-radius-10"></div>
              <div className="d-flex w-100 mt-2"></div>
            </div>
          ) : (
            <div className="w-100 bg-white d-flex justify-content-between mt-1 p-2 border-radius-10">
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <Search setSearch={setSearch} handleClick={handleClick} />
                  <AddButton text={"Agregar unidad"} link={"add/"} />
                  <DownloadButtonExcel
                    text={"Descargar"}
                    disabled={data.length > 0 ? false : true}
                    data={dataExcel}
                    headers={vehiclesHeaders}
                    sheetName={"Unidades"}
                    isLoading={isLoadingExcel}
                    getData={() => GetVehiclesExcelList()}
                  />
                </div>
              </div>
            </div>
          )}

          {isLoading ? (
            <div className="mt-3 bg-white w-100 d-flex justify-content-between p-2 border-radius-10">
              <SkeletonTables
                num={3}
              />
            </div>
          ) : (
            <div className="mt-3 bg-white w-100 d-flex justify-content-between p-3 border-radius-10">
              <VehiclesTable
                GetVehiclesList={GetVehiclesList}
                table={status}
                search={search}
                data={data}
                rol={roles.ADMIN}
                total={total}
                page={page}
                setPage={setPage}
              />
            </div>
          )}
        </div>
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseFilterModal}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <FiltersModal
          errors={errors}
          setErrors={setErrors}
          handleClose={handleCloseModal}
          dates={{
            setEnd: setEndFilterDate,
            end: endFilterDate,
            setStart: setStartFilterDate,
            start: startFilterDate,
          }}
          fields={[
            {
              array: statusList,
              prop: "status",
              id: "status",
              type: "Estatus",
              value: status,
              handleOnChange: handleChangeStatus,
            },
          ]}
          date={{ today }}
          onClickFilter={handleFilter}
          onClickResetFilter={handleResetFilter}
        />
      </Popover>
    </>
  );
};
