import React from 'react'
import { Typography, Box, useMediaQuery } from "@mui/material";
import { WarningTwoTone } from '@mui/icons-material';
import { AcceptButton } from '../Buttons/AcceptButton';
import { useNavigate } from 'react-router-dom';
import { CancelButton } from '../Buttons/CancelButton';


export const WarningModal = ({ handleClose, handleCancel,  text }) => {
    const phone = useMediaQuery("(max-width:480px)");
    const tablet = useMediaQuery("(max-width:900px)");

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: phone ? "90%" : tablet ? "56%" : "37%",
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 3,
        borderRadius: 2,
    };

    return (
        <Box sx={style}>
            <div className=' text-center '>
                <Typography variant='h5' className='font-weight-bold font-color-green'>
                    {text}
                </Typography>
            </div>
            <div className='d-flex justify-content-center mt-2'>
                <WarningTwoTone sx={{ color: "#f5d000", fontSize: 80, }}
                />
            </div>
            <div className='d-flex justify-content-around mt-2'>
                <CancelButton text={"Cancelar"} width={phone ? "90%" : "40%"} onClick={handleCancel} />
                <AcceptButton text={"Continuar"} width={phone ? "90%" : "40%"} onClick={handleClose} />
            </div>
        </Box>
    )
}
