import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { Router } from './router/Router';
import { AuthProvider } from './context/AuthProvider';
import 'leaflet/dist/leaflet.css';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <BrowserRouter>
    <AuthProvider>
        <Router />
    </AuthProvider>
      </BrowserRouter>

  </React.StrictMode>
);
