import { Backdrop, Button, Grid, MenuItem, Modal, TextField, useMediaQuery } from '@mui/material';
import { useFormik } from "formik";
import * as Yup from "yup";

import React, { useState } from 'react'
import { AcceptButton } from '../../../components/Buttons/AcceptButton';
import { CancelButton } from '../../../components/Buttons/CancelButton';
import { CheckCircleTwoTone, DoubleArrowTwoTone } from '@mui/icons-material';

import { useEffect } from 'react';
import { ErrorModal } from '../../../components/Modal/ErrorModal';

import { IntegrationAPI } from '../../../api/integration';

export const Integration = () => {
    const phone = useMediaQuery("(max-width:480px)");
    const [status, setStatus] = useState([]);
    const [openBD, setOpenBD] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [recoveryStatus, setRecoveryStatus] = useState([]);
    const [strStatus, setStrStatus] = useState([]);

    const fields = [
        {
            keyName: "Número de guía",
            value: "trackingNumber",
            editable: false,
            size: false,
        },
    ];

    const dropdown = [
        {
            keyName: "Estatus",
            value: "status",
            editable: false,
            size: false
        },
    ];

    useEffect(() => {
        GetStatusList()
    }, [])


    const GetStatusList = async () => {
        let data = new FormData();

        try {
            const api = new IntegrationAPI()
            const response = await api.getListStatusShipmentEP(data);
            
            setStatus(response.data)

        } catch (error) {}
    };

    const GetUpdateStatus = async (obj) => {
        setOpenBD(true);

        let data = new FormData();
        data.append("trackingNumber", obj.trackingNumber);
        data.append("typeMovement", obj.status);

        try {
            const api = new IntegrationAPI()
            const response = await api.updateShipmentEP(data);

            setOpenBD(false);
            const auxStatus = status.find(el => el.code == obj.status).description;
            setRecoveryStatus([...recoveryStatus, auxStatus])


        } catch (error) {
            setOpenBD(false);
            setOpenError(true);
            
            if (error.response.data[0] == "This guide hsa already finished") {
                setErrorText("Esta guía ya ha sido finalizada.")
            }
        }
    };
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            status: "",
            trackingNumber: "",
        },

        validationSchema: Yup.object({
            status: Yup.string()
                .required("Campo Obligatorio"),
            trackingNumber: Yup.string()
                .required("Campo Obligatorio"),
        }),

        onSubmit: (values) => {
            const obj = {
                ...values,
            };
            // console.log(values)
            GetUpdateStatus(obj)
        },
    });

    return (
        <div style={{ width: "100%", height: "100vh", backgroundColor: "#1f4368", display: 'flex', justifyContent: "center" }}>

            <div style={{ width: "95%" }} className={`d-flex flex-column border-radius-10 px-4`}>
                <div className='mt-4 p-4 d-flex justify-content-center align-items-center'>
                    <img src="https://dspstatic.s3.us-east-2.amazonaws.com/img/logo.svg" alt="" width={80} />
                    <p className='text-white'> <span style={{ fontSize: 50, }}>Daystore</span> <span style={{ fontSize: 20, }}></span> </p>
                </div>
                <div className='bg-white border-radius-10 w-100 pb-4'>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justify="center"
                        rowSpacing={2} >{/*---Contenedor padre---*/}
                        <Grid item xs={12} sm={12} md={6}>{/*---Ocupara mitad de pantalla---*/}

                            <form onSubmit={formik.handleSubmit}>

                                <Grid item style={{ height: "100%" }} xs={12} className={`bg-white p-3 border-radius-10`}>{/*---Altura de 100 para que ocupe verticalmente---*/}
                                    <div className='mb-4'>
                                        <p className={` ${phone ? "h6" : "h2"}`}>
                                            <DoubleArrowTwoTone /> Datos orden
                                        </p>
                                    </div>
                                    <Grid container columns={{ xs: 4, sm: 6, md: 12, lg: 12 }}>
                                        {fields.map((field) => (
                                            <Grid key={field.keyName} item xs={field.size ? 6 : 12}>
                                                <div
                                                    className={` d-flex mb-3 flex-column ${phone ? "px-2 " : "px-2"
                                                        }`}
                                                >
                                                    <div
                                                        className={`d-flex align-items-center mr-2 ${phone && "mb-2"
                                                            }`}
                                                    >
                                                        <p className="h6 te" htmlFor={field.value}>{`${field.keyName
                                                            }: ${field.type ? "*" : ""}`}</p>
                                                    </div>

                                                    <div className="d-flex align-items-center w-100">

                                                        <TextField
                                                            error={
                                                                formik.touched[field.value] &&
                                                                formik.errors[field.value]
                                                            }
                                                            label={field.keyName}
                                                            variant="outlined"
                                                            fullWidth
                                                            onChange={formik.handleChange}
                                                            value={formik.values[field.value]}
                                                            name={field.value}
                                                            id={field.value}
                                                            helperText={
                                                                formik.touched[field.value] &&
                                                                    formik.errors[field.value] ? (
                                                                    <div>{formik.errors[field.value]}</div>
                                                                ) : null
                                                            }

                                                        />

                                                    </div>
                                                </div>

                                            </Grid>
                                        ))}

                                        {dropdown.map((field) => (
                                            <Grid key={field.keyName} item xs={field.size ? 6 : 12}>
                                                <div
                                                    className={` d-flex mb-3 flex-column ${phone ? "px-2 " : "px-2"
                                                        }`}
                                                >
                                                    <div
                                                        className={`d-flex align-items-center mr-2 ${phone && "mb-2"
                                                            }`}
                                                    >
                                                        <p className="h6 te" htmlFor={field.value}>{`${field.keyName
                                                            }: ${field.type ? "*" : ""}`}</p>
                                                    </div>

                                                    <div className="d-flex align-items-center w-100">

                                                        <TextField
                                                            select
                                                            error={
                                                                formik.touched[field.value] &&
                                                                formik.errors[field.value]
                                                            }
                                                            label={field.keyName}
                                                            variant="outlined"
                                                            fullWidth
                                                            onChange={(e) => { formik.handleChange(e) }}
                                                            value={formik.values[field.value]}
                                                            name={field.value}
                                                            id={field.value}
                                                            helperText={
                                                                formik.touched[field.value] &&
                                                                    formik.errors[field.value] ? (
                                                                    <div>{formik.errors[field.value]}</div>
                                                                ) : null
                                                            }
                                                        >
                                                            {status.map((el, i) => (
                                                                <MenuItem key={i} value={el.code}>
                                                                   {el.id} - {el.description}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>

                                                    </div>
                                                </div>

                                            </Grid>

                                        ))}
                                    </Grid>
                                    <div className='w-100 d-flex flex-column align-items-center'>
                                        <AcceptButton text={"Cambiar estatus"} width={"96%"} height={50} type="submit" />
                                        <div className='mt-2 w-100 d-flex flex-column align-items-center'>
                                            <CancelButton text={"Reiniciar"} width={"96%"} height={50} onClick={() => window.location.reload()} />
                                        </div>

                                    </div>
                                </Grid>
                            </form>

                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                            <Grid item xs={12} className={`p-3 `}>
                                <div className='mb-4'>
                                    <p className={` ${phone ? "h6" : "h2"}`}>
                                        <DoubleArrowTwoTone /> Historial
                                    </p>
                                </div>
                                <div >
                                    {/* {console.log(recoveryStatus, "statse")} */}
                                    {
                                        recoveryStatus.length != 0 &&
                                        recoveryStatus.map((el, i) => (
                                            <p key={i} className='d-flex align-items-center'><CheckCircleTwoTone sx={{ color: "green", marginRight: 1 }} />{el}</p>
                                        ))
                                    }
                                </div>
                            </Grid>

                        </Grid>

                    </Grid>
                </div>

            </div>

            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBD}
            >
                <span className="loader"></span>
            </Backdrop>

            <Modal open={openError} onClose={() => console.log("Cerrando")}>
                <ErrorModal
                    text={`Error al actualizar el estatus.`}
                    error={errorText}
                    handleClose={() => setOpenError(false)}
                />
            </Modal>
        </div>
    )
}
