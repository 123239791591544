import {
    Card,
    CardBody,
    CardHeader,
  } from "@material-tailwind/react";
  import Chart from "react-apexcharts";
   
  const chartConfig = {
    type: "bar",
    height: 212, // Ajustar la altura según sea necesario
    width: 500, // Agregar el ancho según sea necesario
    series: [
      {
        name: "Incidencias",
        data: [50, 40, 58, 56, 12, 34, 76, 9],
        colors: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b", "#e377c2", "#7f7f7f"],
      },
    ],
    options: {
      chart: {
        toolbar: {
          show: true,
        },
      },
      title: {
        show: false,
      },
      dataLabels: {
        enabled: true,
      },
      colors: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b", "#e377c2", "#7f7f7f"], // Asignar colores a las barras
      plotOptions: {
        bar: {
          columnWidth: "50%", // Ajustar el ancho de las columnas
          borderRadius: 2,
        },
      },
      xaxis: {
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        labels: {
          style: {
            colors: "#616161",
            fontSize: "12px",
            fontFamily: "inherit",
            fontWeight: 400,
          },
          formatter: function (val) {
            return val.length > 10 ? val.slice(0, 10) + "..." : val;
          },
        },
        categories: [
          "Cliente cerrado",
          "Cliente sin dinero",
          "Fallo de congelador",
          "Direccion inubicable",
          "Producto en malas condiciones",
          "No procesado por el proveedor",
          "Pendiente de indemnizacion",
          "Faltante de piezas",
        ],
      },
      yaxis: {
        labels: {
          style: {
            colors: "#616161",
            fontSize: "12px",
            fontFamily: "inherit",
            fontWeight: 400,
          },
        },
      },
      grid: {
        show: true,
        borderColor: "#dddddd",
        strokeDashArray: 5,
        xaxis: {
          lines: {
            show: true,
          },
        },
        padding: {
          top: 0,
          right: 0,
        },
      },
      fill: {
        opacity: 0.8,
      },
      tooltip: {
        theme: "dark",
      },
    },
  };
   
  export default function BarChart({ title }) {
    return (
      <Card>
        <CardHeader
          floated={false}
          shadow={false}
          color="transparent"
          className="flex flex-col rounded-none md:flex-row md:items-center"
        >
          <div>
            <h3 className="text-2xl text-[#004876] font-bold">{title}</h3>
          </div>
        </CardHeader>
        <CardBody className=" pb-0">
          <div style={{ width: '500px' }}> {/* Ajustar el ancho del contenedor */}
            <Chart {...chartConfig} />
          </div>
        </CardBody>
      </Card>
    );
  }
