import React from "react";

import { Typography, Box, useMediaQuery } from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";
import { AcceptButton } from "../Buttons/AcceptButton";

export const SuccessModal = ({ handleClose, text }) => {
  const phone = useMediaQuery("(max-width:480px)");
  const tablet = useMediaQuery("(max-width:900px)");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: phone ? "90%" : tablet ? "56%" : "37%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
    borderRadius: 2,
  };

  return (
    <Box sx={style}>
      <div className=" text-center ">
        <Typography variant="h5" className="font-weight-bold font-color-green">
          {text}
        </Typography>
      </div>
      <div className="d-flex justify-content-center mt-2">
        <CheckCircleOutline sx={{ color: "#00aa17", fontSize: 80 }} />
      </div>
      <div className="d-flex justify-content-center mt-2">
        <AcceptButton
          text={"Aceptar"}
          width={phone ? "90%" : "40%"}
          onClick={handleClose}
        />
      </div>
    </Box>
  );
};
