import React, { useRef } from "react";
import { ChangeCircleTwoTone, FaceTwoTone, HighlightOffTwoTone } from '@mui/icons-material';
import { useMediaQuery } from "@mui/material";


export const InputProfileImagePreview = ({ handleChangeImage, imageFile, setImageFile, setFieldValue, errorImage, touchedImage }) => {
  const phone = useMediaQuery("(max-width:480px)");

  const inputFile = useRef(null);

  const handleClickOpen = () => {
    inputFile.current.click();
  };

  const handleRemoveImage = () => {
    setImageFile(null)
    setFieldValue("existImageFile", false); /*Cambiar el valor de imageFile en Formik para condicional en image*/
    setFieldValue("image", "")
  }

  return (
    <div className={`w-100 d-flex align-items-center flex-column justify-content-center`} style={{ minHeight: 200}}>

      <div className="w-100 d-flex flex-column align-items-center justify-content-center">
        <div className={`w-100 d-flex flex-column align-items-center justify-content-center cursor-pointer `} style={{ height: 150, width: 150, borderRadius: "50%" }}>
          {imageFile ? (
            <div className="w-100 d-flex flex-column align-items-center justify-content-center">
              <div className="d-flex flex-column justify-content-between">
                <div></div>

                <div className={`circular_image ${touchedImage && errorImage ? "input-red-border" : "input-grey-border"}`}>
                  {typeof (imageFile) == "object" ?
                    (<img src={(URL.createObjectURL(imageFile))} alt="preview" width={"100%"} />)
                    : (
                      <img src={imageFile} alt="preview" width={"100%"} />
                    )
                  }
                </div>

              </div>
              <div className="w-100 d-flex justify-content-around mt-2">
              <div onClick={handleRemoveImage} className="d-flex align-content-center cursor-pointer mr-2"><HighlightOffTwoTone /><p className="m-0 " >Eliminar</p></div>
                <div onClick={handleClickOpen} className="d-flex align-content-center cursor-pointer"><ChangeCircleTwoTone /> <p className="m-0 " >Cambiar</p></div>
              </div>
            </div>

            
          ) : (
            <>

              <div onClick={handleClickOpen} className="d-flex flex-column align-items-center justify-content-center">
                <div className={`text-center ${touchedImage && errorImage ? "text-danger" : "text-secondary"}`}>
                  Click para subir una imagen de perfil
                </div>
                <div className="d-flex justify-content-center text-secondary">
                  <FaceTwoTone sx={{ fontSize: 100 }} color={`${touchedImage && errorImage ? "error" : "inherit"}`} />
                </div>
              </div>
              {/* } */}
            </>
          )}
        </div>
        {touchedImage &&
          errorImage ? (
          <div className="pl-3 mt-1 text-danger xs-font">{errorImage}</div>
        ) : null}
      </div>

      <input
        ref={inputFile}
        onChange={(e) => {
          handleChangeImage(e, 1);
        }}
        id={"image"}
        type="file"
        name="image-license"
        accept="image/png, image/jpeg"
        style={{ display: "none" }}
      />
    </div>
  );
};
