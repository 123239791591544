import React, { useEffect, useState } from "react";
import { useMediaQuery, Popover, Modal } from "@mui/material";
import { Search } from "../../../components/Search/Search";
import { ActiveGuidesTableAdmin } from "../../../components/Tables/ActiveGuidesTableAdmin";
import { AddButton } from "../../../components/Buttons/AddButton";
import { CreateRoutesModal } from "../../../components/Modal/CreateRoutesModal";
import { AdminAPI } from "../../../api/admin";
import { SkeletonTables } from "../../../components/Tables/SkeletonTables";
import { FiltersModal } from "../../../components/Modal/FiltersModal";
import { DownloadButtonExcel } from "../../../components/Excel/DownloadButtonExcel";
import { activeGuidesHeaders } from "../../../components/Excel/headers";
import { getDateCleaned2 } from "../../../helpers/getDateCleaned";
import { UploadFileModal } from "../../../components/Modal/UploadFileModal";
import { MenuActionButton } from "../../../components/Buttons/MenuActionButton";
import { Upload } from "@mui/icons-material";
export const ActivesAdmin = () => {
  const phone = useMediaQuery("(max-width:480px)");
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0); /*Estado p/paginacion*/
  const [total, setTotal] = useState(); /*Total de paginas enviado desde api*/
  const [count, setCount] = useState(); /*Paginacion listas */
  const [openRoutes, setOpenRoutes] = useState(false);
  const [openUploadFile, setOpenUploadFile] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);

  //ERROR STATES
  //------------------------------------------------
  const [errors, setErrors] = useState({
    error: false,
    errorNullStart: false,
    errorNullEnd: false,
  });

  // FILTER STATES
  //------------------------------------------------
  const [startFilterDate, setStartFilterDate] = useState(null);
  const [endFilterDate, setEndFilterDate] = useState(null);
  const [status, setStatus] = useState("Actuales");
  const statusList = [
    { status: "Sin asignar" },
    { status: "Asignadas" },
    { status: "Todas" },
    { status: "Actuales" },
  ];
  const [prevData, setPrevData] = useState({
    startFilterDate: null,
    endFilterDate: null,
    status: "Sin asignar",
  });
  const today = new Date();

  //EFFECTS
  //------------------------------------------------
  useEffect(() => {
    RequestData();
  }, [page, search]);

  // EXCEL STATES
  //------------------------------------------------
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);
  const [dataExcel, setDataExcel] = useState([]);

  //DATA REQUESTS
  //------------------------------------------------
  const getShipments = async () => {
    setIsLoading(true);
    const data = Shipment.form(false);

    try {
      const api = new AdminAPI();
      const response = await api.getShipments(data);
      setData(response.data.data);
      setTotal(response.data.total);
      setCount(Math.ceil(response.data.total / 10));
      setIsLoading(false);
    } catch (error) {}
  };

  const GetShipmentsExcelList = async () => {
    setIsLoadingExcel(true);
    const data = Shipment.form(true);
  
    try {
      const api = new AdminAPI();
      const response = await api.getShipments(data);
      
      const transformedData = response.data.data.map(row => ({
        tracking_id: row.tracking_id || "NA",
        destination: row.destination || "NA",
        coordinates: `lat: ${row.location?.lat}, lng: ${row.location?.lng}` || "NA",
        item_description: row.items.map(item => item.description).join(", ") || "NA",
        total_price: row.items.reduce((sum, item) => sum + item.unitaryProductPrice, 0).toFixed(2) > 0
          ? row.items.reduce((sum, item) => sum + item.unitaryProductPrice, 0).toFixed(2)
          : "NA",
        formatted_date: new Date(Date.parse(row.date_created)).toLocaleDateString() || "NA",
        origin: row.origin || "NA",
        formatted_time: new Date(row.date_created).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) || "NA",
        pick_up_date: row.pick_up_date || "NA",
        status: row.status,
        previous_status: row.previous_status || "NA",
      }));
      
      // Asignar los datos transformados
      setDataExcel(transformedData);
      setIsLoadingExcel(false);
    } catch (error) {
      console.error("Error fetching shipment data", error);
      setIsLoadingExcel(false);
    }
  };

  const RequestData = () => {
    getShipments();
  };

  //CLASSES
  //------------------------------------------------
  class Shipment {
    static form(download) {
      let data = new FormData();
      
      data.append("download", download);
      data.append("page", page + 1);

      if (endFilterDate != null) {
        data.append("endDate", getDateCleaned2(endFilterDate));
      }
      if (startFilterDate != null) {
        data.append("startDate", getDateCleaned2(startFilterDate));
      }
      if (status == "Sin asignar") {
        data.append("allShipments", false);
        data.append("isActive", true);
        data.append("inRoute", false);
      }
      if (status == "Asignadas") {
        data.append("allShipments", false);
        data.append("isActive", true);
        data.append("inRoute", true);
      }
      if (status == "Todas") {
        data.set("isActive", true);
        data.set("allShipments", "");
        data.set("inRoute", "");
      }
      if (status == "Actuales") {
        data.set("allShipments", true);
        data.set("isActive", "");
        data.set("inRoute", "");
      }
      if (search != "") {
        data.append("search", search);
      }

      return data;
    }
  }

  //FILTER SETTINGS
  //------------------------------------------------

  const settingFilters = (status, firstDay, endDay) => {
    setStatus(status);
    setStartFilterDate(firstDay);
    setEndFilterDate(endDay);
  };

  //HANDLERS
  //------------------------------------------------

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleCloseFilterModal = () => setAnchorEl(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseModal = () => {
    setAnchorEl(null);
    settingFilters(
      prevData.status,
      prevData.startFilterDate,
      prevData.endFilterDate
    );
    setErrors({
      error: false,
      errorNullStart: false,
      errorNullEnd: false,
    });
  };

  const handleFilter = () => {
    handleCloseFilterModal();
    if (startFilterDate != null || endFilterDate != null) {
      if (startFilterDate != null && endFilterDate == null) {
        setErrors({ errorNullEnd: true });
      } else if (endFilterDate != null && startFilterDate == null) {
        setErrors({ errorNullStart: true });
      } else if (endFilterDate < startFilterDate) {
        setErrors({ error: true });
      } else {
        setErrors({
          error: false,
          errorNullStart: false,
          errorNullEnd: false,
        });
        RequestData();
        setPrevData({
          status: status,
          startFilterDate: startFilterDate,
          endFilterDate: endFilterDate,
        });
      }
    } else {
      RequestData();
      setPrevData({
        status: status,
        startFilterDate: startFilterDate,
        endFilterDate: endFilterDate,
      });
    }
  };

  const handleResetFilter = () => {
    settingFilters("Actuales");
    setErrors({
      error: false,
      errorNullStart: false,
      errorNullEnd: false,
    });
  };

  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
  };

  return (
    <>
      <div className="w-100 px-3">
        <div>
          {phone ? (
            <div className="mt-2 w-100 p-1 ">
              <div className="d-flex bg-white justify-content-between border-radius-10"></div>
              <div className="d-flex w-100 mt-2"></div>
            </div>
          ) : (
            <div className="w-100 bg-white d-flex justify-content-between mt-1 p-2 border-radius-10">
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <Search setSearch={setSearch} handleClick={handleClick} />
                  <AddButton text={"Crear ruta"} link={"route/"} />

                  <MenuActionButton
                    text={"Carga masiva de guías"}
                    onClick={() => setOpenUploadFile(true)}
                    icon={<Upload className="mr-1" />}
                  />
                  {/* <ButtonTypeLink
                    text={"Programar ruta"}
                    onClick={() => setOpenRoutes(true)}
                  /> */}
                  {/* )} */}
                  <DownloadButtonExcel
                    text={"Descargar"}
                    disabled={data.length > 0 ? false : true}
                    data={dataExcel}
                    headers={activeGuidesHeaders}
                    sheetName={"Guías activas"}
                    isLoading={isLoadingExcel}
                    getData={() => GetShipmentsExcelList()}
                  />
                </div>
              </div>
            </div>
          )}
          {isLoading ? (
            <div className="mt-3 bg-white w-100 d-flex justify-content-between p-2 border-radius-10">
              <SkeletonTables num={3} />
            </div>
          ) : (
            <div className="mt-3 bg-white w-100 d-flex justify-content-between p-3 border-radius-10">
              <ActiveGuidesTableAdmin
                search={search}
                data={data}
                rol={"admin"}
                total={total}
                page={page}
                setPage={setPage}
              />
            </div>
          )}
        </div>
      </div>
      {/* Modals */}
      <Modal open={openRoutes} onClose={() => console.log("Cerrando")}>
        <CreateRoutesModal
          routes={data}
          GetListShipmentList={getShipments}
          total={total}
          page={page}
          setPage={setPage}
          handleClose={() => setOpenRoutes(false)}
        />
      </Modal>
      <Modal open={openUploadFile}>
        <UploadFileModal
          handleClose={() => {
            setOpenUploadFile(false);
          }}
        />
      </Modal>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseFilterModal}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <FiltersModal
          errors={errors}
          setErrors={setErrors}
          handleClose={handleCloseModal}
          dates={{
            setEnd: setEndFilterDate,
            end: endFilterDate,
            setStart: setStartFilterDate,
            start: startFilterDate,
          }}
          fields={[
            {
              array: statusList,
              prop: "status",
              id: "status",
              type: "Estatus",
              value: status,
              handleOnChange: handleChangeStatus,
            },
          ]}
          date={{
            today,
          }}
          onClickFilter={handleFilter}
          onClickResetFilter={handleResetFilter}
        />
      </Popover>
    </>
  );
};
