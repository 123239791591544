import React, { useEffect, useState } from "react";
import Workbook from "react-excel-workbook";
import FictionExcelButton from "../Buttons/FictionExcelTemplateButton";

export const ExcelDownloadMultipleSheet = ({
  setClick,
  click,
  fileName,
  template,
  info,
}) => {
  useEffect(() => {
    if (info.length > 0 && click) {
      document.getElementById("downloadTemplateButton").click();
      setClick(false);
    }
  });

  let keys = Object.keys(template);

  return (
    <>
      <div>
        <Workbook
          filename={`DS Logistics ${fileName}.xlsx`}
          element={<FictionExcelButton />}
        >
          {keys.map((sheet, i) => (
            <Workbook.Sheet key={i} data={info} name={sheet}>
              {template[sheet].map((header, i) => (
                <Workbook.Column
                  key={i}
                  label={header.label}
                  value={header.key}
                />
              ))}
            </Workbook.Sheet>
          ))}
        </Workbook>
      </div>
    </>
  );
};
