import React, { useState } from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Download } from "@mui/icons-material";
import { ExcelDownloadOneSheet } from "./ExcelDownloadOneSheet";
import { ExcelDownloadMultipleSheet } from "./ExcelDownloadMultipleSheet";

const DownloadButtonStyled = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  lineHeight: 1.5,
  borderRadius: 15,
  backgroundColor: "white",
  color: "#1f4368",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  "&:hover": {
    backgroundColor: "#e9eaef",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#FFF",
  }
});

export const DownloadTemplateButton = ({
  text,
  disabled,
  template,
  fileName,
  multiple,
  data,
}) => {
  const [click, setClick] = useState(false);
  return (
    <>
      <div className="d-flex align-items-center m-1">
        <DownloadButtonStyled
          variant="contained"
          disableRipple
          disabled={disabled}
          onClick={() => {
            setClick(true);
          }}
        >
          <Download className="mr-1" />
          <p className="m-0 no-wrap">{text}</p>
        </DownloadButtonStyled>
        {multiple ? (
          <ExcelDownloadMultipleSheet
            click={click}
            setClick={setClick}
            fileName={fileName}
            template={template}
            info={data}
          />
        ) : (
          <ExcelDownloadOneSheet
            headers={template}
            sheetName={fileName}
            click={click}
            setClick={setClick}
          />
        )}
      </div>
    </>
  );
};
