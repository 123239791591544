export const roles = {
    USER: 2,
    ADMIN: 3
}

export const channelsEnum = {
    ALL_TEXT: "Todos",
    ALL_INDEX: 0
}

