import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Modal,
} from "@mui/material";

import {
  DriveFileRenameOutlineTwoTone,
  PersonAddTwoTone,
  Delete,
  PersonRemoveAlt1TwoTone,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useViewPortDimensions } from "../../hooks/useViewPortDimensions";
import { TablesPagination } from "../Pagination/TablesPagination";
import { AssignOperativeModal } from "../Modal/AssignOperativeModal";
import { UnassignOperativeModal } from "../Modal/UnassignOperativeModal";
import { WarningReasonModal } from "../Modal/DeletionReasonModal";

export const VehiclesTable = ({
  search,
  data,
  setPage,
  page,
  total,
  table,
  GetVehiclesList,
}) => {
  const { height, width } = useViewPortDimensions();
  const navigate = useNavigate();

  const [openAssign, setOpenAssign] = useState(false);
  const [openUnassign, setOpenUnassign] = useState(false);
  const [vehicle, setVehicle] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [openBD, setOpenBD] = useState(false);

  const handleAssign = (vehicle) => {
    setOpenAssign(true);
    setVehicle(vehicle);
  };

  const handleUnassign = (vehicle) => {
    setOpenUnassign(true);
    setVehicle(vehicle);
  };

  const handleDelete = (vehicle) => {
    setOpenDelete(true);
    setVehicle(vehicle);
  };

  const DeleteVehicle = async (vehicleId) => {
    setOpenBD(true);
    setOpenDelete(false);
    let data = new FormData();
  };

  const handleClick = (row) => {
    navigate("/admin/vehicles/edit/", {
      state: {
        id: row.idVehicle != null ? row.idVehicle : "",
        brand: row.brand != null ? row.brand : "",
        plate: row.licensePlate != null ? row.licensePlate : "",
        model: row.model != null ? row.model : "",
        typeVehicle: row.typeVehicle != null ? row.typeVehicle : "",
        idTypeVehicle: row.idTypeVehicle != null ? row.idTypeVehicle : "",
        year: row.year != null ? row.year : "",
        capacity: row.max_cargo != null ? row.max_cargo : "",
        hologram: row.hologram != null ? row.hologram : "",
        operative: row.operative != null ? row.operative : "",
        vinNumber: row.vinNumber != null ? row.vinNumber : "",
        licensePlate: row.licensePlate != null ? row.licensePlate : "",
        operatorId: row.operatorId != null ? row.operatorId : "",
        operatorName: row.operatorName != null ? row.operatorName : "",
        operatorLastName:
          row.operatorLastName != null ? row.operatorLastName : "",
      },
    });
  };

  const assigned = (
    <Table sx={{ minWidth: 750 }} aria-label="sticky table" stickyHeader>
      <thead className="h-12 bg-[#004876c2] text-white">
        <TableRow>
          <TableCell align="center">Acciones</TableCell>
          <TableCell align="center">Placas</TableCell>
          <TableCell align="center">Marca</TableCell>
          <TableCell align="center">Modelo</TableCell>
          <TableCell align="center">Tipo</TableCell>
          <TableCell align="center">Año</TableCell>
          <TableCell align="center">Holograma</TableCell>
          <TableCell align="center">Operador</TableCell>
        </TableRow>
      </thead>
      <TableBody>
        {data.map((row, index) => {
          const labelId = `enhanced-table-checkbox-${index}`;
          return (
            <TableRow hover role="checkbox" tabIndex={-1} key={index} style={{ backgroundColor: index % 2 === 0 ? "#f0f0f0" : "white" }}>
              <TableCell padding="checkbox">
                <div className="d-flex justify-content-around">
                  <Tooltip title={"Editar"} arrow placement="top">
                    <div className="d-flex align-items-center justify-content-center">
                      <IconButton onClick={() => handleClick(row)}>
                        <DriveFileRenameOutlineTwoTone />
                      </IconButton>
                    </div>
                  </Tooltip>
                  <Tooltip title={"Desasociar"} arrow placement="top">
                    <div className="d-flex align-items-center justify-content-center">
                      <IconButton onClick={() => handleUnassign(row)}>
                        <PersonRemoveAlt1TwoTone />
                      </IconButton>
                    </div>
                  </Tooltip>
                  <Tooltip title={"Eliminar"} arrow placement="top">
                    <div className="d-flex align-items-center justify-content-center">
                      <IconButton onClick={() => handleDelete(row)}>
                        <Delete />
                      </IconButton>
                    </div>
                  </Tooltip>
                </div>
              </TableCell>

              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="none"
                align="center"
              >
                <span style={{ whiteSpace: "nowrap" }}>{row.licensePlate}</span>
              </TableCell>
              <TableCell align="center">{row.brand}</TableCell>
              <TableCell align="center">
                <span style={{ whiteSpace: "nowrap" }}>{row.model}</span>
              </TableCell>
              <TableCell align="center">{row.typeVehicle}</TableCell>
              <TableCell align="center">{row.year}</TableCell>
              <TableCell align="center">{row.hologram}</TableCell>
              <TableCell align="center">
                <span style={{ whiteSpace: "nowrap" }}>
                  {row.operatorName} {row.operatorLastName}
                </span>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );

  const notAssigned = (
    <Table sx={{ minWidth: 750 }} aria-label="sticky table" stickyHeader>
      <thead className="h-12 bg-[#004876c2] text-white">
        <TableRow>
          <TableCell align="center">Placas</TableCell>
          <TableCell align="center">Marca</TableCell>
          <TableCell align="center">Modelo</TableCell>
          <TableCell align="center">Tipo</TableCell>
          <TableCell align="center">Año</TableCell>
          <TableCell align="center">Holograma</TableCell>
          <TableCell align="center">Acciones</TableCell>
        </TableRow>
      </thead>
      <TableBody>
        {data.map((row, index) => {
          const labelId = `enhanced-table-checkbox-${index}`;
          return (
            <TableRow hover role="checkbox" tabIndex={-1} key={index} style={{ backgroundColor: index % 2 === 0 ? "#f0f0f0" : "white" }}>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="none"
                align="center"
              >
                <span style={{ whiteSpace: "nowrap" }}>{row.licensePlate}</span>
              </TableCell>
              <TableCell align="center">{row.brand}</TableCell>
              <TableCell align="center">
                <span style={{ whiteSpace: "nowrap" }}>{row.model}</span>
              </TableCell>
              <TableCell align="center">{row.typeVehicle}</TableCell>
              <TableCell align="center">{row.year}</TableCell>
              <TableCell align="center">{row.hologram}</TableCell>
              <TableCell padding="checkbox">
                <div className="d-flex justify-content-around">
                  <Tooltip title={"Editar"} arrow placement="top">
                    <div className="d-flex align-items-center justify-content-center">
                      <IconButton onClick={() => handleClick(row)}>
                        <DriveFileRenameOutlineTwoTone />
                      </IconButton>
                    </div>
                  </Tooltip>

                  <Tooltip title={"Asignar"} arrow placement="top">
                    <div className="d-flex align-items-center justify-content-center">
                      <IconButton onClick={() => handleAssign(row)}>
                        <PersonAddTwoTone />
                      </IconButton>
                    </div>
                  </Tooltip>
                </div>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );

  // const getTable = (filter) => {
  //   const options = {
  //     Asignadas: assigned,
  //     "No asignadas": notAssigned,
  //   };
  //   return options[filter] ?? "No hay data";
  // };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: height - (74 + 90) }}>
            {/* {getTable(table)} */}
            <Table
              sx={{ minWidth: 750 }}
              aria-label="sticky table"
              stickyHeader
            >
              <thead className="h-12 bg-[#004876c2] text-white">
                <TableRow>
                  <TableCell align="center">Acciones</TableCell>
                  <TableCell align="center">Placas</TableCell>
                  <TableCell align="center">Marca</TableCell>
                  <TableCell align="center">Modelo</TableCell>
                  <TableCell align="center">Tipo</TableCell>
                  <TableCell align="center">Año</TableCell>
                  <TableCell align="center">Holograma</TableCell>
                  <TableCell align="center">Operador</TableCell>
                  <TableCell align="center">Activo</TableCell>
                </TableRow>
              </thead>
              <TableBody>
                {data.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index} style={{ backgroundColor: index % 2 === 0 ? "#f0f0f0" : "white" }}>
                      <TableCell padding="checkbox">
                        <div className="d-flex justify-content-around">
                          <Tooltip title={"Editar"} arrow placement="top">
                            <div className="d-flex align-items-center justify-content-center">
                              <IconButton onClick={() => handleClick(row)}>
                                <DriveFileRenameOutlineTwoTone />
                              </IconButton>
                            </div>
                          </Tooltip>

                          {row.operatorName ? (
                            <Tooltip title={"Desasociar"} arrow placement="top">
                              <div className="d-flex align-items-center justify-content-center">
                                <IconButton onClick={() => handleUnassign(row)}>
                                  <PersonRemoveAlt1TwoTone />
                                </IconButton>
                              </div>
                            </Tooltip>
                          ) : (
                            <div></div>
                            // <Tooltip title={"Asignar"} arrow placement="top">
                            //   <div className="d-flex align-items-center justify-content-center">
                            //     <IconButton onClick={() => handleAssign(row)}>
                            //       <PersonAddTwoTone />
                            //     </IconButton>
                            //   </div>
                            // </Tooltip>
                          )}

                          {row.isActive ?(<Tooltip title={"Eliminar"} arrow placement="top">
                            <div className="d-flex align-items-center justify-content-center">
                              <IconButton onClick={() => handleDelete(row)}>
                                <Delete />
                              </IconButton>
                            </div>
                          </Tooltip>):( <div></div>)}

                          
                          {/* {row.operatorName || !row.isActive ? (
                            <div></div>
                          ) : (
                            <Tooltip title={"Asignar"} arrow placement="top">
                              <div className="d-flex align-items-center justify-content-center">
                                <IconButton onClick={() => handleAssign(row)}>
                                  <PersonAddTwoTone />
                                </IconButton>
                              </div>
                            </Tooltip>
                          )} */}
                        </div>
                      </TableCell>

                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        align="center"
                      >
                        <span style={{ whiteSpace: "nowrap" }}>
                          {row.licensePlate}
                        </span>
                      </TableCell>
                      <TableCell align="center">{row.brand}</TableCell>
                      <TableCell align="center">
                        <span style={{ whiteSpace: "nowrap" }}>
                          {row.model}
                        </span>
                      </TableCell>
                      <TableCell align="center">{row.typeVehicle}</TableCell>
                      <TableCell align="center">{row.year}</TableCell>
                      <TableCell align="center">{row.hologram}</TableCell>
                      <TableCell align="center">
                        <span style={{ whiteSpace: "nowrap" }}>
                          {row.operatorName} {row.operatorLastName}
                        </span>
                      </TableCell>
                      <TableCell align="center">{row.isActive ? "Activado" : "Desactivado"}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>

            {search != "" && data.length == 0 ? (
              <div className="d-flex justify-content-center p-4">
                No se encontraron resultados para "{search}"
              </div>
            ) : data.length == 0 && search == "" ? (
              <div className="d-flex justify-content-center p-4">
                No existen registros aún.
              </div>
            ) : (
              ""
            )}
          </TableContainer>
          <TablesPagination setPage={setPage} page={page} total={total} />
        </Paper>
      </Box>

      <Modal open={openAssign} onClose={() => console.log("Cerrando")}>
        <AssignOperativeModal
          vehicle={vehicle}
          GetVehiclesList={GetVehiclesList}
          handleClose={() => setOpenAssign(false)}
        />
      </Modal>

      <Modal open={openUnassign} onClose={() => console.log("Cerrando")}>
        <UnassignOperativeModal
          vehicle={vehicle}
          GetVehiclesList={GetVehiclesList}
          handleClose={() => setOpenUnassign(false)}
        />
      </Modal>
      <Modal open={openDelete} onClose={() => console.log("Cerrando")}>
        <WarningReasonModal
          recordId={vehicle.idVehicle}
          action={"DeleteVehicle"}
          handleCancel={() => setOpenDelete(false)}
          text={`Estas apunto de eliminar el vehículo: ${vehicle.idVehicle}`}
        />
      </Modal>
    </>
  );
};
