import React, { useEffect } from "react";
import Workbook from "react-excel-workbook";
import FictionExcelButton from "../Buttons/FictionExcelButton";

export const ExcelDownloadOneSheet = ({
  data,
  headers,
  sheetName,
  setClick,
  click,
}) => {
  useEffect(() => {
    if (data.length > 0 && click) {
      document.getElementById("downloadButton").click();
      setClick(false);
    }
  }, [data]);

  const excelHeaders = headers.filter((header) => header.excel == true);

  return (
    <>
      <div>
        <Workbook
          filename={`DS Logistics ${sheetName}.xlsx`}
          element={<FictionExcelButton />}
        >
          <Workbook.Sheet data={data} name={sheetName}>
            {excelHeaders.map((el, i) => (
              <Workbook.Column key={i} label={el.label} value={el.key} />
            ))}
          </Workbook.Sheet>
        </Workbook>
      </div>
    </>
  );
};
