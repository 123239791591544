import React from "react";
import {
  Box,
  TextField,
  IconButton,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
} from "@mui/material";

import { Close } from "@mui/icons-material";
import { AcceptButton } from "../../components/Buttons/AcceptButton";

export const RejectDeliveryModal = ({ handleClose, detail }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [state, setState] = React.useState({});

  const { gilad, jason, antoine } = state;
  const error = [gilad, jason, antoine].filter((v) => v).length !== 2;
  return (
    <Box sx={style}>
      <div className="d-flex justify-content-end">
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </div>

      <FormControl>
        <FormLabel id="demo-radio-buttons-group-label">
          Seleccione el motivo de rechazo
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="female"
          name="radio-buttons-group"
        >
          <FormControlLabel
            value="direccion_no_accesible"
            control={<Radio />}
            label="Dirección no accesible"
          />
          <FormControlLabel
            value="direccion_no_encontrada"
            control={<Radio />}
            label="Dirección no encontrada"
          />
          <FormControlLabel
            value="personal_no_quiso_entregar_paquete"
            control={<Radio />}
            label="Personal no quiso entregar paquete"
          />
          <FormControlLabel
            value="persona_no_encontrada"
            control={<Radio />}
            label="Persona no encontrada"
          />
          <FormControlLabel
            value="falla_mecanica"
            control={<Radio />}
            label="Falla mecánica"
          />
          <FormControlLabel 
            value="otro" 
            control={<Radio />} 
            label="Otro" />
        </RadioGroup>
        <TextField
          id="filled-basic"
          label="Comentarios (opcional)"
          variant="filled"
        />
        <div className="mt-4 d-flex justify-content-end">
          <AcceptButton text={"Aceptar"} />
        </div>
      </FormControl>
    </Box>
  );
};
