import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";

import {
  TextField,
  Modal,
  useMediaQuery,
  Grid,
  IconButton,
  Backdrop,
  InputAdornment,
} from "@mui/material";

import { useNavigate, useLocation } from "react-router-dom";
import { AcceptButton } from "../Buttons/AcceptButton";
import { InputLicensePreview } from "../InputImage/InputLicensePreview";
import { InputProfileImagePreview } from "../InputImage/InputProfileImagePreview";
import { AdminAPI } from "../../api/admin";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { getDateCleaned } from "../../helpers/getDateCleaned";
import { SuccessModal } from "../Modal/SuccessModal";
import { ErrorModal } from "../Modal/ErrorModal";
import { CancelButton } from "../Buttons/CancelButton";

export const OperativeForm = () => {
  const navigate = useNavigate();
  const phone = useMediaQuery("(max-width:480px)");
  const [imageFile, setImageFile] = useState(null);
  const [imageLicense, setImageLicense] = useState(null);
  const [showPass, setShowPass] = useState(false);

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [openBD, setOpenBD] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dateBirth, setDateBirth] = useState(null);
  const [expirationDriveLicense, setExpirationDriveLicense] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();

  const open = Boolean(anchorEl);

  const today = new Date();

  const handleClose = () => {
    navigate(`/admin/operatives/`);
  };

  const handleChangeDate = (value) => {
    setDateBirth(value);
    formik.setFieldValue("dateBirth", value);
  };
  const handleChangeExpirationDriveLicense = (value) => {
    setExpirationDriveLicense(value);
    formik.setFieldValue("expirationDriveLicense", value);
  };

  const handleShowPass = () => setShowPass(!showPass);

  const handleChangeImage = ({ target }, type) => {
    if (!target.files) {
      return;
    }

    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.tiff)$/i;

    if (!allowedExtensions.exec(target.files[0].name)) {
      alert("Formato inválido, porfavor escoge una imagen");
      target.value = "";
      target.files = null;
      return false;
    }

    const file = target.files[0];
    switch (type) {
      case 1:
        setImageFile(file);
        formik.setFieldValue("existImageFile", true);
        break;

      case 2:
        setImageLicense(file);
        formik.setFieldValue("existImageLicense", true);
        break;
    }
  };

  const fields = [
    {
      keyName: "Nombre(s)",
      value: "name",
      type: true,
      size: false,
      dropdown: false,
      isPass: false,
    },
    {
      keyName: "Apellidos",
      value: "lastName",
      type: true,
      size: false,
      dropdown: false,
      isPass: false,
    },
    {
      keyName: "Teléfono celular",
      value: "personalNumber",
      type: true,
      size: false,
      dropdown: false,
      isPass: false,
    },
    {
      keyName: "Email",
      value: "email",
      type: true,
      size: false,
      dropdown: false,
      isPass: false,
    },
  ];
  const pass = [
    {
      keyName: "Contraseña",
      value: "password",
      type: true,
      size: false,
      dropdown: false,
      isPass: true,
    },
  ];

  useEffect(() => {
    // Validar si es edidión y mostrar imágnes
    if (location.state != undefined) {
      setDateBirth(location.state.dateBirth);
      setExpirationDriveLicense(location.state.dateExpiration);
      setImageFile(location.state.image);
      setImageLicense(location.state.imageLicense);
    }
  }, []);

  const AddPartner = async (obj) => {
    setOpenBD(true);
    let data = new FormData();

    data.append("name", obj.name);
    data.append("last_name", obj.lastName);
    data.append("phone", obj.personalNumber);
    data.append("email", obj.email);

    const parts = getDateCleaned(obj.dateBirth).split("/");

    // Reconstruct the date string in YYYY-MM-DD format
    const formattedDateBirth = `${parts[0]}-${parts[1]}-${parts[2]}`;

    data.append("date_of_birth", formattedDateBirth);

    const parts1 = getDateCleaned(obj.expirationDriveLicense).split("/");

    // Reconstruct the date string in YYYY-MM-DD format
    const formattedExpirationDrive = `${parts1[0]}-${parts1[1]}-${parts1[2]}`;

    data.append("drivers_license_expiration", formattedExpirationDrive);

    if (location.state == undefined) {
      data.append("password", obj.password);
    }

    if (typeof imageFile == "object") {
      data.append("photo", obj.image);
    }
    if (typeof imageLicense == "object") {
      data.append("drivers_license", obj.imageLicense);
    }

    let method = "POST";

    if (location.state != undefined) {
      method = "PUT";

      data.append("partnerId", location.state.id);
    }

    try {
      const api = new AdminAPI();
      const response = await api.createPartner(data, method);

      if (response.status == 200) {
        setOpenSuccess(true);
        setOpenBD(false);
      } else {
        setOpenBD(false);
        setErrorMessage(
          `No se puede crear el registro ${response.response.data.non_field_errors[0]}`
        );
        setOpenError(true);
      }
    } catch (error) {
      setOpenBD(false);
      setOpenError(true);
      //console.log(error);
    }
  };

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues:
      location.state != undefined
        ? {
            name: location.state.name,
            lastName: location.state.lastName,
            email: location.state.email,
            personalNumber: location.state.personalNumber,
            dateBirth: location.state.dateBirth,
            expirationDriveLicense: location.state.dateExpiration,
            existImageFile: location.state.existImageFile,
            image: location.state.image,
            existImageLicense: location.state.existImageLicense,
            imageLicense: location.state.imageLicense,
          }
        : {
            name: "",
            lastName: "",
            email: "",
            personalNumber: "",
            dateBirth: "",
            expirationDriveLicense: "",
            existImageFile: false,
            image: "",
            existImageLicense: false,
            imageLicense: "",
            password: "",
          },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "Sólo alfanuméricos y espacios"
        )
        .max(100, "Demasiado largo"),
      lastName: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "Sólo alfanuméricos y espacios"
        )
        .max(100, "Demasiado largo"),
      email: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          "Formato incorrecto"
        )
        .max(100, "Demasiado largo"),
      personalNumber: Yup.string()
        .required("Campo Obligatorio")
        .matches(/^\d+$/, "Deben ser números sin espacios")
        .min(10, "Demasiado corto")
        .max(50, "Demasiado largo"),
      image: Yup.string().when("existImageFile", {
        is: false,
        then: Yup.string().required("Campo obligatorio"),
      }),
      imageLicense: Yup.string().when("existImageLicense", {
        is: false,
        then: Yup.string().required("Campo obligatorio"),
      }),
      expirationDriveLicense: Yup.date()
        .nullable(true) /*Needed to accept null values */
        .required("Ingresa fecha de expiración"),
      dateBirth: Yup.date()
        .nullable(true) /*Needed to accept null values */
        .required("Ingresa fecha de nacimiento"),

      // employeeId: Yup.string()
      //     .required("Campo Obligatorio")
      //     .matches(
      //         /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
      //         "Sólo alfanuméricos y espacios"
      //     )
      //     .max(50, "Demasiado largo"),
       password: Yup.string()
           .required("Campo Obligatorio")
           .max(30, "Demasiado largo")
           .matches(
               /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
               "Mínimo 8 caracteres, una mayúscula, una minúscula, un número y un carácter especial"
           )
    }),

    onSubmit: (values) => {
      const obj = {
        ...values,
        image: imageFile,
        imageLicense: imageLicense,
      };
      AddPartner(obj);
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          rowSpacing={2}
        >
          {/*---Contenedor padre---*/}
          <Grid item xs={12} sm={12} md={6}>
            {/*---Ocupara mitad de pantalla---*/}
            <Grid
              item
              style={{ height: "100%" }}
              xs={12}
              className={`border bg-white p-3 border-radius-10`}
            >
              {/*---Altura de 100 para que ocupe verticalmente---*/}
              <div>
                <p className={` ${phone ? "h6" : "h5"}`}>Datos generales</p>
              </div>
              <Grid container columns={{ xs: 4, sm: 6, md: 12, lg: 12 }}>
                {fields.map((field) => (
                  <Grid key={field.keyName} item xs={field.size ? 6 : 12}>
                    <div
                      className={` d-flex mb-3 flex-column ${
                        phone ? "px-2 " : "px-2"
                      }`}
                    >
                      <div
                        className={`d-flex align-items-center mr-2 ${
                          phone && "mb-2"
                        }`}
                      >
                        <p className="h6 te" htmlFor={field.value}>{`${
                          field.keyName
                        }: ${field.type ? "*" : ""}`}</p>
                      </div>

                      <div className="d-flex align-items-center w-100">
                        <TextField
                          error={
                            formik.touched[field.value] &&
                            formik.errors[field.value]
                          }
                          size="small"
                          label={field.keyName}
                          variant="outlined"
                          type={
                            !field.isPass
                              ? "text"
                              : field.isPass && showPass
                              ? "password"
                              : "text"
                          }
                          fullWidth
                          onChange={formik.handleChange}
                          value={formik.values[field.value]}
                          name={field.value}
                          id={field.value}
                          helperText={
                            formik.touched[field.value] &&
                            formik.errors[field.value] ? (
                              <div>{formik.errors[field.value]}</div>
                            ) : null
                          }
                          InputProps={{
                            endAdornment: field.isPass && (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleShowPass}
                                  edge="end"
                                >
                                  {showPass ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                ))}
                 {location.state != undefined
                  ? null
                  : pass.map((field) => (
                      <Grid key={field.keyName} item xs={field.size ? 6 : 12}>
                        <div
                          className={` d-flex mb-3 flex-column ${
                            phone ? "px-2 " : "px-2"
                          }`}
                        >
                          <div
                            className={`d-flex align-items-center mr-2 ${
                              phone && "mb-2"
                            }`}
                          >
                            <p className="h6 te" htmlFor={field.value}>{`${
                              field.keyName
                            }: ${field.type ? "*" : ""}`}</p>
                          </div>

                          <div className="d-flex align-items-center w-100">
                            <TextField
                              error={
                                formik.touched[field.value] &&
                                formik.errors[field.value]
                              }
                              size="small"
                              label={field.keyName}
                              variant="outlined"
                              type={
                                !field.isPass
                                  ? "text"
                                  : field.isPass && showPass
                                  ? "password"
                                  : "text"
                              }
                              fullWidth
                              onChange={formik.handleChange}
                              value={formik.values[field.value]}
                              name={field.value}
                              id={field.value}
                              helperText={
                                formik.touched[field.value] &&
                                formik.errors[field.value] ? (
                                  <div>{formik.errors[field.value]}</div>
                                ) : null
                              }
                              InputProps={{
                                endAdornment: field.isPass && (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleShowPass}
                                      edge="end"
                                    >
                                      {showPass ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>
                        </div>
                      </Grid>
                    ))} 

                <Grid item xs={6}></Grid>
                <div className={`w-100 d-flex ${phone && "flex-column"}`}>
                  <div className={`d-flex flex-column mb-3 w-100 px-2`}>
                    <div
                      className={`d-flex align-items-center mr-2 ${
                        phone && "mb-2"
                      }`}
                    >
                      <p
                        className="h6 te"
                        htmlFor={"birthDate"}
                      >{`Fecha de nacimiento: *`}</p>
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        maxDate={today}
                        label={"Fecha nacimiento"}
                        inputFormat="dd/MM/yyyy"
                        value={dateBirth}
                        onChange={handleChangeDate}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={
                              formik.touched.dateBirth &&
                              formik.errors.dateBirth
                            }
                            helperText={
                              formik.touched.dateBirth &&
                              formik.errors.dateBirth ? (
                                <div>{formik.errors.dateBirth}</div>
                              ) : null
                            }
                            onKeyDown={onKeyDown}
                            fullWidth
                            size="small"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>

                  <div className={`d-flex flex-column mb-3 w-100 px-2`}>
                    <div
                      className={`d-flex align-items-center mr-2 ${
                        phone && "mb-2"
                      }`}
                    >
                      <p
                        className="h6 "
                        htmlFor={"expirationDriveLicense"}
                      >{`Expiración licencia: *`}</p>
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        minDate={today}
                        label={"Fecha expiración licencia"}
                        inputFormat="dd/MM/yyyy"
                        value={expirationDriveLicense}
                        onChange={handleChangeExpirationDriveLicense}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={
                              formik.touched.expirationDriveLicense &&
                              formik.errors.expirationDriveLicense
                            }
                            helperText={
                              formik.touched.expirationDriveLicense &&
                              formik.errors.expirationDriveLicense ? (
                                <div>
                                  {formik.errors.expirationDriveLicense}
                                </div>
                              ) : null
                            }
                            onKeyDown={onKeyDown}
                            fullWidth
                            size="small"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Grid
              item
              xs={12}
              className={`border bg-white p-3 border-radius-10`}
            >
              <div className="">
                <p className={` ${phone ? "h6" : "h5"}`}>
                  Fotografía operador *
                </p>
              </div>
              <Grid container columns={{ xs: 4, sm: 6, md: 12, lg: 12 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {/* -------------------------------IMAGEN------------------------------- */}

                    <div
                      className={` d-flex justify-content-center ${
                        phone ? "px-2" : ""
                      } border border-radius-10 bg-white`}
                    >
                      <InputProfileImagePreview
                        location={location.state}
                        setFieldValue={formik.setFieldValue}
                        errorImage={formik.errors.image}
                        touchedImage={formik.touched.image}
                        text={"Imagen de perfil"}
                        handleChangeImage={handleChangeImage}
                        imageFile={imageFile}
                        setImageFile={setImageFile}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              className={`border bg-white p-3 border-radius-10 mt-3`}
            >
              <div className="">
                <p className={` ${phone ? "h6" : "h5"}`}>
                  Fotografía licencia *
                </p>
              </div>
              <Grid container columns={{ xs: 4, sm: 6, md: 12, lg: 12 }}>
                {/* <Grid container spacing={2}> */}
                <Grid item xs={12}>
                  {/* -------------------------------IMAGEN------------------------------- */}

                  <div
                    className={` d-flex justify-content-center ${
                      phone ? "px-2" : ""
                    } border border-radius-10 bg-white`}
                  >
                    <InputLicensePreview
                      location={location.state}
                      setFieldValue={formik.setFieldValue}
                      errorImage={formik.errors.imageLicense}
                      touchedImage={formik.touched.imageLicense}
                      text={"Imagen de la licencia"}
                      handleChangeImage={handleChangeImage}
                      imageFile={imageLicense}
                      setImageLicense={setImageLicense}
                    />
                  </div>
                </Grid>
                {/* </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <div
          className={`mt-2 mb-2 ${!phone ? "d-flex justify-content-end" : ""}`}
        >
          <div className="mr-2">
            <CancelButton
              text={"Cancelar"}
              onClick={() => {
                navigate("/admin/operatives/");
              }}
              width={phone ? "100%" : ""}
            />
          </div>

          <AcceptButton
            text={location.state != undefined ? "Editar" : "Agregar"}
            // text={"Agregar"}
            type={"submit"}
            width={phone ? "100%" : ""}
          />
        </div>
      </form>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBD}
      >
        <span className="loader"></span>
      </Backdrop>

      {/* <Modal open={openSuccess} onClose={() => console.log("Cerrando")}> */}
      <Modal open={openSuccess} onClose={() => console.log("Cerrando")}>
        <SuccessModal
          handleClose={handleClose}
          text={`Operador ${
            location.state != undefined ? "editado" : "creado"
          } con éxito.`}
        />
      </Modal>
      <Modal open={openError}>
        <ErrorModal
          text={errorMessage}
          handleClose={() => setOpenError(false)}
        />
      </Modal>
    </>
  );
};
