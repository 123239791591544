import React, { useEffect, useRef, useState } from "react";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { useViewPortDimensions } from "../../hooks/useViewPortDimensions";
import { TablesPagination } from "../Pagination/TablesPagination";

export const VehiclesAssignedTable = ({ search, data, setPage, page, total, table }) => {
    const { height, width } = useViewPortDimensions();
    const navigate = useNavigate();

    const handleClick = (row) => {
        navigate('/admin/vehicles/edit/', {
            // state: {
            //     id: row.idVehicle != null ? row.idVehicle : "",
            //     brand: row.brand != null ? row.brand : "",
            //     plate: row.licensePlate != null ? row.licensePlate : "",
            //     model: row.model != null ? row.model : "",
            //     typeVehicle: row.typeVehicle != null ? row.typeVehicle : "",
            //     year: row.year != null ? row.year : "",
            //     capacity: row.capacity != null ? row.capacity : "",
            //     hologram: row.hologram != null ? row.hologram : "",
            //     operative: row.operative != null ? row.operative : "",
            //     vinNumber: row.vinNumber != null ? row.vinNumber : "",
            //     licensePlate: row.licensePlate != null ? row.licensePlate : "",
            //     operatorId: row.operatorId != null ? row.operatorId : "",
            //     operatorName: row.operatorName != null ? row.operatorName : "",
            //     operatorLastName: row.operatorLastName != null ? row.operatorLastName : ""
            // },
        });
    };

    const assigned = (
        <Table sx={{ minWidth: 750 }} aria-label="sticky table" stickyHeader>
            <thead className="h-12 bg-[#004876c2] text-white">
                <TableRow>
                    <TableCell align="center"><span style={{ whiteSpace: "nowrap" }}>Operador asignado</span></TableCell>
                    <TableCell align="center">Marca</TableCell>
                    <TableCell align="center">Modelo</TableCell>
                    <TableCell align="center">Tipo</TableCell>
                    <TableCell align="center">Año</TableCell>
                    <TableCell align="center">Holograma</TableCell>
                    <TableCell align="center"><span style={{ whiteSpace: "nowrap" }}>Fecha de asignación</span></TableCell>
                    <TableCell align="center">Acciones</TableCell>

                </TableRow>
            </thead>
            <TableBody>
                {
                    data.map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={index} style={{ backgroundColor: index % 2 === 0 ? "#f0f0f0" : "white" }}>

                                <TableCell component="th" id={labelId} scope="row" padding="none" align="center">
                                {row.operator.name} {row.operator.lastName}
                                </TableCell>
                                <TableCell align="center"><span style={{ whiteSpace: "nowrap" }}> {row.assigned_unit.brand} </span></TableCell>
                                {/* <TableCell align="center">{row.brand}</TableCell> */}
                                <TableCell align="center"><span style={{ whiteSpace: "nowrap" }}>{row.assigned_unit.model}</span></TableCell>
                                <TableCell align="center">{row.assigned_unit.typeVehicle}</TableCell>
                                <TableCell align="center">{row.assigned_unit.year}</TableCell>
                                <TableCell align="center">{row.assigned_unit.hologram}</TableCell>
                                <TableCell align="center">{row.date_assigned}</TableCell>

                                <TableCell padding="checkbox">
                                    <div className="d-flex justify-content-around">
                                        {/* <Tooltip title={"Editar"} arrow placement="top">
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <IconButton onClick={() => handleClick(row)}>
                                                                <DriveFileRenameOutlineTwoTone />
                                                            </IconButton>
                                                        </div>
                                                    </Tooltip>

                                                    <Tooltip title={"Eliminar"} arrow placement="top">
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <IconButton onClick={() => handleClick(row)}>
                                                                <DeleteForeverTwoTone />
                                                            </IconButton>
                                                        </div>
                                                    </Tooltip> */}
                                    </div>

                                </TableCell>

                            </TableRow>
                        );
                    })
                }
            </TableBody>
        </Table>
    );

    const notAssigned = (
        <Table sx={{ minWidth: 750 }} aria-label="sticky table" stickyHeader>
            <TableHead>
                <TableRow>
                    <TableCell align="center">Nombre</TableCell>
                    <TableCell align="center">Apellido</TableCell>
                    <TableCell align="center">Teléfono</TableCell>
                    <TableCell align="center">Email</TableCell>
                    <TableCell align="center"><span style={{ whiteSpace: "nowrap" }}>Expiración licencia</span></TableCell>
                    <TableCell align="center"><span style={{ whiteSpace: "nowrap" }}>Operador asignado</span></TableCell>
                    <TableCell align="center"><span style={{ whiteSpace: "nowrap" }}>Fecha de asignación</span></TableCell>
                    <TableCell align="center">Acciones</TableCell>

                </TableRow>
            </TableHead>
            <TableBody>
                {
                    data.map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={index}>

                                <TableCell component="th" id={labelId} scope="row" padding="none" align="center">
                                    <span style={{ whiteSpace: "nowrap" }}> {row.assigned_unit.brand} </span>
                                </TableCell>
                                {/* <TableCell align="center">{row.brand}</TableCell> */}
                                <TableCell align="center"><span style={{ whiteSpace: "nowrap" }}>{row.assigned_unit.model}</span></TableCell>
                                <TableCell align="center">{row.assigned_unit.typeVehicle}</TableCell>
                                <TableCell align="center">{row.assigned_unit.year}</TableCell>
                                <TableCell align="center">{row.assigned_unit.hologram}</TableCell>
                                <TableCell align="center">{row.operator.name} {row.operator.lastName}</TableCell>
                                <TableCell align="center">{row.date_assigned}</TableCell>

                                <TableCell padding="checkbox">
                                    <div className="d-flex justify-content-around">
                                        {/* <Tooltip title={"Editar"} arrow placement="top">
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <IconButton onClick={() => handleClick(row)}>
                                                                <DriveFileRenameOutlineTwoTone />
                                                            </IconButton>
                                                        </div>
                                                    </Tooltip>

                                                    <Tooltip title={"Eliminar"} arrow placement="top">
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <IconButton onClick={() => handleClick(row)}>
                                                                <DeleteForeverTwoTone />
                                                            </IconButton>
                                                        </div>
                                                    </Tooltip> */}
                                    </div>

                                </TableCell>

                            </TableRow>
                        );
                    })
                }
            </TableBody>
        </Table>
    );

    const getTable = (filter) => {
        const options = {
            "Asignados": assigned,
            "No asignados": notAssigned,
        };
        return options[filter] ?? "No hay data";
    };

    return (
        <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%" }}>
                <TableContainer sx={{ maxHeight: height - (74 + 90) }}>
                    {getTable(table)}

                    {search != "" && data.length == 0 ? (
                        <div className="d-flex justify-content-center p-4">
                            No se encontraron resultados para "{search}"
                        </div>
                    ) : (
                        data.length == 0 && search == "" ?
                            <div className="d-flex justify-content-center p-4">
                                No existen registros aún.
                            </div>
                            :
                            ""
                    )}
                </TableContainer>
                <TablesPagination setPage={setPage} page={page} total={total} />
            </Paper>
        </Box>
    );
};
