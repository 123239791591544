import {
  Box,
  IconButton,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
} from "@mui/material";
import React from "react";
import { Close } from "@mui/icons-material";
import { AcceptButton } from "../../components/Buttons/AcceptButton";

export const ReportProblemModal = ({ handleClose, detail }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [state, setState] = React.useState({});

  const { gilad, jason, antoine } = state;
  const error = [gilad, jason, antoine].filter((v) => v).length !== 2;
  return (
    <Box sx={style}>
      <div className="d-flex justify-content-end">
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </div>

      <FormControl>
        <FormLabel id="demo-radio-buttons-group-label">
          Registar incidencia
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="female"
          name="radio-buttons-group"
        >
          <FormControlLabel
            value="el_paquete_no_existe"
            control={<Radio />}
            label="No hay ningún paquete que recolectar"
          />
          <FormControlLabel
            value="direccion_cerrada"
            control={<Radio />}
            label="La dirección está cerrada"
          />
          <FormControlLabel
            value="direccion_no_existe"
            control={<Radio />}
            label="La dirección no existe"
          />
          <FormControlLabel
            value="mal_embalaje"
            control={<Radio />}
            label="El paquete se recolectó, pero tiene mal embalaje"
          />
          <FormControlLabel
            value="paquete_dañado"
            control={<Radio />}
            label="El paquete se recolectó pero está dañado"
          />
          <FormControlLabel
            value="elementos_no_coinciden"
            control={<Radio />}
            label="El paquete se recolectó, pero alguno de los elementos no coincide con la guía (ej. peso y volúmen)"
          />
        </RadioGroup>
        <div className="mt-4 d-flex justify-content-end">
          <AcceptButton text={"Aceptar"} />
        </div>
      </FormControl>
    </Box>
  );
};
