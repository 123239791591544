import { Grid, Paper, styled, Typography, useMediaQuery } from '@mui/material';
import React from 'react'
import LoginImg from '../../assets/bgLogistics-1.png'
import Logo from '../../assets/LogoDS.svg'
import MobilBG from '../../assets/mobil-1.png'
import { LoginForm } from '../../components/Forms/LoginForm';

export const Login = () => {
    const phone = useMediaQuery("(max-width:600px)");
    const tablet = useMediaQuery("(max-width:900px)");

    const Item = styled(Paper)(({ theme }) => ({
        // height: "70%",
        // width: phone ? "300px" : "",
        borderColor: "#218173",
        borderWidth: 0,
        // borderStyle: "solid",
        // minHeight: 120,
        // display: "flex",
        // flexDirection: "column",
        // alignItems: "center",
        boxShadow: "0px 0px 5px 1px #2181735e",
        // padding: "20px 5px 20px 5px"
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        borderRadius: 10,
    }));

    return (
        <div className='' style={{ backgroundImage: phone ? `url(${MobilBG})` : `url(${LoginImg})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }} >
            <Grid container alignItems="center" columns={{ xs: 1, sm: 1, md: 12, lg: 12 }}>
                <Grid item xs={6}
                    order={{ xs: 2, sm: 2, md: 1, lg: 1, xl: 1 }}
                >
                    <div className='vh-100 d-flex align-items-center justify-content-center flex-column'>
                        {/* {phone && <img src={Logo} alt="" width={100} />} */}

                        <Item className='px-4'>

                            <div className=''>
                                <h2 className='ds-blue-font ds-bold noMargin text-2xl'>
                                    DAYSTORE
                                </h2>
                                {/* <Typography variant='h4' className='ds-blue-font ds-bold'>
                                    DAYSTORE
                                </Typography> */}
                                {/* <Typography className='yellow-ds-font' variant='h3'>
                                    LOGISTICS
                                </Typography> */}
                                <h1 className='yellow-ds-font ds-bold text-3xl'>
                                    LOGISTICS
                                </h1>
                            </div>

                            {/* <Typography className='deep-blue-font' variant='h5'>
                                Login
                            </Typography> */}
                            <div className='mt-3 mb-3'>
                                <LoginForm />
                            </div>
                        </Item>

                    </div>
                </Grid>

               {/* <Grid item xs={6} order={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2 }} >
                    <div className={`${tablet ? "" : "vh-100"} `} style={{ backgroundImage: phone ? "" : `url(${LoginImg})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                        
                    </div>
                </Grid>*/}
            </Grid>
        </div>
    )
}
