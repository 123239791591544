import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useViewPortDimensions } from "../../hooks/useViewPortDimensions";
import { TablesPagination } from "../Pagination/TablesPagination";
import Chip from "@mui/material/Chip";

const status = [
  { id: 1, value: "En proceso", name: "IN_PROCESS" },
  { id: 2, value: "En ruta", name: "IN_ROUTE" },
  { id: 3, value: "Finalizada", name: "FINISHED" },
];

const TableHeader = () => (
  <thead className="h-12 bg-[#004876c2] text-white">
    <TableRow>
      <TableCell align="center">Estado</TableCell>
      <TableCell align="center">Total guías asociadas</TableCell>
      <TableCell align="center">Total guías recolectadas</TableCell>
      <TableCell align="center">Total guías entregadas</TableCell>
      <TableCell align="center">Total guías con incidencia</TableCell>
      <TableCell align="center">Fecha de inicio</TableCell>
      <TableCell align="center">Fecha de finalización</TableCell>
      <TableCell align="center">Comenzar ruta</TableCell>
    </TableRow>
  </thead>
);

// const TableRowItem = ({ row, index }) => (
//   <TableRow style={{ backgroundColor: index % 2 === 0 ? "#f0f0f0" : "white" }}>
//     <TableCell component="th" scope="row" padding="none" align="center">
//       {data.id}
//     </TableCell>
//     <TableCell align="center">
//       <Chip
//         size="small"
//         label={
//           status.find((el) => el.name == data.data.status) != undefined &&
//           status.find((el) => el.name == data.data.status).value
//         }
//         color="primary"
//       />
//     </TableCell>
//     <TableCell align="center">10</TableCell>
//     <TableCell align="center">10</TableCell>
//     <TableCell align="center">10</TableCell>
//     <TableCell align="center">5</TableCell>
//     <TableCell align="center"></TableCell>
//     <TableCell align="center"></TableCell>
//     <TableCell align="center"></TableCell>
//   </TableRow>
// );

export const ActiveGuidesTable = ({ search, data, setPage, page, total }) => {
  const { height, width } = useViewPortDimensions();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`details/`, {
      state: data,
    });
  };


  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: height - (74 + 90) }}>
            <Table
              sx={{ minWidth: 750 }}
              aria-label="sticky table"
              stickyHeader
            >
             <TableHeader/>
              <TableBody>
                {data != null
                  ? Boolean(data.data.shipments.length) && (
                      <TableRow
                        hover
                        role="checkbox"
                        className="cursor-pointer"
                        tabIndex={-1}
                        onClick={() => handleClick()}
                      
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          padding="none"
                          align="center"
                        >
                          {data.id}
                        </TableCell>
                        <TableCell align="center">
                          <Chip
                            size="small"
                            label={
                              status.find(
                                (el) => el.name == data.data.status
                              ) != undefined &&
                              status.find((el) => el.name == data.data.status)
                                .value
                            }
                            color="primary"
                          />
                        </TableCell>
                        <TableCell align="center">10</TableCell>
                        <TableCell align="center">10</TableCell>
                        <TableCell align="center">10</TableCell>
                        <TableCell align="center">5</TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center"></TableCell>
                      </TableRow>
                    )
                  : null}
              </TableBody>
            </Table>
            {data != null && search != "" && data.data.shipments.length == 0 ? (
              <div className="d-flex justify-content-center p-4">
                No se encontraron resultados para "{search}"
              </div>
            ) : data != null &&
              data.data.shipments.length == 0 &&
              search == "" ? (
              <div className="d-flex justify-content-center p-4">
                No hay rutas en este momento.
              </div>
            ) : (
              ""
            )}
          </TableContainer>
          <TablesPagination setPage={setPage} page={page} total={total} />
        </Paper>
      </Box>
    </>
  );
};
