import React from "react";

import { useMediaQuery } from "@mui/material";
import { ShipmentOriginForm } from "../../../components/Forms/ShipmentOriginForm";

export const AddShipmentOrigin = () => {
  const phone = useMediaQuery("(max-width:480px)");

  return (
    <>
      <div className="w-100 px-3">
        <div className=" w-100 d-flex justify-content-between mt-2 d-flex flex-column box-shadow ">
          <div className="bg-white border-radius-10 p-2">
            <p className={` ${phone ? "h6" : "h5"}`}>Agregar nuevo origen</p>

            <div className="d-flex">
              <span className="text-danger">*</span>{" "}
              <p className="m-0 text-secondary"> Campos obligatorios</p>
            </div>
          </div>
          <div className="mt-3">
            <ShipmentOriginForm />
          </div>
        </div>
      </div>
    </>
  );
};
