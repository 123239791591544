import '../App.css';
import { LayoutAdmin } from './LayoutAdmin';

function AppAdmin() {
  return (
    <LayoutAdmin />
  );
}

export default AppAdmin;
