import React, { useEffect, useState } from "react";
import PieChart from "../../../components/Charts/PieChart";
import { AdminAPI } from "../../../api/admin";
import BarChart from "../../../components/Charts/BarChart";

export const Reports = () => {

  const [greenData, setGreenData] = useState(0)
  const [yellowData, setYellowData] = useState(0)
  const [redData, setRedData] = useState(0)

  useEffect(() => {
    GetSummaryRoutes();
  }, []);

  const GetSummaryRoutes = async () => {
    //setIsLoading(true);
    let data = new FormData();
    data.append("download", false);
    data.append("inRoute", true);

    try {
      const api = new AdminAPI();
      const response = await api.getShipmentsSummary(data);
      console.log(response.data);
      setRedData(response.data.red);
      setGreenData(response.data.green);
      setYellowData(response.data.yellow);
      //setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

    const chartConfig = {
        type: "pie",
        width: 400,
        height: 400,
        series: [greenData, yellowData, redData],
        options: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          title: {
            show: "",
          },
          dataLabels: {
            enabled: true,
          },
          colors: ["#23e310", "#dfcb04", "#eb2a10"],
          legend: {
            show: true,
          },
          labels: ["Entrega A tiempo", "Entrega con retraso", "Entrega tardia"],
        },
      };


    return (
        <div className="w-full">
            <div className="w-[99%] pt-4">
                <div className="flex flex-row items-start p-6 bg-white shadow-lg rounded-lg w-full">
                    <div className="my-1 flex flex-col">
                        <h1 className="text-2xl font-bold mb-2 text-[#004876]">
                            Reportes Generales de Envios
                        </h1>
                        <h4 className="text-[#004876]">periodo 2024/07/01 - 2024/07/26</h4>
                    </div>
                </div>
            </div>
            <div className="flex flex-row">
              <div className="w-[49%] pt-4 pr-2">
                <div className="flex flex-col items-center justify-center p-6 bg-white shadow-lg rounded-lg w-full">
                    <PieChart
                        title={"Tiempos de entrega"}
                        chartConfig={chartConfig}
                    />
                </div>
              </div>
            <div className="w-[50%] pt-4">
                <div className="flex flex-col items-center justify-center p-6 bg-white shadow-lg rounded-lg w-full">
                    <BarChart
                      title={"Incidencias"}
                     />
                </div>
              </div>
            </div>
        </div>
    );
};
