import React, { useState } from 'react'
import { useFormik } from "formik";
import * as Yup from "yup";
import {
    TextField,
    useMediaQuery,
    IconButton,
    Modal,
    InputAdornment,
    Backdrop,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { AcceptButton } from "../Buttons/AcceptButton";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { SuccessModal } from '../Modal/SuccessModal';
import { ErrorModal } from '../Modal/ErrorModal';
import { LoginAPI } from '../../api/login';

export const EditPasswordForm = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [showOldPassword, setShowOldPassword] = useState(false);

    const [openSuccess, setOpenSuccess] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [openBD, setOpenBD] = useState(false);

    const [errorPass, setErrorPass] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const handleClickShowOldPassword = () => setShowOldPassword(!showOldPassword);
    const handleClickShowPassword = () => setShowPassword(!showPassword);

    const navigate = useNavigate();

    const phone = useMediaQuery("(max-width:768px)");

    const fields = [
        { keyName: "Contraseña actual", value: "password", type: true },
        { keyName: "Nueva contraseña", value: "new_Password", type: true }, /*type: true para validaciones */
        { keyName: "Confirma tu contraseña", value: "confirmPassword", type: true },
    ];

    const ChangePassword = async (obj) => {
        setOpenBD(true)
        let data = new FormData();

        data.append("new_password", obj.new_Password);
        data.append("password", obj.password)

        try {
            const api = new LoginAPI()
            const response = await api.changePasswordEP(data);

            setOpenBD(false);
            setOpenSuccess(true);
            setErrorPass(true);

        } catch (error) {
            setOpenBD(false);
            setOpenError(true);
            
            if (error.response.status == 401) {
                localStorage.removeItem("token"); /* Remove the token so that the "front" can log out when the token changes, you can't call the log out function because it asks for the token (which doesn't exist anymore). */
                window.location.reload();
            }
            if (error.response.data[0] == "Wrong password") {
                setErrorMsg("Contraseña actual no coincide.")
                setErrorPass(true)
            }
        }
    };

    const formik = useFormik({
        initialValues: {
            password: "",
            new_Password: "",
            confirmPassword: "",
        },
        validationSchema: Yup.object({
            password: Yup.string()
                .required("Campo Obligatorio"),
            new_Password: Yup.string()
                .required("Campo Obligatorio")
                .max(30, "Demasiado largo")
                .matches(
                    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                    "Mínimo 8 caracteres, una mayúscula, una minúscula, un número y un carácter especial"
                )
                .when('password', (password, schema) => {
                    return schema.test({
                        test: new_Password => new_Password !== password,
                        message: 'La contraseña anterior debe ser diferente a la nueva'
                    });
                })
            ,
            confirmPassword: Yup.string()
                .required("Campo Obligatorio")
                .max(30, "Demasiado largo")
                .when("new_Password", {
                    is: val => (val && val.length > 0 ? true : false),
                    then: Yup.string().oneOf(
                        [Yup.ref("new_Password")],
                        "Contraseñas deben coincidir"
                    )
                })

        }),

        onSubmit: (values) => {
            ChangePassword(values);
        },
    });

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                {/* <Divider className="mb-2">Actualiza tu contraseña</Divider> */}
                {/* <Grid container columns={{ xs: 3, sm: 3, md: 6 }} className="bg-success"> */}

                <div className='w-100 d-flex justify-content-center'>

                    <div className={`d-flex flex-column align-items-center justify-content-center border bg-white border-radius-10 p-4 ${phone ? "w-100" : "w-75"}`}>
                        <div className='text-center mb-4'>
                            <p className={`ds-blue-font ${phone ? "h5" : "h5"}`}>*Genera una contraseña que no hayas utilizado antes</p>
                        </div>

                        <div className={`w-100 `}>
                            {
                                fields.map((field) => (
                                    // <Grid item xs={6}>
                                    <div
                                        key={field.keyName}
                                        className={` d-flex mb-3  ${phone ? "px-2 flex-column" : "px-4"}  `}
                                    >
                                        <div
                                            className={`d-flex align-items-center mr-2`}
                                            style={{ minWidth: `${phone ? "" : "200px"}` }}
                                        >
                                            <p className=" h6 w-100" htmlFor={field.value}>{`${field.keyName
                                                }: ${field.type ? "*" : ""}`}</p>
                                        </div>
                                        <div className="d-flex align-items-center w-100 ">
                                            <div className="d-flex flex-column w-100 ">
                                                {field.value == "password" ? (
                                                    <TextField
                                                        error={
                                                            formik.touched[field.value] &&
                                                            formik.errors[field.value]
                                                        }
                                                        helperText={
                                                            formik.touched[field.value] &&
                                                                formik.errors[field.value] ? (
                                                                <div>{formik.errors[field.value]}</div>
                                                            ) : null ||
                                                                (errorPass && field.value == "password") ? (
                                                                <div class="text-danger">
                                                                    {errorMsg}
                                                                </div>
                                                            ) : null}
                                                        size="small"
                                                        type={showOldPassword ? "text" : "password"}
                                                        label={field.keyName}
                                                        variant="outlined"
                                                        fullWidth
                                                        onChange={(e) => {formik.handleChange(e); setErrorMsg("")}}
                                                        value={formik.values[field.value]}
                                                        name={field.value}
                                                        id={field.value}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={handleClickShowOldPassword}
                                                                        edge="end"
                                                                    >
                                                                        {showOldPassword ? <VisibilityOff /> : <Visibility />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                ) : (

                                                    <TextField
                                                        error={
                                                            formik.touched[field.value] &&
                                                            formik.errors[field.value]
                                                        } helperText={
                                                            formik.touched[field.value] &&
                                                                formik.errors[field.value] ? (
                                                                <div>{formik.errors[field.value]}</div>
                                                            ) : null}
                                                        size="small"
                                                        type={showPassword ? "text" : "password"}
                                                        label={field.keyName}
                                                        variant="outlined"
                                                        fullWidth
                                                        onChange={formik.handleChange}
                                                        value={formik.values[field.value]}
                                                        name={field.value}
                                                        id={field.value}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={handleClickShowPassword}
                                                                        edge="end"
                                                                    >
                                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    <div className={`${!phone && "d-flex justify-content-center "} mt-2 w-100`}>
                        <AcceptButton text={"Actualizar"} type={"submit"} width={phone ? "100%" : "20%"} />
                    </div>
                    </div>

                </div>



            </form>

            <Modal open={openSuccess} onClose={() => console.log("Cerrando")}>
                <SuccessModal text={"Contraseña actualizada correctamente."} handleClose={() => {
                       setOpenSuccess(false);
                       localStorage.removeItem("token");
                       window.location.reload();
                }} />
            </Modal>
            <Modal open={openError} onClose={() => console.log("Cerrando")}>
                <ErrorModal text={"Error al actualizar contraseña."} handleClose={() => setOpenError(false)} error={errorMsg} />
            </Modal>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBD}
            >
                <span className="loader"></span>
            </Backdrop>

        </>
    )
}