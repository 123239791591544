import React from 'react'
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ChangeDocButton = ({ text, onClick, target, border, font, component, href }) => {

    const ChangeDocStyled = styled(Button)({
        boxShadow: '0',
        textTransform: 'none',
        fontSize: 14,
        backgroundColor: 'transparent',
        color: "#1f4368",
        textAlign: "center",
        fontWeight: 'bold',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            backgroundColor: 'transparent'
        }
    });


    return (
        <ChangeDocStyled target={target} component={component} href={href} variant="text" onClick={onClick} disableElevation >
            {text}
        </ChangeDocStyled>
    )
}
