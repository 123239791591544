import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  IconButton,
  Modal
} from "@mui/material";
import { DriveFileRenameOutlineTwoTone, Delete } from "@mui/icons-material";

import { useNavigate } from "react-router-dom";
import { useViewPortDimensions } from "../../hooks/useViewPortDimensions";
import { TablesPagination } from "../Pagination/TablesPagination";
import { SuccessModal } from "../Modal/SuccessModal";
import { ErrorModal } from "../Modal/ErrorModal";
import { WarningModal } from "../Modal/WarningModal";
import { AdminAPI } from "../../api/admin";



const TableHeader = () => (
  <thead className="h-12 bg-[#004876c2] text-white">
    <TableRow>
      <TableCell align="center">Acciones</TableCell>
      <TableCell align="center">Id</TableCell>
      <TableCell align="center">Nombre</TableCell>
      <TableCell align="center">Dirección</TableCell>
    </TableRow>
  </thead>
);


const ShipmentOriginsTable = ({ search, data, setPage, page, total }) => {
  
  const [openDelete, setOpenDelete] = useState(false);
  const [origin, setOrigin] = useState({});
  // Backdrop
  const [openBD, setOpenBD] = useState(false);
  
  // Success states
  const [openSuccess, setOpenSuccess] = useState(false);
  const [successText, setSuccessText] = useState("");

  // Error states
  const [openError, setOpenError] = useState(false);
  const [errorText, setErrorText] = useState("");

  const handleDelete = (origin) => {
    setOrigin(origin.id);
    setOpenDelete(true);
    
  };


  const DeleteOrigin = async () => {
    setOpenBD(true)
    let data = new FormData();
    data.set("originId", origin)
    
    try {
      const api = new AdminAPI();
      const response =  await api.deleteFavoriteOrigin(data);
      if (response.status == 200) {
        setOpenBD(false)
        setOpenDelete(false)
        setSuccessText("Origen eliminado con éxito.");
        setOpenSuccess(true);
      } else {
        setOpenBD(false);
        setErrorText(response.response.data)
        setOpenError(true);
      }

    } catch (error) {}


  }

  const { height } = useViewPortDimensions();
  const navigate = useNavigate();
  const handleClick = (row) => {
    navigate("/admin/origins/edit/", {
      state: {
        id: row.id == null ? "" : row.id,
        name_favorite_origin : row.name_favorite_origin == null ? "" : row.name_favorite_origin,
        first_name : row.first_name == null ? "" : row.first_name,
        last_name : row.last_name == null ? "" : row.last_name,
        phone : row.phone == null ? "" : row.phone,
        email : row.email == null ? "" : row.email,
        location_street : row.location__street == null ? "" : row.location__street,
        location_outdoor_number : row.location__outdoor_number == null ? "" : row.location__outdoor_number,
        location_interior_number : row.location__interior_number == null ? "" : row.location__interior_number,
        location_neighborhood : row.location__neighborhood == null ? "" : row.location__neighborhood,
        location_zipcode_zipcode : row.location__zipcode__zipcode == null ? "" : row.location__zipcode__zipcode,
        location_references : row.references == null ? "" : row.references,
      },
    });
  };

  console.log(data)
  return (
    <>
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: height - (74 + 90 + 80) }}>
          <Table sx={{ minWidth: 750 }} aria-label="sticky table" stickyHeader>
            <TableHeader />
            <TableBody>
              {data.map((row, index) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id} style={{ backgroundColor: index % 2 === 0 ? "#f0f0f0" : "white" }}>
                  <TableCell>
                      <Tooltip title={"Editar"} arrow placement="top">
                          <div className="d-flex align-items-center justify-content-center">
                          <IconButton onClick={() => handleClick(row)}>
                              <DriveFileRenameOutlineTwoTone />
                          </IconButton>
                          </div>
                      </Tooltip>
                      <Tooltip title={"Eliminar"} arrow placement="top">
                    <div className="d-flex align-items-center justify-content-center">
                      <IconButton onClick={() => handleDelete(row)}>
                        <Delete />
                      </IconButton>
                    </div>
                  </Tooltip>
                  </TableCell>
                  <TableCell component="th" id={index} scope="row" padding="normal" align="center"> {row.id} </TableCell>
                  <TableCell component="th" id={index} scope="row" padding="normal" align="center"> {row.name_favorite_origin} </TableCell>
                  <TableCell component="th" id={index} scope="row" padding="normal" align="center"> {row.location__address} </TableCell>
                  
              
                  <TableCell component="th" id={index} scope="row" padding="none" align="center">
                    {row.evidence ? (
                      <a href={row.trip_screenshot} target="_blank">
                        Ver
                      </a>
                    ) : (
                      ""
                    )}
                  </TableCell>
                  <TableCell component="th" id={index} scope="row" padding="none" align="center">
                    {row.evidence ? (
                      <a href={row.evidence} target="_blank">
                        Ver
                      </a>
                    ) : (
                      ""
                    )}
                  </TableCell>
                  <TableCell component="th" id={index} scope="row" padding="none"align="center"> {row.comments} </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {search !== "" && data.length === 0 ? (
            <div className="d-flex justify-content-center p-4">
              No se encontraron resultados para "{search}"
            </div>
          ) : (
            data.length === 0 &&
            search === "" && (
              <div className="d-flex justify-content-center p-4">
                No existen registros aún.
              </div>
            )
          )}
        </TableContainer>
        <TablesPagination setPage={setPage} page={page} total={total} />
      </Paper>
    </Box>
    <Modal open={openSuccess}>
      <SuccessModal
        handleClose={() => {
          window.location.reload();
        }}
        text={successText}
      />
    </Modal>
    <Modal open={openError}>
      <ErrorModal
        text={`No se pudo completar la acción.`}
        error={errorText}
        handleClose={() => setOpenError(false)}
      />
    </Modal>
    <Modal open={openDelete}>
    <WarningModal
      handleClose={() => DeleteOrigin()}
      handleCancel={() => setOpenDelete(false)}
      text={`Estas apunto de eliminar el origen: ${origin}`}
    />
  </Modal>
  </>
  );
};

export { ShipmentOriginsTable };
