import { Navigate, useLocation } from "react-router-dom";
import { useAuthContext } from "../context/AuthProvider";

function RequireAuth({ children }) {
  const persistence =
    localStorage.getItem("token") != "null"
      ? localStorage.getItem("token")
      : null;
  const roles = {
    3: "user",
    2: "admin"
  };
  const rol =
    localStorage.getItem("rol") != "null" ? localStorage.getItem("rol") : null;

  const { pathname } = useLocation();
  localStorage.setItem("pathName", pathname);

  if (!persistence) {
    return <Navigate to="/" replace />;
  }
  if (!pathname.match(roles[rol])) {
    // Path security: if pathname doesn't match with rol redirect to rol dashboard
    return <Navigate to={`/${roles[rol]}`} replace />;
  }
  return children;
}

export default RequireAuth;
