import React, { useEffect, useState } from 'react'
import { Typography, Box, useMediaQuery, IconButton, Backdrop, Modal } from "@mui/material";
import { Close } from '@mui/icons-material';
import { AcceptButton } from '../Buttons/AcceptButton';
import { useNavigate } from 'react-router-dom';
import { AdminAPI } from '../../api/admin';
import { SuccessModal } from './SuccessModal';
import { ErrorModal } from './ErrorModal';
import { CancelButton } from '../Buttons/CancelButton';

export const UnassignOperativeModal = ({ handleClose, GetVehiclesList, vehicle }) => {
    const phone = useMediaQuery("(max-width:480px)");
    const tablet = useMediaQuery("(max-width:900px)");
    const [operatives, setOperatives] = useState([]);
    const [openBD, setOpenBD] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openError, setOpenError] = useState(false);
    const navigate = useNavigate();

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: phone ? "90%" : tablet ? "56%" : "37%",
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: "5px 0px 15px 0px",
        borderRadius: 2,
    };

    useEffect(() => {
        GetPartnersList()
    }, [])

    const GetPartnersList = async () => {
        let data = new FormData();
        data.append("available", true);

        try {
            const api = new AdminAPI()
            const response = await api.getPartners(data);
            setOperatives(response.data.data)

        } catch (error) {
            console.log(error);
            // console.log(error.respons, "error partners")
        }
    };

    const UnassignVehicle = async () => {
        setOpenBD(true);
        let data = new FormData();
        data.append("partnerId", vehicle.operatorId);

        try {
            const api = new AdminAPI()
            const response = await api.unassignVehicleToPartner(data);

            setOpenSuccess(true);
            setOpenBD(false);
            GetVehiclesList();
            // window.location.reload()
        } catch (error) {
            setOpenBD(false);
            setOpenError(true);
            console.log(error);
        }
    };

    return (
        <>
            <Box sx={style}>
                <div className=' d-flex justify-content-between align-items-center'>
                    <div></div>
                    <Typography variant='h5' className='font-weight-bold'>
                        Desasociar operador
                    </Typography>
                    <IconButton onClick={handleClose}>
                        <Close />
                    </IconButton>
                </div>

                <div className='p-3 text-center'>
                   <Typography>Estas apunto de deasociar el operador <strong>{vehicle.operatorName} {vehicle.operatorLastName}</strong>  de la unidad <strong>{vehicle.brand}</strong> con placas <strong>{vehicle.licensePlate}</strong></Typography> 
                   <Typography>¿Deseas continuar?</Typography>
                </div>


                <div className='d-flex justify-content-center mt-2'>
                <div className="mr-2">
                        <CancelButton
                            text={"Cancelar"}
                            onClick={handleClose}
                            width={phone ? "100%" : ""}
                        />
                    </div>
                    <AcceptButton text={"Aceptar"} width={phone ? "90%" : ""} onClick={() => {UnassignVehicle()}} />
                </div>

            </Box>

            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBD}
            >
                <span className="loader"></span>
            </Backdrop>

            <Modal open={openSuccess} onClose={() => console.log("Cerrando")}>
                <SuccessModal
                    handleClose={handleClose}
                    text={"Operador asignado con éxito."}
                />
            </Modal>
            <Modal open={openError} onClose={() => console.log("Cerrando")}>
                <ErrorModal
                    text={`No se pudo asignar al operador.`}
                    handleClose={() => setOpenError(false)}
                />
            </Modal>

        </>

    )
}
