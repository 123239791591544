import React from "react";
import { useMediaQuery, Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import { GuideCard } from "./GuideCard";

const steps = [
  {
    label: "En camino a recolección",
    description: `ASD`,
  },
  {
    label: "Paquete recolectado",
    description: "FGH",
  },
  {
    label: "En dirección de entrega",
    description: `JKL`,
  },
];

export const DetailsRoutesOp = () => {
  const location = useLocation();
  const phone = useMediaQuery("(max-width:480px)");

  return (
    <div
      className={`mt-3 border-radius-10 px-3 ${
        phone ? "flex-column" : "justify-content-center align-items-center"
      } `}
    >
      {/*---Contenedor padre---*/}
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
      {location.state !== undefined &&
        location.state.data.shipments.map((el, i) => (
          <Grid item={el} xs={6} sm={12} md={6} lg={4}>
            <GuideCard key={i} el={el} />
          </Grid>
        ))}
        </Grid>
    </div>
  );
};
