import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  useMediaQuery,
  IconButton,
  Grid,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { Wrapper } from "@googlemaps/react-wrapper";
import { Map } from "../Maps/Map";
import { Marker } from "../Maps/Marker";
import { AdminAPI } from "../../api/admin";
import { Line } from "../Maps/Line";

export const LocationMapModal = ({
  handleClose,
  routeId,
  partnerId,
  partnerPosition,
  shipments,
}) => {
  const phone = useMediaQuery("(max-width:480px)");
  const tablet = useMediaQuery("(max-width:900px)");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: phone ? "90%" : "80%",
    bgcolor: "background.paper",
    maxHeight: "90vh",
    overflow: phone ? "scroll" : "hidden",
    boxShadow: 24,
    px: 1,
    borderRadius: 2,
  };

  // Coordenadas iniciales predeterminadas
  // const initialCenter = { lat: 0, lng: 0 };

  // // Estado para el camino del socio
  // const [partnerPath, setPartnerPath] = useState(partnerPosition);

  // Estado para las direcciones
  const [directions, setDirections] = useState(null);

  // Estado para el origen de las direcciones
  const [directionsOrigin, setDirectionsOrigin] = useState();

  // Estado para el destino de las direcciones
  const [directionsDestination, setDirectionsDestination] = useState();
  

  // Modos de viaje
  const TravelModes = {
    DRIVING: "DRIVING",
    BICYCLING: "BICYCLING",
    TRANSIT: "TRANSIT",
    WALKING: "WALKING",
  };

  const initialCenter = { lat: 19.42847, lng: -99.12766 };
  const initialPartnerPath = partnerPosition.length > 0 ? partnerPosition : [initialCenter];
  const initialMapCenter = partnerPosition.length > 0 ? partnerPosition[0] : initialCenter;

  const [partnerPath, setPartnerPath] = useState(initialPartnerPath);
  const [mapCenter, setMapCenter] = useState(initialMapCenter);

  // Función para obtener la posición del socio
  const GetPartnerPosition = async (partnerId) => {
    try {
      const api = new AdminAPI();
      const response = await api.getRouteCoordinates(partnerId, routeId);
      if (response.status === 200) {
        setPartnerPath(response.data);
        // Actualizar el centro del mapa con las nuevas coordenadas del socio si están disponibles
        if (response.data.length > 0) {
          setMapCenter(response.data[0]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Efecto para obtener la posición del socio cuando se monta el componente
  useEffect(() => {
    const intervalId = setInterval(() => {
      GetPartnerPosition(partnerId);
    }, 7000); // Actualizar cada 7 segundos

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // Efecto para registrar la posición del socio en el primer renderizado
  useEffect(() => {
    console.log('Partner position', partnerPosition);
    console.log('PATH partner', partnerPath);
  }, []);

  // Función para manejar la respuesta del servicio de direcciones
  const handleDirectionsResponse = (response) => {
    setDirections(response);
  };

  return (
    <>
      <Box sx={style}>
        <div className="d-flex justify-content-between align-items-center ">
          <div>
            <Typography variant="h5" className="p-3">
              Ubicación
            </Typography>
          </div>
          <IconButton onClick={handleClose}>
            <Close fontSize="large" />
          </IconButton>
        </div>
        <Grid className="p-3">
          <Wrapper apiKey={process.env.REACT_APP_GOOGLE_MAPS_API}>
            <Map
              center={partnerPosition.length > 0 ? partnerPosition[0] : initialCenter} // Utilizar el centro del socio si está disponible, de lo contrario, utilizar las coordenadas iniciales predeterminadas
              style={{ height: "80vh", width: "100%" }}
              zoom={18}
              directions={directions}
            >
              <Line
                path={partnerPath}
                strokeWeight={6}
                strokeOpacity={0}
                defaultVisible={true}
              />
              {shipments.map((shipTo, i) => (
                <Marker
                  key={i}
                  position={shipTo.destination}
                  title={shipTo.trackingId}
                  clickable={false}
                  markerType={"shipmentMarker"}
                />
              ))}
            </Map>
          </Wrapper>
        </Grid>
      </Box>
    </>
  );
};
