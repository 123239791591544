export const adminSections = [
    {
        link: "guides/active/",
        name: "Guías activas",
        section: "Guías activas"
    },
    {
        link: "guides/active/add/",
        name: "Crear guía",
        section: "Crear guía"
    },
    {
        link: "guides/active/addOrigin/",
        name: "Crear origen",
        section: "Crear origen"
    },
    {
        link: "guides/active/route/",
        name: "Crear ruta",
        section: "Crear ruta"
    },
    {
        link: "guides/historical/",
        name: "Histórico",
        section: "Histórico"
    },
    {
        link: "operatives/",
        name: "Operadores",
        section: "Operadores"
    },
    {
        link: "operatives/add/",
        name: "Crear operador",
        section: "Crear operador"
    },
    {
        link: "operatives/edit/",
        name: "Editar operador",
        section: "Editar operador"
    },
    {
        link: "vehicles/",
        name: "Unidades",
        section: "Unidades"
    },
    {
        link: "vehicles/add/",
        name: "Agregar unidad",
        section: "Agregar unidad"
    },
    {
        link: "vehicles/edit/",
        name: "Editar unidad",
        section: "Editar unidad"
    },
    {
        link: "account/profile/",
        name: "Perfil",
        section: "Perfil"
    },
    {
        link: "account/password/",
        name: "Contraseña",
        section: "Contraseña"
    },
]