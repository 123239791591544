import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  TextField,
  Modal,
  useMediaQuery,
  MenuItem,
  Grid,
  IconButton,
  Tooltip,
  Backdrop,
} from "@mui/material";

import { useNavigate, useLocation } from "react-router-dom";
import { AcceptButton } from "../Buttons/AcceptButton";
import { AdminAPI } from "../../api/admin";
import { UploadButton } from "../Buttons/UploadButton";
import { DeleteDocButton } from "../Buttons/DeleteDocButton";
import { ChangeDocButton } from "../Buttons/ChangeDocButton";
import { SuccessModal } from "../Modal/SuccessModal";
import { ErrorModal } from "../Modal/ErrorModal";
import ModalVisorImg from "../Modal/ModalVisorImg";
import { WarningModal } from "../Modal/WarningModal";
import { CancelButton } from "../Buttons/CancelButton";
import {
  PersonRemoveAlt1TwoTone
} from "@mui/icons-material";

export const VehicleForm = () => {
  const navigate = useNavigate();
  const phone = useMediaQuery("(max-width:480px)");
  const [operatives, setOperatives] = useState([]);
  const [operativos, setOperativos] = useState([]);
  const [typesVehicles, setTypesVehicles] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openUnassign, setOpenUnassign] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorText, seterrorText] = useState("");
  const [openWarning, setOpenWarning] = useState(false);
  const [openBD, setOpenBD] = useState(false);
  const [openVisor, setOpenVisor] = useState(false);
  const [urlImg, setUrlImg] = useState("");
  const [obj, setObj] = useState({});
  const [changeOp, setChangeOp] = useState(false);
  const [prevOperative, setPrevOperative] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();

  const open = Boolean(anchorEl);

  const frontalPhoto = useRef(null);
  const rearPhoto = useRef(null);
  const rightPhoto = useRef(null);
  const leftPhoto = useRef(null);
  const frontPlate = useRef(null);
  const rearPlate = useRef(null);
  const [imageSrcs, setImageSrcs] = useState({});


  const [nameDoc, setNameDoc] = useState({
    frontalPhoto: "",
    rearPhoto: "",
    rightPhoto: "",
    leftPhoto: "",
    frontPlate: "",
    rearPlate: "",
  });

  const [docFile, setDocFile] = useState({
    frontalPhoto: null,
    rearPhoto: null,
    rightPhoto: null,
    leftPhoto: null,
    frontPlate: null,
    rearPlate: null,
  });

  const handleVisor = (url) => {
    setOpenVisor(true);
    setUrlImg(url);
  };

  const handleRemoveImage = (e) => {
    setNameDoc({ ...nameDoc, [e.target.value]: "" });
    setDocFile({ ...docFile, [e.target.value]: "" });
  };

  const handleUpload = (uploadRef) => {
    uploadRef.current && uploadRef.current.click();
  };

  const handleChangeImages = ({ target }, field, index) => {
    if (!target.files) {
      return;
    }
    const allowedExtensions = /(\.jpg|\.pdf|\.png|\.jpeg|\.tiff)$/i;
    if (!allowedExtensions.exec(target.files[0].name)) {
      alert("Formato inválido, por favor escoge una imagen");
      target.value = "";
      target.files = null;
      return false;
    }
    const file = target.files[0];
    setNameDoc((prevNameDoc) => ({
      ...prevNameDoc,
      [target.name]: file.name,
    }));
    setDocFile((prevDocFile) => ({
      ...prevDocFile,
      [target.name]: file,
    }));
    formik.setFieldValue(`${field}`, file);
  
    // Para mostrar la imagen seleccionada
    const reader = new FileReader();
    reader.onload = (e) => {
      setImageSrcs((prevImageSrcs) => ({
        ...prevImageSrcs,
        [index]: e.target.result,
      }));
    };
    reader.readAsDataURL(file);
  };
  

  const fields = [
    {
      keyName: "Marca",
      value: "brand",
      type: true,
      size: true,
      dropdown: false,
      array: [],
    },
    {
      keyName: "Placa",
      value: "plate",
      type: true,
      size: true,
      dropdown: false,
      array: [],
    },
    {
      keyName: "Modelo",
      value: "model",
      type: true,
      size: true,
      dropdown: false,
      array: [],
    },
    {
      keyName: "Año",
      value: "year",
      type: true,
      size: true,
      dropdown: false,
      array: [],
    },
    {
      keyName: "Capacidad",
      value: "capacity",
      type: true,
      size: true,
      dropdown: false,
      array: [],
    },
    {
      keyName: "Holograma",
      value: "hologram",
      type: true,
      size: true,
      dropdown: false,
      array: [],
    },
  ];

  const images = [
    {
      id: 1,
      keyName: "Fotografía frontal",
      value: "frontalPhoto",
      type: true,
      ref: frontalPhoto,
      name: nameDoc.frontalPhoto,
      url: docFile.frontalPhoto == "" ? "" : docFile.frontalPhoto,
    },
    {
      id: 2,
      keyName: "Fotografía trasera",
      value: "rearPhoto",
      type: true,
      ref: rearPhoto,
      name: nameDoc.rearPhoto,
      url: docFile.rearPhoto == "" ? "" : docFile.rearPhoto,
    },
    {
      id: 3,
      keyName: "Fotgrafía costado derecho",
      value: "rightPhoto",
      type: true,
      ref: rightPhoto,
      name: nameDoc.rightPhoto,
      url: docFile.rightPhoto == "" ? "" : docFile.rightPhoto,
    },
    {
      id: 4,
      keyName: "Fotografía costado izquierdo",
      value: "leftPhoto",
      type: true,
      ref: leftPhoto,
      name: nameDoc.leftPhoto,
      url: docFile.leftPhoto == "" ? "" : docFile.leftPhoto,
    },
    {
      id: 5,
      keyName: "Fotografía placa delantera",
      value: "frontPlate",
      type: true,
      ref: frontPlate,
      name: nameDoc.frontPlate,
      url: docFile.frontPlate == "" ? "" : docFile.frontPlate,
    },
    {
      id: 6,
      keyName: "Fotografía placa trasera",
      value: "rearPlate",
      type: true,
      ref: rearPlate,
      name: nameDoc.rearPlate,
      url: docFile.rearPlate == "" ? "" : docFile.rearPlate,
    },
  ];

  const vehicles = [
    { name: "Camión", id: 1 },
    { name: "Camión refrigerado", id: 2 },
    { name: "Minivan", id: 3 },
    { name: "Sprinter", id: 4 },
    { name: "Motocicleta", id: 5 },
    { name: "Bicicleta", id: 6 },
  ];

  const handleClose = () => {
    navigate(`/admin/vehicles/`);
  };

  useEffect(() => {
    GetPartnersList();
    GetCategoryVehiclesList();
    if (location.state != undefined) {
      GetImagesVehicles();
      if (location.state.operatorName != "") {
        setPrevOperative(location.state.operatorName);
      }
    }
  }, []);

  const GetImagesVehicles = async () => {
    let data = new FormData();

    data.append("idVehicle", location.state.id);

    try {
      const api = new AdminAPI();
      const response = await api.getVehicleImages(data);
      setDocFile({
        frontalPhoto: response.data.frontal_image,
        rearPhoto: response.data.rear_image,
        rightPhoto: response.data.right_image,
        leftPhoto: response.data.left_image,
        frontPlate: response.data.front_license_plate,
        rearPlate: response.data.rear_license_plate,
      });

      setNameDoc({
        frontalPhoto:
          response.data.frontal_image != null &&
          response.data.frontal_image.split("/").at(-1),
        rearPhoto:
          response.data.rear_image != null &&
          response.data.rear_image.split("/").at(-1),
        rightPhoto:
          response.data.right_image != null &&
          response.data.right_image.split("/").at(-1),
        leftPhoto:
          response.data.left_image != null &&
          response.data.left_image.split("/").at(-1),
        frontPlate:
          response.data.front_license_plate != null &&
          response.data.front_license_plate.split("/").at(-1),
        rearPlate:
          response.data.rear_license_plate != null &&
          response.data.rear_license_plate.split("/").at(-1),
      });

      setTimeout(() => {
        formik.setFieldValue(
          "frontalPhoto",
          response.data.frontal_image != null ? response.data.frontal_image : ""
        );
        formik.setFieldValue(
          "rearPhoto",
          response.data.rear_image != null ? response.data.rear_image : ""
        );
        formik.setFieldValue(
          "rightPhoto",
          response.data.right_image != null ? response.data.right_image : ""
        );
        formik.setFieldValue(
          "leftPhoto",
          response.data.left_image != null ? response.data.left_image : ""
        );
        formik.setFieldValue(
          "frontPlate",
          response.data.front_license_plate != null
            ? response.data.front_license_plate
            : ""
        );
        formik.setFieldValue(
          "rearPlate",
          response.data.rear_license_plate != null
            ? response.data.rear_license_plate
            : ""
        );
      }, 100);
    } catch (error) {
      console.log(error);
      console.log(error.respons, "error partners");
    }
  };

  const GetCategoryVehiclesList = async () => {
    setOpenBD(true);
    let data = new FormData();

    try {
      const api = new AdminAPI();
      const response = await api.getVehicleCategories(data);
      if (response.status == 200) {
        setOpenBD(false);
        setTypesVehicles(response.data);
      }
    } catch (error) {}
  };

  const GetPartnersList = async () => {
    let data = new FormData();
    data.append("available", true);

    try {
      const api = new AdminAPI();
      const response = await api.getAvailableDrivers(data);
      //   setOperatives(response.data.data);
      //console.log("operatives", response.data.data);
      if (location.state != undefined) {
        setOperatives([
          {
            idPartner: location.state.operatorId,
            name: location.state.operatorName,
            lastName: location.state.operatorLastName,
          },
          ...response.data.data,
        ]);
      } else {
        setOperatives(response.data.data);

        setOperativos([operatives]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const AddVehicle = async (obj) => {
    // console.log(obj.typeVehicle, "TIPO DE VEHICULO");
    setOpenBD(true);

    let data = new FormData();
    data.append("typeVehicle", obj.typeVehicle);
    data.append("model", obj.model);
    data.append("brand", obj.brand);
    data.append("year", obj.year);
    data.append("capacity", obj.capacity);
    data.append("licensePlate", obj.plate);
    data.append("hologram", obj.hologram);

    if (typeof docFile.frontalPhoto == "object") {
      data.append("frontalPhoto", obj.frontalPhoto);
    }
    if (typeof docFile.leftPhoto == "object") {
      data.append("leftPhoto", obj.leftPhoto);
    }
    if (typeof docFile.rightPhoto == "object") {
      data.append("rightPhoto", obj.rightPhoto);
    }
    if (typeof docFile.rearPhoto == "object") {
      data.append("rearPhoto", obj.rearPhoto);
    }
    if (typeof docFile.frontPlate == "object") {
      data.append("frontPlate", obj.frontPlate);
    }
    if (typeof docFile.rearPlate == "object") {
      data.append("rearPlate", obj.rearPlate);
    }

    let method = "POST";

    if (location.state != undefined) {
      method = "PUT";
      data.append("vehicleId", location.state.id);
    }

    try {
      const api = new AdminAPI();
      const response = await api.createVehicle(data, method);

      if (response.status == 200) {
        const auxId =
          location.state != undefined ? location.state.id : response.data.id;
        if (changeOp) {
          AssignVehicle(obj, auxId);
        }
        setOpenSuccess(true);
        setOpenBD(false);
      } else {
        seterrorText(`${response.response.data.non_field_errors[0]}`);
        setOpenBD(false);
        setOpenError(true);
      }
    } catch (error) {
      //console.log(error);
      setOpenBD(false);
      setOpenError(true);
      seterrorText("Error al crear la unidad");
    }
  };

  const AssignVehicle = async (obj, id) => {
    let data = new FormData();
    data.append("partnerId", obj.operative);
    data.append("vehicleId", id);

    try {
      const api = new AdminAPI();
      const response = await api.assignVehicleToPartner(data);
      if (response.status == 200) {
        setOpenSuccess(true);
        setOpenBD(false);
      }
    } catch (error) {
      setOpenBD(false);
      setOpenError(true);
      seterrorText("Error al asignar operador");
    }
  };

  const UnassignVehicle = async () => {
    setOpenBD(true);

    let data = new FormData();
    data.append("partnerId", location.state.operatorId);

    try {
      const api = new AdminAPI();
      const response = await api.unassignVehicleToPartner(data);
      setTimeout(() => {
        formik.setFieldValue("operative", "");
        formik.setFieldValue("operativeLastname", "");
      }, 100);
      setOpenUnassign(true);
      setOpenBD(false);
    } catch (error) {
      setOpenBD(false);
      setOpenError(true);
      seterrorText("Error al deasociar el operador de la unidad");

      //console.log(error);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues:
      location.state != undefined
        ? {
            // id: location.state.id,
            brand: location.state.brand,
            plate: location.state.licensePlate,
            model: location.state.model,
            typeVehicle: location.state.idTypeVehicle,
            year: location.state.year,
            capacity: location.state.capacity,
            hologram: location.state.hologram,
            operative: location.state.operatorName,
            operativeLastname: location.state.operatorLastName,
            frontalPhoto: "",
            rearPhoto: "",
            rightPhoto: "",
            leftPhoto: "",
            frontPlate: "",
            rearPlate: "",
          }
        : {
            brand: "",
            plate: "",
            model: "",
            typeVehicle: "",
            year: "",
            capacity: "",
            hologram: "",
            operative: "",
            frontalPhoto: "",
            rearPhoto: "",
            rightPhoto: "",
            leftPhoto: "",
            frontPlate: "",
            rearPlate: "",
          },
    validationSchema: Yup.object({
      brand: Yup.string()
        .required("Campo Obligatorio")
        .matches(/^(?!\s)\w/, "Debe ser alfanumérico, sin espacios al inicio")
        .max(100, "Demasiado largo"),
      plate: Yup.string()
        .required("Campo Obligatorio")
        .matches(/^(?!\s)\w/, "Debe ser alfanumérico, sin espacios al inicio")
        .max(10, "Demasiado largo"),
      model: Yup.string()
        .required("Campo Obligatorio")
        .matches(/^(?!\s)\w/, "Debe ser alfanumérico, sin espacios al inicio")
        .max(50, "Demasiado largo"),
      typeVehicle: Yup.string().required("Campo Obligatorio"),
      year: Yup.number()
        .integer("Ingresa un año válido")
        .required("Campo obligatorio")
        .typeError("Debe ser un número sin espacios")
        .min(2000, "Debe ser un año mayor a 2000")
        .max(2050, "Debe ser un año menor a 2050"),
      capacity: Yup.number()
        .required("Campo Obligatorio")
        .typeError("Debe ser un número sin espacios")
        .min(20, "Debe ser un número mayor a 20")
        .max(225000, "Debe ser un número menor a 225 000"),
      hologram: Yup.string()
        .required("Campo Obligatorio")
        .matches(/^(?!\s)\w/, "Debe ser alfanumérico, sin espacios al inicio")
        .max(8, "Demasiado largo"),
      operative: Yup.string().required("Campo Obligatorio"),
      frontalPhoto: Yup.string().required("Campo Obligatorio"),
      rearPhoto: Yup.string().required("Campo Obligatorio"),
      rightPhoto: Yup.string().required("Campo Obligatorio"),
      leftPhoto: Yup.string().required("Campo Obligatorio"),
      frontPlate: Yup.string().required("Campo Obligatorio"),
      rearPlate: Yup.string().required("Campo Obligatorio"),
    }),

    onSubmit: (values) => {
      const obj = {
        ...values,
        frontalPhoto: docFile.frontalPhoto,
        rearPhoto: docFile.rearPhoto,
        rightPhoto: docFile.rightPhoto,
        leftPhoto: docFile.leftPhoto,
        frontPlate: docFile.frontPlate,
        rearPlate: docFile.rearPhoto,
      };
      //console.log(obj);
      if (values.operative == "") {
        setOpenWarning(true);
        setObj(obj);
      } else {
        AddVehicle(obj);
      }
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          rowSpacing={2}
        >
          {/*---Contenedor padre---*/}
          <Grid item xs={12} sm={12} md={6}>
            {/*---Ocupara mitad de pantalla---*/}
            <Grid
              style={{ height: "100%" }}
              item
              xs={12}
              className={`border bg-white p-3 border-radius-10`}
            >
              {/*---Altura de 100 para que ocupe verticalmente---*/}
              <div className="">
                <p className={` ${phone ? "h6" : "h5"}`}>Datos unidad</p>
              </div>
              <Grid container columns={{ xs: 4, sm: 6, md: 12, lg: 12 }}>
                {fields.map((field) => (
                  <Grid
                    key={field.keyName}
                    item
                    xs={field.size ? 6 : 12}
                    md={12}
                    lg={field.size ? 6 : 12}
                  >
                    <div
                      className={` d-flex mb-3 flex-column ${
                        phone ? "px-2 " : "px-2"
                      }`}
                    >
                      <div
                        className={`d-flex align-items-center mr-2 ${
                          phone && "mb-2"
                        }`}
                      >
                        <p className="h6 te" htmlFor={field.value}>{`${
                          field.keyName
                        }: ${field.type ? "*" : ""}`}</p>
                      </div>
                      <div className="d-flex align-items-center w-100">
                        <div className="d-flex flex-column w-100">
                          <TextField
                            select={field.dropdown}
                            error={
                              formik.touched[field.value] &&
                              formik.errors[field.value]
                            }
                            size="small"
                            label={field.keyName}
                            variant="outlined"
                            fullWidth
                            onChange={formik.handleChange}
                            value={formik.values[field.value]}
                            name={field.value}
                            id={field.value}
                            helperText={
                              formik.touched[field.value] &&
                              formik.errors[field.value] ? (
                                <div>{formik.errors[field.value]}</div>
                              ) : null
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Grid>
                ))}

                <Grid item xs={6} md={12} lg={12}>
                  <div
                    className={` d-flex mb-3 flex-column ${
                      phone ? "px-2 " : "px-2"
                    }`}
                  >
                    <div
                      className={`d-flex align-items-center mr-2 ${
                        phone && "mb-2"
                      }`}
                    >
                      <p
                        className="h6 te"
                        htmlFor="typeVehicle"
                      >{`Tipo de vehículo: *`}</p>
                    </div>
                    <div className="d-flex align-items-center w-100">
                      <div className="d-flex flex-column w-100">
                        <TextField
                          select
                          error={
                            formik.touched.typeVehicle &&
                            formik.errors.typeVehicle
                          }
                          size="small"
                          label={"Tipo de vehículo"}
                          variant="outlined"
                          fullWidth
                          onChange={formik.handleChange}
                          value={
                            typesVehicles.length != 0
                              ? formik.values.typeVehicle
                              : ""
                          }
                          name={"typeVehicle"}
                          id={"typeVehicle"}
                          helperText={
                            formik.touched.typeVehicle &&
                            formik.errors.typeVehicle ? (
                              <div>{formik.errors.typeVehicle}</div>
                            ) : null
                          }
                        >
                          {
                            // typesVehicles.length == 0 ? (
                            //   <MenuItem>Sin vehículos disponibles</MenuItem>
                            // ) : (
                            typesVehicles.map((vehicle, i) => (
                              <MenuItem key={i} value={vehicle.id}>
                                {
                                  vehicles.find((el) => el.id == vehicle.id)
                                    .name
                                }
                              </MenuItem>
                            ))
                            //)
                          }
                        </TextField>
                      </div>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={6} md={12} lg={12}>
                  <div className="d-flex w-100">
                    <div className={`w-100  ${phone ? "px-2 " : "px-2"}`}>
                      <div className="d-flex flex-column">
                        <p className="h6 te" htmlFor="typeVehicle">
                          Operador responsable:*
                        </p>
                      </div>
                      {formik.values.operative &&
                      formik.values.operativeLastname ? (
                        <p>
                          {" " +
                            formik.values.operative +
                            " " +
                            formik.values.operativeLastname}
                        </p>
                      ) : null}

                      <div className="d-flex align-items-center w-100">
                        <div className="d-flex flex-column w-100">
                          {/* {console.log(formik.values)} */}
                          <TextField
                            hidden={
                              formik.values.operative != "" &&
                              formik.values.operative == prevOperative
                                ? true
                                : false
                            }
                            select
                            error={
                              formik.touched.operative &&
                              formik.errors.operative
                            }
                            size="small"
                            label={"Operadores"}
                            variant="outlined"
                            fullWidth
                            onChange={(e) => {
                              formik.handleChange(e);
                              setChangeOp(true);
                            }}
                            name={"operative"}
                            id={"operative"}
                            helperText={
                              formik.touched.operative &&
                              formik.errors.operative ? (
                                <div>{formik.errors.operative}</div>
                              ) : null
                            }
                          >
                            {operatives.length == 0 ? (
                              <MenuItem>Sin operadores disponibles</MenuItem>
                            ) : (
                              operatives.map((op, i) => (
                                <MenuItem key={i} value={op.partnerId}>
                                  {op.name} {op.lastName}
                                </MenuItem>
                              ))
                            )}
                          </TextField>
                        </div>
                      </div>
                    </div>

                    {location.state !=
                      undefined /* To change the operator you must first disassociate the current one */ &&
                    location.state.operatorId != "" ? (
                      <Tooltip
                        title={"Desasociar operador"}
                        arrow
                        placement="top"
                      >
                        <div className="d-flex align-items-center justify-content-center">
                          <IconButton
                            disabled={
                              formik.values.operative != "" ? "" : "disabled"
                            }
                            onClick={() => {
                              UnassignVehicle();
                            }}
                          >
                            <PersonRemoveAlt1TwoTone />
                          </IconButton>
                        </div>
                      </Tooltip>
                    ) : null}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Grid
              style={{ height: "100%" }}
              item
              xs={12}
              className={`border bg-white p-3 border-radius-10`}
            >
              <div className="">
                <p className={`ds-blue-font ${phone ? "h6" : "h5"}`}>
                  Fotografías de la unidad
                </p>
              </div>

              <Grid container columns={{ xs: 4, sm: 6, md: 12, lg: 12 }}>
                {images.map((image, i) => (
                  <Grid key={i} item xs={6} md={12} lg={6}>
                    <div
                      className={` d-flex flex-column ${
                        phone ? "px-2 " : "px-2"
                      }`}
                    >
                      <div className="d-flex align-items-center w-100 mt-2 ">
                        <div className="d-flex flex-column w-100 ">
                          <input
                            name={image.value}
                            ref={image.ref}
                            type="file"
                            accept="image/jpg,image/png,application/pdf"
                            style={{ display: "none" }}
                            onChange={(e) => {
                              handleChangeImages(e, image.value, i);
                            }}
                          />
                          <label htmlFor={image.value}>
                            <div className="small-font d-flex justify-content-between">
                              {image.keyName}:
                            </div>
                            <UploadButton
                              errorImage={formik.errors[image.value]}
                              touchedImage={formik.touched[image.value]}
                              text={
                                image.name != ""
                                  ? <img src={imageSrcs[i]} />
                                  : image.keyName
                              }
                              onClick={() => {
                                handleUpload(image.ref);
                              }}
                              border={image.name != "" ? "#1f4368" : "#8d8d8d"}
                              font={image.name != "" ? "#1f4368" : "#575757"}
                            />
                          </label>
                          {image.name == "" ? null : (
                            <div
                              className="d-flex justify-content-between"
                              style={{ marginTop: -10 }}
                            >
                              <DeleteDocButton
                                value={image.value}
                                onClick={handleRemoveImage}
                                text={"Eliminar"}
                              />
                              {location.state != undefined &&
                              typeof image.url != "object" ? (
                                <ChangeDocButton
                                  text={"Ver"}
                                  onClick={() => handleVisor(image.url)}
                                />
                              ) : null}
                              <ChangeDocButton
                                onClick={() => {
                                  handleUpload(image.ref);
                                }}
                                text={"Cambiar"}
                              />
                            </div>
                          )}
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <div
          className={`mt-2 mb-2 ${
            !phone ? "d-flex justify-content-end margin-right" : ""
          }`}
        >
          <div className="mr-2">
            <CancelButton
              text={"Cancelar"}
              onClick={() => {
                navigate("/admin/vehicles/");
              }}
              width={phone ? "100%" : ""}
            />
          </div>
          <AcceptButton
            text={location.state != undefined ? "Editar" : "Agregar"}
            type={"submit"}
            width={phone ? "100%" : ""}
          />
        </div>
      </form>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBD}
      >
        <span className="loader"></span>
      </Backdrop>

      <Modal open={openSuccess} onClose={() => console.log("Cerrando")}>
        <SuccessModal
          handleClose={handleClose}
          text={`Unidad ${
            location.state != undefined ? "editada" : "creada"
          } con éxito.`}
        />
      </Modal>
      <Modal open={openUnassign}>
        <SuccessModal
          handleClose={() => setOpenUnassign(false)}
          text={`Operador desasociado con éxito, puedes seleccionar un nuevo operador.`}
        />
      </Modal>
      <Modal open={openError}>
        <ErrorModal
          text={`Error al ${
            location.state != undefined ? "editar" : "crear"
          } la unidad`}
          error={errorText}
          handleClose={() => setOpenError(false)}
        />
      </Modal>
      <Modal open={openWarning}>
        <WarningModal
          text={`Estas creando una unidad sin asignar operador.`}
          handleCancel={() => setOpenWarning(false)}
          handleClose={() => {
            AddVehicle(obj);
            setOpenWarning(false);
          }}
        />
      </Modal>
      <Modal open={openVisor}>
        <ModalVisorImg image={urlImg} handleClose={() => setOpenVisor(false)} />
      </Modal>
    </>
  );
};

{
  /* <Autocomplete
                            disabled={
                              formik.values.operative != "" &&
                              formik.values.operative == prevOperative
                                ? true
                                : false
                            }
                            error={
                              formik.touched.operative &&
                              formik.errors.operative
                            }
                            disablePortal
                            name={"operative"}
                            id={"operative"}
                            options={operatives.map((op, i) => {
                              return {
                                value: op.partnerId,
                                label: op.name,
                              };
                            })}
                            //getOptionLabel={option}
                            onChange={(e, value) => {
                              formik.handleChange(e);
                              setChangeOp(true);
                            }}
                            sx={{ width: 300 }}
                            renderInput={(params) => (
                              <TextField {...params} label="Operadores" />
                            )}
                          />*/
}
