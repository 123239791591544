import React from "react";

export const TrackingSearch = () => {
  return (
    <div>
      <div className="d-flex align-items-center bg-white">
        <p className="d-flex align-items-center" style={{ color: "#1f4368" }}>
          <img
            src="https://dspstatic.s3.us-east-2.amazonaws.com/img/logo.svg"
            alt=""
            width={40}
          />{" "}
          <span style={{ fontSize: 30 }}>Daystore</span>{" "}
        </p>
      </div>
    </div>
  );
};
