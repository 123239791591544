import { useEffect, useState } from 'react';
import './App.css';
import { Layout } from './router/Layout';

function App() {
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const [status, setStatus] = useState(null);

  useEffect(() => {
    getLocation()
  }, []);

  const getLocation = () => { /* Send location to backend (still in testing) */
    if (!navigator.geolocation) {
      setStatus("Tu navegador no soporta la geolocalización")
    } else {
      setStatus("Localizando...");
      navigator.geolocation.watchPosition((position) => { /* call automatically every time the position of the device changes */
        setStatus(null);
        setLat(position.coords.latitude);
        setLong(position.coords.longitude);
        postLocation()
      }, (err) => {
        console.log(err)
        setStatus("No se pudo acceder a la localización");
      }, {
        enableHighAccuracy: true, /* The application wants to receive the best possible results */
        maximumAge: 0, /* It will try to retrieve the current position */
        timeout: Infinity /* Will not return until position is available */
      });
    }
  }

  const postLocation = () => {
    const formData = new FormData();
    formData.append('lat', lat);
    formData.append('lng', long);

 

    // fetch('https://webhook.site/8590058c-b70e-48c7-9dfb-27108fbe9060', { /* Url test */
    //   method: 'POST',
    //   body: formData
    // })
    //   .then(response => response.json())
    //   .catch(error => console.error('Error:', error))
    //   .then(response => console.log('Success:', response));
  }


  return (
    <Layout />
  );
}

export default App;
