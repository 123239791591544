import React from "react";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Skeleton,
    Typography,
} from "@mui/material";

export const SkeletonTables = ({ num }) => {

    const a = Array(num).fill(true);

    return (
        <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%" }}>
                <TableContainer sx={{ maxHeight: 400 }}>
                    <Table sx={{ minWidth: 750 }} aria-label="sticky table" stickyHeader>
                        <thead className="h-12 bg-[#004876c2] text-white">
                            <TableRow>
                                <TableCell align="center"><Skeleton variant="text" /></TableCell>
                                <TableCell align="center"><Skeleton variant="text" /></TableCell>
                                <TableCell align="center"><Skeleton variant="text" /></TableCell>
                                <TableCell align="center"><Skeleton variant="text" /></TableCell>
                                <TableCell align="center"><Skeleton variant="text" /></TableCell>
                                <TableCell align="center"><Skeleton variant="text" /></TableCell>
                            </TableRow>
                        </thead>
                        <TableBody>
                            {a.map((row, index) => {
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={index} style={{ backgroundColor: index % 2 === 0 ? "#f0f0f0" : "white" }}>

                                        <TableCell component="th" id={labelId} align="center"><Skeleton variant="text" /></TableCell>
                                        <TableCell align="center"><Skeleton variant="text" /></TableCell>
                                        <TableCell align="center"><Skeleton variant="text" /></TableCell>
                                        <TableCell align="center"><Skeleton variant="text" /></TableCell>
                                            <TableCell align="center"><Skeleton variant="text" /></TableCell>
                                        <TableCell align="center"><Skeleton variant="text" /></TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className="d-flex justify-content-start align-items-center p-3">
                    <Typography variant="caption" className="ml-2"><Skeleton width={50} /></Typography>
                    <Skeleton variant="circular" className="ml-4" width={20} />
                    <Skeleton variant="circular" className="ml-2" width={20} />
                </div>
            </Paper>
        </Box>
    );
};
