import { HttpRequest } from './HttpRequest';
import { getPath } from '../helpers/getHost';

export class AccountAPI {
    constructor() {
        this.http = new HttpRequest()
        this.host = getPath()
        this.token = localStorage.getItem("token");
        this.config = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Token ${this.token}`
            }
        }
    }

    getInfoEP(data){
        return this.http.post(`${this.host}/ws/detailsAccount/`, data, this.config)
    }

    editAdminInfoEP(data){
        return this.http.put(`${this.host}/ws/detailsAccount/`, data, this.config)
    }
}
