import React, { useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import readXlsxFile from "read-excel-file";
import {
  Typography,
  Box,
  useMediaQuery,
  IconButton,
  Modal,
  Backdrop,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { AcceptButton } from "../Buttons/AcceptButton";
import { CancelButton } from "../Buttons/CancelButton";
import { UploadButton } from "../Buttons/UploadButton";
import { RemoveIconButton } from "../Buttons/RemoveIconButton";
import { AdminAPI } from "../../api/admin";
import { ErrorModal } from "./ErrorModal";
import { SuccessModal } from "./SuccessModal";
import LoaderDS from "../../assets/LoaderDS.svg";
import { DownloadTemplateButton } from "../Excel/DownloadExcelTemplate";
import {
  shipmentGuideHeaders,
  shipmentItemsHeaders,
} from "../Excel/headers.js";

export const UploadFileModal = ({ handleClose, text }) => {
  const phone = useMediaQuery("(max-width:480px)");
  const tablet = useMediaQuery("(max-width:900px)");

  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [excelFile, setExcelFile] = useState(false);
  const inputExcelFile = useRef(null);
  const [uploadedShipments, setUploadedShipments] = useState([]);
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      originName: "",
      originLastName: "",
      originPhoneNumber: "",
      originMail: "",
      originReferences: "",
      originStreet: "",
      originInteriorNumber: "",
      originOutdoorNumber: "",
      originCountry: "",
      originState: "",
      originCity: "",
      originZipCode: "",
      destinationFirstName: "",
      destinationLastName: "",
      destinationPhoneNumber: "",
      destinationMail: "",
      destinationReferences: "",
      destinationStreet: "",
      destinationInteriorNumber: "",
      destinationOutdoorNUmber: "",
      destinationCountry: "",
      destinationState: "",
      destinationCity: "",
      destinationZipCode: "",
      massive: false,
      excelFile: false,
      validExcelData: true,
    },

    validationSchema: Yup.object({
      originName: Yup.string().required("Campo obligatorio"),
      originLastName: Yup.string().required("Campo obligatorio"),
      originPhoneNumber: Yup.string().required("Campo obligatorio"),
      originMail: Yup.string().required("Campo obligatorio"),
      originReferences: Yup.string().required("Campo obligatorio"),
      originStreet: Yup.string().required("Campo obligatorio"),
      originInteriorNumber: Yup.string().required("Campo obligatorio"),
      originOutdoorNumber: Yup.string().required("Campo obligatorio"),
      originCountry: Yup.string().required("Campo obligatorio"),
      originState: Yup.string().required("Campo obligatorio"),
      originCity: Yup.string().required("Campo obligatorio"),
      originZipCode: Yup.string().required("Campo obligatorio"),
      destinationFirstName: Yup.string().required("Campo obligatorio"),
      destinationLastName: Yup.string().required("Campo obligatorio"),
      destinationPhoneNumber: Yup.string().required("Campo obligatorio"),
      destinationMail: Yup.string().required("Campo obligatorio"),
      destinationReferences: Yup.string().required("Campo obligatorio"),
      destinationStreet: Yup.string().required("Campo obligatorio"),
      destinationInteriorNumber: Yup.string().required("Campo obligatorio"),
      destinationOutdoorNumber: Yup.string().required("Campo obligatorio"),
      destinationCountry: Yup.string().required("Campo obligatorio"),
      destinationState: Yup.string().required("Campo obligatorio"),
      destinationCity: Yup.string().required("Campo obligatorio"),
      destinationZipCode: Yup.string().required("Campo obligatorio"),
      excelFile: Yup.bool().when("massive", {
        is: true,
        then: Yup.bool().oneOf([true], "Campo obligatorio"),
      }),
      validExcelData: Yup.bool().when("massive", {
        is: true,
        then: Yup.bool().oneOf([true], "El archivo contiene errores"),
      }),
    }),
    onSubmit: (values) => {},
  });

  const handleExcelFile = ({ files }) => {
    if (!files) {
      return;
    }
    const file = files[0];
    setExcelFile(file);
    setUploadedShipments([]);
    formik.setFieldValue("excelFile", true);
    validateExcelData(file);
  };
  const getErrorMessages = (errors) => {
    const errorMessages = errors.map((error) => {
      if (error.emptySheetError) {
        return error.emptySheetError
      }
      if (error.error == "required") {
        return `Existe una celda vacía en la fila ${error.row}, columna "${error.column}"`;
      } else {
        return `Fila ${error.row}: La columna "${error.column}" ${error.error}`;
      }
    });
    return (
      <>
        <p className="text-left">
          Corrige los siguientes errores en el archivo y vuelve a intentar.
        </p>
        <ul className="text-danger text-left">
          {errorMessages.map((error) => (
            <li>{error}</li>
          ))}
        </ul>
      </>
    );
  };

  const validateExcelData = (excelFile) => {
    const excelValidationSchema = Yup.object({
      Id: Yup.string()
        .matches(/^\d+$/, "sólo debe contener números (sin espacios)")
        .max(15, "Máximo 15 dígitos"),
      firstName: Yup.string()
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "sólo perimte alfanuméricos y espacios"
        )
        .max(50, "Máximo 50 caracteres"),
      lastName: Yup.string()
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "sólo alfanuméricos y espacios"
        )
        .max(50, "Máximo 50 caracteres"),
      phoneNumber: Yup.string()
        .matches(/^\d+$/, "sólo debe contener números (sin espacios)")
        .max(15, "Máximo 15 dígitos"),
      email: Yup.string()
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          "Formato incorrecto"
        )
        .max(256, "el correo electrónico es demasiado largo"),
      streetName: Yup.string().max(50, "Máximo 50 caracteres"),
      buildingNumber: Yup.string()
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "sólo permite alfanuméricos y espacios"
        )
        .max(10, "Máximo 10 caracteres"),
      neighborhoodName: Yup.string().max(80, "Máximo 50 caracteres"),
      zipCode: Yup.string()
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "sólo permite alfanuméricos y espacios"
        )
        .max(10, "Máximo 10 caracteres"),
      description: Yup.string().max(140, "Máximo 140 caracteres"),
    });

    // Excel Schema, this is for extract data from the file and convert it to JSON
    const schema = {
      Id: {
        prop: "id",
        required: true,
        validate: (value) => {
          excelValidationSchema.validateSync({ Id: value });
        },
      },
      OrigenNombre: {
        prop: "originFirstName",
        required: true,
        validate: (value) => {
          excelValidationSchema.validateSync({ firstName: value });
        },
      },
      OrigenApellido: {
        prop: "originLastName",
        required: true,
        validate: (value) => {
          excelValidationSchema.validateSync({ lastName: value });
        },
      },
      OrigenTelefono: {
        prop: "originPhoneNumber",
        required: true,
        validate: (value) => {
          excelValidationSchema.validateSync({ phoneNumber: value });
        },
      },
      OrigenCorreo: {
        prop: "originMail",
        required: true,
        validate: (value) => {
          excelValidationSchema.validateSync({ email: value });
        },
      },
      OrigenCalle: {
        prop: "originStreet",
        required: true,
        validate: (value) => {
          excelValidationSchema.validateSync({ streetName: value });
        },
      },
      OrigenNumeroInterior: {
        prop: "originInteriorNumber",
        required: true,
        validate: (value) => {
          excelValidationSchema.validateSync({ buildingNumber: value });
        },
      },
      OrigenNumeroExterior: {
        prop: "originOutdoorNumber",
        required: true,
        validate: (value) => {
          excelValidationSchema.validateSync({ buildingNumber: value });
        },
      },
      OrigenColonia: {
        prop: "originNeighborhood",
        required: true,
        validate: (value) => {
          excelValidationSchema.validateSync({ neighborhoodName: value });
        },
      },
      OrigenCodigoPostal: {
        prop: "originZipCode",
        required: true,
        validate: (value) => {
          excelValidationSchema.validateSync({ zipCode: value });
        },
      },
      OrigenReferencias: {
        prop: "originReferences",
        required: true,
        validate: (value) => {
          excelValidationSchema.validateSync({ description: value });
        },
      },
      DestinoNombre: {
        prop: "destinationFirstName",
        required: true,
        validate: (value) => {
          excelValidationSchema.validateSync({ firstName: value });
        },
      },
      DestinoApellido: {
        prop: "destinationLastName",
        required: true,
        validate: (value) => {
          excelValidationSchema.validateSync({ lastName: value });
        },
      },
      DestinoTelefono: {
        prop: "destinationPhoneNumber",
        required: true,
        validate: (value) => {
          excelValidationSchema.validateSync({ phoneNumber: value });
        },
      },
      DestinoCorreo: {
        prop: "destinationMail",
        required: true,
        validate: (value) => {
          excelValidationSchema.validateSync({ email: value });
        },
      },
      DestinoCalle: {
        prop: "destinationStreet",
        required: true,
        validate: (value) => {
          excelValidationSchema.validateSync({ streetName: value });
        },
      },
      DestinoNumeroInterior: {
        prop: "destinationInteriorNumber",
        required: true,
        validate: (value) => {
          excelValidationSchema.validateSync({ buildingNumber: value });
        },
      },
      DestinoNumeroExterior: {
        prop: "destinationOutdoorNumber",
        required: true,
        validate: (value) => {
          excelValidationSchema.validateSync({ buildingNumber: value });
        },
      },
      DestinoColonia: {
        prop: "destinationNeighborhood",
        required: true,
        validate: (value) => {
          excelValidationSchema.validateSync({ neighborhoodName: value });
        },
      },
      DestinoCodigoPostal: {
        prop: "destinationZipCode",
        required: true,
        validate: (value) => {
          excelValidationSchema.validateSync({ zipCode: value });
        },
      },
      DestinoReferencias: {
        prop: "destinationReferences",
        required: true,
        validate: (value) => {
          excelValidationSchema.validateSync({ description: value });
        },
      },
    };

    //readXlsxFile(excelFile, { sheet: "Sheet1" }).then((data) => {
    readXlsxFile(excelFile, { sheet: "ShipmentInfo", schema: schema }).then(
      ({ rows, errors }) => {
        if (errors.length > 0) {
          formik.setFieldValue("validExcelData", false);
          const errorMessages = getErrorMessages(errors);
          setErrorMessage(errorMessages);
          setOpenError(true);
          return;
        } else {
          formik.setFieldValue("validExcelData", true);
          setUploadedShipments(...uploadedShipments, () =>
            rows.map((shipment) => {
              return {
                id: shipment.id,
                deliveryType: "",
                origin: {
                  firstName: shipment.originFirstName,
                  lastName: shipment.originLastName,
                  phone: shipment.originPhoneNumber,
                  email: shipment.originMail,
                  address: {
                    street: shipment.originStreet,
                    interior_number: shipment.originInteriorNumber,
                    outdoor_number: shipment.originOutdoorNumber,
                    neighborhood: shipment.originNeighborhood,
                    zipCode: shipment.originZipCode,
                  },
                },
                destination: {
                  firstName: shipment.destinationFirstName,
                  lastName: shipment.destinationLastName,
                  phone: shipment.destinationPhoneNumber,
                  email: shipment.destinationMail,
                  address: {
                    street: shipment.destinationStreet,
                    interior_number: shipment.destinationInteriorNumber,
                    outdoor_number: shipment.destinationOutdoorNumber,
                    neighborhood: shipment.destinationNeighborhood,
                    zipCode: shipment.destinationZipCode,
                  },
                },
                items: validateExcelItems(excelFile, shipment.id),
              };
            })
          );
        }
      }
    );
  };

  const validateExcelItems = (excelFile, shipmentId) => {
    const itemValidationSchema = Yup.object({
      guideId: Yup.string()
        .matches(/^\d+$/, "Sólo debe contener números (sin espacios)")
        .max(15, "Máximo 15 dígitos"),
      itemDescription: Yup.string()
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "Sólo alfanuméricos y espacios"
        )
        .max(140, "Máximo 140 caracteres"),
      size: Yup.string(),
      width: Yup.number().test(
        "max-width",
        "El ancho del artículo no puede ser mayor a 300",
        (value) => {
          if (value != undefined) {
            return value < 300;  
          }
          return true;
          
        }
      ),
      height: Yup.number().test(
        "max-height",
        "La altura del artículo no puede ser mayor a 280",
        (value) => {
          if (value != undefined) {
            return value < 280;  
          }
          return true;
          
        }
      ),
      length: Yup.number().test(
        "max-length",
        "La longitud del artículo no puede ser mayor a 300",
        (value) => {
          if (value != undefined) {
            return value < 300;  
          }
          return true;
          
        }
      ),
      weight: Yup.number().test(
        "max-weight",
        "El peso del artículo no puede ser mayor a 70",
        (value) => {
          if (value != undefined) {
            return value < 70;  
          }
          return true;
          
        }
      ),
    });

    const itemsValidationSchema = {
      IdGuia: {
        prop: "guideId",
        required: true,
        validate: (value) => {
          itemValidationSchema.validateSync({ guideId: value });
        },
      },

      Descripcion: {
        prop: "itemDescription",
        required: true,
        validate: (value) => {
          itemValidationSchema.validateSync({ itemDescription: value });
        },
      },
      Tamaño: {
        prop: "size",
        required: true,
        validate: (value) => {
          itemValidationSchema.validateSync({ size: value });
        },
      },
      Peso: {
        prop: "weight",
        required: true,
        validate: (value) => {
          itemValidationSchema.validateSync({ weight: value });
        },
      },
      Largo: {
        prop: "length",
        required: true,
        validate: (value) => {
          itemValidationSchema.validateSync({ length: value });
        },
      },
      Ancho: {
        prop: "width",
        required: true,
        validate: (value) => {
          itemValidationSchema.validateSync({ width: value });
        },
      },
      Alto: {
        prop: "height",
        required: true,
        validate: (value) => {
          itemValidationSchema.validateSync({ height: value });
        },
      },
    };

    let items = [];

    readXlsxFile(excelFile, {
      sheet: "Items",
      schema: itemsValidationSchema,
    }).then(({ rows, errors }) => {
      //console.log("rows", rows, rows.length);
      //console.log("Errors", errors, typeof(errors));
      if (rows.length == 0 ){
        errors.push({
          emptySheetError: 'Por favor ingrese la información de los artículos en la hoja "Items"'
        })
      }

      if (errors.length > 0) {
        formik.setFieldValue("validExcelData", false);
        const errorMessages = getErrorMessages(errors);
        setErrorMessage(errorMessages);
        setOpenError(true);
      }
      for (let x in rows) {
        if (rows[x].guideId == shipmentId) {
          items.push({
            size: rows[x].size,
            width: rows[x].width,
            height: rows[x].height,
            length: rows[x].length,
            weight: rows[x].weight,
            description: rows[x].itemDescription,
          });
        }
      }
    });
    return items;
  };

  const createGuides = async () => {
    setIsLoadingRequest(true);
    let data = new FormData();

    data.append("shipments", JSON.stringify(uploadedShipments));

    // for (const value of data.entries()) {
    //   console.log(value);
    // }

    try {
      const api = new AdminAPI();
      const response = await api.createGuide(data);

      if (response.status == 200) {
        setIsLoadingRequest(false);
        setOpenSuccess(true);
      } else {
        setIsLoadingRequest(false);
        setOpenError(true);
      }
    } catch (error) {}
  };

  const handleClickInput = (input) => {
    input.current.value = "";
    input.current.click();
    input.current.blur();
  };

  const removeExcelFile = () => {
    setOpenError(false);
    inputExcelFile.current.value = "";
    setExcelFile(null);
    setUploadedShipments([]);
    setErrorMessage("");
    formik.setFieldValue("excelFile", false);
    formik.setFieldValue("validExcelData", false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: phone ? "80%" : tablet ? "56%" : "35%",
    bgcolor: "background.paper",
    maxHeight: "90vh",
    overflow: phone ? "scroll" : "hidden",
    boxShadow: 24,
    px: 1,
    borderRadius: 2,
  };

  //console.log(uploadedShipments);

  return (
    <>
      <Box sx={style}>
        <div className="d-flex justify-content-between align-items-center ">
          <div></div>

          <div>
            <Typography variant="h5" className="p-3">
              Carga masiva de guías
            </Typography>
          </div>
          <IconButton onClick={handleClose}>
            <Close fontSize="large" />
          </IconButton>
        </div>
        <div className=" text-left  p-3">
          <Typography className="font-weight-bold pb-2">
            Instrucciones
          </Typography>
          <Typography className="font-weight-bold font-color-green">
            1. Descargar la plantilla en formato xls.
          </Typography>
          <DownloadTemplateButton
            text={"Descargar"}
            disabled={false}
            template={{
              ShipmentInfo: shipmentGuideHeaders,
              Items: shipmentItemsHeaders,
            }}
            fileName={`Carga masiva de guias`}
            multiple={true}
            data={[
              {
                Id: "1",
                OrigenNombre: "Juan",
                OrigenApellido: "Perez",
                OrigenTelefono: "5563232300",
                OrigenCorreo: "juan.perez@daystore.mx",
                OrigenCalle: "Moliere",
                OrigenNumeroInterior: "61",
                OrigenNumeroExterior: "61",
                OrigenColonia: "Polanco",
                OrigenCodigoPostal: "11550",
                OrigenReferencias: "Day store",
                DestinoNombre: "Francisco",
                DestinoApellido: "López",
                DestinoTelefono: "7845693698",
                DestinoCorreo: "francisco.lopez@daystore.mx",
                DestinoCalle: "Lago Hielmar",
                DestinoNumeroInterior: "61",
                DestinoNumeroExterior: "61",
                DestinoColonia: "Modelo Pensil",
                DestinoCodigoPostal: "11460",
                DestinoReferencias: "Casa",
              },
            ]}
          />
          <Typography className="font-weight-bold font-color-green">
            2. Llenar el archivo con los datos necesarios
          </Typography>
          <Typography>
            <ul>
              <li>
                En la columna "Id" de la página "ShipmentInfo" se debe asignar
                un número para identificar la guía.
              </li>
              <li>
                En la columnna "IdGuia" de la página "Items" se debe especificar
                el "Id" de la guía a la que corresponde el artículo
              </li>
            </ul>
          </Typography>
          <Typography className="font-weight-bold font-color-green">
            3. Cargar el archivo
          </Typography>
          <div className="d-flex align-items-center m-1">
            <input
              name={"excelFile"}
              ref={inputExcelFile}
              type="file"
              accept=".xlsx"
              onChange={(e) => {
                handleExcelFile(e.target);
              }}
              style={{ display: "none" }}
            />
            <UploadButton
              text={excelFile ? excelFile.name : "Cargar archivo"}
              loadedFileType={excelFile ? "excel" : ""}
              onClick={() => handleClickInput(inputExcelFile)}
              error={formik.errors.excelFile || formik.errors.validExcelData}
              touched={formik.touched.excelFile || formik.touched.excelFile}
            />
            {excelFile ? (
              <RemoveIconButton onClick={removeExcelFile} text={"Eliminar"} />
            ) : null}
          </div>
        </div>
        <div
          className="text-right px-3"
          hidden={uploadedShipments.length !== 0 ? false : true}
        >
          <Typography>Todo parece correcto</Typography>
          <Typography>Total: {uploadedShipments.length} guías </Typography>
        </div>
        <div className="d-flex justify-content-end p-3">
          <div className="mr-2">
            <AcceptButton
              text={"Continuar"}
              width={phone ? "90%" : ""}
              onClick={() => createGuides()}
              disabled={uploadedShipments.length !== 0 ? false : true}
            />
          </div>
          <CancelButton text={"Cancelar"} onClick={handleClose} />
        </div>
      </Box>

      <Modal open={openError}>
        <ErrorModal
          text={
            formik.values.validExcelData
              ? `No se pudieron subir los registros`
              : `Error`
          }
          handleClose={removeExcelFile}
          error={errorMessage}
        />
      </Modal>

      <Modal open={openSuccess}>
        <SuccessModal
          text={"Guías creadas correctamente"}
          handleClose={() => {
            window.location.reload();
          }}
        />
      </Modal>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoadingRequest}
      >
        <img src={LoaderDS} alt="" width={200} />
      </Backdrop>
    </>
  );
};
