import React, { useEffect, useState } from "react";
import { useMediaQuery, Popover } from "@mui/material";
import { ShipmentOriginsTable } from "../../../components/Tables/ShipmentOriginsTable";
import { roles } from "../../../helpers/enums";
import { AdminAPI } from "../../../api/admin";
import { SkeletonTables } from "../../../components/Tables/SkeletonTables";

export const ShipmentsOrigins = () => {
  const [isLoading, setIsLoading] = useState(false);
  const phone = useMediaQuery("(max-width:480px)");
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0); /*Estado p/paginacion*/
  const [total, setTotal] = useState(); /*Total de paginas enviado desde api*/
  const [count, setCount] = useState(); /*Paginacion listas */
  const [anchorEl, setAnchorEl] = useState(null);

  //ERROR STATES
  //------------------------------------------------
  const [errors, setErrors] = useState({
    error: false,
    errorNullStart: false,
    errorNullEnd: false,
  });

  // EXCEL STATES
  //------------------------------------------------
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);
  const [dataExcel, setDataExcel] = useState([]);

  // FILTER STATES
  //------------------------------------------------
  const [startFilterDate, setStartFilterDate] = useState(null);
  const [endFilterDate, setEndFilterDate] = useState(null);
  const [statusList, setStatusList] = useState([]);
  const [status, setStatus] = useState(null);
  const [prevData, setPrevData] = useState({
    startFilterDate: null,
    endFilterDate: null,
    status: null,
  });
  const today = new Date();

  //EFFECTS
  //------------------------------------------------


  // useEffect(() => {
  //   getShipmentStatusCategories();
  // }, []);

  //CLASSES
  //------------------------------------------------



  //DATA REQUESTS
  //------------------------------------------------


  const GetShipmentOrigins = async () => {
    setIsLoading(true);
    try {
      const api = new AdminAPI();
      const response = await api.getFavoriteOrigins();
      if (response.status == 200) {
        setData(response.data);
        setTotal(response.data.total);
        setCount(Math.ceil(response.data.total / 10));
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetShipmentOrigins();
  }, []);




  //HANDLERS
  //------------------------------------------------

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <div className="w-100 px-3">
        <div>
          {phone ? (
            <div className="mt-2 w-100 p-1 ">
              <div className="d-flex bg-white justify-content-between border-radius-10"></div>
              <div className="d-flex w-100 mt-2"></div>
            </div>
          ) : (
            <div className="w-100 bg-white d-flex justify-content-between mt-1 p-2 border-radius-10">
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center">
                  
                </div>
              </div>
            </div>
          )}
          {isLoading ? (
            <div className="mt-3 bg-white w-100 d-flex justify-content-between p-2 border-radius-10">
              <SkeletonTables num={4} icon={true} />
            </div>
          ) : (
            <div className="mt-3 bg-white w-100 d-flex justify-content-between p-3 border-radius-10">
              <ShipmentOriginsTable
                search={""}
                data={data}
                rol={roles.ADMIN}
                total={total}
                page={page}
                setPage={setPage}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
