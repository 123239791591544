import React from 'react'
import { Typography, useMediaQuery } from '@mui/material'
import { ProfileFormAdmin } from '../../../components/Forms/ProfileFormAdmin';

export const AccountProfileAdmin = () => {

  const phone = useMediaQuery('(max-width:480px)');

  return (
    <>
      <div className='w-100 px-3'>
        <div className=' w-100 d-flex justify-content-between p-2  d-flex flex-column' >
          <div className=' p-2 d-flex align-items-center bg-white border-radius-10'>
            <Typography className={`deep-blue-font`} variant={phone ? "h6" : "h5"}> Mi perfil </Typography>
            <div className="d-flex">
              {/* <span className="text-danger">*</span> <p className="m-0 text-secondary"> Campos obligatorios</p> */}
            </div>
          </div>
          <div className='mt-4'>
            <ProfileFormAdmin />
          </div>
        </div>
      </div>
    </>
  )
}

