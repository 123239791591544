import React, { useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Modal,
} from "@mui/material";

import { VisibilityTwoTone, Delete } from "@mui/icons-material";
import { useViewPortDimensions } from "../../hooks/useViewPortDimensions";
import { TablesPagination } from "../Pagination/TablesPagination";
import { DetailsGuidesModal } from "../Modal/DetailsGuidesModal";
import { WarningReasonModal } from "../Modal/DeletionReasonModal";

export const ActiveGuidesTableAdmin = ({
  search,
  data,
  setPage,
  page,
  total,
}) => {
  const { height, width } = useViewPortDimensions();
  const [shipmentId, setShipmentId] = useState(0);
  const [openDetail, setOpenDetail] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [guideDetails, setGuideDetails] = useState({});
  const [warningText, setWarningText] = useState("");

  const handleOpenDetail = (row) => {
    setOpenDetail(true);
    setGuideDetails(row);
  };

  const handleDeleteShipmentGuide = (row) => {
    setShipmentId(row.tracking_id);

    if (row.route == null) {
      setWarningText(`Estás a punto de eliminar la guía ${row.tracking_id}`);
    } else {
      setWarningText(
        `La guía ${row.tracking_id} se encuentra actualmente en la ruta ${row.route} \n¿Está seguro que desea eliminarla?`
      );
    }

    setOpenDelete(true);
  };

  const TableHeader = () => (
    <thead className="h-12 bg-[#004876c2] text-white">
      <TableRow>
        <TableCell>Acciones</TableCell>
        <TableCell>Ver detalles</TableCell>
        <TableCell align="center">No de Cliente</TableCell>
        <TableCell align="center">
          <span style={{ whiteSpace: "nowrap" }}>Direccion de entrega</span>
        </TableCell>
        <TableCell align="center">
          <span style={{ whiteSpace: "nowrap" }}>Geolocalización</span>
        </TableCell>
        <TableCell align="center">No. Rastreo</TableCell>
        <TableCell align="center">Producto (s)</TableCell>
        <TableCell>Monto</TableCell>
        <TableCell>Fecha de creacion</TableCell>
        <TableCell>Punto de recolección</TableCell>
        <TableCell>Hora de creacion</TableCell>
        <TableCell>Hora de entrega</TableCell>
        <TableCell>Estatus</TableCell>
        <TableCell>Rechazo/Incidencia</TableCell>
      </TableRow>
    </thead>
  );


  const TableRowItem = ({ row, index }) => (
    <TableRow hover role="checkbox" tabIndex={-1} key={row.id} style={{ backgroundColor: index % 2 === 0 ? "#f0f0f0" : "white" }}>
      <TableCell>
        <Tooltip title={"Eliminar guía"} arrow placement="top">
          <div className="d-flex align-items-center justify-content-center">
            <IconButton
              style={{ color: "#d50000" }}
              onClick={() => handleDeleteShipmentGuide(row)}
            >
              <Delete />
            </IconButton>
          </div>
        </Tooltip>
      </TableCell>
      <TableCell padding="checkbox">
        <Tooltip title={"Detalles"} arrow placement="top">
          <div className="d-flex align-items-center justify-content-center">
            <IconButton
              style={{ color: "#258FC4" }}
              onClick={() => handleOpenDetail(row)}
            >
              <VisibilityTwoTone />
            </IconButton>
          </div>
        </Tooltip>
      </TableCell>
      <TableCell
        component="th"
        id={`enhanced-table-checkbox-${index}`}
        scope="row"
        padding="normal"
        align="center"
      >
        {row.tracking_id}
      </TableCell>
      <TableCell align="center">{row.destination}</TableCell>
      <TableCell align="center">lat: {row.location.lat},<br/>lng:{row.location.lng}</TableCell>
      <TableCell align="center">{row.tracking_id}</TableCell>
      <TableCell align="center">
        {row.items.map((item, index) => (
          <p key={index}>-{item.description}.</p>
        ))}
      </TableCell>
      <TableCell align="center">
        {row.items.reduce((sum, item) => sum + item.unitaryProductPrice || 0, 0).toFixed(2) > 0 ? row.items.reduce((sum, item) => sum + item.unitaryProductPrice, 0).toFixed(2): "No disponible"} 
      </TableCell>
      <TableCell align="center"> {new Date(Date.parse(row.date_created.toString())).toLocaleDateString()}</TableCell>
      <TableCell align="center">{row.origin}</TableCell>
      <TableCell align="center">{new Date(row.date_created).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</TableCell>
      <TableCell align="center">{row.pick_up_date || "No disponible"}</TableCell>
      <TableCell align="center">{row.status}</TableCell>
      <TableCell align="center">{row.previous_status || "No disponible"}</TableCell>
    </TableRow>
  );

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: height - (74 + 90 + 80) }}>
            <Table
              sx={{ minWidth: 750 }}
              aria-label="sticky table"
              stickyHeader
            >
              <TableHeader/>
              <TableBody>
                {data.map((row, index) => {
                  return (<TableRowItem key={row.id} row={row} index={index}/>)                  
                })}
              </TableBody>
            </Table>
            {search != "" && data.length == 0 ? (
              <div className="d-flex justify-content-center p-4">
                No se encontraron resultados para "{search}"
              </div>
            ) : data.length == 0 && search == "" ? (
              <div className="d-flex justify-content-center p-4">
                No existen registros aún.
              </div>
            ) : (
              ""
            )}
          </TableContainer>
          <TablesPagination setPage={setPage} page={page} total={total} />
        </Paper>
      </Box>
      <Modal open={openDetail} onClose={() => console.log("Cerrando")}>
        <DetailsGuidesModal
          detail={guideDetails}
          handleClose={() => setOpenDetail(false)}
        />
      </Modal>
      <Modal open={openDelete}>
        <WarningReasonModal
          recordId={shipmentId}
          action={"DeleteShipment"}
          handleCancel={() => setOpenDelete(false)}
          text={warningText}
        />
      </Modal>
    </>
  );
};
