import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField, useMediaQuery, Grid, Modal, Skeleton, Backdrop } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { AcceptButton } from "../Buttons/AcceptButton";

import { SuccessModal } from "../Modal/SuccessModal";
import { ErrorModal } from "../Modal/ErrorModal";
import { AccountAPI } from "../../api/account";



export const ProfileFormAdmin = () => {
    const navigate = useNavigate();
    const phone = useMediaQuery("(max-width:480px)");
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [openBD, setOpenBD] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [adminInfo, setAdminInfo] = useState({
        employee_id: "",
        nombre: "",
        apellidos: "",
        email: "",
        telefono: "",
    });

    useEffect(() => {
        AdminInfo();
    }, []);

    const AdminInfo = async (obj) => {
        setIsLoading(true)
        let data = new FormData();

        try {
            const api = new AccountAPI()
            const response = await api.getInfoEP(data);

            setAdminInfo(response.data);
            setIsLoading(false)
        } catch (error) {}
    };

    const EditAdmin = async (obj) => {
        setOpenBD(true)

        let data = new FormData();
        data.append("nombre", obj.nombre);
        data.append("apellidos", obj.apellidos);
        data.append("email", obj.email);
        data.append("telefono", obj.telefono);

        try {
            const api = new AccountAPI()
            const response = await api.editAdminInfoEP(data);
            setOpenSuccess(true);
            setOpenBD(false)
        } catch (error) {
            setOpenBD(false);
            setOpenError(true)
        }
    };

    const fields = {

        0: [
            { keyName: "Nombre(s)", value: "nombre", type: true, editable: "enabled" },
            { keyName: "Apellidos", value: "apellidos", type: true, editable: "enabled", }
        ],

        1: [
            { keyName: "Email", value: "email", type: true, editable: "enabled" },
            { keyName: "Teléfono", value: "telefono", type: true, editable: "enabled", }
        ],

    };

    // const handleClose = (path) => {
    //     if (path == "nuevo") {
    //         window.location.reload();
    //     } else {
    //         navigate(`/administrador/cuenta/`);
    //     }
    // };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            nombre: adminInfo.nombre,
            apellidos: adminInfo.apellidos,
            email: adminInfo.email,
            telefono: adminInfo.telefono,
        },

        validationSchema: Yup.object({
            nombre: Yup.string()
                .required("Campo Obligatorio")
                .matches(
                    /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
                    "Sólo alfanuméricos y espacios"
                )
                .max(100, "Demasiado largo"),
            apellidos: Yup.string()
                .required("Campo Obligatorio")
                .matches(
                    /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
                    "Sólo alfanuméricos y espacios"
                )
                .max(100, "Demasiado largo"),
            email: Yup.string()
                .required("Campo Obligatorio")
                .matches(
                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    "Formato incorrecto"
                )
                .max(100, "Demasiado largo"),
            telefono: Yup.string()
                .required("Campo Obligatorio")
                .matches(/^\d+$/, "Deben ser números sin espacios")
                .min(10, "Demasiado corto")
                .max(12, "Demasiado largo"),
        }),

        onSubmit: (values) => {
            const obj = {
                ...values,
            };
            //console.log(values);
            EditAdmin(obj)
        },
    });

    return (
        <>
            <form onSubmit={formik.handleSubmit}>

                <Grid container rowSpacing={2}>

                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <div className={`border bg-white border-radius-10 p-4 ${!phone && "mr-2"}`}>
                            <div className=''>
                                <p className={`ds-blue-font px-2 ${phone ? "h6" : "h5"}`}> Datos </p>
                            </div>

                            {fields[0].map((field) => (
                                <div
                                    key={field.keyName}
                                    className={` d-flex mb-3 flex-column ${phone ? "px-2 " : "px-4"}`}
                                >
                                    <div
                                        className={`d-flex align-items-center mr-2 ${phone && "mb-2"} `}
                                        style={{ minWidth: `${phone ? "125px" : "160px"}` }}
                                    >
                                        <p className=" h6" htmlFor={field.value}>{`${field.keyName
                                            }: ${field.type ? "*" : ""}`}</p>
                                    </div>
                                    <div className="d-flex align-items-center w-100 ">
                                        <div className="d-flex flex-column w-100">
                                            {
                                                isLoading ? (
                                                    <Skeleton variant="rectangular" height={40} />
                                                ) : (
                                                    field.editable == "disabled" ? (
                                                        <TextField
                                                            disabled
                                                            size="small"
                                                            label={field.keyName}
                                                            variant="outlined"
                                                            fullWidth
                                                            value={formik.values[field.value]}
                                                            name={field.value}
                                                            id={field.value}
                                                        />
                                                    ) : (
                                                        <TextField
                                                            error={
                                                                formik.touched[field.value] &&
                                                                formik.errors[field.value]
                                                            }
                                                            size="small"
                                                            label={field.keyName}
                                                            variant="outlined"
                                                            fullWidth
                                                            onChange={formik.handleChange}
                                                            value={formik.values[field.value]}
                                                            name={field.value}
                                                            id={field.value}
                                                            helperText={
                                                                formik.touched[field.value] &&
                                                                    formik.errors[field.value] ? (
                                                                    <div>{formik.errors[field.value]}</div>
                                                                ) : null
                                                            }
                                                        />
                                                    )
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={6} >
                        <div className={`border bg-white border-radius-10 p-4 ${!phone && "ml-2"}`}>
                            <div className=''>
                                <p className={`ds-blue-font px-2 ${phone ? "h6" : "h5"}`}> Contacto </p>
                            </div>
                            {fields[1].map((field) => (
                                <div
                                    key={field.keyName}
                                    className={` d-flex mb-3 flex-column ${phone ? "px-2 " : "px-4"}`}
                                >
                                    <div
                                        className={`d-flex align-items-center mr-2 ${phone && "mb-2"} `}
                                        style={{ minWidth: `${phone ? "125px" : "160px"}` }}
                                    >
                                        <p className="h6" htmlFor={field.value}>{`${field.keyName
                                            }: ${field.type ? "*" : ""}`}</p>
                                    </div>
                                    <div className="d-flex align-items-center w-100 ">
                                        <div className="d-flex flex-column w-100">
                                            {
                                                isLoading ? (
                                                    <Skeleton variant="rectangular" height={40} />
                                                ) : (
                                                    field.editable == "disabled" ? (
                                                        <TextField
                                                            disabled
                                                            size="small"
                                                            label={field.keyName}
                                                            variant="outlined"
                                                            fullWidth
                                                            value={formik.values[field.value]}
                                                            name={field.value}
                                                            id={field.value}
                                                        />
                                                    ) : (
                                                        <TextField
                                                            error={
                                                                formik.touched[field.value] &&
                                                                formik.errors[field.value]
                                                            }
                                                            size="small"
                                                            label={field.keyName}
                                                            variant="outlined"
                                                            fullWidth
                                                            onChange={formik.handleChange}
                                                            value={formik.values[field.value]}
                                                            name={field.value}
                                                            id={field.value}
                                                            helperText={
                                                                formik.touched[field.value] &&
                                                                    formik.errors[field.value] ? (
                                                                    <div>{formik.errors[field.value]}</div>
                                                                ) : null
                                                            }
                                                        />
                                                    )
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                    </Grid>
                </Grid>

                <div className={`${!phone && "d-flex justify-content-end margin-right"} mt-2`}>
                    <div className={`${phone && "mb-2"}`}>
                        {/* <CancelButton text={"Cancelar"} onClick={() => { navigate("/administrador/cuenta/") }} width={phone ? "100%" : ""} /> */}
                    </div>
                    <AcceptButton text={"Actualizar"} type={"submit"} width={phone ? "100%" : ""} />
                </div>
            </form>
            <Modal open={openSuccess} onClose={() => console.log("Cerrando")}>
                <SuccessModal
                    handleClose={() => setOpenSuccess(false)}
                    text={"Información actualizada con éxito."}
                />
            </Modal>
            <Modal open={openError} onClose={() => console.log("Cerrando")}>
                <ErrorModal
                    text={`No se pudo actualizar la información.`}
                    handleClose={() => setOpenError(false)}
                />
            </Modal>

            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBD}
            >
                <span className="loader"></span>
            </Backdrop>

        </>
    );
};
