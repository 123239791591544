import React from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const MenuActionButtonStyled = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  lineHeight: 1.5,
  borderRadius: 15,
  backgroundColor: "white",
  color: "#1f4368",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  "&:hover": {
    backgroundColor: "#e9eaef",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#FFF",
  },
});

export const MenuActionButton = ({ text, onClick, icon }) => {
    return (
        <div className='d-flex align-items-center m-1'>
            {/* <Link to={link}> */}
                <div className='d-flex align-items-center'>
                    <MenuActionButtonStyled variant="contained" disableRipple onClick={onClick}>
                       {icon}
                        <p className='m-0 no-wrap'>{text}</p>
                    </MenuActionButtonStyled>
                </div>
            {/* </Link> */}
        </div>
    )
}

