import React, { useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import { useViewPortDimensions } from "../../hooks/useViewPortDimensions";
import { TablesPagination } from "../Pagination/TablesPagination";
import { ModalEvidence } from "../Modal/ModalEvidence";

const TableHeader = ({store}) => (
  <thead className="h-12 bg-[#004876c2] text-white">
    <TableRow>
      <TableCell align="center">Guía</TableCell>
      <TableCell align="center">Fecha de creación</TableCell>
      <TableCell align="center">Fecha de recolección</TableCell>
      <TableCell align="center">Fecha de entrega</TableCell>
      <TableCell align="center">Operador</TableCell>
      <TableCell align="center">Vehículo</TableCell>
      <TableCell align="center">Estatus</TableCell>
      <TableCell align="center">Ruta</TableCell>
      <TableCell align="center">Unidad</TableCell>
      <TableCell align="center">Punto de recolección</TableCell>
      <TableCell align="center">Punto de entrega</TableCell>
      <TableCell align="center">#Rutas creadas previamente</TableCell>
      <TableCell align="center">Ruta</TableCell>
      {store == 'Unilever' &&(<TableCell align="center">Evidencia de producto</TableCell>)}
      <TableCell align="center">Evidencia</TableCell>
      <TableCell align="center">Tiempo total de entrega</TableCell>
      <TableCell align="center">Comentarios</TableCell>
    </TableRow>
  </thead>
);

const TableRowItem = ({ row, index, handleEvidence, handleRoute, handleButtonEvidenceProduct, store }) => (
  <TableRow hover role="checkbox" tabIndex={-1} key={row.id} style={{ backgroundColor: index % 2 === 0 ? "#f0f0f0" : "white" }}>
    <TableCell component="th" id={index} scope="row" padding="normal" align="center"> {row.tracking_id} </TableCell>
    <TableCell component="th" id={index} scope="row" padding="normal" align="center"> {row.date_created} </TableCell>
    <TableCell component="th" scope="row" padding="none" align="center"> {row.pick_up_date} </TableCell>
    <TableCell component="th" id={index} scope="row" padding="none" align="center"> {row.delivery_date} </TableCell>
    <TableCell component="th" id={index} scope="row" padding="none" align="center"> {row.partner.name + " " + row.partner.lastName} </TableCell>
    <TableCell component="th" id={index} scope="row" padding="none" align="center"> {row.partner.vehicle} </TableCell>
    <TableCell component="th" id={index} scope="row" padding="none" align="center"> {row.status} </TableCell>
    <TableCell component="th" id={index} scope="row" padding="none" align="center"> {row.route} </TableCell>
    <TableCell component="th" id={index} scope="row" padding="none" align="center"> {row.unit} </TableCell>
    <TableCell component="th" id={index} scope="row" padding="none" align="center"> {row.origin} </TableCell>
    <TableCell component="th" id={index} scope="row" padding="none" align="center"> {row.destination} </TableCell>
    <TableCell component="th" id={index} scope="row" padding="none" align="center"> {row.routes_count} </TableCell>
    <TableCell component="th" id={index} scope="row" padding="none" align="center">
      {row.evidence ? (
        // <a href={row.trip_screenshot} target="_blank">
        //   Ver
        // </a>
        <button onClick={() => handleRoute(row)}>
          Ver
        </button>
      ) : (
        ""
      )}
    </TableCell>
    {store == 'Unilever'&&(<TableCell component="th" id={index} scope="row" padding="none" align="center">
      {row.second_evidence ? (
        // <a href={row.evidence} target="_blank">
        //   Ver m
        // </a>
        <button onClick={() => handleButtonEvidenceProduct(row)}>
          Ver
        </button>
      ) : (
        ""
      )}
    </TableCell>)}
    <TableCell component="th" id={index} scope="row" padding="none" align="center">
      {row.evidence ? (
        // <a href={row.evidence} target="_blank">
        //   Ver m
        // </a>
        <button onClick={() => handleEvidence(row)}>
          Ver
        </button>
      ) : (
        ""
      )}
    </TableCell>
    <TableCell component="th" id={index} scope="row" padding="none"align="center"> {row.delivery_time} </TableCell>
    <TableCell component="th" id={index} scope="row" padding="none"align="center"> {row.comments} </TableCell>
  </TableRow>
);



const HistoricalGuidesTable = ({ search, data, setPage, page, total }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [modalTitle, setModalTitle] = useState("");
  const store = localStorage.getItem("company");

  const handleModalClose = () => {
    setIsModalOpen(false);
    selectedRow(null);
    setModalTitle("");
  }

  const handleButtonEvidence = (row) =>{
    setIsModalOpen(true);
    setSelectedRow(row.evidence);
    setModalTitle("Evidencia");
  }

  const handleButtonRoute = (row) =>{
    setIsModalOpen(true);
    setSelectedRow(row.trip_screenshot);
    setModalTitle("Ruta");
  }

  const handleButtonEvidenceProduct = (row) =>{
    setIsModalOpen(true);
    setSelectedRow(row.second_evidence);
    setModalTitle("Evidencia de Producto");
  }

  const { height } = useViewPortDimensions();
  console.log(data)
  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: height - (74 + 90 + 80) }}>
          <Table sx={{ minWidth: 750 }} aria-label="sticky table" stickyHeader>
            <TableHeader store={store}/>
            <TableBody>
              {data.map((row, index) => (
                <TableRowItem key={row.id} row={row} index={index} handleEvidence={handleButtonEvidence} handleRoute={handleButtonRoute} handleButtonEvidenceProduct={handleButtonEvidenceProduct} store={store}/>
              ))}
            </TableBody>
          </Table>
          {search !== "" && data.length === 0 ? (
            <div className="d-flex justify-content-center p-4">
              No se encontraron resultados para "{search}"
            </div>
          ) : (
            data.length === 0 &&
            search === "" && (
              <div className="d-flex justify-content-center p-4">
                No existen registros aún.
              </div>
            )
          )}
        </TableContainer>
        <TablesPagination setPage={setPage} page={page} total={total} />
      </Paper>
      {isModalOpen && <ModalEvidence onClose={handleModalClose} title={modalTitle} rowData={selectedRow}/>}
    </Box>
  );
};

export { HistoricalGuidesTable };
