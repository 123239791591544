import React, { useEffect, useState } from "react";
import { useMediaQuery, Popover } from "@mui/material";
import { Search } from "../../../components/Search/Search";
import { RoutesTable } from "../../../components/Tables/RoutesTable";
import { FiltersModal } from "../../../components/Modal/FiltersModal";
import { SkeletonTables } from "../../../components/Tables/SkeletonTables";
import { DownloadButtonExcel } from "../../../components/Excel/DownloadButtonExcel";
import { routesHeaders } from "../../../components/Excel/headers";
import { AdminAPI } from "../../../api/admin";
import { getDateCleaned } from "../../../helpers/getDateCleaned";
export const RoutesAdmin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const phone = useMediaQuery("(max-width:480px)");
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0); /*Estado p/paginacion*/
  const [total, setTotal] = useState(); /*Total de paginas enviado desde api*/
  const [count, setCount] = useState(); /*Paginacion listas */
  const [anchorEl, setAnchorEl] = useState(null);

  // ERRORS STATES
  const [errors, setErrors] = useState({
    error: false,
    errorNullStart: false,
    errorNullEnd: false,
  });

  // EXCEL STATES
  const [dataExcel, setDataExcel] = useState([]);
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);

  // FILTER STATES
  const [startFilterDate, setStartFilterDate] = useState(null);
  const [endFilterDate, setEndFilterDate] = useState(null);
  const [status, setStatus] = useState("Todas");
  const statusList = [
    { status: "Todas" },
    { status: "En proceso" },
    { status: "En ruta" },
    { status: "Finalizadas" },
    { status: "Canceladas" },
  ];
  const [prevData, setPrevData] = useState({
    startFilterDate: null,
    endFilterDate: null,
    status: "Todas",
  });
  const today = new Date();

  //EFFECTS
  //------------------------------------------------

  useEffect(() => {
    RequestData();
  }, [page, search]);

  //CLASSES
  //------------------------------------------------

  class Route {
    static form(download) {
      let data = new FormData();
      data.append("download", download);
      data.append("page", page + 1);

      if (endFilterDate != null) {
        data.append("endDate", getDateCleaned(endFilterDate));
      }
      if (startFilterDate != null) {
        data.append("startDate", getDateCleaned(startFilterDate));
      }
      if (status == "En proceso") {
        data.append("statusRoute", 1);
      }
      if (status == "En ruta") {
        data.append("statusRoute", 2);
      }
      if (status == "Finalizadas") {
        data.append("statusRoute", 3);
      }
      if (search != "") {
        data.append("search", search);
      }

      return data;
    }
  }

  //DATA REQUESTS
  //------------------------------------------------
  const GetRoutes = async () => {
    setIsLoading(true);
    const data = Route.form(false);
    try {
      const api = new AdminAPI();
      const response = await api.getRoutesEP(data);

      // console.log(data.entries());
      // for (const value of data.entries()) {
      //   console.log(value);
      // }

      setData(response.data.data);
      setTotal(response.data.total);
      setCount(Math.ceil(response.data.total / 10));
      setIsLoading(false);
    } catch (error) {}
  };

  const GetRoutesExcelList = async () => {
    setIsLoadingExcel(true);
    const data = Route.form(true);

    try {
      const api = new AdminAPI();
      const response = await api.getRoutesEP(data);
      setDataExcel(response.data.data);
      setIsLoadingExcel(false);
    } catch (error) {}
  };

  const RequestData = () => {
    GetRoutes();
  };

  //FILTERS SETTINGS
  //------------------------------------------------
  const settingFilters = (status, firstDay, endDay) => {
    setStatus(status);
    setStartFilterDate(firstDay);
    setEndFilterDate(endDay);
  };

  //HANDLERS
  //------------------------------------------------
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleCloseFilterModal = () => setAnchorEl(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseModal = () => {
    setAnchorEl(null);
    settingFilters(
      prevData.status,
      prevData.startFilterDate,
      prevData.endFilterDate
    );
    setErrors({
      error: false,
      errorNullStart: false,
      errorNullEnd: false,
    });
  };

  const handleFilter = () => {
    handleCloseFilterModal();
    if (startFilterDate != null || endFilterDate != null) {
      if (startFilterDate != null && endFilterDate == null) {
        setErrors({ errorNullEnd: true });
      } else if (endFilterDate != null && startFilterDate == null) {
        setErrors({ errorNullStart: true });
      } else if (endFilterDate < startFilterDate) {
        setErrors({ error: true });
      } else {
        setErrors({
          error: false,
          errorNullStart: false,
          errorNullEnd: false,
        });
        RequestData();
        setPrevData({
          status: status,
          startFilterDate: startFilterDate,
          endFilterDate: endFilterDate,
        });
      }
    } else {
      RequestData();
      setPrevData({
        status: status,
        startFilterDate: startFilterDate,
        endFilterDate: endFilterDate,
      });
    }
  };

  const handleResetFilter = () => {
    settingFilters("Todas");
    setErrors({
      error: false,
      errorNullStart: false,
      errorNullEnd: false,
    });
  };

  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
  };

  return (
    <>
      <div className="w-100 px-3">
        <div>
          {phone ? (
            <div className="mt-2 w-100 p-1 ">
              <div className="d-flex bg-white justify-content-between border-radius-10"></div>
              <div className="d-flex w-100 mt-2"></div>
            </div>
          ) : (
            <div className="w-100 bg-white d-flex justify-content-between mt-1 p-2 border-radius-10">
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <Search setSearch={setSearch} handleClick={handleClick} />
                  <DownloadButtonExcel
                    text={"Descargar"}
                    disabled={data.length > 0 ? false : true}
                    data={dataExcel}
                    headers={routesHeaders}
                    sheetName={"Rutas programadas"}
                    isLoading={isLoadingExcel}
                    getData={() => GetRoutesExcelList()}
                  />
                  {/* <ButtonTypeLink text={"Programar ruta"}/> */}
                </div>
              </div>

              {/* <ToggleView setView={setView} view={view} /> */}
            </div>
          )}

          {isLoading ? (
            <div className="mt-3 bg-white w-100 d-flex justify-content-between p-2 border-radius-10">
              <SkeletonTables num={3} icon={true} />
            </div>
          ) : (
            <div className="mt-3 bg-white w-100 d-flex justify-content-between p-3 border-radius-10">
              <RoutesTable
                search={search}
                data={data}
                rol={"admin"}
                total={total}
                page={page}
                setPage={setPage}
              />
            </div>
          )}
        </div>
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseFilterModal}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <FiltersModal
          errors={errors}
          setErrors={setErrors}
          handleClose={handleCloseModal}
          dates={{
            setEnd: setEndFilterDate,
            end: endFilterDate,
            setStart: setStartFilterDate,
            start: startFilterDate,
          }}
          fields={[
            {
              array: statusList,
              prop: "status",
              id: "status",
              type: "Estatus",
              value: status,
              handleOnChange: handleChangeStatus,
            },
          ]}
          date={{
            today,
          }}
          onClickFilter={handleFilter}
          onClickResetFilter={handleResetFilter}
        />
      </Popover>
    </>
  );
};
