import React from "react";
import { Button, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { RemoveCircle } from "@mui/icons-material";

export const RemoveIconButton = ({ onClick, value }) => {
  const DeleteBtnStyled = styled(Button)({
    boxShadow: "0",
    textTransform: "none",
    fontSize: 14,
    backgroundColor: "transparent",
    color: "#B01A1A",
    textAlign: "center",
    fontWeight: "bold",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&:active": {},
    "&:focus": {},
  });

  return (
    <Tooltip title="Eliminar" arrow placement="top">
      <DeleteBtnStyled
        value={value}
        variant="text"
        onClick={(e) => onClick(e)}
        disableElevation
      >
        <RemoveCircle />
      </DeleteBtnStyled>
    </Tooltip>
  );
};
