import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { SuccessModal } from "../Modal/SuccessModal";
import {
  TextField,
  Modal,
  useMediaQuery,
  Grid,
  Skeleton,
  Backdrop,
  MenuItem,
} from "@mui/material";
import { Search } from "@mui/icons-material";

import { useNavigate, useLocation } from "react-router-dom";
import { AcceptButton } from "../Buttons/AcceptButton";
import { CancelButton } from "../Buttons/CancelButton";
import { AdminAPI } from "../../api/admin";
import { ErrorModal } from "../Modal/ErrorModal";
import { Map } from "../Maps/Map";
import { Marker } from "../Maps/Marker";
import { method } from "lodash";

export const ShipmentOriginForm = () => {
  const navigate = useNavigate();
  const phone = useMediaQuery("(max-width:480px)");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openBD, setOpenBD] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [addressIsLoading, setAddressIsLoading] = useState(false);
  const [errorText, setErrorText] = useState("");

  const location = useLocation();

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  const [shipmentOrigin, setShipmentOrigin] = useState({
    originName: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    references: "",
    address: {
      street: "",
      interior_number: "",
      outdoor_number: "",
      neighborhood: "",
      zipCode: "",
    },
  });

  const countryPhoneCodes = [
    { country: "MX", phoneCode: "+52" },
    { country: "USA", phoneCode: "+1" },
  ];

  const handleClose = () => {
    navigate(`/admin/shipmentsOrigins/`);
  };

  const fields = {
    0: [
      {
        keyName: "Nombre de origen",
        value: "originName",
        type: true,
        size: 12,
        dropdown: false,
      },
      {
        keyName: "Nombre de contacto",
        value: "originFirstName",
        type: true,
        size: 6,
        dropdown: false,
      },
      {
        keyName: "Apellido",
        value: "originLastName",
        type: true,
        size: 6,
        dropdown: false,
      },
      {
        keyName: "País",
        value: "originCountryPhoneCode",
        type: true,
        size: 2,
        dropdown: true,
      },
      {
        keyName: "Número de telefono",
        value: "originPhoneNumber",
        type: true,
        size: 10,
        dropdown: false,
      },
      {
        keyName: "Correo electronico",
        value: "originMail",
        type: true,
        size: 12,
        dropdown: false,
      },
    ],

    1: [
      {
        keyName: "Calle",
        value: "originStreet",
        type: true,
        size: 12,
      },
      {
        keyName: "Número exterior",
        value: "originOutdoorNumber",
        type: true,
        size: 6,
      },
      {
        keyName: "Número interior",
        value: "originInteriorNumber",
        type: true,
        size: 6,
      },
      {
        keyName: "Colonia",
        value: "originNeighborhood",
        type: true,
        size: 12,
      },
      {
        keyName: "Código postal",
        value: "originZipCode",
        type: true,
        size: 6,
      },
      {
        keyName: "Referencias",
        value: "originReferences",
        type: true,
        size: 6,
      },
    ],
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: location.state != undefined
    ? {
      originName: location.state.name_favorite_origin,
      originFirstName: location.state.first_name,
      originLastName: location.state.last_name,
      originCountryPhoneCode: countryPhoneCodes[0].phoneCode,
      originPhoneNumber: location.state.phone,
      originMail: location.state.email,
      originReferences: location.state.location_references,
      originStreet: location.state.location_street,
      originBuildingNumber: location.state.location_outdoor_number,
      originInteriorNumber: location.state.location_interior_number,
      originOutdoorNumber: location.state.location_outdoor_number,
      originNeighborhood: location.state.location_neighborhood,
      originZipCode: location.state.location_zipcode_zipcode,
          
    }: {
      markerPosition: "",
      search: "",
      originName: shipmentOrigin.originName,
      originFirstName: shipmentOrigin.firstName,
      originLastName: shipmentOrigin.lastName,
      originCountryPhoneCode: countryPhoneCodes[0].phoneCode,
      originPhoneNumber: shipmentOrigin.phone,
      originMail: shipmentOrigin.email,
      originReferences: shipmentOrigin.references,
      originStreet: shipmentOrigin.address.street,
      originBuildingNumber: shipmentOrigin.address.fullAddress,
      originInteriorNumber: shipmentOrigin.address.interior_number,
      originOutdoorNumber: shipmentOrigin.address.outdoor_number,
      originNeighborhood: shipmentOrigin.address.neighborhood,
      originZipCode: shipmentOrigin.address.zipCode,
    },
    validationSchema: Yup.object({
      originName: Yup.string()
        .required("Campo Obligatorio")
        .max(100, "Máximo 100 caracteres"),
      originFirstName: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "Sólo alfanuméricos y espacios"
        )
        .max(100, "Máximo 100 caracteres"),
      originLastName: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "Sólo alfanuméricos y espacios"
        )
        .max(100, "Máximo 100 caracteres"),
      originPhoneNumber: Yup.string()
        .required("Campo Obligatorio")
        .matches(/^\d+$/, "Sólo debe contener números (sin espacios)")
        .max(50, "Demasiado largo"),
      originMail: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          "Formato incorrecto"
        )
        .max(256, "El correo electrónico es demasiado largo"),
      originReferences: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "Sólo alfanuméricos y espacios"
        )
        .max(128, "Máximo 128 caracteres"),
      originStreet: Yup.string()
        .required("Campo Obligatorio")
        .max(128, "Máximo 128 caracteres"),
      originInteriorNumber: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "Sólo alfanuméricos y espacios"
        )
        .max(100, "Máximo 100 caracteres"),
      originOutdoorNumber: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "Sólo alfanuméricos y espacios"
        )
        .max(100, "Máximo 100 caracteres"),

      originNeighborhood: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "Sólo alfanuméricos y espacios"
        )
        .max(128, "Máximo 128 caracteres"),
      originZipCode: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "Sólo alfanuméricos y espacios"
        )
        .max(128, "Máximo 128 caracteres"),
    }),

    onSubmit: (values) => {
      const obj = {
        ...values,
      };

      addShipmentOrigin(obj);
    },
  });

  const getGeolocation = (address) => {
    setAddressIsLoading(true);
  
    // Llamada a la API de Nominatim para buscar la dirección
    fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(address)}`)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.length > 0) {
          const result = data[0];
          
          // Obtener los componentes de la dirección
          const displayName = result.display_name;
          const lat = result.lat;
          const lon = result.lon;
  
          // Aquí puedes utilizar una librería de análisis de direcciones o manejar los resultados como desees
          const components = displayName.split(',');
          const neighborhood = components[1] || "";
          const street = components[0] || "";
          const postalCode = components.find((component) => component.trim().match(/^\d+$/)) || "";
          const country = components[components.length - 1].trim();
  
          if (country === "Mexico") {
            formik.setFieldValue("markerPosition", { lat, lon });
  
            formik.setFieldValue("originNeighborhood", neighborhood ? neighborhood.trim() : "");
            formik.setFieldValue("originStreet", street ? street.trim() : "");
            formik.setFieldValue("originOutdoorNumber", ""); // Nominatim no siempre proporciona números de calles
            formik.setFieldValue("originZipCode", postalCode ? postalCode.trim() : "");
          } else {
            setOpenError(true);
            setErrorText("Lo sentimos, no tenemos cobertura en esa región");
          }
        } else {
          setOpenError(true);
          setErrorText("No se encontró ningún resultado, incluye más datos a tu búsqueda");
          clearAddressFields();
        }
  
        setAddressIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setOpenError(true);
        setErrorText("No se encontró ningún resultado, incluye más datos a tu búsqueda");
        clearAddressFields();
        setAddressIsLoading(false);
      });
  };
  
  // Función para limpiar los campos de dirección en caso de error
  const clearAddressFields = () => {
    formik.setFieldValue("originZipCode", "");
    formik.setFieldValue("originNeighborhood", "");
    formik.setFieldValue("originStreet", "");
    formik.setFieldValue("originOutdoorNumber", "");
    formik.setFieldValue("markerPosition", "");
  };

  const getAddress = (location) => {
    setAddressIsLoading(true);
  
    const { lat, lng } = location;
  
    // Llamada a la API de Nominatim para buscar la dirección basada en la latitud y longitud
    fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.address) {
          // Obtener los componentes de la dirección
          const address = data.address;
  
          const neighborhood = address.suburb || address.neighbourhood || "";
          const street = address.road || "";
          const number = address.house_number || "";
          const postalCode = address.postcode || "";
  
          // Actualizar los campos con la información obtenida
          formik.setFieldValue("markerPosition", { lat, lng });
          formik.setFieldValue("originNeighborhood", neighborhood);
          formik.setFieldValue("originStreet", street);
          formik.setFieldValue("originOutdoorNumber", number);
          formik.setFieldValue("originZipCode", postalCode);
  
          // Si necesitas más datos del código postal, puedes llamar a getCPData(postalCode);
        } else {
          setOpenError(true);
          setErrorText(
            "No se encontró ningún resultado, incluye más datos a tu búsqueda"
          );
          clearAddressFields();
        }
  
        setAddressIsLoading(false);
      })
      .catch((e) => {
        setOpenError(true);
        setErrorText(
          "No se encontró ningún resultado, incluye más datos a tu búsqueda"
        );
        clearAddressFields();
        setAddressIsLoading(false);
        console.error(e);
      });
  };
  
  // Función para limpiar l
  

  const addShipmentOrigin = async (obj) => {
    setOpenBD(true);
    let data = new FormData();
    data.append("name_origin", obj.originName);
    data.append("name", obj.originFirstName);
    data.append("last_name", obj.originLastName);
    data.append("phone_number", obj.originPhoneNumber);
    data.append("email", obj.originMail);
    data.append("references", obj.originReferences);
    data.append("street", obj.originStreet);
    data.append("outdoor_number", obj.originOutdoorNumber);
    data.append("inner_number", obj.originInteriorNumber);
    data.append("colony", obj.originNeighborhood);
    data.append("postal_code", obj.originZipCode);
    data.append("ubication", JSON.stringify(obj.markerPosition));

    // for (const value of data.entries()) {
    //   console.log(value);
    // }
    let method = "POST";
    if (location.state != undefined) {
      method = "PUT";
      data.append("originId", location.state.id); 
    }
    try {
      const api = new AdminAPI();
      const response = await api.createFavoriteOrigin(data, method);

      if (response.status == 200) {
        setOpenSuccess(true);
        setOpenBD(false);
      } else {
        setOpenBD(false);
        if (response.response.status == 400) {
          setErrorText(response.response.data["msg"]);
        } else {
          setErrorText("Error al crear el registro");
        }
        setOpenError(true);
      }
    } catch (error) {
      console.log(error);
      setOpenBD(false);
      setOpenError(true);
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container rowSpacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <div
              className={`border bg-white border-radius-10 p-3 ${
                !phone && "mr-2"
              }`}
            >
              <div className="">
                <p className={`ds-blue-font p-2  ${phone ? "h6" : "h5 "}`}>
                  Datos de contacto
                </p>
              </div>
              <Grid container columns={{ xs: 4, sm: 6, md: 12, lg: 12 }}>
                {fields[0].map((field) => (
                  <Grid
                    key={field.keyName}
                    item
                    xs={field.size}
                    md={12}
                    lg={field.size}
                  >
                    <div
                      key={field.keyName}
                      className={` d-flex mb-3 flex-column ${
                        phone ? "px-2 " : "px-2"
                      }`}
                    >
                      <div
                        className={`d-flex align-items-center mr-2 ${
                          phone && "mb-2"
                        } `}
                        style={{ minWidth: `${phone ? "125px" : "160px"}` }}
                      >
                        <p className=" h6" htmlFor={field.value}>{`${
                          field.keyName
                        }: ${field.type ? "*" : ""}`}</p>
                      </div>
                      <div className="d-flex align-items-center w-100 ">
                        {isLoading ? (
                          <Skeleton variant="rectangular" height={40} />
                        ) : field.editable == "disabled" ? (
                          <TextField
                            disabled
                            size="small"
                            label={field.keyName}
                            variant="outlined"
                            fullWidth
                            value={formik.values[field.value]}
                            name={field.value}
                            id={field.value}
                          />
                        ) : (
                          <TextField
                            select={field.dropdown}
                            error={
                              formik.touched[field.value] &&
                              formik.errors[field.value]
                            }
                            size="small"
                            label={field.keyName}
                            variant="outlined"
                            fullWidth
                            onChange={formik.handleChange}
                            value={formik.values[field.value]}
                            name={field.value}
                            id={field.value}
                            helperText={
                              formik.touched[field.value] &&
                              formik.errors[field.value] ? (
                                <div>{formik.errors[field.value]}</div>
                              ) : null
                            }
                          >
                            {countryPhoneCodes.map((prefix, i) => (
                              <MenuItem key={i} value={prefix.phoneCode}>
                                {prefix.country + " (" + prefix.phoneCode + ")"}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <div className={`border bg-white border-radius-10 p-3`}>
              <p className={`ds-blue-font p-2 ${phone ? "h6" : "h5 ml-3"}`}>
                Dirección
              </p>
              <Grid container columns={{ xs: 4, sm: 6, md: 12, lg: 12 }}>
                <Grid item xs={12} md={12} lg={12}>
                  <div
                    className={` d-flex mb-3 flex-column ${
                      phone ? "px-2 " : "px-4"
                    }`}
                  >
                    <div
                      className={`d-flex align-items-center  ${
                        phone && "mb-2"
                      } `}
                      style={{ minWidth: `${phone ? "125px" : "160px"}` }}
                    >
                      <p
                        className={`mb-1 ${phone ? "xs-font" : ""}`}
                        htmlFor={"search"}
                      >{`Lugar o dirección:*`}</p>
                      <TextField
                        className=""
                        size="small"
                        fullWidth
                        label={"Lugar o dirección"}
                        onChange={formik.handleChange}
                        onKeyPress={(e) => {
                          if (e.key == "Enter") {
                            e.preventDefault();
                            getGeolocation(e.target.value);
                          }
                        }}
                        value={formik.values.search}
                        name={"search"}
                        inputProps={phone && { style: { fontSize: 13 } }}
                        InputProps={{
                          endAdornment: (
                            <Search
                              className="ds-yellow-font mr-2 cursor-pointer"
                              onClick={() =>
                                getGeolocation(formik.values.search)
                              }
                            />
                          ),
                        }}
                        error={formik.errors.location && formik.touched.search}
                        helperText={
                          formik.errors.location &&
                          formik.touched.search &&
                          formik.errors.location
                        }
                      />
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <div
                    className={` d-flex mb-4 flex-column ${
                      phone ? "px-2 " : "px-4"
                    }`}
                  >
                    <div
                      className={`d-flex align-items-center  ${
                        phone && "mb-2"
                      } `}
                      style={{ minWidth: `${phone ? "125px" : "160px"}` }}
                    >
                      
                        <Map
                          center={{
                            lat: 19.406011,
                            lng: -99.137612,
                          }}
                          zoom={8}
                          style={{ height: "30vh", width: "100%" }}
                        >
                          {formik.values.markerPosition && (
                            <Marker
                              position={formik.values.markerPosition}
                              draggable={true}
                              getAddress={getAddress}
                            />
                          )}
                        </Map>
                    </div>
                  </div>
                </Grid>
                {fields[1].map((field) => (
                  <Grid
                    key={field.keyName}
                    item
                    xs={field.size}
                    md={12}
                    lg={field.size}
                  >
                    <div
                      key={field.keyName}
                      className={` d-flex mb-3 flex-column ${
                        phone ? "px-2 " : "px-4"
                      }`}
                    >
                      <div
                        className={`d-flex align-items-center mr-2 ${
                          phone && "mb-2"
                        } `}
                        style={{ minWidth: `${phone ? "125px" : "160px"}` }}
                      >
                        <p className="h6" htmlFor={field.value}>{`${
                          field.keyName
                        }: ${field.type ? "*" : ""}`}</p>
                      </div>
                      <div className="d-flex align-items-center w-100 ">
                        <div className="d-flex flex-column w-100">
                          {isLoading ? (
                            <Skeleton variant="rectangular" height={40} />
                          ) : field.editable == "disabled" ? (
                            <TextField
                              disabled
                              size="small"
                              label={field.keyName}
                              variant="outlined"
                              fullWidth
                              value={
                                addressIsLoading &&
                                (field.value == "originZipCode" ||
                                  field.value == "originNeighborhood" ||
                                  field.value == "originStreet" ||
                                  field.value == "originOutdoorNumber")
                                  ? "Cargando... "
                                  : formik.values[field.value]
                              }
                              name={field.value}
                              id={field.value}
                            />
                          ) : (
                            <TextField
                              error={
                                formik.touched[field.value] &&
                                formik.errors[field.value]
                              }
                              size="small"
                              label={field.keyName}
                              variant="outlined"
                              fullWidth
                              onChange={formik.handleChange}
                              value={
                                addressIsLoading &&
                                (field.value == "originZipCode" ||
                                  field.value == "originNeighborhood" ||
                                  field.value == "originStreet" ||
                                  field.value == "originOutdoorNumber")
                                  ? "Cargando... "
                                  : formik.values[field.value]
                              }
                              name={field.value}
                              id={field.value}
                              helperText={
                                formik.touched[field.value] &&
                                formik.errors[field.value] ? (
                                  <div>{formik.errors[field.value]}</div>
                                ) : null
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </div>
          </Grid>
        </Grid>
        <div
          className={`mt-2 mb-2 ${
            !phone ? "d-flex justify-content-end margin-right" : ""
          }`}
        >
          <div className="mr-2">
            <CancelButton
              text={"Cancelar"}
              onClick={() => {
                navigate("/admin/shipmentsOrigins/");
              }}
              width={phone ? "100%" : ""}
            />
          </div>
          <AcceptButton
            text={location.state != undefined ? "Editar" : "Guardar"}
            type={"submit"}
            width={phone ? "100%" : ""}
          />
        </div>
      </form>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBD}
      >
        <span className="loader"></span>
      </Backdrop>
      <Modal open={openSuccess} onClose={() => console.log("Cerrando")}>
        <SuccessModal
          handleClose={handleClose}
          text={`Origen de envío ${
            location.state != undefined ? "editado" : "creado"
          } exitosamente.`}
        />
      </Modal>
      <Modal open={openError} onClose={() => console.log("Cerrando")}>
        <ErrorModal
          text={errorText}
          handleClose={() => setOpenError(false)}
        />
      </Modal>
    </>
  );
};
