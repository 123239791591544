import { useState, useEffect } from "react";

export const Line = (options) => {
  const [line, setLine] = useState();
  const symbolOne = {
    path: "M -2,0 0,-2 2,0 0,2 z",
    strokeColor: "#F00",
    fillColor: "#F00",
    fillOpacity: 1,
  };

  const lineSymbol = {
    //path: "M 0,-1 0,1",
    //path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    //path: "M 0, -1 0, 2 L 1, 1 M 0, -1 0, 2 L -1, 1 M 0, -1",
    path: "M3.458 1.951 2.25 0.75l-1.208 1.201A0.125 0.125 0 1 0 1.219 2.125L2.125 1.226v2.391a0.125 0.125 0 1 0 0.25 0V1.226L3.281 2.125a0.125 0.125 0 0 0 0.176 -0.177Z",
    strokeOpacity: 1,
    scale: 4,
  };

  useEffect(() => {
    if (!line) {
      setLine(
        new window.google.maps.Polyline({
          icons: [
            {
              icon: lineSymbol,
              repeat: "25px",
            },
            {
              icon: symbolOne,
              offset: "100%",
            },
          ],
        })
      );
    }
    if (line) {
      line.setOptions(options);
      //animateCircle(line);
    }
    return () => {
      if (line) {
        line.setMap(null);
      }
    };
  }, [line, options]);

  useEffect(() => {
    if (line) {
      line.setPath(options.path);
      const icons = line.get("icons");
      //console.log(icons[0].offset);
      // icons[0].offset = "95%";
      // line.set("icons", icons);
    }
  }, [options]);
  return null;
};

// function animateCircle(line) {
//   let count = 0;

//   const myInterval = window.setInterval(move, 1000);

//   function move() {
//     console.log("count", count);
//     const icons = line.get("icons");
//     count = (count + 1) % 10000;

//     if (parseInt(icons[0].offset.slice(0, -1)) < 99) {
//       icons[0].offset =
//         String(parseInt(icons[0].offset.slice(0, -1)) + count / 5) + "%";
//       console.log(icons[0].offset);
//       line.set("icons", icons);
//     } else {
//       window.clearInterval(myInterval);
//     }
//   }
// }

// const [ruta, setRuta] = useState([{lat: 19.4316246, lng: -99.2019084},
//   {lat: 19.4316241, lng: -99.2019148}, 
//   {lat: 19.431625, lng: -99.2019079},
//   {lat: 19.4316144, lng: -99.2020761},
//   {lat: 19.4312388, lng: -99.2020511},
//   {lat: 19.4307719, lng: -99.2022789},
//   {lat: 19.4304326, lng: -99.202728},
//   {lat: 19.4304326, lng: -99.202728},
//   {lat: 19.4309542, lng: -99.2030812},
//   {lat: 19.4309542, lng: -99.2030812},
//   {lat: 19.4314987, lng: -99.202848}])


// const [ruta2, setRuta2] = useState([
//   {lat: 19.4317538, lng: -99.2027383},
//   {lat: 19.4317538, lng: -99.2027383},
//   {lat: 19.4316215, lng: -99.2019153},
//   {lat: 19.4316237, lng: -99.2019146},
//   {lat: 19.431631, lng: -99.2019737},
//   {lat: 19.4316191, lng: -99.2020773},
//   {lat: 19.431177, lng: -99.202051},
//   {lat: 19.4307719, lng: -99.2022789},
//   {lat: 19.4304144, lng: -99.2027953},
//   {lat: 19.4304144, lng: -99.2027953}])