import React from 'react'

import { Button } from '@mui/material'
import { styled } from '@mui/material/styles';

import { AddCircleTwoTone } from '@mui/icons-material';

import { Link } from 'react-router-dom';

const AddButtonStyled = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    // border: '1px solid',
    lineHeight: 1.5,
    borderRadius: 12,
    backgroundColor: 'white',
    // borderColor: '#1f4368',
    color: '#487b94',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        backgroundColor: '#21817328',
        fontWeight: "bold",
        color: "#122028",
        //   backgroundColor: 'transparent',
        //   border: '1px solid #21817328',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        //   backgroundColor: '#0062cc',
        //   borderColor: '#005cbf',
    },
    '&:focus': {
        //   boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
});

export const AddButton = ({ text, link }) => {
    return (
        <div className='d-flex align-items-center m-1'>
            <Link to={link}>
                <div className='d-flex align-items-center'>
                    <AddButtonStyled variant="contained" disableRipple>
                        <AddCircleTwoTone className='mr-1' />
                        <p className='m-0 no-wrap'>{text}</p>
                    </AddButtonStyled>
                </div>
            </Link>
        </div>
    )
}
