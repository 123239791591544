export const nameSectionsUser = [
    {
        link: "/user/guides/active/",
        name: "Guías",
        bCName: "Activas",
    },
    {
        link: "/user/guides/historical/",
        name: "Histórico",
        bCName: "Histórico",
    },
    {
        link: "/user/guides/active/details/",
        name: "Detalle guías",
        bCName: "Detalle",
    },
    {
        link: "/user/account/",
        name: "Cuenta",
        bCName: "Cuenta",
    }
];

export const nameSectionsAdmin = [
    {
        link: "/admin/guides/active/",
        name: "Guías",
        bCName: "Activas",
    },
    {
        link: "/admin/guides/historical/",
        name: "Histórico",
        bCName: "Histórico",
    },
    {
        link: "/admin/guides/routes/",
        name: "Rutas Programadas",
        bCName: "Rutas",
    },
    {
        link: "/admin/operatives/",
        name: "Operadores",
        bCName: "Operadores",
    },
    {
        link: "/admin/operatives/add/",
        name: "Operadores",
        bCName: "Agregar",
    },
    {
        link: "/admin/operatives/edit/",
        name: "Operadores",
        bCName: "Editar",
    },
    {
        link: "/admin/vehicles/",
        name: "Unidades",
        bCName: "Unidades",
    },
    {
        link: "/admin/vehicles/add/",
        name: "Unidades",
        bCName: "Agregar",
    },
    {
        link: "/admin/vehicles/edit/",
        name: "Unidades",
        bCName: "Editar",
    },
    {
        link: "/admin/account/profile/",
        name: "Cuenta",
        bCName: "Cuenta",
    },
    {
        link: "/admin/account/password/",
        name: "Contraseña",
        bCName: "Contraseña",
    },
];