import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  TextField,
  useMediaQuery,
  InputAdornment,
  IconButton,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { AcceptButton } from "../Buttons/AcceptButton";
import { useAuthContext } from "../../context/AuthProvider";

export const LoginForm = () => {
  const { signIn, errors, isLoading } = useAuthContext();

  const [showPassword, setShowPassword] = useState(false);
  const [counter, setCounter] = useState(0);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const [labelLogin, setLabelLogin] = useState(true)
  const [labelPass, setLabelPass] = useState(true)

  const phone = useMediaQuery("(max-width:480px)");
  const horizontal = useMediaQuery("(max-height:480px)");


  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    // validate,
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          "Formato incorrecto"
        )
        .required("Campo Obligatorio"),
      password: Yup.string().required("Campo obligatorio"),
    }),

    onSubmit: (values) => {
      signIn(values);

    },
  });

  useEffect(() => {
    labeled();
  }, []);

  useEffect(() => {
    labeled();
  }, [formik.values.email, formik.values.password]);

  const labeled = () =>{
    if (formik.values.email !== '') {
      setLabelLogin(false);
    }
    
    if (formik.values.password !== '') {
      setLabelPass(false);
    }
  }


  const handleLoginFocus = () =>{
      setLabelLogin(false)
  }

  const handleBlurLogin = () =>{
    if(formik.values.email === ""){
      setLabelLogin(true)
    }   
  }

  const handlePassFocus = () =>{
    setLabelPass(false)
  }

  const handleBlurPass = () =>{
    if(formik.values.password === ""){
      setLabelPass(true)
    }   
  }

  return (
    <form onSubmit={formik.handleSubmit}>

      <div key={"email"} className={` d-flex flex-column mb-3 ${phone ? "" : "px-4"}`}>
        {labelLogin && (<div className="d-flex align-items-center mr-2">
          <label
            className={` m-0 no-wrap login-input-width text-left`}
            htmlFor={"email"}
          >{``}</label>
        </div>)}
        <div className="d-flex align-items-center w-100">
          <TextField
            // disabled={counter == 5 ? true : false}
            error={formik.touched.email && formik.errors.email}
            size="small"
            label={"Correo"}
            variant="outlined"
            fullWidth
            onChange={formik.handleChange}
            onFocus={handleLoginFocus}
            onBlur={handleBlurLogin}
            value={formik.values.email}
            name={"email"}
            id={"email"}
            helperText={
              formik.touched.email && formik.errors.email ? (
                <div>{formik.errors.email}</div>
              ) : null
            }
          />
        </div>
        {/* {formik.errors.email ? <div>{formik.errors.email}</div> : null} */}
      </div>

      <div key={"password"} className={` d-flex flex-column mb-3 ${phone ? "" : "px-4"}`}>
        {labelPass && (<div className="d-flex align-items-center mr-2">
          <label
            className={` m-0 no-wrap login-input-width text-left`}
            htmlFor={"password"}
          >{` `}</label>
        </div>)}
        <div className="d-flex align-items-center w-100">
          <TextField
            // disabled={counter == 5 ? true : false}
            error={formik.touched.password && formik.errors.password}
            helperText={
              formik.touched.password && formik.errors.password ? (
                <div>{formik.errors.password}</div>
              ) : null
            }
            size="small"
            type={showPassword ? "text" : "password"}
            label={"Contraseña"}
            variant="outlined"
            fullWidth
            onChange={formik.handleChange}
            onFocus={handlePassFocus}
            onBlur={handleBlurPass}
            value={formik.values.password}
            name={"password"}
            id={"password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>

      {errors.wrongEmail ? (
        <div className="mb-3">
          {/* <p className="m-0 text-danger">{counter == 5 ? "Espera 5 segundos y vuelve a intentarlo" : "Usuario inexistente."}</p> */}

        </div>
      ) : (
        ""
      )}
      {errors.nonAllowedUser ? (
        <div className="mb-3 text-center">
          <p className="m-0 text-danger">
            Credenciales incorrectas.
          </p>
        </div>
      ) : (
        ""
      )}
      {errors.wrongPassword ? (
        <div className="mb-3">
          <p className="m-0 text-danger">Contraseña incorrecta.</p>
        </div>
      ) : (
        ""
      )}
      <div className="w-100 d-flex justify-content-center margBott">
        {/* <AcceptButton
          width={150}
          height={40}
          text={!isLoading ? "Iniciar sesión" : <span className="loader-small"></span>}
          type={"submit"}
        /> */}
        <button type="submit" className="yellow-button">
          Login
        </button>
      </div>
    </form>
  );
};
