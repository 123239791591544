import React, { useState } from 'react'
import { useFormik } from "formik";
import * as Yup from "yup";
import { Typography, Box, useMediaQuery, TextField, IconButton, MenuItem, Checkbox, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, Modal } from "@mui/material";
import { Close } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { AcceptButton } from '../Buttons/AcceptButton';
import { useViewPortDimensions } from '../../hooks/useViewPortDimensions';
import { AdminAPI } from "../../api/admin";
import { useEffect } from 'react';
import { CancelButton } from '../Buttons/CancelButton';
import { TablesPagination } from '../Pagination/TablesPagination';
import { CreateRouteConfirmationModal } from './CreateRouteConfirmationModal';

export const CreateRoutesModal = ({ handleClose, routes, GetListShipmentList, setPage, total, page }) => {
    const phone = useMediaQuery("(max-width:480px)");
    const navigate = useNavigate();

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: phone ? "90%" : "80%",
        bgcolor: 'background.paper',
        maxHeight: "90vh",
        overflow: phone ? "scroll" : "hidden",
        // border: '2px solid #487b94 ',
        boxShadow: 24,
        px: 1,
        borderRadius: 2,
    };
    const { height, width } = useViewPortDimensions();
    const [selected, setSelected] = useState([]);
    const handleOnChange = (e) => {
        const { value, checked } = e.target
        //console.log(`${value} is ${checked}`)

        if(checked){
            setSelected([...selected, value]);
            
        } else {
            setSelected(selected.filter((e)=> e!=value))
        }

    };


    const [data, setData] = useState();
    const [operative, setOperative] = useState(0)
    const [operatives, setOperatives] = useState([]);
    const [openConfirmation, setOpenConfirmation] = useState(false);

    // const handleToggle = (value) => () => {
    //     setChecked([...checked, value]);
    // };

    const fields = [
        { keyName: "Operadores", value: "operative", type: true, size: true, dropdown: true, },
    ];

    const tourStops = [
        { lat: 34.8791806, lng: -111.8265049 },
        { lat: 34.8559195, lng: -111.7988186 },        
        { lat: 34.823736, lng: -111.8001857 }, 
        { lat: 34.800326, lng: -111.7665047 },];
    
    useEffect(() => {
        GetPartnersList();
    }, []);

  

    const GetPartnersList = async () => {
        let data = new FormData();
        const token = localStorage.getItem("token");
        data.append("available", true);
        data.append("allOperators", false);

        try {
            const api = new AdminAPI()
            const response = await api.getAssignmentsEP(data);
            
            setOperatives(response.data.data)
            GetListShipmentList();

        } catch (error) {
            console.log(error);
        }
    };



    const formik = useFormik({
        enableReinitialize: true,
        initialValues:
        {
            operative: "",
        },
        validationSchema: Yup.object({


        }),

        onSubmit: (values) => {
            const obj = {
                ...values,
                shipments: selected
            };
            console.log('obj', obj)
            setData(obj);
            setOpenConfirmation(true)
        },
    });

    return (
        <>
            <Box sx={style}>

                <div className="d-flex justify-content-between align-items-center ">
                    <div></div>

                    <div>
                        <Typography variant="h4" className='font-color-blue'>Guías activas</Typography>
                    </div>
                    <IconButton onClick={handleClose}>
                        <Close fontSize="large" />
                    </IconButton>
                </div>

                <div className={`d-flex w-100 pb-3 flex-column align-items-center justify-content-center`}>

                    <form className='w-100 ' onSubmit={formik.handleSubmit}>
                        <div className='d-flex flex-column align-items-center justify-content-center'>
                            {fields.map((field, i) => (
                                <div key={i} className='w-75 px-3'>
                                    <div
                                        className={` d-flex mb-3  ${phone ? "px-2 flex-column" : ""
                                            }`}
                                    >
                                        <div
                                            className={`d-flex align-items-center mr-2 ${phone && "mb-2"
                                                }`}
                                            style={{ width: "145px" }}
                                        >
                                            <p className="h6" htmlFor={field.value}>{`${field.keyName
                                                }: ${field.type ? "*" : ""}`}</p>
                                        </div>
                                        <div className="d-flex align-items-center w-100 ">
                                            <div className="d-flex flex-column w-100 ">

                                                <TextField
                                                    select={field.dropdown}
                                                    error={
                                                        formik.touched[field.value] &&
                                                        formik.errors[field.value]
                                                    }
                                                    size="small"
                                                    label={field.keyName}
                                                    variant="outlined"
                                                    fullWidth
                                                    onChange={(e) => { formik.handleChange(e); setOperative(e.target.value) }}
                                                    value={formik.values[field.value]}
                                                    name={field.value}
                                                    id={field.value}
                                                    helperText={
                                                        formik.touched[field.value] &&
                                                            formik.errors[field.value] ? (
                                                            <div>{formik.errors[field.value]}</div>
                                                        ) : null
                                                    }
                                                >
                                                    {field.dropdown &&
                                                        operatives.map((op, i) => (
                                                            // console.log(op.assigned_unit)
                                                            <MenuItem key={i} value={op.id}>
                                                                {op.assigned_unit.operatorName} {op.assigned_unit.operatorLastName}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </TextField>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {/* <Wrapper apiKey={"AIzaSyD7GYQaPOZk4Q70GQ4Hb4EKUGco8ReTjWA"}>
                                <Map center={{ lat: 34.84555, lng: -111.8035 }} zoom={12}>
                                 {
                                    tourStops.map((position, i) => (
                                        <Marker key={i} position={position} title={"mont"}/>
                                    ))
                                        
                                }
                                </Map>
                            </Wrapper> */}
                            <Box sx={{ width: "100%" }}>
                                <Paper sx={{ width: "100%" }}>
                                    <TableContainer sx={{ maxHeight: height - (80 + 90 + 150) }}>
                                        <Table sx={{ minWidth: 750 }} aria-label="sticky table" stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Seleccionar</TableCell>

                                                    <TableCell align="center">Guía</TableCell>
                                                    <TableCell align="center"><span style={{ whiteSpace: "nowrap" }}>Punto de recolección</span></TableCell>
                                                    <TableCell align="center"><span style={{ whiteSpace: "nowrap" }}>Punto de entrega</span></TableCell>
                                                    <TableCell align="center">Fecha</TableCell>
                                                    {/* <TableCell>Acciones</TableCell> */}

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    routes.map((row, index) => {
                                                        const labelId = `enhanced-table-checkbox-${index}`;
                                                        return (
                                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                                <TableCell component="th" id={labelId} scope="row" align="center" padding="checkbox">
                                                                    <div className='d-flex justify-content-center'>
                                                                        <Checkbox
                                                                            edge="end"
                                                                            //onChange={handleToggle(row.id)}
                                                                            value={row.id}
                                                                            //onChange={() => handleOnChange(index)}
                                                                            onChange={(e) => {handleOnChange(e) }}
                                                                            // checked={checked.indexOf(row.id) !== -1}
                                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                                        />
                                                                    </div>
                                                                </TableCell>

                                                                <TableCell align="center">
                                                                    {row.tracking_id}
                                                                </TableCell>
                                                                <TableCell align="center">{row.origin}</TableCell>
                                                                <TableCell align="center">{row.destination}</TableCell>
                                                                <TableCell align="center">{row.date_created}</TableCell>

                                                            </TableRow>
                                                        );
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablesPagination setPage={setPage} page={page} total={total} />
                                </Paper>
                            </Box>

                        </div>
                        <div className={`mt-2 ${!phone ? "d-flex justify-content-center" : "px-3"}  w-100 `}>
                            <div className={`mr-2`}>
                                <CancelButton text={"Cancelar"} onClick={handleClose} width={phone ? "100%" : ""} />
                            </div>

                            <Tooltip title={`Selecciona un operador y al menos una ruta`} placement="top" arrow>
                                <div>
                                    <AcceptButton disabled={operative !== 0 && selected.length !== 0 ? false : true} text={"Crear ruta"} type={"submit"} width={phone ? "100%" : ""} />
                                </div>
                            </Tooltip>
                        </div>
                    </form>

                </div>
            </Box>

            <Modal open={openConfirmation} onClose={() => console.log("Cerrando")}>
                <CreateRouteConfirmationModal
                    handleCancel={() => setOpenConfirmation(false)}
                    obj={data}
                    routes={routes}
                    operatives={operatives}
                    text={"Ruta creada con éxito."}
                />
            </Modal>
         
        </>
    )
}