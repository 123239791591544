import React, { useState } from "react";
import {
  Typography,
  Box,
  useMediaQuery,
  TextField,
  Modal,
  Backdrop,
} from "@mui/material";
import { WarningTwoTone } from "@mui/icons-material";
import { SuccessModal } from "../Modal/SuccessModal";
import { ErrorModal } from "../Modal/ErrorModal";
import { AcceptButton } from "../Buttons/AcceptButton";
import { CancelButton } from "../Buttons/CancelButton";
import { useFormik } from "formik";
import { AdminAPI } from "../../api/admin";
import * as Yup from "yup";

export const WarningReasonModal = ({
  recordId,
  action,
  handleCancel,
  text,
}) => {
  const phone = useMediaQuery("(max-width:480px)");
  const tablet = useMediaQuery("(max-width:900px)");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: phone ? "90%" : tablet ? "56%" : "37%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
    borderRadius: 2,
  };

  // Success states
  const [openSuccess, setOpenSuccess] = useState(false);
  const [successText, setSuccessText] = useState("");

  // Error states
  const [openError, setOpenError] = useState(false);
  const [errorText, setErrorText] = useState("");

  // Backdrop
  const [openBD, setOpenBD] = useState(false);

  // Formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      deletionReason: "",
    },

    validationSchema: Yup.object({
      deletionReason: Yup.string().required("Por favor completa este campo"),
    }),

    onSubmit: (values) => {
      const obj = {
        ...values,
      };

      DeleteRecord(obj);
    },
  });

  // Requests
  const DeleteRecord = async (obj) => {
    setOpenBD(true);
    if (action == "DeletePartner") {
      let data = new FormData();
      data.set("partnerId", recordId);
      data.set("comments", obj.deletionReason);
      data.set("is_active", false);

      // console.log("FORMDATA");
      // for (var pair of data.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }

      try {
        const api = new AdminAPI();
        const response = await api.updatePartner(data);

        if (response.status == 200) {
          setOpenBD(false);
          setSuccessText("Operador desactivado con éxito.");
          setOpenSuccess(true);
        } else {
          setOpenBD(false);
          setErrorText(response.response.data);
          setOpenError(true);
        }
      } catch (error) {}
    }
    if (action == "DeleteRoute") {
      let data = new FormData();
      data.set("route", recordId);
      data.set("reason", obj.deletionReason);

      try {
        const api = new AdminAPI();
        const response = await api.deleteRoute(data);
        if (response.status == 200) {
          setOpenBD(false);
          setSuccessText("Ruta eliminada con éxito.");
          setOpenSuccess(true);
        } else {
          setOpenBD(false);
          setErrorText(response.response.data);
          setOpenError(true);
        }
      } catch (error) {}
    }
    if (action == "DeleteVehicle") {
      let data = new FormData();
      data.set("vehicleId", recordId);
      data.set("comments", obj.deletionReason);

      try {
        const api = new AdminAPI();
        const response = await api.deleteVehicle(data);
        if (response.status == 200) {
          setOpenBD(false);
          setSuccessText("Vehículo eliminado con éxito.");
          setOpenSuccess(true);
        } else {
          setOpenBD(false);
          setErrorText(response.data);
          setOpenError(true);
        }
      } catch (error) {}
    }
    if (action == "FreeDriver") {
      let data = new FormData();
      data.set("idRoute", recordId);
      data.set("comments", obj.deletionReason);

      try {
        const api = new AdminAPI();
        const response = await api.freeDriverFromRoute(data);
        if (response.status == 200) {
          setOpenBD(false);
          setSuccessText("Conductor liberado con éxito");
          setOpenSuccess(true);
        } else {
          setOpenBD(false);
          setErrorText(response.data);
          setOpenError(true);
        }
      } catch (error) {}
    }
    if (action == "DeleteShipment") {
      let data = new FormData();
      data.set("shipment", recordId);
      data.set("comments", obj.deletionReason);

      try {
        const api = new AdminAPI();
        const response = await api.deleteShipment(data);
        if (response.status == 200) {
          setOpenBD(false);
          setSuccessText("Envío eliminado exitosamente");
          setOpenSuccess(true);
        } else {
          setOpenBD(false);
          setErrorText(response.response.data);
          setOpenError(true);
        }
      } catch (error) {}
    }
  };

  return (
    <>
      <Box sx={style}>
        <div className=" text-center ">
          <Typography
            variant="h5"
            className="font-weight-bold font-color-green"
          >
            {text}
          </Typography>
        </div>
        <div className="d-flex justify-content-center my-2">
          <WarningTwoTone sx={{ color: "#f5d000", fontSize: 80 }} />
        </div>
        <form className="w-100 " onSubmit={formik.handleSubmit}>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <div key={"deletionReason"}>
              <div
                className={` d-flex mb-2  ${phone ? "px-2 flex-column" : ""}`}
              >
                <div className="d-flex align-items-center w-100 ">
                  <div className="d-flex flex-column w-100 ">
                    <TextField
                      onKeyDown={onkeydown}
                      error={
                        formik.touched.deletionReason &&
                        formik.errors.deletionReason
                      }
                      size="small"
                      label={"Motivo"}
                      variant="outlined"
                      fullWidth
                      onChange={formik.handleChange}
                      value={formik.values.deletionReason}
                      name={"deletionReason"}
                      id={"deletionReason"}
                      helperText={
                        formik.touched.deletionReason &&
                        formik.errors.deletionReason ? (
                          <div>{formik.errors.deletionReason}</div>
                        ) : null
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`mt-2 ${
              !phone ? "d-flex justify-content-center" : "px-3"
            }  w-100 `}
          >
            <div className={`mr-2`}>
              <CancelButton
                text={"Cancelar"}
                onClick={handleCancel}
                width={phone ? "100%" : ""}
              />
            </div>
            <div>
              <AcceptButton
                text={"Continuar"}
                type={"submit"}
                width={phone ? "100%" : ""}
              />
            </div>
          </div>
        </form>
        {/* Modals */}
        <Modal open={openSuccess}>
          <SuccessModal
            handleClose={() => {
              handleCancel();
              window.location.reload();
            }}
            text={successText}
          />
        </Modal>
        <Modal open={openError}>
          <ErrorModal
            text={`No se pudo completar la acción.`}
            error={errorText}
            handleClose={() => setOpenError(false)}
          />
        </Modal>
      </Box>
      {/* Backdrop */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBD}
      >
        <span className="loader"></span>
      </Backdrop>
    </>
  );
};
