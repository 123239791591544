import React from "react";
import { Toolbar, Typography, IconButton, useMediaQuery } from "@mui/material";
import { Menu, Close } from "@mui/icons-material";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { nameSectionsAdmin } from "../utils/Menu/NameSections";
import { ActivesAdmin } from "./pages/Guides/ActivesAdmin";
import { RoutesAdmin } from "./pages/Guides/RoutesAdmin";
import { CreateRoute } from "../pages/Guides/CreateRoute";
import { HistoricalAdmin } from "./pages/Guides/HistoricalAdmin";
import { Operatives } from "./pages/Operatives/Operatives";
import { Vehicles } from "./pages/Vehicles/Vehicles";
import { AccountProfileAdmin } from "./pages/Account/AccountProfileAdmin";
import { AddOperative } from "./pages/Add/AddOperative";
import { AddOrigin } from "./pages/Add/AddOrigin";
import { AddVehicle } from "./pages/Add/AddVehicle";
import { AddGuide } from "./pages/Add/AddGuide";
import { AddShipmentOrigin } from "./pages/Add/AddShipmentOrigin";
import { EditPasswordAdmin } from "./pages/Account/EditPasswordAdmin";
import { Assignments } from "./pages/Assigments/Assignments";

export const LayoutAdminRouting = ({ handleDrawerToggle }) => {
  const { pathname } = useLocation();
  const phone = useMediaQuery("(max-width:600px)");

  const section = nameSectionsAdmin.find((el) => el.link == pathname);

  return (
    <>
      <Toolbar
        sx={{
          bgcolor: "white",
          color: "#122028",
          height: phone ? "50px" : "65px",
          boxShadow: "1px 0 5px 1px #d0d0d0",
        }}
        className={`w-100 d-flex  ${
          phone ? "" : "flex-column justify-content-center "
        }`}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <Menu />
        </IconButton>

        <div className={`w-100 ${phone ? "text-center" : ""}`}>
          <Typography sx={{ fontSize: 20 }}>
            {section != undefined && section.name.toUpperCase()}
          </Typography>
        </div>
      </Toolbar>

      <div
        className={`mt-1 w-100 d-flex ${
          phone
            ? "justify-content-center"
            : "justify-content-start ml-3 pl-4 mb-2"
        }`}
      >
        {/* <BreadcrumbsMenu namesSections={nameSections} rol={"hfm"} /> */}
      </div>

      <div className="w-100">
        <Routes>
          {/* Todas las rutas inexistentes te redirigen a ruta principal */}
          <Route path="*" element={<Navigate to="guides/active/" replace />} />
          <Route path="guides/active/" element={<ActivesAdmin />} />
          <Route path="guides/active/add/" element={<AddGuide />} />
          <Route
            path="guides/active/addOrigin/"
            element={<AddShipmentOrigin />}
          />
          <Route path="guides/routes/" element={<RoutesAdmin />} />
          <Route path="guides/active/route/" element={<CreateRoute />} />
          <Route path="guides/historical/" element={<HistoricalAdmin />} />
          <Route path="operatives/" element={<Operatives />} />
          <Route path="operatives/add/" element={<AddOperative />} />
          <Route path="operatives/edit/" element={<AddOperative />} />
          <Route path="origins/edit/" element={<AddOrigin />} />
          <Route path="vehicles/" element={<Vehicles />} />
          <Route path="vehicles/add/" element={<AddVehicle />} />
          <Route path="vehicles/edit/" element={<AddVehicle />} />
          <Route path="assignments/" element={<Assignments />} />
          <Route path="account/profile/" element={<AccountProfileAdmin />} />
          <Route path="account/password/" element={<EditPasswordAdmin />} />
        </Routes>
      </div>
    </>
  );
};
