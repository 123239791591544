import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
    TextField,
    useMediaQuery,
    Grid,

} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { AccountAPI } from "../../api/account";

export const ProfileForm = () => {
    const navigate = useNavigate();
    const phone = useMediaQuery("(max-width:480px)");
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [openBD, setOpenBD] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [info, setInfo] = useState({
        nombre: "",
        apellidos: "",
        email: "",
        telefono: "",
        drive_license: "",
        photo: "",
        date_birth: "",
        expiration_drive_license: "",
    });

    useEffect(() => {
        GetInfoPartner();
    }, []);

    const GetInfoPartner = async () => {
        setIsLoading(true)
        let data = new FormData();
        try {
            const api = new AccountAPI()
            const response = await api.getInfoEP(data);

            setInfo(response.data);
            setIsLoading(false)
        } catch (error) {}
    };

    const fields = [
        {
            keyName: "Nombre",
            value: "nombre",
            type: true,
            editable: false,
            size: true
        },
        {
            keyName: "Apellido",
            value: "apellidos",
            type: true,
            editable: false,
            size: true
        },
        {
            keyName: "Celular",
            value: "telefono",
            type: true,
            editable: false,
            size: false
        },
        {
            keyName: "Email",
            value: "email",
            type: true,
            editable: false,
            size: false
        },
        {
            keyName: "Fecha de nacimiento",
            value: "date_birth",
            type: true,
            editable: false,
            size: false
        },
        {
            keyName: "Fecha expiración licencia",
            value: "expiration_drive_license",
            type: true,
            editable: false,
            size: false
        },
    ];

    const handleClose = (path) => {
        setOpenSuccess(false);
        navigate(`/hfm/account/`);

    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: info.id,
            nombre: info.nombre,
            apellidos: info.apellidos,
            email: info.email,
            telefono: info.telefono,
            date_birth: info.date_birth,
            expiration_drive_license: info.expiration_drive_license,
        },

        validationSchema: Yup.object({
            name: Yup.string()
                .required("Campo Obligatorio")
                .matches(
                    /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
                    "Sólo alfanuméricos y espacios"
                )
                .max(100, "Demasiado largo"),
            lastName: Yup.string()
                .required("Campo Obligatorio")
                .matches(
                    /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
                    "Sólo alfanuméricos y espacios"
                )
                .max(100, "Demasiado largo"),
            email: Yup.string()
                .required("Campo Obligatorio")
                .matches(
                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    "Formato incorrecto"
                )
                .max(100, "Demasiado largo"),
            phoneNumber: Yup.string()
                .required("Campo Obligatorio")
                .matches(/^\d+$/, "Deben ser números sin espacios")
                .min(10, "Demasiado corto")
                .max(12, "Demasiado largo"),
        }),

        onSubmit: (values) => {
            const obj = {
                ...values,
            };
        },
    });

    return (
        <div className={`d-flex mt-3 border-radius-10  ${phone ? "flex-column" : "justify-content-center align-items-center"} `}>
            <form onSubmit={formik.handleSubmit}>
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justify="center"
                    rowSpacing={2} >{/*---Contenedor padre---*/}
                    <Grid item xs={12} sm={12} md={6}>{/*---Ocupara mitad de pantalla---*/}
                        <Grid item style={{ height: "100%" }} xs={12} className={`border bg-white p-3 border-radius-10`}>{/*---Altura de 100 para que ocupe verticalmente---*/}
                            <div>
                                <p className={` ${phone ? "h6" : "h5"}`}>
                                    Datos generales
                                </p>
                            </div>
                            <Grid container columns={{ xs: 4, sm: 6, md: 12, lg: 12 }}>
                                {fields.map((field) => (
                                    <Grid key={field.keyName} item xs={field.size ? 6 : 12}>
                                        <div
                                            className={` d-flex mb-3 flex-column ${phone ? "px-2 " : "px-2"
                                                }`}
                                        >
                                            <div
                                                className={`d-flex align-items-center mr-2 ${phone && "mb-2"
                                                    }`}
                                            >
                                                <p className="h6 te" htmlFor={field.value}>{`${field.keyName
                                                    }: ${field.type ? "*" : ""}`}</p>
                                            </div>

                                            <div className="d-flex align-items-center w-100">

                                                <TextField
                                                    disabled
                                                    error={
                                                        formik.touched[field.value] &&
                                                        formik.errors[field.value]
                                                    }
                                                    size="small"
                                                    label={field.keyName}
                                                    variant="outlined"
                                                    fullWidth
                                                    onChange={formik.handleChange}
                                                    value={formik.values[field.value]}
                                                    name={field.value}
                                                    id={field.value}
                                                    helperText={
                                                        formik.touched[field.value] &&
                                                            formik.errors[field.value] ? (
                                                            <div>{formik.errors[field.value]}</div>
                                                        ) : null
                                                    }

                                                />

                                            </div>
                                        </div>

                                    </Grid>

                                ))}

                            </Grid>
                        </Grid>

                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>

                        <Grid item xs={12} className={`border bg-white p-3 border-radius-10`}>
                            <div className="">
                                <p className={` ${phone ? "h6" : "h5"}`}>
                                    Fotografía operador
                                </p>
                            </div>
                            <Grid container columns={{ xs: 4, sm: 6, md: 12, lg: 12 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>

                                        {/* -------------------------------IMAGEN------------------------------- */}

                                        <div className={` d-flex justify-content-center ${phone ? "px-2" : ""} border border-radius-10 bg-white`}>
                                            <img src={info.photo} alt="" height={200} />
                                        </div>

                                    </Grid>
                                </Grid>
                            </Grid>


                        </Grid>

                        <Grid item xs={12} className={`border bg-white p-3 border-radius-10 mt-3`}>
                            <div className="">
                                <p className={` ${phone ? "h6" : "h5"}`}>
                                    Fotografía licencia
                                </p>
                            </div>
                            <Grid container columns={{ xs: 4, sm: 6, md: 12, lg: 12 }}>
                                {/* <Grid container spacing={2}> */}
                                <Grid item xs={12}>

                                    {/* -------------------------------IMAGEN------------------------------- */}

                                    <div className={` d-flex justify-content-center ${phone ? "px-2" : ""} border border-radius-10 bg-white`}>
                                        <img src={info.drive_license} alt="" width={300} />
                                    </div>

                                </Grid>
                                {/* </Grid> */}
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </form>
        </div>
    );
};
