import React, { useState } from "react";
import {
    Drawer,
    List,
    ListItemIcon,
    Toolbar,
    Typography,
    Collapse,
    ListItem,
    Backdrop,
} from "@mui/material";

import {ExpandLess, ExpandMore, MeetingRoom, Logout } from "@mui/icons-material";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { hasChildren } from "../utils/hasChildren";
import { useAuthContext } from "../context/AuthProvider";
const drawerWidth = 220;

export default function Sidebar({ mobileOpen, handleDrawerToggle, menu }) {
    const { signOut, openBD } = useAuthContext();

    return (
        <>
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: "none", sm: "block" },
                    "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
                }}
                open
            >
                <Toolbar className="p-1" sx={{ height: "65px" }}>
                    <div className="d-flex justify-content-center w-100">
                        <div className="d-flex flex-column align-items-center mt-4">
                            <Typography className="text-white" variant="h4">Logistics</Typography>
                            <Typography className="yellow-ds-font" variant="h5">DS</Typography>
                        </div>
                    </div>
                </Toolbar>
                {/* <Divider /> */}
                <div className="mt-4 text-white">
                    {menu.map((item, key) => <MenuItem key={key} item={item} />)}
                </div>

                <div className={`w-100 mt-3 d-flex align-items-center p-3 cursor-pointer`} onClick={() => { signOut(); }}>
                    <div className="ml-1 mr-2">
                        <Logout sx={{ color: "#FFF" }} />
                    </div>
                    <Typography sx={{ fontSize: 20, color: "#FFF" }}>Cerrar sesión</Typography>
                </div>

            </Drawer>

            <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: "block", sm: "none" },
                    "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
                }}
            >
                <Toolbar className="p-1">
                    <div className="d-flex justify-content-center w-100 ">
                        <div className="d-flex flex-column align-items-center mt-4">
                            <Typography className="text-white" variant="h5">Lastmile</Typography>
                            <Typography className="yellow-ds-font" variant="h6">DS</Typography>
                        </div>
                    </div>
                </Toolbar>
                <div className="mt-4 text-white">
                    {menu.map((item, key) => <MenuItem key={key} item={item} />)}
                </div>

                <div className={`w-100 mt-3 d-flex align-items-center p-3 cursor-pointer`} onClick={signOut}>
                    <div className="ml-1 mr-2">
                        <MeetingRoom sx={{ color: "#FFF" }} />
                    </div>
                    <Typography sx={{ fontSize: 20, color: "#FFF" }}>Cerrar sesión</Typography>
                </div>
            </Drawer>

            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBD}
            >
                <span className="loader"></span>
            </Backdrop>
        </>
    );
}

const MenuItem = ({ item, }) => {
    const Component = hasChildren(item) ? MultiLevel : SingleLevel;
    return <Component item={item} />
}

const SingleLevel = ({ item, }) => {
    const { pathname } = useLocation();

    return (
        <ListItem
            className="hover-text"
            button
            component={Link}
            to={item.link || "/404"}
        >
            <div className={`w-100 mb-3 d-flex ${pathname.includes(`/${item.user}/${item.link}`) && "active-sidebar"}`}>
                <ListItemIcon sx={{ color: "#FFF", }}>{item.icon}</ListItemIcon>
                <Typography sx={{ fontWeight: pathname.includes(`/${item.user}/${item.link}`) && "bold", fontSize: 20, }}>{item.name}</Typography>
            </div>
        </ListItem>
    )
}

const MultiLevel = ({ item }) => {
    const { items: children } = item;
    const [open, setOpen] = useState(false);
    const { pathname } = useLocation();

    const handleClick = () => {
        setOpen(!open)
    }

    return (
        <>
            <ListItem className="hover-text" onClick={handleClick}>
                <div className={`w-100 mb-2 d-flex cursor-pointer`}>

                    <ListItemIcon sx={{ color: "#FFF", }}>{item.icon}</ListItemIcon>
                    <Typography sx={{ fontSize: 20 }}>{item.name}</Typography>
                </div>

                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={open} timeout="auto" unmountOnExit>
                <List
                    component={"div"}
                    disablePadding
                >
                    {children.map((child, i) => (
                        <MenuItem key={i} item={child} />
                    ))}

                </List>
            </Collapse>
        </>
    )
}

// import BagLogo from "../assets/BagLogo.svg"
// import LoaderDS from "../assets/LoaderDS.svg"


