import React from 'react'

import { useMediaQuery } from '@mui/material'
import { Close } from '@mui/icons-material'

import { useNavigate } from 'react-router-dom'
import { EditPasswordForm } from '../../../components/Forms/EditPasswordForm'

export const EditPasswordAdmin = () => {

  const navigate = useNavigate();

  const phone = useMediaQuery('(max-width:480px)');

  return (
    <>
      <div className='w-100 px-3'>
        <div className='bg-white border-radius-10 mt-2 mb-3 pt-3 px-3 pb-2'>
          <p className={`ds-blue-font ${phone ? "h6" : "h5"}`}> Actualizar contraseña</p>
        </div>
        <div className='mt-2 w-100 ' >
          <EditPasswordForm />
        </div>
      </div>
    </>
  )
}

