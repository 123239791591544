import React, { useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Modal,
  Backdrop,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import {
  VisibilityTwoTone,
  ShareLocation,
  Delete,
  PersonRemove,
} from "@mui/icons-material";
import { useViewPortDimensions } from "../../hooks/useViewPortDimensions";
import { TablesPagination } from "../Pagination/TablesPagination";
import { DetailsRoutesModal } from "../Modal/DetailsRoutesModal";
import { LocationMapModal } from "../Modal/LocationMap";
import { AdminAPI } from "../../api/admin";
import { WarningReasonModal } from "../Modal/DeletionReasonModal";
import { ErrorModal } from "../Modal/ErrorModal";
import { WarningModal } from "../Modal/WarningModal"; 

export const RoutesTable = ({ search, data, setPage, page, total }) => {
  const { height, width } = useViewPortDimensions();
  const [openDetail, setOpenDetail] = useState(false);
  const [openMap, setOpenMap] = useState(false);
  const [shipments, setShipments] = useState();
  const [partnerToTrackId, setPartnerToTrackId] = useState();
  const [openBD, setOpenBD] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openFreeDriver, setOpenFreeDriver] = useState(false);
  const [routeId, setRouteId] = useState(0);
  const [partnerPosition, setPartnerPosition] = useState({});
  const [openError, setOpenError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [openWarning, setOpenWarning] = useState(false);


  const status = [
    { id: 1, value: "En proceso", name: "IN_PROCESS" },
    { id: 2, value: "En ruta", name: "IN_ROUTE" },
    { id: 3, value: "Finalizada", name: "FINISHED" },
  ];
  
  const handleOpenDetail = (row) => {
    setOpenDetail(true);
    setShipments(row);
  };

  const handleDeleteRoute = (row) => {
    setOpenDelete(true);
    setRouteId(row.id);
  };

  const handleFreeDriver = (row) => {
    setOpenFreeDriver(true);
    setRouteId(row.id)
  };

  const handleOpenLocationMap = (row) => {
    setShipments(row.shipments);
    GetPartnerPosition(row.assignedTo.partnerId, row.id);
    setPartnerToTrackId(row.assignedTo.partnerId);
  };

  const GetPartnerPosition = async (partnerId, routeId) => {
    setOpenBD(true);
    setRouteId(routeId)
    try {
      const api = new AdminAPI();
      const response = await api.getRouteCoordinates(partnerId, routeId);
      if (response.status == 200) {
        setPartnerPosition(response.data);
        setOpenBD(false);
        setOpenMap(true);
      } else {
        setOpenBD(false);
        setErrorText(String(response));
        setOpenError(true);
      }
    } catch (error) {
      setOpenBD(false);
      console.log(error);
    }
  };

  
  

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: height - (74 + 90) }}>
            <Table
              sx={{ minWidth: 750 }}
              aria-label="sticky table"
              stickyHeader
            >
              <thead className="h-12 bg-[#004876c2] text-white">
                <TableRow style={{ whiteSpace: "nowrap" }}>
                  <TableCell>Ver ubicación</TableCell>
                  <TableCell className="px-4" >Ver detalles</TableCell>
                  <TableCell className="px-4"  align="center">
                    <span>Folio</span>
                  </TableCell>
                  <TableCell className="px-4"  align="center">
                    <span>Cantidad de guías</span>
                  </TableCell>
                  <TableCell className="px-4"  align="center">
                    <span>Unidad asignada</span>
                  </TableCell>
                  <TableCell className="px-4"  align="center">
                    <span>Operador</span>
                  </TableCell>
                  <TableCell className="px-4"  align="center">Estatus</TableCell>
                  <TableCell className="px-4"  align="center">
                    <span>Fecha inicio</span>
                  </TableCell>
                  <TableCell className="px-4"  align="center">
                    <span>Fecha recolección esperada</span>
                  </TableCell>
                  <TableCell className="px-4"  align="center">
                    <span>Fecha entrega</span>
                  </TableCell>
                  <TableCell className="px-4"  align="center">
                    <span>% Avance</span>
                  </TableCell>
                  <TableCell className="px-4"  align="center">
                    <span># Guías canceladas</span>
                  </TableCell>
                  <TableCell className="px-4"  align="center">
                    <span>Tipo de ruta</span>
                  </TableCell>
                  <TableCell className="px-4"  align="center">
                    <span>Liberar conductor</span>
                  </TableCell>
                  <TableCell className="px-4" >Cancelar</TableCell>
                </TableRow>
              </thead>
              <TableBody>
                {data.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    // {index % 2 === 0 ? "bg-[#F0F0F0] dark:bg-[#22303c]" : "dark:bg-[#15202b]"}
                    <TableRow tabIndex={-1} key={row.id} style={{ backgroundColor: index % 2 === 0 ? "#f0f0f0" : "white" }}>
                      
                        <TableCell padding="checkbox">
                          <Tooltip
                            title={"Ver ubicación"}
                            arrow
                            placement="top"
                          >
                            <div className="d-flex align-items-center justify-content-center">
                              <IconButton
                                style={{ color: "#2196f3" }}
                                onClick={() => handleOpenLocationMap(row)}
                              >
                                <ShareLocation />
                              </IconButton>
                            </div>
                          </Tooltip>
                        </TableCell>
                    

                      <TableCell padding="checkbox">
                        <div className="d-flex justify-content-around">
                          <Tooltip title={"Editar"} arrow placement="top">
                            <div className="d-flex align-items-center justify-content-center">
                              <IconButton onClick={() => handleOpenDetail(row)}>
                                <VisibilityTwoTone />
                              </IconButton>
                            </div>
                          </Tooltip>
                        </div>
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        align="center"
                      >
                        {row.id}
                      </TableCell>
                      <TableCell align="center">
                        {row.shipments_count}
                      </TableCell>
                      <TableCell align="center">
                        {row.assignedTo != null && row.assignedTo.unit}
                      </TableCell>
                      <TableCell align="center">
                        {row.assignedTo != null &&
                          `${
                            row.assignedTo.partnerName == null
                              ? ""
                              : row.assignedTo.partnerName
                          } 
                                                    ${
                                                      row.assignedTo
                                                        .partnerLastName == null
                                                        ? ""
                                                        : row.assignedTo
                                                            .partnerLastName
                                                    }`}
                      </TableCell>
                      <TableCell align="center">
                        <span style={{ whiteSpace: "nowrap" }}>
                          {status.find((el) => el.id == row.status).value}
                        </span>
                      </TableCell>
                      <TableCell align="center">{row.start_date}</TableCell>
                      <TableCell align="center">
                        {row.expected_start_date}
                      </TableCell>
                      <TableCell align="center">{row.end_date}</TableCell>
                      <TableCell align="center">
                        {row.percentage_completion}
                      </TableCell>
                      <TableCell align="center">
                        {row.canceled_guides}
                      </TableCell>
                      <TableCell align="center">
                        {row.is_automatic == true ? "Automática" : "Manual"}
                      </TableCell>

                      {row.status == 2 ? (
                        <TableCell>
                          <Tooltip
                            title={"Liberar conductor"}
                            arrow
                            placement="top"
                          >
                            <div className="d-flex align-items-center justify-content-center">
                              <IconButton
                                style={{ color: "#D4AC0D" }}
                                onClick={() => handleFreeDriver(row)}
                              >
                                <PersonRemove />
                              </IconButton>
                            </div>
                          </Tooltip>
                        </TableCell>
                      ) : (
                        <TableCell></TableCell>
                      )}

                      {row.status == 2 ? (
                        <TableCell></TableCell>
                      ) : (
                        <TableCell>
                          <Tooltip
                            title={"Cancelar ruta"}
                            arrow
                            placement="top"
                          >
                            <div className="d-flex align-items-center justify-content-center">
                              <IconButton
                                style={{ color: "#d50000" }}
                                onClick={() => handleDeleteRoute(row)}
                              >
                                <Delete />
                              </IconButton>
                            </div>
                          </Tooltip>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {search != "" && data.length == 0 ? (
              <div className="d-flex justify-content-center p-4">
                No se encontraron resultados para "{search}"
              </div>
            ) : data.length == 0 && search == "" ? (
              <div className="d-flex justify-content-center p-4">
                No existen registros aún.
              </div>
            ) : (
              ""
            )}
          </TableContainer>
          <TablesPagination setPage={setPage} page={page} total={total} />
        </Paper>
      </Box>
      {/* Modals */}
      <Modal open={openError} onClose={() => console.log("Cerrando")}>
        <ErrorModal
          text={`No se pudo completar la acción.`}
          error={errorText}
          handleClose={() => setOpenError(false)}
        />
      </Modal>
      <Modal open={openMap}>
        <LocationMapModal
          handleClose={() => setOpenMap(false)}
          routeId={routeId}
          partnerId={partnerToTrackId}
          partnerPosition={partnerPosition}
          shipments={shipments}
        />
      </Modal>
      <Modal open={openDetail} onClose={() => console.log("Cerrando")}>
        <DetailsRoutesModal
          route={shipments}
          handleClose={() => setOpenDetail(false)}
        />
      </Modal>
      <Modal open={openDelete} onClose={() => console.log("Cerrando")}>
        <WarningReasonModal
          recordId={routeId}
          action={"DeleteRoute"}
          handleCancel={() => setOpenDelete(false)}
          text={`Estas a punto de cancelar la ruta con Id: ${routeId}.`}
        />
      </Modal>
      <Modal open={openFreeDriver}>
        <WarningReasonModal
          recordId={routeId}
          action={"FreeDriver"}
          handleCancel={() => setOpenFreeDriver(false)}
          text={`Estas a punto de liberar al conductor de la ruta: ${routeId}.`}
        />
      </Modal>
      <Modal open={openWarning}>
        <WarningModal
          handleCancel={() => setOpenWarning(false)}
          handleClose={() => setOpenWarning(false)}
          text={"No se recibieron datos de la localización del conductor"}
        />
      </Modal>
      {/* Backdrop */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBD}
      >
        <span className="loader"></span>
      </Backdrop>
    </>
  );
};
