import React, { useEffect, useMemo, useRef, useState } from "react";
import { Badge, Divider, IconButton, InputBase, Paper, TextField, Tooltip } from "@mui/material";
import { FilterList, Search as SearchIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import debounce from 'lodash/debounce';
import { useLocation } from "react-router-dom";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#1f4368",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#d1d8f3",
      borderRadius: 5,
    },
    "&:hover fieldset": {
      borderColor: "#1f4368",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#1f4368",
    },
  },
});


export const Search = ({ label, setSearch, handleClick, invisible }) => {

  const sendQuery = (query) => setSearch(query);
  const [text, setText] = useState("");

  const delayedQuery = useRef(debounce(q => sendQuery(q), 500)).current;

  const handleOnChange = (e) => {
    setText(e.target.value);
    delayedQuery(e.target.value);
  };

  return (
    <>
      <Paper
        component="form"
        sx={{
          p: '2px',
          display: 'flex',
          alignItems: 'center',
          border: "1px solid #8d8d8d8a",
          borderRadius: 5,
          '&:hover': {
            // border: "1px solid #f00",
          },
        }}
      >
        <InputBase
          sx={{ ml: 2, flex: 1, height: 35, }}
          placeholder={"Buscar"}
          inputProps={{ 'aria-label': 'search ' }}
          onChange={handleOnChange}
          onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
        />
        {/* <IconButton type="submit" sx={{ p: '10px' }} aria-label="search"> */}
        <SearchIcon className="text-secondary mr-2" />
        {/* </IconButton> */}

          <>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <Tooltip title="Filtrar" arrow placement="top">
              <IconButton sx={{ p: '10px' }} aria-label="directions" onClick={handleClick}>
                {/* <Badge
                  variant="dot"
                  sx={{
                    "& .MuiBadge-badge": {
                      color: "lightgreen",
                      backgroundColor: "#fbae08",
                      width: 10,
                      height: 10,
                      borderRadius: '50%'
                    }
                  }}
                  color="primary"
                  invisible={invisible}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
                > */}
                  <FilterList />
                {/* </Badge> */}
              </IconButton>
            </Tooltip>
          </>
      </Paper>

      {/* <div className="d-flex align-items-center w-100">
        <CssTextField
          label={
            <div className="d-flex align-items-center">
              <SearchIcon className="mr-1" /> {label}
            </div>
          }
          id="custom-css-outlined-input"
          value={text}
          onChange={handleOnChange}
          fullWidth
          size="small"
        />
      </div> */}
    </>
  );
};
