import React from 'react'
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DeleteDocButton = ({ text, onClick, value }) => {

    const DeleteDocStyled = styled(Button)({
        boxShadow: '0',
        textTransform: 'none',
        fontSize: 14,
        backgroundColor: 'transparent',
        color: "#1f4368",
        textAlign: "center",
        fontWeight: 'bold',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            backgroundColor: 'transparent',
        }
    });


    return (
        <DeleteDocStyled value={value} variant="text" onClick={(e) => onClick(e)} disableElevation >
            {text}
        </DeleteDocStyled>
    )
}
