import React, { useState } from "react";
import { Button, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Upload } from "@mui/icons-material";
import ExcelLogo from "../../assets/SVGIcons/ExcelLogo.svg";
import pdfLogo from "../../assets/SVGIcons/pdfLogo.svg";

const UploadStyledButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  lineHeight: 1.5,
  borderRadius: 15,
  backgroundColor: "white",
  color: "#1f4368",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  "&:hover": {
    backgroundColor: "#e9eaef",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#FFF",
  },
});

export const UploadButton = ({
  text,
  onClick,
  disabled,
  loadedFileType
}) => {
  const [click, setClick] = useState(false);

  return (
    <>
      <UploadStyledButton
        variant="contained"
        disableRipple
        disabled={disabled}
        onClick={onClick}
        disableElevation
      >
        <div className="d-flex align-items-center">
          {
            {
              "": <Upload />,
              excel: <img src={ExcelLogo} width="15" className="mr-1" />,
              pdf: <img src={pdfLogo} width="15" className="mr-1" />,
            }[loadedFileType]
          }
          <Tooltip placement="top" arrow title={text.length > 20 ? text : ""}>
            <p className="m-0">
              {text.length > 20 ? `${text.substring(0, 15)}...` : text}
            </p>
          </Tooltip>
        </div>
      </UploadStyledButton>
    </>
  );
};
