import React, { useState } from 'react'
import Box from '@mui/material/Box';
import { CssBaseline, Grid, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LayoutRouting } from './LayoutRouting';
import Sidebar from '../components/Sidebar';
import { menuUser } from '../utils/Menu/Menu';

const drawerWidth = 220;

export const Layout = () => {
    const navigate = useNavigate();

    const [mobileOpen, setMobileOpen] = useState(false)
    const tablet = useMediaQuery("(max-width:600px)");

    const handleDrawerToggle = () => {
        tablet && setMobileOpen(!mobileOpen);
    };

    const handleAccount = () => {
        // navigate("/hfm/account/");
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline enableColorScheme />
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                <Sidebar mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} menu={menuUser} />
            </Box>
            <div className='content-width'>
                <Grid container className='content-width'>
                    <LayoutRouting handleDrawerToggle={handleDrawerToggle} />
                </Grid>
            </div>
        </Box>
    )
}
