import React, { useState } from "react";
import {
  Box,
  useMediaQuery,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  Backdrop,
} from "@mui/material";
import { TablesPagination } from "../Pagination/TablesPagination";
import { Cached } from "@mui/icons-material";
import { AdminAPI } from "../../api/admin";
import { SuccessModal } from "../Modal/SuccessModal";
import { ErrorModal } from "../Modal/ErrorModal";

export const AvailableVehiclesTable = ({
  data,
  search,
  setPage,
  page,
  total,
  operatives,
  routeInfo,
  setVehicleInfo,
  handleCloseModal,
}) => {
  const phone = useMediaQuery("(max-width:480px)");
  const tablet = useMediaQuery("(max-width:900px)");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: phone ? "90%" : tablet ? "56%" : "60%",
    bgcolor: "background.paper",
    // border: '2px solid #487b94 ',
    boxShadow: 24,
    p: 3,
    borderRadius: 2,
  };

  const [openBD, setOpenBD] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  // Success states
  const [openSuccess, setOpenSuccess] = useState(false);
  const [successText, setSuccessText] = useState("");

  // Error states
  const [openError, setOpenError] = useState(false);
  const [errorText, setErrorText] = useState("");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const ChangePartnerVehicle = async (unit) => {
    setOpenBD(true);

    let data = new FormData();
    data.append(
      "partnerId",
      operatives.find((el) => el.id === routeInfo.operative).operator.partnerId
    );
    data.append("vehicleId", unit.idVehicle);

    try {
      const api = new AdminAPI();
      const response = await api.updatePartnerVehicle(data);

      if (response.status == 200) {
        setOpenBD(false);

        setVehicleInfo({
          model: unit.model,
          brand: unit.brand,
          licensePlate: unit.licensePlate,
          capacity: unit.capacity,
          img_source: unit.carImage,
        });
        setOpenSuccess(true);
        document.getElementById("vehicles-table").classList.add("d-none");
      } else {
        setOpenBD(false);
        setErrorText("No se pudo asignar la unidad");
        setOpenError(true);
      }
    } catch (error) {}
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%" }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-label="sticky table"
              stickyHeader
            >
              <thead className="h-12 bg-[#004876c2] text-white">
                <TableRow>
                  <TableCell align="center" colSpan={2}>
                    Unidad
                  </TableCell>
                </TableRow>
              </thead>
              <TableBody>
                {data.map((unit, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={unit.id} style={{ backgroundColor: index % 2 === 0 ? "#f0f0f0" : "white" }}>
                      <TableCell
                        component="th"
                        scope="row"
                        padding="normal"
                        align="center"
                      >
                        <p>
                          {unit.typeVehicle} {unit.brand} {unit.model}
                        </p>
                        {unit.licensePlate}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          onClick={() => ChangePartnerVehicle(unit)}
                          style={{
                            color: "white",
                            backgroundColor: "#182B7E",
                          }}
                          aria-label="change"
                          size="medium"
                        >
                          <Cached fontSize="inherit" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {search != "" && data.length == 0 ? (
              <div className="d-flex justify-content-center p-4">
                No se encontraron resultados para "{search}"
              </div>
            ) : data.length == 0 && search == "" ? (
              <div className="d-flex justify-content-center p-4">
                No existen registros aún.
              </div>
            ) : (
              ""
            )}
          </TableContainer>
          <TablesPagination setPage={setPage} page={page} total={total} />
        </Paper>
        {/* Backdrop */}
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBD}
        >
          <span className="loader"></span>
        </Backdrop>
        <Modal open={openSuccess}>
          <SuccessModal
            text={"Vehículo asignado correctamente"}
            handleClose={() => handleCloseModal()}
          />
        </Modal>
        <Modal open={openError}>
          <ErrorModal
            text={"No se pudo cambiar el vehículo"}
            handleClose={() => setOpenError(false)}
          />
        </Modal>
      </Box>
    </>
  );
};

// const [vehicleInfo, setVehicleInfo] = useState({
//   model: operatives.find((el) => el.id == obj.operative).assigned_unit.model,
//   brand: operatives.find((el) => el.id == obj.operative).assigned_unit.brand,
//   licensePlate: operatives.find((el) => el.id == obj.operative).assigned_unit.licensePlate,
//   capacity: operatives.find((el) => el.id == obj.operative).assigned_unit.capacity,
//   img_source: operatives.find((el) => el.id == obj.operative).assigned_unit.carImage,
// });

// const ChangePartnerVehicle = async (unit) => {
//   setOpenBD(true);
//   console.log(
//     "OPERADOR",
//     operatives.find((el) => el.id == obj.operative).operator.partnerId
//   );
//   console.log("VEHICULO", unit);
//   let data = new FormData();
//   data.append(
//     "partnerId",
//     operatives.find((el) => el.id == obj.operative).operator.partnerId
//   );
//   data.append("vehicleId", unit.idVehicle);
//   try {
//     const api = new AdminAPI();
//     const response = await api.changePartnerVehicle(data);
//     setOpenBD(false);
//     setVehicleInfo({
//       model: unit.model,
//       brand: unit.brand,
//       licensePlate: unit.licensePlate,
//       capacity: unit.capacity,
//       img_source: unit.carImage,
//     });
//     document.getElementById("vehicles-table").classList.add("d-none");
//     console.log(
//       response.data,
//       "response change paartner vehicle",
//       typeof response.data
//     );
//   } catch (error) {
//     console.log(error);
//     setOpenBD(false);
//   }
// };
