import React, { useState } from "react";

import { RejectDeliveryModal } from "../../components/Modal/RejectDeliveryModal";
import { ReportProblemModal } from "../../components/Modal/ReportProblemModal";

import { Grid, Modal } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import {
  LocationOnOutlined,
  CheckCircleOutlineOutlined,
  ReportProblem,
  Close,
} from "@mui/icons-material";
import { Tooltip, Typography, Box, useMediaQuery } from "@mui/material";

const steps = [
  {
    label: "En camino a recolección",
    description: `ASD`,
  },
  {
    label: "Paquete recolectado",
    description: "FGH",
  },
  {
    label: "En dirección de entrega",
    description: `JKL`,
  },
];

export const GuideCard = ({ el }) => {

  const phone = useMediaQuery("(max-width:480px)");
  const [openRejectDelivery, setOpenRejectDelivery] = useState(false);
  const [rejectionReasons, setRejectionReasons] = useState(false);

  const [OpenIncidences, setOpenIncidences] = useState(false);
  const [incidences, setIncidences] = useState(false);

  const collect_address = el.origin.split(",");

  const [address, setAddress] = useState(
    `${collect_address[0]}+${collect_address[2]}+${collect_address[3]}+${collect_address[4]}`
  );

  const handleRejectDelivery = (el) => {
    setOpenRejectDelivery(true);
    setRejectionReasons(true);
    console.log(el);
  };

  const handleReportProblem = (el) => {
    setOpenIncidences(true);
    setIncidences(true);
    console.log(el);
  };

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = (i) => {
    console.log(i);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (i === 1) {
      const delivery_address = el.destination.split(",");
      setAddress(
        `${delivery_address[0]}+${delivery_address[2]}+${delivery_address[3]},${delivery_address[4]}`
      );
    }
    if (i === steps.length - 1) {
      //console.log(document.getElementById("stepper"));
      document.getElementById('stepper').setAttribute("alternativeLabel", "");
      console.log(document.getElementById('stepper'))
    }
  };

  return (
    <>
      <div
        style={{ minHeight: "150px", ggmaxWidth: "" }}
        className="border bg-white p-4 border-radius-10 mb-3"
      >
        {/* {el.name} */}

        <div className="d-flex align-items-center justify-content-between mb-3">
          <h3 className="my-2 font-weight-bold">
            No. Rastreo: {el.trackingId}
          </h3>
          <Tooltip title="Reportar incidencia">
            <Button
              variant="contained"
              startIcon={
                <ReportProblem style={{ fontSize: phone ? 20: 30, marginLeft: 10 }} />
              }
              style={{
                width: phone ? 45 : 60,
                height: phone ? 45 : 60,
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 150,
                backgroundColor: "#F1C40F",
              }}
              onClick={() => handleReportProblem(el)}
            ></Button>
          </Tooltip>
        </div>
        <Tooltip title="Rechazar">
          <Button
            className="float-right"
            variant="contained"
            style={{
              width: phone ? 45 : 60,
              height: phone ? 45 : 60,
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 150,
              backgroundColor: "#E74C3C",
            }}
            onClick={() => handleRejectDelivery(el)}
            startIcon={<Close style={{ fontSize: 20, marginLeft: 10 }} />}
          ></Button>
        </Tooltip>
        <div className="d-flex justify-content-between">
          <p className="d-flex">
            <CheckCircleOutlineOutlined
              style={{ color: "green", fontSize:20 }}
              className="mr-2"
            />
            <p style={{ fontSize: 14 }}> {el.status}</p>
          </p>
        </div>
        <p className="font-weight-bold" style={{ fontSize: 14 }}>Datos de contacto</p>
        <p style={{ fontSize: 14 }}>John Doe</p>
        <p style={{ fontSize: 14 }}>+52 5589897612</p>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6} md={6}>
            <Stepper
              id="stepper"
              activeStep={activeStep}
              orientation="vertical"
              className="mt-4"
            >
              {steps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel
                    optional={
                      index === 2 ? (
                        <Typography variant="caption">
                          Paquete entregado
                        </Typography>
                      ) : null
                    }
                  >
                    {step.label}
                  </StepLabel>
                  <StepContent>
                    <Typography className="text-center">
                      {index === 0 ? (
                        <Typography style={{ fontSize: 14 }}>
                          <LocationOnOutlined />
                          <p>{el.origin}</p>
                        </Typography>
                      ) : index === 2 ? (
                        <Typography style={{ fontSize: 14 }}>
                          <LocationOnOutlined />
                          <p> {el.destination}</p>
                        </Typography>
                      ) : (
                        <Typography style={{ fontSize: 14 }} className="pl-3">
                          <p>El paquete ha sido recolectado</p>
                        </Typography>
                      )}
                    </Typography>
                    <Box sx={{ mb: 2 }}>
                      <div className="text-center">
                        <Button
                          variant="contained"
                          onClick={() => handleNext(index)}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          {index === steps.length - 1
                            ? "Finalizar"
                            : "Continuar"}
                        </Button>
                      </div>
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Grid item xs={6} md={6}></Grid>
        </Grid>

        <div>
          <iframe
            className="w-100"
            loading="lazy"
            allowfullscreen
            referrerpolicy="no-referrer-when-downgrade"
            src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAl2Rp_7gvwhkcuTdOQP7DeqP2C8O5QoPE&q=${address}`}
          ></iframe>
        </div>
      </div>
      <Modal open={openRejectDelivery} onClose={() => console.log("Cerrando")}>
        <RejectDeliveryModal
          detail={rejectionReasons}
          handleClose={() => setOpenRejectDelivery(false)}
        />
      </Modal>
      <Modal open={OpenIncidences}>
        <ReportProblemModal
          detail={incidences}
          handleClose={() => setOpenIncidences(false)}
        />
      </Modal>
    </>
  );
};
