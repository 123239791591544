import React, { useEffect, useState } from "react";

import { Box, useMediaQuery, IconButton, Grid } from "@mui/material";
import { Close } from "@mui/icons-material";
import { AvailableVehiclesTable } from "../Tables/AvailableVehiclesTable";
import { AdminAPI } from "../../api/admin";
import { SkeletonTables } from "../Tables/SkeletonTables";
import { Search } from "../Search/SearchInput";

export const AvailableVehiclesModal = ({ handleClose, operatives,
    routeInfo,
    setVehicleInfo }) => {
  const phone = useMediaQuery("(max-width:480px)");
  const tablet = useMediaQuery("(max-width:900px)");

  // Backdrop states
  const [openBD, setOpenBD] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0); /*Estado p/paginacion*/
  const [total, setTotal] = useState(); /*Total de paginas enviado desde api*/

  const style = {
    position: "absolute",
    maxHeight: "90vh",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: phone ? "90%" : tablet ? "56%" : "65%",
    bgcolor: "background.paper",
    overflow: "scroll",
    boxShadow: 24,
    p: "0px 0px 15px 0px",
    borderRadius: 2,
  };

  useEffect(() => {
    showAvailableVehicles();
  }, [page, search]);

  const showAvailableVehicles = async () => {
    setIsLoading(true);
    let data = new FormData();
    data.append("available", true);
    data.append("page", page + 1);
    if (search !== "") {
      data.append("search", search);
    }
    try {
      const api = new AdminAPI();
      const response = await api.getVehicles(data);

      setTotal(response.data.total);
      setData(response.data.data);
      setIsLoading(false);
    } catch (error) {}
  };

  return (
    <Box sx={style}>
      <div className="w-100 px-3  ">
        <div className="d-flex justify-content-end">
          <IconButton onClick={handleClose}>
            <Close sx={{ fontSize: 30 }} />
          </IconButton>
        </div>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          rowSpacing={2}
          columns={{ xs: 6, sm: 6, md: 12, lg: 12 }}
        >
          {/*---Contenedor padre---*/}
          <Grid item xs={6} sm={6} md={12} lg={12}>
            {" "}
            {/*---Ocupara mitad de pantalla---*/}
            <Grid item xs={12} style={{ height: "100%" }}>
              {/*---Altura de 100 para que ocupe verticalmente---*/}
              <Search
                placeholder={"Tipo, marca, modelo, placa"}
                setSearch={setSearch}
              />
              {isLoading ? (
                <div className="bg-white w-100 d-flex justify-content-between p-2 border-radius-10">
                  <SkeletonTables num={5} />
                </div>
              ) : (
                <div className="bg-white w-100 d-flex justify-content-between p-2 border-radius-10">
                  <AvailableVehiclesTable
                    data={data}
                    search={search}
                    total={total}
                    page={page}
                    setPage={setPage}
                    operatives={operatives}
                    routeInfo={routeInfo}
                    setVehicleInfo={setVehicleInfo}
                    handleCloseModal={handleClose}
                  />
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};
