import React, { useEffect, useState } from "react";
import { useMediaQuery, Popover } from "@mui/material";
import { HistoricalGuidesTable } from "../../../components/Tables/HistoricalGuidesTable";
import { roles } from "../../../helpers/enums";
import { Search } from "../../../components/Search/Search";
import { AdminAPI } from "../../../api/admin";
import { SkeletonTables } from "../../../components/Tables/SkeletonTables";
import { historicalHeaders } from "../../../components/Excel/headers";
import { DownloadButtonExcel } from "../../../components/Excel/DownloadButtonExcel";
import { getDateCleaned, getDateCleaned2 } from "../../../helpers/getDateCleaned";
import { FiltersModal } from "../../../components/Modal/FiltersModal";

export const HistoricalAdmin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const phone = useMediaQuery("(max-width:480px)");
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0); /*Estado p/paginacion*/
  const [total, setTotal] = useState(); /*Total de paginas enviado desde api*/
  const [count, setCount] = useState(); /*Paginacion listas */
  const [anchorEl, setAnchorEl] = useState(null);

  //ERROR STATES
  //------------------------------------------------
  const [errors, setErrors] = useState({
    error: false,
    errorNullStart: false,
    errorNullEnd: false,
  });

  // EXCEL STATES
  //------------------------------------------------
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);
  const [dataExcel, setDataExcel] = useState([]);

  // FILTER STATES
  //------------------------------------------------
  const [startFilterDate, setStartFilterDate] = useState(null);
  const [endFilterDate, setEndFilterDate] = useState(null);
  const [statusList, setStatusList] = useState([]);
  const [status, setStatus] = useState(null);
  const [prevData, setPrevData] = useState({
    startFilterDate: null,
    endFilterDate: null,
    status: null,
  });
  const today = new Date();

  //EFFECTS
  //------------------------------------------------

  useEffect(() => {
    GetListShipmentList();
  }, [page, search]);

  useEffect(() => {
    getShipmentStatusCategories();
  }, []);

  //CLASSES
  //------------------------------------------------

  class Shipment {
    static form(download) {
      let data = new FormData();

      data.append("download", download);
      data.append("page", page + 1);
      data.append("inRoute", true);

      if (endFilterDate != null) {
        data.append("endDate", getDateCleaned2(endFilterDate));
      }
      if (startFilterDate != null) {
        data.append("startDate", getDateCleaned2(startFilterDate));
      }
      if (status != null) {
        data.append("shipmentStatus", status);
      }
      if (search != "") {
        data.append("searchFinished", search);
      }

      return data;
    }
  }

  //DATA REQUESTS
  //------------------------------------------------

  const getShipmentStatusCategories = async () => {
    let data = new FormData();
    data.append("finish", true);

    try {
      const api = new AdminAPI();
      const response = await api.getShipmentStatusCategories(data);
      setStatusList(response.data);
    } catch (error) {}
  };

  const GetListShipmentList = async () => {
    setIsLoading(true);
    const data = Shipment.form(false);
    // for (const value of data.entries()) {
    //   console.log(value);
    // }
    try {
      const api = new AdminAPI();
      const response = await api.getHistoricalShipments(data);
      setData(response.data.data);
      setTotal(response.data.total);
      setCount(Math.ceil(response.data.total / 10));
      setIsLoading(false);
    } catch (error) {}
  };

  const GetHistoricalExcelList = async () => {
    setIsLoadingExcel(true);
    const data = Shipment.form(true);

    try {
      const api = new AdminAPI();
      const response = await api.getHistoricalShipments(data);
      setDataExcel(response.data.data);
      setIsLoadingExcel(false);
    } catch (error) {}
  };

  const RequestData = () => {
    GetListShipmentList();
  };

  //FILTER SETTINGS
  //------------------------------------------------
  const settingFilters = (status, firstDay, endDay) => {
    setStatus(status);
    setStartFilterDate(firstDay);
    setEndFilterDate(endDay);
  };

  //HANDLERS
  //------------------------------------------------

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleCloseFilterModal = () => setAnchorEl(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseModal = () => {
    setAnchorEl(null);
    settingFilters(
      prevData.status,
      prevData.startFilterDate,
      prevData.endFilterDate
    );
    setErrors({
      error: false,
      errorNullStart: false,
      errorNullEnd: false,
    });
  };

  const handleFilter = () => {
    handleCloseFilterModal();
    if (startFilterDate != null || endFilterDate != null) {
      if (startFilterDate != null && endFilterDate == null) {
        setErrors({ errorNullEnd: true });
      } else if (endFilterDate != null && startFilterDate == null) {
        setErrors({ errorNullStart: true });
      } else if (endFilterDate < startFilterDate) {
        setErrors({ error: true });
      } else {
        setErrors({
          error: false,
          errorNullStart: false,
          errorNullEnd: false,
        });
        RequestData();
        setPrevData({
          status: status,
          startFilterDate: startFilterDate,
          endFilterDate: endFilterDate,
        });
      }
    } else {
      RequestData();
      setPrevData({
        status: status,
        startFilterDate: startFilterDate,
        endFilterDate: endFilterDate,
      });
    }
  };

  const handleResetFilter = () => {
    settingFilters(400);
    setErrors({
      error: false,
      errorNullStart: false,
      errorNullEnd: false,
    });
  };

  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
  };

  return (
    <>
      <div className="w-100 px-3">
        <div>
          {phone ? (
            <div className="mt-2 w-100 p-1 ">
              <div className="d-flex bg-white justify-content-between border-radius-10"></div>
              <div className="d-flex w-100 mt-2"></div>
            </div>
          ) : (
            <div className="w-100 bg-white d-flex justify-content-between mt-1 p-2 border-radius-10">
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <Search setSearch={setSearch} handleClick={handleClick} />
                  <DownloadButtonExcel
                    text={"Descargar"}
                    disabled={data.length > 0 ? false : true}
                    data={dataExcel}
                    headers={historicalHeaders}
                    sheetName={"Histórico"}
                    isLoading={isLoadingExcel}
                    getData={() => GetHistoricalExcelList()}
                  />
                </div>
              </div>
            </div>
          )}
          {isLoading ? (
            <div className="mt-3 bg-white w-100 d-flex justify-content-between p-2 border-radius-10">
              <SkeletonTables num={4} icon={true} />
            </div>
          ) : (
            <div className="mt-3 bg-white w-100 d-flex justify-content-between p-3 border-radius-10">
              <HistoricalGuidesTable
                search={search}
                data={data}
                rol={roles.ADMIN}
                total={total}
                page={page}
                setPage={setPage}
              />
            </div>
          )}
        </div>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseFilterModal}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <FiltersModal
          errors={errors}
          setErrors={setErrors}
          handleClose={handleCloseModal}
          dates={{
            setEnd: setEndFilterDate,
            end: endFilterDate,
            setStart: setStartFilterDate,
            start: startFilterDate,
          }}
          fields={[
            {
              array: statusList,
              prop: "status_name",
              id: "id",
              type: "Estatus",
              value: status,
              handleOnChange: handleChangeStatus,
            },
          ]}
          date={{
            today,
          }}
          onClickFilter={handleFilter}
          onClickResetFilter={handleResetFilter}
        />
      </Popover>
    </>
  );
};
