import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// MUI
import { IconButton, Tooltip, useMediaQuery, Popover } from "@mui/material";
import { FilterAltTwoTone } from "@mui/icons-material";
import { Search } from "../../../components/Search/Search";
import { SkeletonTables } from "../../../components/Tables/SkeletonTables";
import { VehiclesAssignedTable } from "../../../components/Tables/VehiclesAssignedTable";
import { FiltersModal } from "../../../components/Modal/FiltersModal";

// Refactor
import { AdminAPI } from "../../../api/admin";

export const Assignments = () => {
    const phone = useMediaQuery("(max-width:480px)");
    const navigate = useNavigate();

    const [view, setView] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [assigned, setAssigned] = useState([]);
    const [notAssigned, setNotAssigned] = useState([]);    
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0); /*Estado paginacion*/
    const [total, setTotal] = useState(); /*Total de paginas enviado desde api*/
    const [count, setCount] = useState(); /*Paginacion listas */

    //ERRORES
    //------------------------------------------------

    //------------------------------------------------
    //FILTERS STATES
    //------------------------------------------------

    const [prevData, setPrevData] = useState({
        table: "Asignados"
    });

    const [table, setTable] = useState("Asignados");
    const [option, setOption] = useState("Asignados");
    const tables = [
        { table: "Asignados", value: 1, },
        { table: "No asignados", value: 2, },
    ];

    //------------------------------------------------

    //HANDLERS
    //------------------------------------------------

    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => { setAnchorEl(event.currentTarget); };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const handleCloseFilterModal = () => setAnchorEl(null);

    const handleCloseModal = () => {
        setAnchorEl(null);
        settingFilters(
            prevData.table
        );
    };

    const handleFilter = () => {
        handleCloseFilterModal();
        RequestData();
        setPrevData({
            table: table
        });
    };

    const handleResetFilter = async () => {
        settingFilters("Asignados");

    };

    const handleChangeTable = (e) => {
        setTable(e.target.value);
    }
    //------------------------------------------------

    //FILTERS SETTING
    //------------------------------------------------

    const settingFilters = (table) => {
        setTable(table);
    };

    //------------------------------------------------

    //EFFECTS
    //------------------------------------------------

    useEffect(() => {
        RequestData();
    }, [])

    //DATA REQUEST
    //------------------------------------------------

    const RequestData = () => {
        switch (table) {
            case "Asignados":
                GetAssigned();
                setPage(0)
                break;
            case "No asignados":
                GetNotAssigned();
                setPage(0)
                break;
        }
    };

    const GetAssigned = async () => {
        setIsLoading(true);
        let data = new FormData();

        try {
            const api = new AdminAPI()
            const response = await api.getAssignmentsEP(data)

            setAssigned(response.data.data);
            setTotal(response.data.total);
            setCount(Math.ceil(response.data.total / 10));
            setIsLoading(false);

        } catch (error) {}
    };

    const GetNotAssigned = async () => {
        setIsLoading(true);
        let data = new FormData();

        try {
            const api = new AdminAPI()
            const response = await api.partnersListEP(data)

            setNotAssigned(response.data.data)
            setTotal(response.data.total);
            setCount(Math.ceil(response.data.total / 10));
            setIsLoading(false);
           
        } catch (error) {}
    };

    function getTable(filter) {
        const options = {
            "Asignados": assigned,
            "No asignados": notAssigned,
        };
        return options[filter] ?? "No hay data";
    }

    return (
        <>
            <div className="w-100 px-3">
                <div>
                    {phone ? (
                        <div className="mt-2 w-100 p-1 ">
                            <div className="d-flex bg-white justify-content-between border-radius-10">

                            </div>
                            <div className="d-flex w-100 mt-2">
                            </div>
                        </div>
                    ) : (
                        <div className="w-100 bg-white d-flex justify-content-between mt-1 p-2 border-radius-10">

                            <div className="d-flex align-items-center">


                                <div className="d-flex align-items-center">
                                    <Tooltip title="Filtrar" arrow placement="top">
                                        <IconButton onClick={handleClick}>
                                            <FilterAltTwoTone fontSize="large" sx={{ color: "#122028" }} />
                                        </IconButton>
                                    </Tooltip>
                                    <Search setSearch={setSearch} />
                                    {/* <AddButton text={"Agregar unidad"} link={"add/"} /> */}

                                </div>
                            </div>

                            {/* <ToggleView setView={setView} view={view} /> */}
                        </div>
                    )}

                    {isLoading ? (
                        <div className="mt-3 bg-white w-100 d-flex justify-content-between p-2 border-radius-10">
                            <SkeletonTables
                                num={3}
                                icon={true}
                            // total={total}
                            // page={page}
                            // setPage={setPage}
                            />
                        </div>
                    ) : (
                        <div className="mt-3 bg-white w-100 d-flex justify-content-between p-3 border-radius-10">
                            <VehiclesAssignedTable
                                search={search}
                                table={option}
                                data={getTable(option)}
                                // rol={roles.ADMIN}
                                total={total}
                                page={page}
                                setPage={setPage}
                            />
                        </div>
                    )}

                </div>
            </div>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleCloseFilterModal}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <FiltersModal
                    handleClose={handleCloseModal}
                    dates={{
                    }}
                    fields={[
                        {
                            array: tables,
                            prop: "table",
                            type: "Tabla",
                            value: table,
                            handleOnChange: handleChangeTable,
                        },
                    ]}
                    onClickFilter={handleFilter}
                    onClickResetFilter={handleResetFilter}
                />
            </Popover>
        </>
    );
};