import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Map } from "../Maps/Map";
import { Marker } from "../Maps/Marker";
import { SuccessModal } from "../Modal/SuccessModal";
import { ErrorModal } from "../Modal/ErrorModal";
import { Add, Close } from "@mui/icons-material";
import { Search } from "@mui/icons-material";
import {
  TextField,
  Modal,
  useMediaQuery,
  Grid,
  IconButton,
  Tooltip,
  Backdrop,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  MenuItem,
  Paper,
  CircularProgress,
} from "@mui/material";

import { useNavigate, useLocation } from "react-router-dom";
import { AcceptButton } from "../Buttons/AcceptButton";
import { CancelButton } from "../Buttons/CancelButton";
import { AdminAPI } from "../../api/admin";

export const GuideForm = () => {
  const navigate = useNavigate();
  const phone = useMediaQuery("(max-width:480px)");
  const [openSuccess, setOpenSuccess] = useState(false);

  // Error states
  const [sizeError, setSizeError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [weightError, setWeightError] = useState(false);
  const [lengthError, setLengthError] = useState(false);
  const [widthError, setWidthError] = useState(false);
  const [heightError, setHeightError] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openBD, setOpenBD] = useState(false);
  const [addressIsLoading, setAddressIsLoading] = useState(false);
  const [errorText, setErrorText] = useState("");

  const [guideItems, setGuideItems] = useState([]);
  const [favoriteOrigins, setFavoriteOrigins] = useState([]);

  // Guide items States
  const [size, setSize] = useState("");
  const [description, setDescription] = useState(null);
  const [weight, setWeight] = useState(null);
  const [length, setLength] = useState(null);
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);

  const location = useLocation();

  useEffect(() => {
    getFavoriteOrigins();
  }, []);

  const handleFavoriteOrigin = (favoriteOrigin) => {
    formik.setFieldValue("originId", favoriteOrigin.id);
    formik.setFieldValue("originFirstName", favoriteOrigin.first_name);
    formik.setFieldValue("originLastName", favoriteOrigin.last_name);
    formik.setFieldValue("originPhoneNumber", favoriteOrigin.phone);
    formik.setFieldValue("originMail", favoriteOrigin.email);
    formik.setFieldValue("originStreet", favoriteOrigin.street);
    formik.setFieldValue(
      "originInteriorNumber",
      favoriteOrigin.interior_number
    );
    formik.setFieldValue(
      "originOutdoorNumber",
      favoriteOrigin.outdoor_number
    );
    formik.setFieldValue(
      "originNeighborhood",
      favoriteOrigin.neighborhood
    );
    formik.setFieldValue("originReferences", favoriteOrigin.references);
    formik.setFieldValue(
      "originZipCode",
      favoriteOrigin.zipcode
    );
  };

  const countryPhoneCodes = [
    { country: "MX", phoneCode: "+52" },
    { country: "USA", phoneCode: "+1" },
  ];

  const itemSizeCategories = [
    { size: "xs" },
    { size: "s" },
    { size: "m" },
    { size: "l" },
    { size: "xl" },
  ];

  const handleClose = () => {
    navigate(`/admin/guides/active/`);
  };

  const resetInputs = () => {
    setSize("");
    setDescription("");
    setWeight("");
    setLength("");
    setWidth("");
    setHeight("");
    setDescriptionError(false);
    setSizeError(false);
    setWeightError(false);
    setLengthError(false);
    setWidthError(false);
    setHeightError(false);
  };

  const addItem = () => {
    if (weight > 70 || weight <= 0 || weight % 1 != 0 ) {
      setWeightError(true);
    } else {
      setWeightError(false);
      if (length > 300 || length <= 0 || length % 1 != 0 ) {
        setLengthError(true);
      } else {
        setLengthError(false);
        if (width > 300 || width <= 0 || width % 1 != 0 ) {
          setWidthError(true);
        } else {
          setWidthError(false);
          if (height > 280 || height <= 0 || height % 1 != 0 ) {
            setHeightError(true);
          } else {
            setHeightError(false);
            setGuideItems([
              ...guideItems,
              { size, description, weight, length, width, height },
            ]);
            resetInputs();
          }
        }
      }
    }
  };

  const deleteItem = (description) => {
    setGuideItems(
      guideItems.filter((item) => item.description !== description)
    );
  };

  const handleClick = (e, action, info) => {
    switch (action) {
      case "delete":
        deleteItem(info.description);
        break;
    }
  };

  const originFields = [
    {
      keyName: "Nombre",
      value: "originFirstName",
      type: true,
      size: 6,
      dropdown: false,
      isPass: false,
    },
    {
      keyName: "Apellido",
      value: "originLastName",
      type: true,
      size: 6,
      dropdown: false,
      isPass: false,
    },
    {
      keyName: "País",
      value: "originCountryPhoneCode",
      type: true,
      size: 2,
      dropdown: true,
    },
    {
      keyName: "Número de telefono",
      value: "originPhoneNumber",
      type: true,
      size: 10,
      dropdown: false,
      isPass: false,
    },
    {
      keyName: "Correo electronico",
      value: "originMail",
      type: true,
      size: 12,
      dropdown: false,
      isPass: false,
    },
    {
      keyName: "Calle",
      value: "originStreet",
      type: true,
      size: 12,
      dropdown: false,
      isPass: false,
    },
    {
      keyName: "Número exterior",
      value: "originOutdoorNumber",
      type: true,
      size: 6,
      dropdown: false,
      isPass: false,
    },
    {
      keyName: "Número interior",
      value: "originInteriorNumber",
      type: true,
      size: 6,
      dropdown: false,
      isPass: false,
    },
    {
      keyName: "Colonia",
      value: "originNeighborhood",
      type: true,
      size: 12,
      dropdown: false,
      isPass: false,
    },
    {
      keyName: "Código postal",
      value: "originZipCode",
      type: true,
      size: 6,
      dropdown: false,
      isPass: false,
    },
    {
      keyName: "Referencias",
      value: "originReferences",
      type: true,
      size: 6,
      dropdown: false,
      isPass: false,
    },
  ];

  const destinationFields = [
    {
      keyName: "Calle",
      value: "destinationStreet",
      type: true,
      size: 12,
      dropdown: false,
      isPass: false,
    },
    {
      keyName: "Número exterior",
      value: "destinationOutdoorNumber",
      type: true,
      size: 6,
      dropdown: false,
      isPass: false,
    },
    {
      keyName: "Número interior",
      value: "destinationInteriorNumber",
      type: true,
      size: 6,
      dropdown: false,
      isPass: false,
    },
    {
      keyName: "Colonia",
      value: "destinationNeighborhood",
      type: true,
      size: 12,
      dropdown: false,
      isPass: false,
    },
    {
      keyName: "Código postal",
      value: "destinationZipCode",
      type: true,
      size: 6,
      dropdown: false,
      isPass: false,
    },
    {
      keyName: "Referencias",
      value: "destinationReferences",
      type: true,
      size: 6,
      dropdown: false,
      isPass: false,
    },
    {
      keyName: "Nombre",
      value: "destinationFirstName",
      type: true,
      size: 6,
      dropdown: false,
      isPass: false,
    },
    {
      keyName: "Apellido",
      value: "destinationLastName",
      type: true,
      size: 6,
      dropdown: false,
      isPass: false,
    },
    {
      keyName: "País",
      value: "destinationCountryPhoneCode",
      type: true,
      size: 2,
      dropdown: true,
    },
    {
      keyName: "Número de telefono",
      value: "destinationPhoneNumber",
      type: true,
      size: 10,
      dropdown: false,
      isPass: false,
    },
    {
      keyName: "Correo electronico",
      value: "destinationMail",
      type: true,
      size: 12,
      dropdown: false,
      isPass: false,
    },
  ];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      markerPosition: "",
      search: "",
      favoriteOrigin: "",
      originId: 0,
      originFirstName: "",
      originLastName: "",
      originCountryPhoneCode: countryPhoneCodes[0].phoneCode,
      originPhoneNumber: "",
      originMail: "",
      originReferences: "",
      originStreet: "",
      originBuildingNumber: "",
      originInteriorNumber: "",
      originOutdoorNumber: "",
      originNeighborhood: "",
      originZipCode: "",
      destinationFirstName: "",
      destinationLastName: "",
      destinationCountryPhoneCode: countryPhoneCodes[0].phoneCode,
      destinationPhoneNumber: "",
      destinationMail: "",
      destinationReferences: "",
      destinationStreet: "",
      destinationInteriorNumber: "",
      destinationOutdoorNumber: "",
      destinationNeighborhood: "",
      destinationZipCode: "",
    },
    validationSchema: Yup.object({
      originFirstName: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "Sólo alfanuméricos y espacios"
        )
        .max(100, "Máximo 100 caracteres"),
      originLastName: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "Sólo alfanuméricos y espacios"
        )
        .max(100, "Máximo 100 caracteres"),
      originPhoneNumber: Yup.string()
        .required("Campo Obligatorio")
        .matches(/^\d+$/, "Sólo debe contener números (sin espacios)")
        .max(50, "Demasiado largo"),
      originMail: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          "Formato incorrecto"
        )
        .max(256, "El correo electrónico es demasiado largo"),
      originReferences: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "Sólo alfanuméricos y espacios"
        )
        .max(128, "Máximo 128 caracteres"),
      originStreet: Yup.string()
        .required("Campo Obligatorio")
        .max(128, "Máximo 128 caracteres"),
      originInteriorNumber: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "Sólo alfanuméricos y espacios"
        )
        .max(100, "Máximo 100 caracteres"),
      originOutdoorNumber: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "Sólo alfanuméricos y espacios"
        )
        .max(100, "Máximo 100 caracteres"),
      destinationInteriorNumber: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "Sólo alfanuméricos y espacios"
        )
        .max(100, "Máximo 100 caracteres"),
      destinationOutdoorNumber: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "Sólo alfanuméricos y espacios"
        )
        .max(100, "Máximo 100 caracteres"),
      destinationFirstName: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "Sólo alfanuméricos y espacios"
        )
        .max(100, "Máximo 100 caracteres"),
      destinationLastName: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "Sólo alfanuméricos y espacios"
        )
        .max(100, "Máximo 100 caracteres"),
      destinationPhoneNumber: Yup.string()
        .required("Campo Obligatorio")
        .matches(/^\d+$/, "Sólo debe contener números (sin espacios)")
        .max(10, "Máximo 10 dígitos"),
      destinationMail: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          "Formato incorrecto"
        )
        .max(256, "El correo electrónico es demasiado largo"),
      destinationReferences: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "Sólo alfanuméricos y espacios"
        )
        .max(128, "Máximo 128 caracteres"),
      destinationStreet: Yup.string()
        .required("Campo Obligatorio")
        .max(128, "Máximo 128 caracteres"),
      originNeighborhood: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "Sólo alfanuméricos y espacios"
        )
        .max(128, "Máximo 128 caracteres"),
      originZipCode: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "Sólo alfanuméricos y espacios"
        )
        .max(128, "Máximo 128 caracteres"),
      destinationNeighborhood: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "Sólo alfanuméricos y espacios"
        )
        .max(128, "Máximo 128 caracteres"),
      destinationZipCode: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "Sólo alfanuméricos y espacios"
        )
        .max(128, "Máximo 128 caracteres"),
    }),

    onSubmit: (values) => {
      const obj = {
        ...values,
      };

      AddGuide(obj);
    },
  });

  const getGeolocation = (address) => {
    setAddressIsLoading(true);
  
    const nominatimUrl = `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(address)}&format=json&addressdetails=1&limit=1`;
  
    fetch(nominatimUrl)
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          const result = data[0];
         
          const { address } = result;
          const neighborhood = address.neighbourhood || address.suburb;
          const street = address.road;
          const number = address.house_number;
          const postalCode = address.postcode;
          const country = address.country_code;
  
          if (country === 'mx') {
            formik.setFieldValue('markerPosition', { lat: parseFloat(result.lat), lng: parseFloat(result.lon) });
  
            formik.setFieldValue('destinationNeighborhood', neighborhood || '');
            formik.setFieldValue('destinationStreet', street || '');
            formik.setFieldValue('destinationOutdoorNumber', number || '');
            formik.setFieldValue('destinationZipCode', postalCode || '');
          } else {
            setOpenError(true);
            setErrorText('Lo sentimos, no tenemos cobertura en esa región');
          }
        } else {
          setOpenError(true);
          setErrorText('No se encontró ningún resultado, incluye más datos a tu búsqueda');
          formik.setFieldValue('destinationZipCode', '');
          formik.setFieldValue('destinationNeighborhood', '');
          formik.setFieldValue('destinationStreet', '');
          formik.setFieldValue('destinationOutdoorNumber', '');
          formik.setFieldValue('markerPosition', '');
        }
        setAddressIsLoading(false);
      })
      .catch((err) => {
        setOpenError(true);
        setErrorText('Hubo un error al procesar la geolocalización');
        formik.setFieldValue('destinationZipCode', '');
        formik.setFieldValue('destinationNeighborhood', '');
        formik.setFieldValue('destinationStreet', '');
        formik.setFieldValue('destinationOutdoorNumber', '');
        formik.setFieldValue('markerPosition', '');
        setAddressIsLoading(false);
      });
  };
  
  const getAddress = (location) => {
    setAddressIsLoading(true);
  
    const { lat, lng } = location; // Asumiendo que 'location' tiene latitud y longitud
    const url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`;
  
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.address) {
          const neighborhood = data.address.neighbourhood || data.address.sublocality || "";
          const street = data.address.road || "";
          const number = data.address.house_number || "";
          const postalCode = data.address.postcode || "";
  
          formik.setFieldValue("markerPosition", { lat: parseFloat(lat), lng: parseFloat(lng) });
          formik.setFieldValue("destinationNeighborhood", neighborhood);
          formik.setFieldValue("destinationStreet", street);
          formik.setFieldValue("destinationOutdoorNumber", number);
          formik.setFieldValue("destinationZipCode", postalCode);
  
        } else {
          setOpenError(true);
          setErrorText(
            "No se encontró ningún resultado, incluye más datos a tu búsqueda"
          );
          formik.setFieldValue("destinationZipCode", "");
          formik.setFieldValue("destinationNeighborhood", "");
          formik.setFieldValue("destinationStreet", "");
          formik.setFieldValue("destinationOutdoorNumber", "");
          formik.setFieldValue("markerPosition", "");
        }
        setAddressIsLoading(false);
      })
      .catch((e) => {
        setOpenError(true);
        setErrorText(
          "Error al obtener los datos de ubicación"
        );
        formik.setFieldValue("destinationZipCode", "");
        formik.setFieldValue("destinationNeighborhood", "");
        formik.setFieldValue("destinationStreet", "");
        formik.setFieldValue("destinationOutdoorNumber", "");
        formik.setFieldValue("markerPosition", "");
        setAddressIsLoading(false);
        console.log(e);
      });
  };
  

  const AddGuide = async (obj) => {
    setOpenBD(true);
    // let data = new FormData();

    // data.append(
    //   "shipments",
    //   JSON.stringify([
    //     {
    //       deliveryType: "NXD",
    //       confirmed: true,
    //       origin: obj.originId,
    //       destination: {
    //         firstName: obj.destinationFirstName,
    //         lastName: obj.destinationLastName,
    //         phone:
    //           obj.destinationCountryPhoneCode +
    //           " " +
    //           obj.destinationPhoneNumber,
    //         email: obj.destinationMail,
    //         references: obj.destinationReferences,
    //         address: {
    //           street: obj.destinationStreet,
    //           interior_number: obj.destinationInteriorNumber,
    //           outdoor_number: obj.destinationOutdoorNumber,
    //           neighborhood: obj.destinationNeighborhood,
    //           zipCode: obj.destinationZipCode,
    //           ubication: JSON.stringify(obj.markerPosition),
    //         },
    //       },

    //       items: guideItems,
    //     },
    //   ])
    // );

    // for (const value of data.entries()) {
    //   //console.log(value);
    // }

    let shipment = {
      deliveryType: "NXD",
      confirmed: true,
      origin: obj.originId,
      destination: {
        firstName: obj.destinationFirstName,
        lastName: obj.destinationLastName,
        phone: `${obj.destinationCountryPhoneCode} ${obj.destinationPhoneNumber}`,
        email: obj.destinationMail,
        references: obj.destinationReferences,
        address: {
          street: obj.destinationStreet,
          interiorNumber: obj.destinationInteriorNumber,
          outdoorNumber: obj.destinationOutdoorNumber,
          neighborhood: obj.destinationNeighborhood,
          zipCode: obj.destinationZipCode,
          location: obj.markerPosition, // assuming obj.markerPosition is already an object
        },
      },
      items: guideItems,
    };
    
    let data = new FormData();
    data.append("shipments", JSON.stringify([shipment]));

    for (let entry of data.entries()) {
      console.log(entry[0] + ', ' + entry[1]); // Print key-value pair
    }

    try {
      const api = new AdminAPI();
      const response = await api.createGuide(data);

      if (response.status == 200) {
        setOpenSuccess(true);
        setOpenBD(false);
      } else {
        setOpenBD(false);
        setErrorText("Error. " + "\n" +  String(response.response.data.msg));
        setOpenError(true);
      }
    } catch (error) {
      //console.log(error);
      setOpenBD(false);
      setOpenError(true);
    }
  };

  const getFavoriteOrigins = async () => {
    setOpenBD(true);
    try {
      const api = new AdminAPI();
      const response = await api.getFavoriteOrigins();
      if (response.status == 200) {
        setOpenBD(false);
        setFavoriteOrigins(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          rowSpacing={2}
        >
          <Grid item xs={12} sm={12} md={6}>
            <Grid
              item
              style={{ height: "100%" }}
              xs={12}
              className={"border bg-white p-3 border-radius-10"}
            >
              <div>
                <p className={` ${phone ? "h6" : "h5"}`}>Datos de origen</p>
              </div>
              <TextField
                className={"my-2"}
                select
                error={
                  formik.touched["favoriteOrigin"] &&
                  formik.errors["favoriteOrigin"]
                }
                size="small"
                label={"Origenes recurrentes"}
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  formik.handleChange(e);
                  handleFavoriteOrigin(e.target.value);
                }}
                value={formik.values.favoriteOrigin}
                name={"favoriteOrigin"}
                id={"favoriteOrigin"}
                helperText={
                  formik.touched["favoriteOrigin"] &&
                  formik.errors["favoriteOrigin"] ? (
                    <div>{formik.errors["favoriteOrigin"]}</div>
                  ) : null
                }
              >
                {favoriteOrigins.length == 0 ? (
                  <MenuItem>Aún no se han creado orígenes favoritos.</MenuItem>
                ) : (
                  favoriteOrigins.map((origin, i) => (
                    <MenuItem key={i} value={origin}>
                      {origin.name}
                    </MenuItem>
                  ))
                )}
              </TextField>
              <Grid container columns={{ xs: 4, sm: 6, md: 12, lg: 12 }}>
                {originFields.map((textField) => (
                  <Grid key={textField.keyName} item xs={textField.size}>
                    <div
                      className={` d-flex mb-3 flex-column ${
                        phone ? "px-2 " : "px-2"
                      }`}
                    >
                      <div
                        className={`d-flex align-items-center mr-2 ${
                          phone && "mb-2"
                        }`}
                      >
                        <p className="h6 te" htmlFor={textField.value}>{`${
                          textField.keyName
                        }: ${textField.type ? "*" : ""}`}</p>
                      </div>

                      <div className="d-flex align-items-center w-100">
                        <TextField
                          disabled={true}
                          error={
                            formik.touched[textField.value] &&
                            formik.errors[textField.value]
                          }
                          select={textField.dropdown}
                          size="small"
                          label={textField.keyName}
                          variant="outlined"
                          fullWidth
                          onChange={formik.handleChange}
                          value={formik.values[textField.value]}
                          name={textField.value}
                          id={textField.value}
                          helperText={
                            formik.touched[textField.value] &&
                            formik.errors[textField.value] ? (
                              <div>{formik.errors[textField.value]}</div>
                            ) : null
                          }
                        >
                          {countryPhoneCodes.map((prefix, i) => (
                            <MenuItem key={i} value={prefix.phoneCode}>
                              {prefix.country + " (" + prefix.phoneCode + ")"}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Grid
              item
              style={{ height: "100%" }}
              xs={12}
              className={"border bg-white p-3 border-radius-10"}
            >
              <div>
                <p className={` ${phone ? "h6" : "h5"}`}>Datos de destino</p>
              </div>
              <Grid container columns={{ xs: 4, sm: 6, md: 12, lg: 12 }}>
                <p
                  className={`mr-2 mb-1 ${phone ? "xs-font" : ""}`}
                  htmlFor={"search"}
                >{`Lugar o dirección:*`}</p>
                <TextField
                  className="mb-2"
                  size="small"
                  fullWidth
                  label={"Lugar o dirección"}
                  onChange={formik.handleChange}
                  onKeyPress={(e) => {
                    if (e.key == "Enter") {
                      e.preventDefault();
                      getGeolocation(e.target.value);
                    }
                  }}
                  value={formik.values.search}
                  name={"search"}
                  inputProps={phone && { style: { fontSize: 13 } }}
                  InputProps={{
                    endAdornment: (
                      <Search
                        className="ds-yellow-font mr-2 cursor-pointer"
                        onClick={() => getGeolocation(formik.values.search)}
                      />
                    ),
                  }}
                  error={formik.errors.location && formik.touched.search}
                  helperText={
                    formik.errors.location &&
                    formik.touched.search &&
                    formik.errors.location
                  }
                />

                
                  <Map
                    center={{
                      lat: 19.406011,
                      lng: -99.137612,
                    }}
                    zoom={8}
                    style={{ height: "30vh", width: "100%" }}
                  >
                    {formik.values.markerPosition && (
                      <Marker
                        position={formik.values.markerPosition}
                        draggable={true}
                        getAddress={getAddress}
                        markerType="genericMarker"
                        clickable={true}
                        selected={true}
                      />
                    )}
                  </Map>
               
                {destinationFields.map((textField) => (
                  <Grid key={textField.keyName} item xs={textField.size}>
                    <div
                      className={` d-flex mb-3 flex-column ${
                        phone ? "px-2 " : "px-2"
                      }`}
                    >
                      <div
                        className={`d-flex align-items-center mr-2 ${
                          phone && "mb-2"
                        }`}
                      >
                        <p className="h6 te" htmlFor={textField.value}>{`${
                          textField.keyName
                        }: ${textField.type ? "*" : ""}`}</p>
                        {addressIsLoading &&
                        (textField.value == "destinationZipCode" ||
                          textField.value == "destinationNeighborhood" ||
                          textField.value == "destinationStreet" ||
                          textField.value == "destinationOutdoorNumber") ? (
                          <CircularProgress
                            size={18}
                            className="ml-1 ds-yellow-font"
                          />
                        ) : null}{" "}
                      </div>

                      <div className="d-flex align-items-center w-100">
                        <TextField
                          error={
                            formik.touched[textField.value] &&
                            formik.errors[textField.value]
                          }
                          select={textField.dropdown}
                          size="small"
                          label={textField.keyName}
                          variant="outlined"
                          type={"text"}
                          fullWidth
                          onChange={formik.handleChange}
                          value={
                            addressIsLoading &&
                            (textField.value == "destinationZipCode" ||
                              textField.value == "destinationNeighborhood" ||
                              textField.value == "destinationStreet" ||
                              textField.value == "destinationOutdoorNumber")
                              ? "Cargando... "
                              : formik.values[textField.value]
                          }
                          name={textField.value}
                          id={textField.value}
                          helperText={
                            formik.touched[textField.value] &&
                            formik.errors[textField.value] ? (
                              <div>{formik.errors[textField.value]}</div>
                            ) : null
                          }
                        >
                          {countryPhoneCodes.map((prefix, i) => (
                            <MenuItem key={i} value={prefix.phoneCode}>
                              {prefix.country + " " + prefix.phoneCode}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {" "}
          {/*---Ocupara mitad de pantalla---*/}
          <Grid
            item
            xs={12}
            style={{ height: "100%" }}
            className={`border bg-white p-3 r-15 border-radius-10 my-3`}
          >
            <div className="mb-2 d-flex justify-content-between">
              <p className={`${phone ? "h6" : "h5"}`}>Artículos</p>
            </div>

            <>
              <div
                key={"products"}
                className={` d-flex flex-column px-2 ${
                  phone ? "mb-1" : "mb-3"
                }`}
              >
                <div className="d-flex flex-row">
                  <div
                    key={"description"}
                    className={` d-flex flex-column w-25 ml-2 pr-2`}
                  >
                    <label
                      className={`mb-1 ${phone ? "xs-font" : ""}`}
                      htmlFor={"description"}
                    >
                      {"Descripción"}
                    </label>
                    <div className="d-flex align-items-start w-100">
                      <TextField
                        error={descriptionError}
                        size="small"
                        label={"Descripción"}
                        variant="outlined"
                        fullWidth
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                        value={description}
                        name={"description"}
                        id={"description"}
                        helperText={descriptionError ? "Error" : null}
                      />
                    </div>
                  </div>
                  {/* <div
                    key={"size"}
                    className={` d-flex flex-column w-25 mr-2 pl-2 ${
                      phone ? "mb-1" : "mb-3"
                    }`}
                  >
                    <label
                      className={`mb-1 ${phone ? "xs-font" : ""}`}
                      htmlFor={"size"}
                    >
                      {"Tamaño"}
                    </label>
                    <TextField
                      select
                      error={sizeError}
                      size="small"
                      label={"Tamaño"}
                      variant="outlined"
                      fullWidth
                      onChange={(e) => {
                        setSize(e.target.value);
                      }}
                      value={size}
                      name={"size"}
                      id={"size"}
                      InputLabelProps={phone && { style: { fontSize: 13 } }}
                      InputProps={{
                        style: { fontSize: phone ? 13 : "inherit" },
                      }}
                    >
                      {itemSizeCategories.map((category, i) => (
                        <MenuItem key={i} value={category.size}>
                          {category.size}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div> */}
                  <div
                    key={"weight"}
                    className={` d-flex flex-column w-25 ml-2 pr-2`}
                  >
                    <label
                      className={`mb-1 ${phone ? "xs-font" : ""}`}
                      htmlFor={"weight"}
                    >
                      {"Peso"}
                    </label>
                    <div className="d-flex align-items-start w-100">
                      <TextField
                        type="number"
                        error={weightError}
                        size="small"
                        label={"Peso"}
                        variant="outlined"
                        fullWidth
                        onChange={(e) => {
                          setWeight(e.target.value);
                        }}
                        value={weight}
                        name={"weight"}
                        id={"weight"}
                      />
                    </div>
                  </div>
                  <div
                    key={"length"}
                    className={` d-flex flex-column w-25 ml-2 pr-2`}
                  >
                    <label
                      className={`mb-1 ${phone ? "xs-font" : ""}`}
                      htmlFor={"length"}
                    >
                      {"Largo"}
                    </label>
                    <div className="d-flex align-items-start w-100">
                      <TextField
                        type="number"
                        error={lengthError}
                        size="small"
                        label={"Largo"}
                        variant="outlined"
                        fullWidth
                        onChange={(e) => {
                          setLength(e.target.value);
                        }}
                        value={length}
                        name={"length"}
                        id={"length"}
                      />
                    </div>
                  </div>
                  <div
                    key={"width"}
                    className={` d-flex flex-column w-25 ml-2 pr-2`}
                  >
                    <label
                      className={`mb-1 ${phone ? "xs-font" : ""}`}
                      htmlFor={"width"}
                    >
                      {"Ancho"}
                    </label>
                    <div className="d-flex align-items-start w-100">
                      <TextField
                        type="number"
                        error={widthError}
                        size="small"
                        label={"Ancho"}
                        variant="outlined"
                        fullWidth
                        onChange={(e) => {
                          setWidth(e.target.value);
                        }}
                        value={width}
                        name={"width"}
                        id={"width"}
                      />
                    </div>
                  </div>
                  <div
                    key={"height"}
                    className={` d-flex flex-column w-25 ml-2 pr-2`}
                  >
                    <label
                      className={`mb-1 ${phone ? "xs-font" : ""}`}
                      htmlFor={"height"}
                    >
                      {"Alto"}
                    </label>
                    <div className="d-flex align-items-start w-100">
                      <TextField
                        type="number"
                        error={heightError}
                        size="small"
                        label={"Alto"}
                        variant="outlined"
                        fullWidth
                        onChange={(e) => {
                          setHeight(e.target.value);
                        }}
                        value={height}
                        name={"height"}
                        id={"height"}
                      />
                    </div>
                  </div>
                  <Tooltip arrow placement="top" title="Agregar">
                    <IconButton
                      onClick={() => {
                        addItem();
                      }}
                    >
                      <Add />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
              <div className="d-flex align-items-center w-100">
                <Paper sx={{ width: "100%" }}>
                  <TableContainer sx={{ maxHeight: 200 }}>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Descripción</TableCell>
                          {/* <TableCell align="center">Tamaño</TableCell> */}
                          <TableCell align="center">Peso</TableCell>
                          <TableCell align="center" sx={{ maxWidth: 20 }}>
                            Largo
                          </TableCell>
                          <TableCell align="center" sx={{ maxWidth: 20 }}>
                            Ancho
                          </TableCell>
                          <TableCell align="center" sx={{ maxWidth: 20 }}>
                            Alto
                          </TableCell>
                          <TableCell align="center"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {guideItems.map((item, index) => (
                          <TableRow hover role="checkbox" key={index}>
                            <TableCell align="center">
                              {item.description}
                            </TableCell>
                            {/* <TableCell align="center">{item.size}</TableCell> */}
                            <TableCell align="center">{item.weight}</TableCell>
                            <TableCell align="center">{item.length}</TableCell>
                            <TableCell align="center">{item.width}</TableCell>
                            <TableCell align="center">{item.height}</TableCell>
                            <TableCell
                              padding="checkbox"
                              sx={{ width: "20px" }}
                            >
                              <IconButton
                                size="medium"
                                sx={{
                                  "&:hover": { color: "red" },
                                  padding: "0 1px 0 0",
                                }}
                                onClick={(e) => handleClick(e, "delete", item)}
                                disabled={false}
                              >
                                <Close fontSize="small" />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </div>
            </>
          </Grid>
        </Grid>
        <div
          className={`mt-2 mb-2 ${
            !phone ? "d-flex justify-content-end margin-right" : ""
          }`}
        >
          <div className="mr-2">
            <CancelButton
              text={"Cancelar"}
              onClick={() => {
                navigate("/admin/guides/active/");
              }}
              width={phone ? "100%" : ""}
            />
          </div>
          <AcceptButton
            text={location.state != undefined ? "Editar" : "Agregar"}
            type={"submit"}
            width={phone ? "100%" : ""}
          />
        </div>
      </form>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBD}
      >
        <span className="loader"></span>
      </Backdrop>

      <Modal open={openSuccess}>
        <SuccessModal
          handleClose={handleClose}
          text={`Guia ${
            location.state != undefined ? "editada" : "creada"
          } con éxito.`}
        />
      </Modal>
      <Modal open={openError}>
        <ErrorModal
          // text={`Error al ${
          //   location.state != undefined ? "editar" : "crear"
          // } la guía`}
          text={errorText}
          error={""}
          handleClose={() => setOpenError(false)}
        />
      </Modal>
    </>
  );
};
