import { getPath } from "../helpers/getHost";
import { HttpRequest } from "./HttpRequest";

export class AdminAPI {
  constructor() {
    this.http = new HttpRequest();
    this.host = getPath();
    this.token = localStorage.getItem("token");
    this.config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${this.token}`,
      },
    };
  }

  // Shipment Guides endpoints
  createGuide(data) {
    return this.http.post(`${this.host}/ws/createShipment/`, data, this.config);
  }

  addGuideToRoute(data) {
    return this.http.post(
      `${this.host}/ws/addShipmentToRoute/`,
      data,
      this.config
    );
  }

  removeShipmentFromRoute(data) {
    return this.http.post(`${this.host}/ws/unpairShipment/`, data, this.config);
  }

  // Shipments Origins endpoints
  createFavoriteOrigin(data, method) {
    if(method == "POST"){
      return this.http.post(
        `${this.host}/ws/createFavoriteOrigin/`,
        data,
        this.config
      );
    } else {
      return this.http.put(
        `${this.host}/ws/createFavoriteOrigin/`,
        data,
        this.config
      );
    }
  }

  deleteFavoriteOrigin(data) {
    return this.http.delete(`${this.host}/ws/deleteShipmentOrigin/`, {
      headers: this.config.headers,
      data,
    });
  }

  getFavoriteOrigins() {
    return this.http.get(`${this.host}/ws/getFavoriteOrigins/`, this.config);
  }

  // Shipments endpoints
  getShipments(data) {
    return this.http.post(`${this.host}/ws/getShipments/`, data, this.config);
  }

  getAllShipments(data) {
    return this.http.post(`${this.host}/ws/getPendingShipments/`, data, this.config);
  }

  getShipmentStatusCategories(data) {
    return this.http.post(
      `${this.host}/ws/getListStatusShipment/`,
      data,
      this.config
    );
  }

  getShipmentDetails(data) {
    return this.http.post(
      `${this.host}/ws/getShipmentDetails/`,
      data,
      this.config
    );
  }

  getShipmentsSummary(data){
    return this.http.post(
      `${this.host}/ws/GetRouteSummary/`,
      data,
      this.config
    );
  }

  deleteShipment(data) {
    return this.http.delete(`${this.host}/ws/deleteShipment/`, {
      headers: this.config.headers,
      data,
    });
  }

  // Historical Shipments enpoints
  getHistoricalShipments(data) {
    return this.http.post(
      `${this.host}/ws/getFinishedShipments/`,
      data,
      this.config
    );
  }

  // Partner endpoints
  getPartners(data) {
    return this.http.post(`${this.host}/ws/getPartners/`, data, this.config);
  }

  getAvailableDrivers(data) {
    return this.http.post(`${this.host}/ws/getAvailableDrivers/`, data, this.config);
  }

  createPartner(data, method) {
    if (method == "POST") {
      return this.http.post(`${this.host}/ws/driver/`, data, this.config);
    } else {
      return this.http.put(`${this.host}/ws/driver/`, data, this.config);
    }
  }

  updatePartner(data) {
    return this.http.put(`${this.host}/ws/partner/`, data, this.config);
  }

  resetPasswordPartner(data){
    return this.http.post(`${this.host}/ws/resetPassword/`, data, this.config)
  }

  getRouteCoordinates(partner, route) {
    this.config.params = { partner, route };
    return this.http.get(`${this.host}/ws/getLocation/`, this.config);
  }

  // Routes

  createRoute(data) {
    return this.http.post(`${this.host}/ws/createRoute/`, data, this.config);
  }

  getRoutesEP(data) {
    return this.http.post(`${this.host}/ws/getRoutes/`, data, this.config);
  }

  deleteRoute(data) {
    return this.http.delete(`${this.host}/ws/deleteRoute/`, {
      headers: this.config.headers,
      data,
    });
  }

  // Vehicle Endpoints
  getVehicles(data) {
    return this.http.post(`${this.host}/ws/getVehicles/`, data, this.config);
  }

  createVehicle(data, method) {
    if (method == "POST") {
      return this.http.post(`${this.host}/ws/addVehicle/`, data, this.config);
    } else {
      return this.http.put(`${this.host}/ws/addVehicle/`, data, this.config);
    }
  }

  assignVehicleToPartner(data) {
    return this.http.post(
      `${this.host}/ws/assignPartnerVehicle/`,
      data,
      this.config
    );
  }

  unassignVehicleToPartner(data) {
    return this.http.delete(`${this.host}/ws/assignPartnerVehicle/`, {
      // This structure is needed for the delete method
      headers: this.config.headers,
      data,
    });
  }

  getVehicleCategories(data) {
    return this.http.post(
      `${this.host}/ws/getCategoryVehicle/`,
      data,
      this.config
    );
  }

  updatePartnerVehicle(data) {
    return this.http.post(`${this.host}/ws/changeVehicle/`, data, this.config);
  }

  deleteVehicle(data) {
    return this.http.put(`${this.host}/ws/vehicle/`, data, this.config);
  }

  freeDriverFromRoute(data) {
    return this.http.post(`${this.host}/ws/freeDriverFromRoute/`, 
      data,
      this.config
    );
  }

  getVehicleImages(data) {
    return this.http.post(
      `${this.host}/ws/getImageVehicle/`,
      data,
      this.config
    );
  }

  UpdateVehicle(data) {
    return this.http.post(`${this.host}/ws/addVehicle/`, data, this.config);
  }

  getAssignmentsEP(data) {
    return this.http.post(`${this.host}/ws/getAssignments/`, data, this.config);
  }
}
