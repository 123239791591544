import React, { useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Modal,
  Backdrop,
} from "@mui/material";

import { ToggleOff, DriveFileRenameOutlineTwoTone } from "@mui/icons-material";
import LockResetIcon from '@mui/icons-material/LockReset';
import { useNavigate } from "react-router-dom";
import { useViewPortDimensions } from "../../hooks/useViewPortDimensions";
import { TablesPagination } from "../Pagination/TablesPagination";
import { WarningReasonModal } from "../Modal/DeletionReasonModal";
import { AdminAPI } from "../../api/admin";
import { SuccessModal } from "../Modal/SuccessModal";
import { ErrorModal } from "../Modal/ErrorModal";
import { ResetPasswordModal } from "../Modal/ResetPasswordModal";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const OperativesTable = ({ search, data, setPage, page, total }) => {
  const { height, width } = useViewPortDimensions();
  const navigate = useNavigate();

  const [openDelete, setOpenDelete] = useState(false);
  const [operativeId, setOperativeId] = useState(0);
  const [openBD, setOpenBD] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [successText, setSuccessText] = useState("");
  const [openError, setOpenError] = useState(false);
  const [isModalResetPassOpen, setIsModalResetPassOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  

  const handleOpenModalPass = (row) => {
    setIsModalResetPassOpen(!isModalResetPassOpen);
    setSelectedUser(row.email);
  }


  const handleClick = (row) => {
    navigate("/admin/operatives/edit/", {
      state: {
        id: row.partnerId == null ? "" : row.partnerId,
        name: row.name == null ? "" : row.name,
        lastName: row.lastName == null ? "" : row.lastName,
        email: row.email == null ? "" : row.email,
        personalNumber: row.phone == null ? "" : row.phone,
        dateBirth:
          row.dateBirth == null ? "" : new Date(`${row.dateBirth} 00:00:00`),
        dateExpiration:
          row.expirationDriveLicense == null
            ? ""
            : new Date(`${row.expirationDriveLicense} 00:00:00`),
        image: row.photo == null ? "" : row.photo,
        existImageFile: row.photo == null ? false : true,
        imageLicense: row.driveLicense == null ? "" : row.driveLicense,
        existImageLicense: row.driveLicense == null ? false : true,
        employeeId: row.employeeId == null ? "" : row.employeeId,
      },
    });
  };

  const status = [
    { id: 1, value: "Pendiente" },
    { id: 2, value: "Finalizado" },
  ];

  const handleDeleteOperative = (partnerId, active) => {
    if (!active) {
      activatePartner(partnerId);
    } else {
      setOpenDelete(true);
      setOperativeId(partnerId);
    }
  };

  const activatePartner = async (partnerId) => {
    setOpenBD(true);
    let data = new FormData();
    data.append("partnerId", partnerId);
    data.append("is_active", true);

    try {
      const api = new AdminAPI();
      const response = await api.updatePartner(data);

      if (response.status == 200) {
        setSuccessText("Operador activado con éxito");
        setOpenSuccess(true);
      } else {
        setOpenError(true);
      }
    } catch (error) {}
  };

  const handleResetPassword = async (emailEmploye, password) => {
    const api = new AdminAPI();
    let data = new FormData();
    data.append("email", emailEmploye);
    data.append("new_password", password);

    try {
      const api = new AdminAPI();
      const response = await api.resetPasswordPartner(data);

      if (response.status == 200) {
        // setSuccessText("Operador activado con éxito");
        // setOpenSuccess(true);
        toast.success(`Cambio exitoso de contraseña para: ${emailEmploye}.`);
      } else {
        toast.error(`Falló al cambiar contraseña.`);
      }
    } catch (error) {
      console.log("Error", error);
    }
    
      };

  return (
    <Box sx={{ width: "100%" }}>
      <ToastContainer />
      <Modal open={isModalResetPassOpen} onClose={handleOpenModalPass}>
        <ResetPasswordModal handleClose={handleOpenModalPass} emailEmploye={selectedUser} onClick={handleResetPassword}/>
      </Modal>
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: height - (74 + 90 + 80) }}>
          <Table sx={{ minWidth: 750 }} aria-label="sticky table" stickyHeader>
            <thead className="h-12 bg-[#004876c2] text-white">
              <TableRow>
                <TableCell align="center">Acciones</TableCell>
                <TableCell align="center">
                  <span style={{ whiteSpace: "nowrap" }}>
                    Código de empleado
                  </span>
                </TableCell>
                <TableCell align="center">Nombre</TableCell>
                <TableCell align="center">Apellidos</TableCell>
                <TableCell align="center">Teléfono</TableCell>
                <TableCell align="center">Email</TableCell>
                <TableCell align="center">
                  <span style={{ whiteSpace: "nowrap" }}>
                    Expiración licencia
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span style={{ whiteSpace: "nowrap" }}>Fecha nacimiento</span>
                </TableCell>
                <TableCell align="center">Disponible</TableCell>
                <TableCell align="center">Activo</TableCell>
                <TableCell align="center">En ruta</TableCell>
                <TableCell align="center">Vehículo actual</TableCell>
                <TableCell align="center">Licencia</TableCell>
                <TableCell align="center">Fotografía</TableCell>
              </TableRow>
            </thead>
            <TableBody>
              {data.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id} style={{ backgroundColor: index % 2 === 0 ? "#f0f0f0" : "white" }}>
                    <TableCell padding="checkbox">
                      <div className="d-flex justify-content-around">
                        <Tooltip title={"Editar"} arrow placement="top">
                          <div className="d-flex align-items-center justify-content-center">
                            <IconButton onClick={() => handleClick(row)}>
                              <DriveFileRenameOutlineTwoTone />
                            </IconButton>
                          </div>
                        </Tooltip>

                        <Tooltip title={"Recuperar contraseña"} arrow placement="top">
                          <div className="d-flex align-items-center justify-content-center">
                            <IconButton onClick={() => handleOpenModalPass(row)}>
                              <LockResetIcon />
                            </IconButton>
                          </div>
                        </Tooltip>

                        <Tooltip
                          title={row.isActive ? "Desactivar" : "Activar"}
                          arrow
                          placement="top"
                        >
                          <div className="d-flex align-items-center justify-content-center">
                            <IconButton
                              style={
                                row.isActive
                                  ? { color: "green" }
                                  : { color: "red" }
                              }
                              onClick={() =>
                                handleDeleteOperative(
                                  row.employeeId,
                                  row.isActive
                                )
                              }
                            >
                              <ToggleOff />
                            </IconButton>
                          </div>
                        </Tooltip>
                      </div>
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      align="center"
                    >
                      {row.employeeId != null ? row.employeeId : ""}
                    </TableCell>
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">{row.lastName}</TableCell>
                    <TableCell align="center">{row.phone}</TableCell>
                    <TableCell align="center">{row.email}</TableCell>
                    <TableCell align="center">
                      {row.expirationDriveLicense}
                    </TableCell>
                    <TableCell align="center">{row.dateBirth}</TableCell>

                    <TableCell align="center">
                      {row.isAvailable ? "Disponible" : "No disponible"}
                    </TableCell>
                    <TableCell align="center">
                      {row.isActive ? "Activo" : "Inactivo"}
                    </TableCell>
                    <TableCell align="center">
                      {row.inRoute ? "En ruta" : ""}
                    </TableCell>
                    <TableCell align="center">
                      {row.currentVehicle}
                    </TableCell>
                    <TableCell align="center">
                      <img
                        src={row.driveLicense}
                        className={row.driveLicense && "img-thumbnail"}
                        alt=""
                        width={80}
                        length={80}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <div className="img-thumbnail-photo">
                        <img
                          src={row.photo}
                          className={row.photo && "img-thumbnail"}
                          alt="profile-img"
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {search != "" && data.length == 0 ? (
            <div className="d-flex justify-content-center p-4">
              No se encontraron resultados para "{search}"
            </div>
          ) : data.length == 0 && search == "" ? (
            <div className="d-flex justify-content-center p-4">
              No existen registros aún.
            </div>
          ) : (
            ""
          )}
        </TableContainer>
        <TablesPagination setPage={setPage} page={page} total={total} />
      </Paper>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBD}
      >
        <span className="loader"></span>
      </Backdrop>
      <Modal open={openDelete}>
        <WarningReasonModal
          recordId={operativeId}
          action={"DeletePartner"}
          handleCancel={() => setOpenDelete(false)}
          text={`Estás a punto de desactivar al empleado con código ${operativeId}`}
        />
      </Modal>
      <Modal open={openSuccess}>
        <SuccessModal
          handleClose={() => {
            window.location.reload();
          }}
          text={successText}
        />
      </Modal>
      <Modal open={openError}>
        <ErrorModal
          text={errorMessage}
          handleClose={() => setOpenError(false)}
        />
      </Modal>
    </Box>
  );
};
