import React, { useState, useEffect, useRef } from "react";
import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";

export const Map = ({ center, zoom, children, onClick, style, ...options }) => {
  const mapRef = useRef();

  useEffect(() => {
    const map = mapRef.current;
    if (map && options.directions) {
      // Puedes implementar renderizado de direcciones usando Leaflet Routing Machine o cualquier otra librería de rutas.
      // Aquí tendrías la lógica para gestionar rutas, que Leaflet no trae por defecto.
    }
  }, [options.directions]);

  const MapEvents = () => {
    useMapEvents({
      click: (e) => {
        if (onClick) {
          onClick(e);
        }
      },
    });
    return null;
  };

  return (
    <MapContainer
      center={center}
      zoom={zoom}
      style={style}
      ref={mapRef}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <MapEvents />
      {children}
    </MapContainer>
  );
};
