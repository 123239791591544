import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { LoginAPI } from "../api/login";

export const useAuth = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [openBD, setOpenBD] = useState(false);
  const persistence =
    localStorage.getItem("token") != "null"
      ? localStorage.getItem("token")
      : null;
  const [token, setToken] = useState(persistence);
  const [errors, setErrors] = useState({
    wrongEmail: false,
    wrongPassword: false,
    nonAllowedUser: false,
  });

  const signIn = async (values) => {
    setIsLoading(true);

    let data = new FormData();
    data.append("email", values.email);
    data.append("password", values.password);

    try {
      const api = new LoginAPI();
      const response = await api.loginEP(data);

      if (response.status == 200) {
        console.log(response.data);
        localStorage.setItem("company_image", response.data.company_image)
        localStorage.setItem("company", response.data.company);
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("name", response.data.name);
        localStorage.setItem("clientCode", response.data.clientCode);
        localStorage.setItem("rol", response.data.rol);
        localStorage.setItem("companyName", response.data.companyName);

        switch (response.data.rol) {
          case 2:
            navigate("/admin/guides/active/");
            break;
          case 3:
            navigate("/user/guides/active/");
            break;
        }
        setIsLoading(false);
      }
      if (response.response.status == 404) {
        setErrors({ nonAllowedUser: true });
        setIsLoading(false);
      }
    } catch (error) {}
  };

  const signOut = async () => {
    setOpenBD(true);
    setIsLoading(true);

    let data = new FormData();

    try {
      const api = new LoginAPI();
      const response = await api.logoutEP(data);
      if (response.status == 200) {
        localStorage.removeItem("token");
        window.location.reload();

        setOpenBD(false);
        setIsLoading(false);
      }
    } catch (error) {}
  };

  return { token, setToken, signIn, errors, signOut, isLoading, openBD };
};
