import React from "react";
import { Typography, Box, useMediaQuery } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { AcceptButton } from "../Buttons/AcceptButton";
export const ErrorModal = ({ handleClose, text, error }) => {
  const phone = useMediaQuery("(max-width:600px)");
  const tablet = useMediaQuery("(max-width:900px)");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: phone ? "90%" : tablet ? "56%" : "35%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
    borderRadius: 2,
  };

  return (
    <Box sx={style}>
      <div className="text-center justify-content-center p-2">
        <Typography variant="h5" className="font-weight-bold p-2">
          {text}
        </Typography>
        <Typography>{error}</Typography>
      </div>
      <div className="d-flex justify-content-center mt-2">
        <Cancel sx={{ color: "#d60000", fontSize: 80 }} />
      </div>
      <div className="d-flex justify-content-center mt-2">
        <AcceptButton
          text={"Aceptar"}
          width={phone ? "90%" : "40%"}
          onClick={handleClose}
        />
      </div>
    </Box>
  );
};
