import React, { useEffect, useState } from "react";
import {
  Box,
  useMediaQuery,
  TextField,
  IconButton,
  MenuItem,
  Backdrop,
  Modal,
  Grid,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Skeleton,
  Tooltip,
} from "@mui/material";
import {
  AddCircleTwoTone,
  Close,
  RemoveCircleTwoTone,
} from "@mui/icons-material";
import { AdminAPI } from "../../api/admin";
import { SuccessModal } from "./SuccessModal";
import { ErrorModal } from "./ErrorModal";
import styled from "styled-components";
import { WarningModal } from "./WarningModal";

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  // padding: theme.spacing(1),
  // textAlign: 'center',
  // color: theme.palette.text.secondary,
}));

export const DetailsRoutesModal = ({ handleClose, text, route }) => {
  const phone = useMediaQuery("(max-width:480px)");
  const tablet = useMediaQuery("(max-width:900px)");
  const [guideInfo, setGuideInfo] = useState({
    id: "",
    trackingId: "",
  });

  const [routeInfo, setRouteInfo] = useState({
    id: "",
    comments: "",
  });

  const [guides, setGuides] = useState([]);
  const [guideId, setGuideId] = useState("");
  const [openBD, setOpenBD] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: phone ? "90%" : tablet ? "56%" : "65%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: "0px 0px 15px 0px",
    borderRadius: 2,
  };

  const handleChangeGuide = (e) => {
    setGuideId(e.target.value);
  };

  const handleAddGuide = () => {
    setOpenAdd(true);
  };

  const handleDeleteGuide = (track) => {
    setOpenDelete(true);
    setGuideInfo({
      id: track.id,
      trackingId: track.trackingId,
    });
  };

  useEffect(() => {
    GetListShipmentList();
  }, []);

  const GetListShipmentList = async () => {
    let data = new FormData();
    data.append("isActive", true);
    data.append("inRoute", false);

    try {
      const api = new AdminAPI();
      const response = await api.getShipments(data);
      setGuides(response.data.data);
      // console.log()
    } catch (error) {
      console.log(error);
    }
  };

  const AddGuide = async () => {
    setOpenBD(true);
    setOpenAdd(false);

    let data = new FormData();
    data.append("idShipment", guideId);
    data.append("idRoute", route.id);

    try {
      const api = new AdminAPI();
      const response = await api.addGuideToRoute(data);

      if (response.status == 200) {
        setOpenBD(false);
        setOpenSuccess(true);
      } else {
        setOpenError(true);
        setOpenBD(false);
        setErrorText();
      }
    } catch (error) {}
  };

  const UnpairGuide = async (guide) => {
    setOpenBD(true);
    setOpenDelete(false);
    let data = new FormData();
    const token = localStorage.getItem("token");
    data.append("id", guide);

    try {
      const api = new AdminAPI();
      const response = await api.removeShipmentFromRoute(data);

      if (response.status == 200) {
        setOpenSuccess(true);
      } else {
        setErrorText("Guía en ruta, no es posible eliminarla");
      }
      setOpenError(true);
      setOpenBD(false);
    } catch (error) {}
  };

  return (
    <Box sx={style}>
      <div className="w-100 px-3 mt-2">
        <div className="d-flex justify-content-between">
          <div></div>
          <p
            className={`font-weight-bold gray-font ml-2 ${phone ? "h5" : "h4"}`}
          >
            Guías asignadas
          </p>
          <IconButton onClick={handleClose}>
            <Close sx={{ fontSize: 30 }} />
          </IconButton>
        </div>

        <div className={`mt-2 d-flex mb-3 ${phone ? "px-2 " : "px-2"}`}>
          <div className={`d-flex align-items-center w-75`}>
            <div
              className={`mt-2 d-flex align-items-center mr-2 ${
                phone && "mb-2"
              }`}
            >
              <span
                style={{ whiteSpace: "nowrap" }}
                className="h6 te"
                htmlFor="operative"
              >{`Guías sin asignar: `}</span>
            </div>
            <div className="d-flex align-items-center w-100">
              <div className="d-flex  w-100">
                <TextField
                  select
                  size="small"
                  label={"Guías sin asignar"}
                  variant="outlined"
                  fullWidth
                  onChange={(e) => handleChangeGuide(e)}
                  value={guideId}
                  name={"guide"}
                  id={"guide"}
                >
                  {guides.length == 0 ? (
                    <MenuItem>Sin guías disponibles</MenuItem>
                  ) : (
                    guides.map((guide, i) => (
                      <MenuItem key={i} value={guide.id}>
                        <strong>Guía: </strong>
                        {guide.tracking_id}
                      </MenuItem>
                    ))
                  )}
                </TextField>
                <Tooltip title={"Agregar"} arrow placement="top">
                  <div className="d-flex align-items-center justify-content-center">
                    <IconButton
                      disabled={guideId == "" ? true : false}
                      onClick={() => handleAddGuide()}
                    >
                      <AddCircleTwoTone />
                    </IconButton>
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>

        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          rowSpacing={2}
          columns={{ xs: 6, sm: 6, md: 12, lg: 12 }}
        >
          <Grid item xs={6} sm={6} md={12} lg={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} className="">
                <Box className="mt-2 " sx={{ width: "100%" }}>
                  <Paper className="px-2" sx={{ width: "100%" }}>
                    <TableContainer sx={{ maxHeight: 400 }}>
                      <Table aria-label="sticky table" stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">
                              Número de rastreo
                            </TableCell>
                            <TableCell align="center">Estado</TableCell>
                            <TableCell align="center">Estatus</TableCell>
                            <TableCell align="center">Acciones</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {isLoading ? (
                            <TableRow hover role="checkbox" tabIndex={-1}>
                              <TableCell>
                                <Skeleton variant="text" />
                              </TableCell>
                              <TableCell align="center">
                                <Skeleton variant="text" />
                              </TableCell>
                              <TableCell align="center">
                                <Skeleton variant="text" />
                              </TableCell>
                              <TableCell align="center">
                                <Skeleton variant="text" />
                              </TableCell>
                            </TableRow>
                          ) : (
                            route.shipments.map((item, index) => {
                              const labelId = `enhanced-table-checkbox-${index}`;
                              return (
                                <TableRow hover role="checkbox" tabIndex={-1}>
                                  <TableCell
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                    padding="none"
                                    align="center"
                                  >
                                    {item.trackingId}
                                  </TableCell>
                                  <TableCell align="center">
                                    {item.active ? "Activa" : "Inactiva"}
                                  </TableCell>
                                  <TableCell align="center">
                                    {item.status}
                                  </TableCell>

                                  <TableCell padding="checkbox">
                                    <Tooltip
                                      title={"Eliminar"}
                                      arrow
                                      placement="top"
                                    >
                                      <div className="d-flex align-items-center justify-content-center">
                                        <IconButton
                                          onClick={() =>
                                            handleDeleteGuide(item)
                                          }
                                        >
                                          <RemoveCircleTwoTone />
                                        </IconButton>
                                      </div>
                                    </Tooltip>
                                  </TableCell>
                                </TableRow>
                              );
                            })
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBD}
      >
        <span className="loader"></span>
      </Backdrop>
      {/* Modals */}
      <Modal open={openAdd} onClose={() => console.log("Cerrando")}>
        <WarningModal
          handleCancel={() => setOpenAdd(false)}
          handleClose={() => AddGuide()}
          text={`Estas apunto de añadir la guía: ${
            guideId != "" && guides.find((el) => el.id == guideId).tracking_id
          } a la ruta con folio: ${route.id}.`}
        />
      </Modal>
      <Modal open={openDelete} onClose={() => console.log("Cerrando")}>
        <WarningModal
          handleCancel={() => setOpenDelete(false)}
          handleClose={() => UnpairGuide(guideInfo.id)}
          text={`Estas apunto de eliminar la guía con número: ${guideInfo.trackingId} de esta ruta.`}
        />
      </Modal>
      <Modal open={openSuccess} onClose={() => console.log("Cerrando")}>
        <SuccessModal
          handleClose={() => {
            handleClose();
            window.location.reload();
          }}
          text={"Ruta actualizada con éxito."}
        />
      </Modal>
      <Modal open={openError} onClose={() => console.log("Cerrando")}>
        <ErrorModal
          text={`No se pudo actualizar la ruta.`}
          error={errorText}
          handleClose={() => setOpenError(false)}
        />
      </Modal>
    </Box>
  );
};
