import {
  ForkRight,
  ListAlt,
  LocationOn,
  ManageAccounts,
  LocalShipping,
  Password,
  Settings,
  Add,
  AccountCircle,
  Pending,
  AddLocationAltOutlined
} from "@mui/icons-material";

export const menuUser = [
  {
    link: "/",
    name: "Guías",
    icon: <LocationOn sx={{ fontSize: 28 }} />,
    user: "user",
    items: [
      {
        link: "guides/active/",
        name: "Activas",
        icon: <Pending sx={{ fontSize: 28 }} />,
        user: "user",
      },
      {
        link: "guides/historical/",
        name: "Histórico",
        icon: <ListAlt sx={{ fontSize: 28 }} />,
        user: "user",
      },
    ],
  },
  {
    link: "account/",
    name: "Cuenta",
    icon: <Settings sx={{ fontSize: 28 }} />,
    user: "user",
    items: [],
  },
];

export const menuAdmin = [
  {
    link: "/",
    name: "Guías",
    icon: <LocationOn sx={{ fontSize: 28 }} />,
    user: "admin",
    items: [
      {
        link: "guides/active/",
        name: "Activas",
        icon: <Pending sx={{ fontSize: 28 }} />,
        user: "admin",
      },
      {
        link: "guides/active/add/",
        name: "Crear guía",
        icon: <Add sx={{ fontSize: 28 }} />,
        user: "admin",
      },
      {
        link: "guides/active/addOrigin/",
        name: "Crear origen",
        icon: <AddLocationAltOutlined sx={{ fontSize: 28 }} />,
        user: "admin",
      },

      {
        link: "guides/routes/",
        name: "Rutas",
        icon: <ForkRight sx={{ fontSize: 28 }} />,
        user: "admin",
      },
      {
        link: "guides/historical/",
        name: "Histórico",
        icon: <ListAlt sx={{ fontSize: 28 }} />,
        user: "admin",
      },
    ],
  },
  {
    link: "operatives/",
    name: "Operadores",
    icon: <AccountCircle sx={{ fontSize: 28 }} />,
    user: "admin",
    items: [],
  },
  {
    link: "vehicles/",
    name: "Unidades",
    icon: <LocalShipping sx={{ fontSize: 28 }} />,
    user: "admin",
    items: [],
  },
  {
    link: "/",
    name: "Cuenta",
    icon: <Settings sx={{ fontSize: 28 }} />,
    user: "admin",
    items: [
      {
        link: "account/profile/",
        name: "Perfil",
        icon: <ManageAccounts sx={{ fontSize: 28 }} />,
        user: "admin",
      },
      {
        link: "account/password/",
        name: "Contraseña",
        icon: <Password sx={{ fontSize: 28 }} />,
        user: "admin",
      },
    ],
  },
];
