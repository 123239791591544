import React, { useState } from "react";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  Typography,
  Box,
  useMediaQuery,
  TextField,
  Backdrop,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker, TimePicker } from "@mui/x-date-pickers";
import { AcceptButton } from "../Buttons/AcceptButton";
import { useNavigate } from "react-router-dom";
import { CancelButton } from "../Buttons/CancelButton";
import { AdminAPI } from "../../api/admin";
import { SuccessModal } from "./SuccessModal";
import { ErrorModal } from "./ErrorModal";
import { Person, LocalShipping } from "@mui/icons-material";
import { getDateCleaned } from "../../helpers/getDateCleaned";
import { getDateCleaned2 } from "../../helpers/getDateCleaned";
import { useFormik } from "formik";
import { AvailableVehiclesModal } from "./AvailableVehiclesModal";
import * as Yup from "yup";

export const CreateRouteConfirmationModal = ({
  handleCancel,
  routeInfo,
  operatives,
}) => {
  const phone = useMediaQuery("(max-width:480px)");
  const tablet = useMediaQuery("(max-width:900px)");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: phone ? "90%" : tablet ? "56%" : "60%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
    borderRadius: 2,
  };

  // Expected start date and hour states
  const [expectedStartDate, setExpectedStartDate] = useState(null);
  const [expectedStartHour, setExpectedStartHour] = useState(null);

  const [availableUnits, setAvailableUnits] = useState([]);

  // Backdrop states
  const [openBD, setOpenBD] = useState(false);

  // Success states
  const [openSuccess, setOpenSuccess] = useState(false);
  const [successText, setSuccessText] = useState("");

  // Error states
  const [openError, setOpenError] = useState(false);
  const [errorText, setErrorText] = useState("");

  const [openAvailableVehiclesModal, setOpenAvailableVehiclesModal] =
    useState(false);

  const [vehicleInfo, setVehicleInfo] = useState({
    model: operatives.find((el) => el.id === routeInfo.operative).assigned_unit
      .model,
    brand: operatives.find((el) => el.id === routeInfo.operative).assigned_unit
      .brand,
    licensePlate: operatives.find((el) => el.id === routeInfo.operative)
      .assigned_unit.licensePlate,
    capacity: operatives.find((el) => el.id === routeInfo.operative)
      .assigned_unit.capacity,
    img_source: operatives.find((el) => el.id === routeInfo.operative)
      .assigned_unit.carImage,
  });

  const today = new Date();

  const navigate = useNavigate();

  const handleChangeDate = (value) => {
    setExpectedStartDate(getDateCleaned(value));
    formik.setFieldValue("expected_start_date", value);
  };

  const handleChangeHour = (value) => {
    setExpectedStartHour(value);
    formik.setFieldValue("expected_start_hour", value);
  };

  console.log(expectedStartHour);

  //2023-10-21 11:32:03.688634

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  // Requests
  const createRoute = async () => {
    setOpenBD(true);
    const auxShipments = routeInfo.shipments.map((item, index) => {
      /*Prevent duplicate id's */
      return item.id;
    });
    let data = new FormData();

    const startDate = expectedStartDate.split("/");

    const formattedStartDay = `${startDate[0]}-${startDate[1]}-${startDate[2]} ${expectedStartHour.$H}:${expectedStartHour.$m}:${expectedStartHour.$s}`;

    data.append("expected_start_date", formattedStartDay);
    data.append("shipments", auxShipments.join(","));
    data.append("driver_vehicle", routeInfo.operative);

    // console.log("FORMDATA");
    // for (var pair of data.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }

    try {
      const api = new AdminAPI();
      const response = await api.createRoute(data);

      if (response.status == 200) {
        setOpenBD(false);
        setOpenSuccess(true);
      } else {
        setOpenBD(false);
        setErrorText(
          "No se pudo crear la ruta" + String(response.response.data[0])
        );
        setOpenError(true);
        setOpenBD(false);
      }
    } catch (error) {}
  };

  const showAvailableVehicles = async () => {
    setOpenAvailableVehiclesModal(true);
  };

  const ChangePartnerVehicle = async (unit) => {
    setOpenBD(true);

    let data = new FormData();
    data.append(
      "partnerId",
      operatives.find((el) => el.id === routeInfo.operative).operator.partnerId
    );
    data.append("vehicleId", unit.idVehicle);

    try {
      const api = new AdminAPI();
      const response = await api.updatePartnerVehicle(data);

      if (response.status == 200) {
        setOpenBD(false);
        setVehicleInfo({
          model: unit.model,
          brand: unit.brand,
          licensePlate: unit.licensePlate,
          capacity: unit.capacity,
          img_source: unit.carImage,
        });
        document.getElementById("vehicles-table").classList.add("d-none");
      } else {
        setOpenBD(false);
        setErrorText("No se pudo asignar la unidad");
        setOpenError(true);
      }
    } catch (error) {}
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      expected_start_date: "",
      expected_start_hour: "",
    },

    validationSchema: Yup.object({
      expected_start_date: Yup.date().required("Por favor completa este campo"),
      expected_start_hour: Yup.date().required("Por favor completa este campo"),
    }),

    onSubmit: (values) => {
      const data = {
        ...values,
      };
      createRoute(data);
    },
  });

  return (
    <>
      <Box sx={style}>
        <form className="w-100" onSubmit={formik.handleSubmit}>
          <h4 className="font-weight-bold">Información de la ruta</h4>
          <div className="d-flex justify-content-center my-3">
            <div className="w-30 mr-2">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  minDate={today}
                  label={"Fecha inicio recolección"}
                  inputFormat="dd/MM/yyyy"
                  value={expectedStartDate}
                  onChange={handleChangeDate}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={
                        formik.touched.expected_start_date &&
                        formik.errors.expected_start_date
                      }
                      helperText={
                        formik.touched.expected_start_date &&
                        formik.errors.expected_start_date ? (
                          <div>{formik.errors.expected_start_date}</div>
                        ) : null
                      }
                      onKeyDown={onKeyDown}
                      fullWidth
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="w-30">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  minTime={dayjs("2023-01-01T07:00")}
                  maxTime={dayjs("2023-01-01T18:00")}
                  label="Hora inicio recolección"
                  value={expectedStartHour}
                  onChange={handleChangeHour}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={
                        formik.touched.expected_start_hour &&
                        formik.errors.expected_start_hour
                      }
                      helperText={
                        formik.touched.expected_start_hour &&
                        formik.errors.expected_start_hour ? (
                          <div>{formik.errors.expected_start_hour}</div>
                        ) : null
                      }
                      onKeyDown={onKeyDown}
                      fullWidth
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col">
              <div className="row">
                <div className="col-md-auto pr-0">
                  <Person className="d-block" />
                  <LocalShipping className="d-block" />
                </div>
                <div className="col pl-2">
                  <Typography>
                    {" "}
                    {
                      operatives.find((el) => el.id === routeInfo.operative)
                        .operator.name
                    }{" "}
                    {
                      operatives.find((el) => el.id === routeInfo.operative)
                        .operator.lastName
                    }
                  </Typography>
                  <Typography>{vehicleInfo.typeVehicle}</Typography>
                  <Typography>{vehicleInfo.brand}</Typography>
                  <Typography>{vehicleInfo.model}</Typography>
                  <Typography>{vehicleInfo.licensePlate}</Typography>
                  <Typography>{vehicleInfo.capacity}</Typography>
                </div>
                {/* <div className="col-md-auto d-flex align-items-center">
                  <Tooltip title="Elegir otra unidad disponible">
                    <IconButton
                      onClick={() => showAvailableVehicles()}
                      aria-label="delete"
                      size="large"
                    >
                      <Cached fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </div> */}
              </div>
            </div>
            <div className="col d-flex align-items-center" align="center">
              <img src={vehicleInfo.img_source} alt="profile-img" width={200} />
            </div>
          </div>
          <TableContainer
            component={Paper}
            style={{ overflowY: "scroll", whiteSpace: "nowrap", height: 190 }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Guía</TableCell>
                  <TableCell align="center">Peso</TableCell>
                  <TableCell align="center">Volumen</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {routeInfo.shipments.map(
                  (
                    el,
                    i /*map the array returned by the function to show each tracking number*/
                  ) => (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="center" component="th" scope="row">
                        {el.tracking_id}
                      </TableCell>
                      <TableCell align="center">{el.total_weight}</TableCell>
                      <TableCell align="center">{el.total_volume}</TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <div className="d-flex justify-content-around mt-2">
            <CancelButton
              text={"Cancelar"}
              width={phone ? "90%" : "40%"}
              onClick={handleCancel}
            />
            <AcceptButton
              text={"Continuar"}
              type={"submit"}
              width={phone ? "90%" : "40%"}
            />
          </div>
        </form>
      </Box>
      {/* Backdrop */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBD}
      >
        <span className="loader"></span>
      </Backdrop>
      {/* Modals */}
      <Modal open={openSuccess}>
        <SuccessModal
          handleClose={() => navigate("/admin/guides/routes/")}
          text={"Ruta creada con éxito."}
        />
      </Modal>
      <Modal open={openError}>
        <ErrorModal text={errorText} handleClose={() => setOpenError(false)} />
      </Modal>
      <Modal open={openAvailableVehiclesModal}>
        <AvailableVehiclesModal
          data={availableUnits}
          operatives={operatives}
          routeInfo={routeInfo}
          setVehicleInfo={setVehicleInfo}
          handleClose={() => setOpenAvailableVehiclesModal(false)}
        />
      </Modal>
    </>
  );
};

//const [anchorEl, setAnchorEl] = useState(null);
// const handleClick = (event) => {
//   setAnchorEl(event.currentTarget);
// };
//console.log("operatives", operatives);
//console.log("Collect hour", colectHour, "COLLECT DATE", colectDate);
//console.log("routeInfo", routeInfo);

// function getName() {
//   console.log("ROUTES", routes);
//   console.log("ROUTE INFO", obj);

//   const arr = [];
//   for (let i = 0; i < obj.shipments.length; i++) {
//     const e = obj.shipments[i];
//     arr.push(routes.find((el) => el.id== e.id));
//   }
//   console.log("ARR", arr);
//   return arr; /*Compare the id of each guide to find the tracking number and return an array with each one */
// }

//   const getRouteInfo = obj.shipments.filter((item, index) => {
//     /*Prevent duplicate id's */
//     return obj.shipments.indexOf(item) === index;
//   });
